/* custom non-standard colors goes here */

export const Colors = {
  black: '#212529',
  primaryColor: '#003b4c',
  secondaryColor: '#00A3D6',
  lightGray: '#D8D8D8',
  gray: '#757575',
  inputBorder: '#A6ACB5',
  text1: '#43454A',

  tableDiff: {
    odd: {
      color: '#ffffff80',
      contrastText: '#000',
    },
    even: {
      color: '#9e9e9e80',
      contrastText: '#000',
    }
  },

  appBar: {
    light: '#FF6745',
    main: '#EF4C28',
  },
  green: {
    light: '#8bc34a',
    main: '#00A368',
  },
  blue: {
    light: '#438fff',
    main: '#4285f4',
  },
  event: {
    color: '#ab47bc',
    contrastText: '#fff',
    borderColor: '#56245e',
  },
  schedule: {
    color: '#a1887f',
    contrastText:  '#fff',
  },
  requestDonationAdd: {
    color: '#85a17f',
    contrastText:  '#fff',
  },
  pendingFoodDonor: {
    color: '#207075',
    contrastText:  '#fff',
  },
  switchSites: {
    color: '#14a0a9',
    contrastText:  '#fff',
  },
  requestDonationList: {
    color: '#807fa1',
    contrastText:  '#fff',
  },
  idMode: {
    color: "#ea9999",
    contrastText:  '#fff',
  },
  rescues: {
    today: {
      color: '#078DC7',
    },
    upcoming: {
      color: '#E91E63',
    },
    recent: {
      color: '#D6BA74',
    },
    closed: {
      color: '#3d7100',
      contrastText: '#fff',
      borderColor: '#1e3800',
    },
    unclaimed: {
      color: '#ec9a29',
      contrastText: '#fff',
      borderColor: '#764d14',
    },
    claimed: {
      color: '#8bc34a',
      contrastText: '#fff',
      borderColor: '#466225',
    },
    cancelled: {
      color: '#EF4C28',
      contrastText: '#fff',
      borderColor: '#782614',
    },
    noReceiver: {
      color: '#2196f3',
      contrastText: '#fff',
    },
  },
  donation: {
    pickups: {
      claimed: {
        color: '#ea80fc',
        contrastText: '#fff',
      },
      notClaimed: {
        color: '#9cffff',
        contrastText: '#000',
      },
      deleted: {
        color: '#EF4C28',
        contrastText: '#fff',
      },
      modified: {
        color: '#ffc107',
        contrastText: '#474747',
      },
      new: {
        color: '#89ff00',
        contrastText: '#474747',
      },
      nonModified: {
        color: '#9e9e9e',
        contrastText: '#fff',
      }
    }
  },
  environment: {
    color: "#00A368",
  },
  goals: {
    color: '#efb62b',
  },
  badges: {
    color: 'rgb(7,93,199)',
  },
  sites: {
    disabled: {
      color: '#EF4C28',
    },
  },
  bulkEdit: {
    highlight: '#B1D2F9',
    light: '#CADFF9',
    main: '#4285f4',
  },
  marketingHub: {
    facebook: {
      main: '#0B8AF0',
      light: '#14A4FB',
      calendar: {
        main: 'rgba(0, 163, 214, 0.2)',
        light: 'rgba(255, 255, 255, 0.5)',
      },
    },
    instagram: {
      main: '#DC148F',
      light: '#F6AB47',
      calendar: {
        main: 'rgba(246, 171, 71, 0.2)',
        light: 'rgba(255, 255, 255, 0.5)',
      }
    },
  },
  donorLink: {
    color: '#0395ce',
  },
  white: '#fff',
  red: '#f44336',
  errorText: '#f44336', // red[500]
  errorTextLight: '#e57373', // red[300]
};
