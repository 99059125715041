import React, { useState, useEffect } from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { get } from 'lodash';
import { Button, CardContent, Grid, Breadcrumbs, Box, makeStyles, Typography } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { BaseCard } from '../../components/Common/BaseCard';
import { GridRow, RowContent, RowTitle } from '../Rescue/RescueEdit/FormGrid';
import { formatAddress, formatDateTime, formatPhoneNumber } from '../../helpers/formatters';
import RegistrationDeclineDialog from './RegistrationDeclineDialog';
import routes from '../../routes';
import RegistrationApproveDialog from './RegistrationApproveDialog';
import { fetchFoodDonor } from '../../actions/foodDonors';
import { approveFoodDonorRequest, declineFoodDonorRequest } from '../../api/foodDonors';
import { fetchSiteDonorsLocationsIfNeeded } from '../../actions/sites';
import { fetchDonorsIfNeeded } from '../../actions/donors';
import useActiveSite from '../../hooks/useActiveSite';
import useNotificationService from '../../hooks/useNotificationService';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import DonorLinkIcon from '../../components/DonorLinkIcon';
import { Colors } from '../../assets/theme/Colors';
import useHasActiveFeatureFlag from '../../hooks/useFeatureFlags';
import { FF_DONOR_LINK } from '../../components/FeatureFlagWrapper';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  donorLink: {
    position: 'absolute',
    top: 20,
    right: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  donorLinkText: {
    color: Colors.donorLink.color,
  }
}));

const FoodDonorRegistrationRequest = ({ history, match, isMobileView }) => {
  const classes = useStyles();
  const { params } = match;
  const foodDonorId = params.foodDonorId;
  const dispatch = useDispatch();
  const umbrellaFoodDonors = useSelector(state => state.entities.donors);
  const umbrellaFoodDonorsList = umbrellaFoodDonors.allIds.map(
    umbrellaFoodDonorId => umbrellaFoodDonors.byId[umbrellaFoodDonorId]
  );
  const foodDonors = useSelector(state => state.entities.foodDonors.byId);
  const foodDonor = foodDonors[foodDonorId];
  const activeSite = useActiveSite();
  const { addErrorNotification } = useNotificationService();
  const [showDialog, setShowDialog] = useState(undefined);
  const hasDonorLinkFF = useHasActiveFeatureFlag(FF_DONOR_LINK);

  useEffect(() => {
    dispatch(fetchSiteDonorsLocationsIfNeeded(activeSite.id));
    dispatch(fetchFoodDonor(foodDonorId));
    dispatch(fetchDonorsIfNeeded(foodDonorId));
  }, [dispatch]);

  const handleDecline = async reason => {
    try {
      await declineFoodDonorRequest(foodDonorId, reason);
      const redirectUrl = isMobileView
        ? generatePath(routes.pendingFoodDonorsRegistrations)
        : generatePath(routes.dashboardSC);
      history.push(redirectUrl);
    } catch (error) {
      setShowDialog(false);
      addErrorNotification(error, 'Unable to decline food donor');
    }
  };

  const handleApprove = async values => {
    const other = values.other;
    const fd = values.food_donor;

    try {
      await approveFoodDonorRequest(foodDonorId, {
        parentFoodDonorId: fd.id !== -1 ? fd.id : null,
        other: other,
      });
      const redirectUrl = isMobileView
        ? generatePath(routes.pendingFoodDonorsRegistrations)
        : generatePath(routes.dashboardSC);
      history.push(redirectUrl);
    } catch (error) {
      setShowDialog(false);
      addErrorNotification(error, 'Unable to approve food donor');
    }
  };

  if (!foodDonor) {
    return 'loading...';
  }

  return (
    <>
      {isMobileView && (
        <Breadcrumbs aria-label="breadcrumb">
          <StyledRescuerBreadcrumb
            component={RouterLink}
            to={generatePath(routes.mobileDashboard)}
            label="Home"
            icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
          />
          <StyledRescuerTextBreadcrumb
            component={RouterLink}
            to={routes.pendingFoodDonorsRegistrations}
            label="Pending Food Donors"
          />
          <StyledRescuerTextBreadcrumb component="a" href="#" label="Verify" />
        </Breadcrumbs>
      )}
      <Grid container>
        <Grid item container md={12} lg={6} spacing={3}>
          <Grid item xs={12}>
            <BaseCard className={classes.container}>
              <CardContent>
                <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                  <GridRow>
                    <RowTitle>Registration Date:</RowTitle>
                    <RowContent>{formatDateTime(foodDonor.created_at.value)}</RowContent>
                  </GridRow>

                  <GridRow>
                    <RowTitle>Food Donor Location Name:</RowTitle>
                    <RowContent>{foodDonor.name}</RowContent>
                  </GridRow>

                  <GridRow>
                    <RowTitle>Address:</RowTitle>
                    <RowContent>
                      {formatAddress({
                        address: get(foodDonor, 'business_address.address', ''),
                        city: get(foodDonor, 'business_address.city', ''),
                        zip: get(foodDonor, 'business_address.zip_code', ''),
                        st: get(foodDonor, 'business_address.state', ''),
                      })}
                    </RowContent>
                  </GridRow>

                  {foodDonor.store_id && (
                    <GridRow>
                      <RowTitle>Store # or ID:</RowTitle>
                      <RowContent>{foodDonor.store_id}</RowContent>
                    </GridRow>
                  )}

                  <GridRow>
                    <RowTitle>Name:</RowTitle>
                    <RowContent>
                      {get(foodDonor, 'business_address.user.firstname', '') &&
                      get(foodDonor, 'business_address.user.lastname', '')
                        ? `${get(foodDonor, 'business_address.user.firstname', '')} ${get(
                            foodDonor,
                            'business_address.user.lastname',
                            ''
                          )}`
                        : 'N/A'}
                    </RowContent>
                  </GridRow>

                  <GridRow>
                    <RowTitle>Title:</RowTitle>
                    <RowContent>{foodDonor.title || 'N/A'}</RowContent>
                  </GridRow>

                  <GridRow>
                    <RowTitle>E-mail:</RowTitle>

                    <RowContent>
                      <a href={`mailto:${get(foodDonor, 'business_address.user.email', '')}`}>
                        {get(foodDonor, 'business_address.user.email', '')}
                      </a>
                    </RowContent>
                  </GridRow>

                  <GridRow>
                    <RowTitle>Contact Phone#:</RowTitle>

                    {get(foodDonor, 'business_address.user') && (
                      <RowContent>
                        <span>Phone: </span>
                        <a href={`tel:${get(foodDonor, 'business_address.user.phone', '')}`}>
                          {formatPhoneNumber(get(foodDonor, 'business_address.user.phone', ''))}
                        </a>
                        {get(foodDonor, 'business_address.user.phone_ext', '') && (
                          <span>{` (ext: ${get(foodDonor, 'business_address.user.phone_ext', '')})`}</span>
                        )}
                      </RowContent>
                    )}
                  </GridRow>
                </Grid>
              </CardContent>
              {hasDonorLinkFF && foodDonor.donor_link && (
                <Box className={classes.donorLink}>
                  <DonorLinkIcon size={36} />
                  <Typography variant="subtitle1" className={classes.donorLinkText}>
                    Donor Link
                  </Typography>
                </Box>
              )}
            </BaseCard>
          </Grid>

          <Grid item container xs={12} spacing={3}>
            {!!foodDonor?.donor_link ? (
              <Grid item>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    history.push(generatePath(routes.foodDonor, { foodDonorId: foodDonorId, tab: 'profile' }))
                  }
                >
                  Continue
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button type="button" variant="contained" color="primary" onClick={() => setShowDialog('approve')}>
                  Approve
                </Button>
              </Grid>
            )}

            <Grid item>
              <Button type="button" variant="contained" color="primary" onClick={() => setShowDialog('decline')}>
                Decline
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <RegistrationDeclineDialog
        isOpen={showDialog === 'decline'}
        handleCancel={() => setShowDialog(undefined)}
        onClose={() => setShowDialog(undefined)}
        handleDecline={handleDecline}
      />
      <RegistrationApproveDialog
        foodDonor={foodDonor}
        isOpen={showDialog === 'approve'}
        handleCancel={() => setShowDialog(undefined)}
        onClose={() => setShowDialog(undefined)}
        handleSave={handleApprove}
        umbrellaFoodDonors={umbrellaFoodDonorsList}
      />
    </>
  );
};

export default FoodDonorRegistrationRequest;
