import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Checkbox, FormControlLabel, makeStyles, Typography } from '@material-ui/core';
import ReactSelect from 'react-select';
import { ExpandMore } from '@material-ui/icons';
import { getChromaColor } from '../../../helpers/colors';

const colourStyles = {
  menu: provided => ({
    ...provided,
    zIndex: 101,
  }),
  container: provided => ({
    ...provided,
    maxWidth: 400,
  }),
  valueContainer: styles => ({ ...styles, minHeight: 50, minWidth: 200 }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: getChromaColor(data.color).backgroundColor,
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: getChromaColor(data.color).color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};

const useStyles = makeStyles(() => ({
  accordion: {
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  summary: {
    position: 'relative',
    fontWeight: 'bold',
    height: '40px',
    minHeight: '40px',
    '&.Mui-expanded': {
      minHeight: '50px',
    },
  },
  badge: {
    position: 'absolute',
    right: 50,
    top: (props) => (props.expanded ? 25 : 20),
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}));

const MapSiteSelector = ({
  selectedSite,
  handleSiteChange,
  isSiteAreaVisible,
  setSiteAreaVisibility,
  setSiteAreaStrokeVisibility,
  isSiteAreaStrokeVisible,
  onAccordionChange,
  expanded = false,
  isLoading = false,
  sitesList = [],
  badgeCounter = 0,
}) => {
  const classes = useStyles({ expanded });

  return (
    <Accordion className={classes.accordion} elevation={0} onChange={onAccordionChange} expanded={expanded}>
      <AccordionSummary className={classes.summary} expandIcon={<ExpandMore />}>
        Sites
        <Badge className={classes.badge} badgeContent={badgeCounter} color="error" />
      </AccordionSummary>
      <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
        {sitesList.length > 1 && (
          <Box>
            <ReactSelect
              styles={colourStyles}
              isDisabled={isLoading}
              placeholder="Select site(s) or leave blank to show data for all sites"
              value={selectedSite}
              options={sitesList}
              isMulti
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              onChange={handleSiteChange}
            />
          </Box>
        )}
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disabled={isLoading}
                checked={isSiteAreaVisible}
                onChange={() => {
                  setSiteAreaVisibility(!isSiteAreaVisible);
                  setSiteAreaStrokeVisibility(!isSiteAreaStrokeVisible);
                }}
                name="area_site"
              />
            }
            label="Show site area"
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default MapSiteSelector;
