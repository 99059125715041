import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useActiveUser from '../../../../../hooks/useActiveUser';
import {
  List,
  ListItem,
  ListSubheader,
  makeStyles,
  Box,
  Avatar,
  CardContent,
} from '@material-ui/core';
import { get } from 'lodash';
import RecentRescue from '../../../../../components/RescueNotification/RecentRescue';
import UpcomingRescue from '../../../../../components/RescueNotification/UpcomingRescue';
import routes from '../../../../../routes';
import RescueMobileCard from '../../../../Rescue/RescueMobileCard';
import useRescuerDashboardActions from '../../../../../hooks/useRescuerDashboardActions';
import { NoRescueCard, RESCUER_MOBILE_DASHBOARD_TAB_MY_RESCUES } from '../RescuerRescuesDashboardMobileContainer';
import {
  getRecentRescues,
  getTodayRescues,
  getUpcomingRescues,
} from '../../../../../helpers/RescuesHelper';
import { BaseCard } from '../../../../../components/Common/BaseCard';
import { Today as TodayIcon, History as HistoryIcon, Update as UpdateIcon } from '@material-ui/icons';
import { Colors } from '../../../../../assets/theme/Colors';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  listSubheader: {
    ...typography['h5'],
    top: -1,
    padding: spacing(1),
    paddingBottom: 0,
    borderRadius: spacing(4),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  listSubItem: {
    display: 'block',
    padding: spacing(1),
  },
  ul: {
    marginBottom: spacing(3),
    backgroundColor: 'inherit',
    padding: 0,
  },
  icon: {
    borderRadius: 12,
    width: 50,
    height: 50,
  },
  green: {
    backgroundColor: Colors.rescues.unclaimed.color,
  },
  today: {
    backgroundColor: Colors.rescues.today.color,
    filter: 'brightness(0.9)',
  },
  recent: {
    backgroundColor: Colors.rescues.recent.color,
    filter: 'brightness(0.9)',
  },
  upcoming: {
    backgroundColor: Colors.rescues.claimed.color,
    filter: 'brightness(0.9)',
  },
  event: {
    backgroundColor: Colors.event.color,
    filter: 'brightness(0.9)',
  },
  photos: {
    backgroundColor: '#5ab53f',
    filter: 'brightness(0.9)',
  },
  random: {
    backgroundColor: '#3fa7b5',
    filter: 'brightness(0.9)',
  },
}));

const StyledBaseCard = withStyles({
  root: ({ color }) => ({
    height: '100%',
    borderRadius: 16,
    backgroundColor: color,
  }),
})(BaseCard);

export const StyledSubHeader = ({ text, subText, icon, component = 'div', to = undefined, color }) => (
  <StyledBaseCard color={color} className="subheader">
    <CardContent style={{
      paddingTop: 8,
      paddingBottom: 8,
    }}>
      <Box
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          textDecoration: 'none',
        }}
        component={component}
        to={to}
      >
        <Box display="flex" flexDirection="column" alignItems="start" gridRowGap={4} pr={1}>
          {text && (
            <Box fontFamily="fontFamily" fontSize="h5.fontSize" color="#fff">
              {text}
            </Box>
          )}
          {subText && (
            <Box className="subtext" fontFamily="fontFamily" fontSize="caption.fontSize" color="#fff">
              {subText}
            </Box>
          )}
        </Box>
        {icon}
      </Box>
    </CardContent>
  </StyledBaseCard>
);

const MyFrusMobile = ({ history }) => {
  const classes = useStyles();
  const scrollContainer = useRef();
  const dispatch = useDispatch();
  const activeUser = useActiveUser();
  const { fetchMyFrusTab } = useRescuerDashboardActions();
  const { formatMessage } = useIntl();

  const rescuesEntities = useSelector((state) => state.entities.rescues);
  const isLoading = get(rescuesEntities, `claimedAndAdoptedIds[${activeUser.id}].inflight`, true);
  const isLoadingRecentRescues = get(rescuesEntities, `pastIds[${activeUser.id}].inflight`, true);
  const todayRescues = getTodayRescues(activeUser.id, rescuesEntities);
  const recentRescues = getRecentRescues(activeUser.id, rescuesEntities);
  const upcomingRescues = getUpcomingRescues(activeUser.id, rescuesEntities);

  const onNotificationClick = (rescue) => history.push(generatePath(routes.rescue, { rescueId: rescue.id }));

  useEffect(() => {
    fetchMyFrusTab(false, false);
  }, [activeUser.id, dispatch]);

  return (
    <List className={classes.container} subheader={<li />} ref={scrollContainer}>
      <li className={classes.listSection}>
        <ul className={classes.ul}>
          <ListSubheader className={classes.listSubheader}>
            <StyledSubHeader
              color={Colors.rescues.today.color}
              text={formatMessage({ id: 'rescuer-dashboard-mobile.my-frus.today-rescues' })}
              icon={
                <Avatar className={classNames(classes.icon, classes.today)}>
                  <TodayIcon />
                </Avatar>
              }
            />
          </ListSubheader>
          {isLoading && (
            <ListItem key={`rescue-today-loader}`} className={classes.listSubItem}>
              <RescueMobileCard highlighted isLoading />
            </ListItem>
          )}
          {!isLoading && todayRescues.length === 0 && (
            <ListItem className={classes.listSubItem}>
              <NoRescueCard text="You have no rescues today." />
            </ListItem>
          )}

          {!isLoading &&
            todayRescues.map((rescue) => (
              <ListItem key={`rescue-today-${rescue.id}`} className={classes.listSubItem}>
                <RecentRescue rescue={rescue} onClick={() => onNotificationClick(rescue)} />
              </ListItem>
            ))}
        </ul>
      </li>

      <li className={classes.listSection}>
        <ul className={classes.ul}>
          <ListSubheader id="recent-rescues" className={classes.listSubheader}>
            <StyledSubHeader
              color={Colors.rescues.recent.color}
              text={formatMessage({ id: 'rescuer-dashboard-mobile.my-frus.recent-rescues' })}
              subText={formatMessage({ id: 'rescuer-dashboard-mobile.my-frus.recent-rescues-sub' })}
              icon={
                <Avatar className={classNames(classes.icon, classes.recent)}>
                  <HistoryIcon />
                </Avatar>
              }
            />
          </ListSubheader>
          {isLoadingRecentRescues && (
            <ListItem key={`rescue-today-loader}`} className={classes.listSubItem}>
              <RescueMobileCard highlighted isLoading />
            </ListItem>
          )}
          {!isLoadingRecentRescues && recentRescues.length === 0 && (
            <ListItem className={classes.listSubItem}>
              <NoRescueCard text="You have no recent rescues." />
            </ListItem>
          )}

          {!isLoadingRecentRescues &&
            recentRescues.map((rescue) => (
              <ListItem key={`rescue-recent-${rescue.id}`} className={classes.listSubItem}>
                <RecentRescue rescue={rescue} onClick={() => onNotificationClick(rescue)} />
              </ListItem>
            ))}
        </ul>
      </li>

      <li className={classes.listSection}>
        <ul className={classes.ul}>
          <ListSubheader className={classes.listSubheader}>
            <StyledSubHeader
              color={Colors.rescues.claimed.color}
              component={RouterLink}
              to={generatePath(routes.mobileDashboardRescues, { tab: RESCUER_MOBILE_DASHBOARD_TAB_MY_RESCUES })}
              text={formatMessage({ id: 'rescuer-dashboard-mobile.my-frus.upcoming-rescues' })}
              subText={formatMessage({ id: 'rescuer-dashboard-mobile.my-frus.upcoming-rescues-sub' })}
              icon={
                <Avatar className={classNames(classes.icon, classes.upcoming)}>
                  <UpdateIcon />
                </Avatar>
              }
            />
          </ListSubheader>
          {isLoading && (
            <ListItem key={`rescue-upcoming-loader}`} className={classes.listSubItem}>
              <RescueMobileCard highlighted isLoading />
            </ListItem>
          )}
          {upcomingRescues.length === 0 && !isLoading && (
            <ListItem key={`rescue-upcoming-nodata`} className={classes.listSubItem}>
              <NoRescueCard text="You have no upcoming rescues." />
            </ListItem>
          )}

          {!isLoading &&
            upcomingRescues.map((rescue) => (
              <ListItem key={`rescue-upcoming-${rescue.id}`} className={classes.listSubItem}>
                <UpcomingRescue
                  key={rescue.id}
                  user={activeUser}
                  rescue={rescue}
                  onClick={() => onNotificationClick(rescue)}
                />
              </ListItem>
            ))}
        </ul>
      </li>
    </List>
  );
};

export default MyFrusMobile;
