import React from 'react';
import { useRouteMatch, Link, generatePath, Route, Switch, useHistory } from 'react-router-dom';
import { Box, Typography, makeStyles } from '@material-ui/core';
import routes from '../routes';
import { Roles } from '../models/roles';
import { currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite } from '../services/auth';
import { Colors } from '../assets/theme/Colors';
import DonationsListView from './DonationsListView';
import RescuesScheduleView from './RescuesScheduleView';
import PastRescuesListView from './PastRescuesListView';
import FutureRescuesListView from './FutureRescuesListView';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: Colors.gray,
    paddingLeft: 10,
    '&.active': {
      color: theme.palette.primary.main,
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        left: -3,
        top: 0,
        height: '100%',
        borderLeft: '4px solid',
        borderColor: theme.palette.primary.main,
      },
    },
  },
  container: {
    width: 'calc(100% - 170px)',
  },
}));

const rescuesList = [
  {
    name: 'Rescues',
    component: DonationsListView,
    path: generatePath(routes.rescuesList, { tab: 'rescues' }),
    tab: 'rescues',
  },
  {
    name: 'Schedule',
    component: RescuesScheduleView,
    path: generatePath(routes.rescuesList, { tab: 'schedule' }),
    tab: 'schedule',
  },
  {
    name: 'Past Rescues',
    component: PastRescuesListView,
    path: generatePath(routes.rescuesList, { tab: 'past-rescues' }),
    tab: 'past-rescues',
  },
  {
    name: 'Upcoming Rescues',
    component: FutureRescuesListView,
    path: generatePath(routes.rescuesList, { tab: 'upcoming-rescues' }),
    tab: 'upcoming-rescues',
  },
];

const RescuesView = () => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const history = useHistory();

  const userHasAcess = currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
    Roles.Admin,
    Roles.NationalSiteDirector,
    Roles.SiteDirector,
    Roles.SiteCoordinator,
  ]);

  const paths = rescuesList.map((rescue) => rescue.path);

  const getActiveTab = () => {
    const path = paths.indexOf(url);

    return Math.max(path, 0);
  };

  if (!userHasAcess) {
    history.push(routes.index);
  }

  return (
    <>
      <Typography variant="h5">Rescues</Typography>
      <Box mt={5} display="flex" gridGap="16px">
        <Box className={classes.subMenu} minWidth="max-content">
          {rescuesList.map((rescue, index) => (
            <Typography variant="subtitle2" key={rescue.name} gutterBottom>
              <Link
                className={index !== getActiveTab() ? classes.link : `${classes.link} active`}
                to={rescue.path}
                key={rescue.name}
              >
                {rescue.name}
              </Link>
            </Typography>
          ))}
        </Box>
        <Box className={classes.container}>
          <Switch>
            {rescuesList.map((rescue, index) => (
              <Route
                exact
                path={index === 0 ? [generatePath(routes.rescuesList), rescue.path] : rescue.path}
                component={rescue.component}
                key={rescue.name}
              />
            ))}
          </Switch>
        </Box>
      </Box>
    </>
  );
};

export default RescuesView;
