import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  clearDonationAllDaysTheSame,
  clearDonationAllPickupsTheSame,
  createDraftDonation as setCreateDraftDonation,
  setClearDonation, setDonationAddDayOfMonth,
  setDonationAddDayOfWeek,
  setDonationAllDaysTheSame,
  setDonationAllPickupsTheSame,
  setDonationAppendPickup,
  setDonationCalendarRescues,
  setDonationDayOfMonthDay,
  setDonationDayOfMonthOrdinal,
  setDonationEndDate,
  setDonationFoodDonor,
  setDonationFrequency,
  setDonationFrequencyInterval,
  setDonationNewAddFoodType,
  setDonationNewDescription,
  setDonationLBSValue,
  setDonationNewFoodTypeOther,
  setDonationNewRemoveFoodType,
  setDonationNewRescueSize,
  setDonationPauseDate,
  setDonationPickupAdopter,
  setDonationPickupRescuer,
  setDonationPickupBegin,
  setDonationPickupEnd,
  setDonationPickupLocation,
  setDonationPickupReceiver,
  setDonationPickupRescuerNotes,
  setDonationRedoLastAction,
  setDonationRemoveDayOfMonth,
  setDonationRemoveDayOfWeek,
  setDonationRemovePickup,
  setDonationRescueCanceller,
  setDonationRescuePickupBegin,
  setDonationRescuePickupEnd,
  setDonationRescueRescuer,
  setDonationRescueRescuerNotes,
  setDonationResumeDate,
  setDonationStartDate,
  setDonationUndoLastAction,
  setResetDonation,
  setDonationFormError,
  removeDonationError,
  setDonationDonorNotes,
  clearDonationErrors,
  setDonationNewFoodSize,
  setDonationNewAddFoodSubCategory,
  setDonationNewremoveFoodSubCategory,
  setDonationNationalDonation,
  setDonationDonorLinkDonation,
} from '../actions/donationNew';

export default function useDonationManageActions() {
  const dispatch = useDispatch();

  const createDraftDonation = useCallback((value) => {dispatch(setCreateDraftDonation(value))}, [dispatch]); //todo: unused
  const clearDonation = useCallback(() => {dispatch(setClearDonation())}, [dispatch]);
  const resetDonation = useCallback(() => {dispatch(setResetDonation())}, [dispatch]);
  const deleteDonation = useCallback(() => {dispatch(setResetDonation())}, [dispatch]);
  const setCalendarRescues = useCallback((value) => {dispatch(setDonationCalendarRescues(value))}, [dispatch]);
  const undoLastAction = useCallback(() => {dispatch(setDonationUndoLastAction())}, [dispatch]);
  const redoLastAction = useCallback(() => {dispatch(setDonationRedoLastAction())}, [dispatch]);

  const setError = useCallback((field, error) => {dispatch(setDonationFormError(field, error))}, [dispatch]);
  const removeError = useCallback((field, index) => {dispatch(removeDonationError(field, index))}, [dispatch]);
  const clearErrors = useCallback(() => {dispatch(clearDonationErrors())}, [dispatch]);

  const setFoodDonor = useCallback((value) => {dispatch(setDonationFoodDonor(value))}, [dispatch]);
  const setPickupLocation = useCallback((value) => {dispatch(setDonationPickupLocation(value))}, [dispatch]);
  const setDescription = useCallback((value) => {dispatch(setDonationNewDescription(value))}, [dispatch]);
  const setLBSValue = useCallback((value) => {dispatch(setDonationLBSValue(value))}, [dispatch]);
  const setRescueSize = useCallback((value) => {dispatch(setDonationNewRescueSize(value))}, [dispatch]);
  const setFoodSize = useCallback((value) => {dispatch(setDonationNewFoodSize(value))}, [dispatch]);
  const setAddFoodTypes = useCallback((value) => {dispatch(setDonationNewAddFoodType(value))}, [dispatch]);
  const setRemoveFoodTypes = useCallback((value) => {dispatch(setDonationNewRemoveFoodType(value))}, [dispatch]);
  const setAddFoodSubCategory = useCallback((value) => {dispatch(setDonationNewAddFoodSubCategory(value))}, [dispatch]);
  const setRemoveFoodSubCategory = useCallback((value) => {dispatch(setDonationNewremoveFoodSubCategory(value))}, [dispatch]);
  const setFoodTypeOther = useCallback((value) => {dispatch(setDonationNewFoodTypeOther(value))}, [dispatch]);
  const setAddDayOfWeek = useCallback((value) => {dispatch(setDonationAddDayOfWeek(value))}, [dispatch]);
  const setRemoveDayOfWeek = useCallback((value) => {dispatch(setDonationRemoveDayOfWeek(value))}, [dispatch]);

  const setDonorLinkDonation = useCallback((value) => {dispatch(setDonationDonorLinkDonation(value))}, [dispatch]);


  const setAddDayOfMonth = useCallback((value) => {dispatch(setDonationAddDayOfMonth(value))}, [dispatch]);
  const setDayOfMonthDay = useCallback((value) => {dispatch(setDonationDayOfMonthDay(value))}, [dispatch]);
  const setDayOfMonthOrdinal = useCallback((value) => {dispatch(setDonationDayOfMonthOrdinal(value))}, [dispatch]);
  const setRemoveDayOfMonth = useCallback((value) => {dispatch(setDonationRemoveDayOfMonth(value))}, [dispatch]);

  const setStartDate = useCallback((value) => {dispatch(setDonationStartDate(value))}, [dispatch]);
  const setEndDate = useCallback((value) => {dispatch(setDonationEndDate(value))}, [dispatch]);
  const setPauseDate = useCallback((value) => {dispatch(setDonationPauseDate(value))}, [dispatch]);
  const setResumeDate = useCallback((value) => {dispatch(setDonationResumeDate(value))}, [dispatch]);
  const setAppendPickup = useCallback((value) => {dispatch(setDonationAppendPickup(value))}, [dispatch]);
  const setRemovePickup = useCallback((value) => {dispatch(setDonationRemovePickup(value))}, [dispatch]);
  const setPickupReceiver = useCallback((value) => {dispatch(setDonationPickupReceiver(value))}, [dispatch]);
  const setPickupAdopter = useCallback((value) => {dispatch(setDonationPickupAdopter(value))}, [dispatch]);
  const setPickupRescuer = useCallback((value) => {dispatch(setDonationPickupRescuer(value))}, [dispatch]);
  const setPickupRescuerNotes = useCallback((value) => {dispatch(setDonationPickupRescuerNotes(value))}, [dispatch]);
  const setPickupBegin = useCallback((value) => {dispatch(setDonationPickupBegin(value))}, [dispatch]);
  const setPickupEnd = useCallback((value) => {dispatch(setDonationPickupEnd(value))}, [dispatch]);
  const setFrequency = useCallback((value) => {dispatch(setDonationFrequency(value))}, [dispatch]);
  const setFrequencyInterval = useCallback((value) => {dispatch(setDonationFrequencyInterval(value))}, [dispatch]);
  const setAllDaysTheSame = useCallback((value) => {dispatch(setDonationAllDaysTheSame(value))}, [dispatch]);
  const clearAllDaysTheSame = useCallback(() => {dispatch(clearDonationAllDaysTheSame())}, [dispatch]);
  const setAllPickupsTheSame = useCallback((value) => {dispatch(setDonationAllPickupsTheSame(value))}, [dispatch]);
  const clearAllPickupsTheSame = useCallback(() => {dispatch(clearDonationAllPickupsTheSame())}, [dispatch]);

  const setRescuePickupBegin = useCallback((value) => {dispatch(setDonationRescuePickupBegin(value))}, [dispatch]);
  const setRescuePickupEnd = useCallback((value) => {dispatch(setDonationRescuePickupEnd(value))}, [dispatch]);
  const setRescueRescuerNotes = useCallback((value) => {dispatch(setDonationRescueRescuerNotes(value))}, [dispatch]);
  const setRescueRescuer = useCallback((value) => {dispatch(setDonationRescueRescuer(value))}, [dispatch]);
  const setRescueCanceller = useCallback((value) => {dispatch(setDonationRescueCanceller(value))}, [dispatch]);
  const setDonorNotes = useCallback((value) => {dispatch(setDonationDonorNotes(value))}, [dispatch]);
  const setNationalDonation = useCallback((value) => {dispatch(setDonationNationalDonation(value))}, [dispatch]);

  return {
    createDraftDonation: createDraftDonation,
    setCalendarRescues: setCalendarRescues,
    undoLastAction: undoLastAction,
    redoLastAction: redoLastAction,
    clearDonation: clearDonation,
    resetDonation: resetDonation,
    deleteDonation: deleteDonation,

    setFoodDonor: setFoodDonor,
    setPickupLocation: setPickupLocation,
    setDescription: setDescription,
    setLBSValue: setLBSValue,
    setRescueSize: setRescueSize,
    setFoodSize: setFoodSize,
    setAddFoodTypes: setAddFoodTypes,
    setRemoveFoodTypes: setRemoveFoodTypes,
    setAddFoodSubCategory: setAddFoodSubCategory,
    setRemoveFoodSubCategory: setRemoveFoodSubCategory,
    setFoodTypeOther: setFoodTypeOther,
    setAddDayOfWeek: setAddDayOfWeek,
    setRemoveDayOfWeek: setRemoveDayOfWeek,
    setAddDayOfMonth: setAddDayOfMonth,
    setDayOfMonthDay: setDayOfMonthDay,
    setDayOfMonthOrdinal: setDayOfMonthOrdinal,
    setRemoveDayOfMonth: setRemoveDayOfMonth,
    setStartDate: setStartDate,
    setEndDate: setEndDate,
    setPauseDate: setPauseDate,
    setResumeDate: setResumeDate,
    setAppendPickup: setAppendPickup,
    setRemovePickup: setRemovePickup,
    setPickupReceiver: setPickupReceiver,
    setPickupAdopter: setPickupAdopter,
    setPickupRescuer: setPickupRescuer,
    setPickupRescuerNotes: setPickupRescuerNotes,
    setPickupBegin: setPickupBegin,
    setPickupEnd: setPickupEnd,
    setFrequency: setFrequency,
    setFrequencyInterval: setFrequencyInterval,
    setAllDaysTheSame: setAllDaysTheSame,
    clearAllDaysTheSame: clearAllDaysTheSame,
    setAllPickupsTheSame: setAllPickupsTheSame,
    clearAllPickupsTheSame: clearAllPickupsTheSame,

    setRescuePickupBegin: setRescuePickupBegin,
    setRescuePickupEnd: setRescuePickupEnd,
    setRescueRescuerNotes: setRescueRescuerNotes,
    setRescueRescuer: setRescueRescuer,
    setRescueCanceller: setRescueCanceller,

    setDonorNotes: setDonorNotes,
    setNationalDonation: setNationalDonation,

    setDonorLinkDonation: setDonorLinkDonation,

    setError: setError,
    removeError: removeError,
    clearErrors: clearErrors,
  };
}
