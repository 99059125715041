import React, { useEffect, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, generatePath, useLocation, Switch, Route } from 'react-router-dom';
import { Breadcrumbs, Typography, Tabs, Grid, AppBar } from '@material-ui/core';
import UmbrellaFoodDonorEditForm from '../components/UmbrellaFoodDonorEditForm';
import * as sitesActions from '../actions/sites';
import * as foodDonorsActions from '../actions/foodDonors';
import * as umbrellaFoodDonorsActions from '../actions/umbrellaFoodDonors';
import routes from '../routes';
import { TabLink } from '../components/Common/BottomNavTabs';
import Loading from '../components/Loading';
import FutureRescuesListView, { RescuesFromSiteFilterTypes } from './FutureRescuesListView';
import PastRescuesListView from './PastRescuesListView';
import UmbrellaFoodDonorStaffView from '../pages/UmbrellFoodDonor/UmbrellaFoodDonorStaffView';
import UmbrellaFoodDonorLocationView from '../pages/UmbrellFoodDonor/UmbrellaFoodDonorLocationView';
import { currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite } from '../services/auth';
import { Roles } from '../models/roles';

export const umbrellaFoodDonorPaths = umbrellaFoodDonorId => [
  generatePath(routes.umbrellaFoodDonor, { id: umbrellaFoodDonorId }),
  generatePath(routes.umbrellaFoodDonor, { id: umbrellaFoodDonorId, tab: 'past-rescues' }),
  generatePath(routes.umbrellaFoodDonor, { id: umbrellaFoodDonorId, tab: 'food-donors' }),
  generatePath(routes.umbrellaFoodDonor, { id: umbrellaFoodDonorId, tab: 'staff' }),
  generatePath(routes.umbrellaFoodDonor, { id: umbrellaFoodDonorId, tab: 'profile' }),
];

const UmbrellaFoodDonorView = ({ match, renderParams }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const umbrellaFoodDonorId = parseInt(match.params.id || renderParams.id);
  const sites = useSelector(state => state.entities.sites);
  const sitesList = Object.values(sites.byId);
  const umbrellaFoodDonors = useSelector(state => state.entities.umbrellaFoodDonors);
  const umbrellaFoodDonor = umbrellaFoodDonors.byId[umbrellaFoodDonorId];
  const paths = umbrellaFoodDonorPaths(umbrellaFoodDonorId);

  useEffect(() => {
    dispatch(sitesActions.fetchSitesIfNeeded());
    dispatch(foodDonorsActions.fetchFoodDonors(umbrellaFoodDonorId));
    dispatch(umbrellaFoodDonorsActions.fetchUmbrellaFoodDonorIfNeeded(umbrellaFoodDonorId));
    dispatch(umbrellaFoodDonorsActions.fetchUmbrellaFoodDonorsStaff(umbrellaFoodDonorId));
  }, [dispatch]);

  const updateUmbrellaFoodDonor = (umbrellaFoodDonorId, data) =>
    dispatch(umbrellaFoodDonorsActions.updateUmbrellaFoodDonor(umbrellaFoodDonorId, data));

  const filterRescues = (fromSiteFilter) => (rescue) => {
    const rescueSite = sitesList.find((site) => site.id === rescue.site_id);
    const siteDisabled = rescueSite?.disabled_at;

    if (fromSiteFilter === RescuesFromSiteFilterTypes.enabled) {
      return rescue.donor_id === umbrellaFoodDonorId && !siteDisabled;
    }

    if (fromSiteFilter === RescuesFromSiteFilterTypes.disabled) {
      return rescue.donor_id === umbrellaFoodDonorId && siteDisabled;
    }

    return rescue.donor_id === umbrellaFoodDonorId;
  };

  if (umbrellaFoodDonors.inflight || !umbrellaFoodDonor) {
    return <div>fetching Umbrella Food Donors</div>;
  }

  const isAdmin = currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
    Roles.Admin,
    Roles.NationalSiteDirector,
    Roles.SiteDirector,
  ]);

  return (
    <>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Link color="inherit" to={routes.umbrellaFoodDonors}>
          Umbrella Food Donors
        </Link>

        <Typography color="textPrimary">{umbrellaFoodDonor.name}</Typography>
      </Breadcrumbs>

      <AppBar position="static">
        <Tabs value={pathname}>
          <TabLink label="Future Rescues" to={paths[0]} value={paths[0]} />
          <TabLink label="Past Rescues" to={paths[1]} value={paths[1]} />
          <TabLink label="Food Donors" to={paths[2]} value={paths[2]} />
          <TabLink label="Staff" to={paths[3]} value={paths[3]} />
          <TabLink label="Profile" to={paths[4]} value={paths[4]} />
        </Tabs>
      </AppBar>

      <Suspense fallback={<Loading />}>
        <Switch>
          <Route
            path={paths[0]}
            exact
            render={() => (
              <FutureRescuesListView
                umbrellaFoodDonorId={umbrellaFoodDonorId}
                sites={sites.byId}
                filter={filterRescues}
                displayFoodDonorFilters={false}
                showDisabledSiteFilter
                showEditRescueButton={isAdmin}
                renderNamesAsLinks={false}
              />
            )}
          />
          <Route
            path={paths[1]}
            exact
            render={() => (
              <PastRescuesListView
                umbrellaFoodDonorId={umbrellaFoodDonorId}
                filter={filterRescues}
                displayFoodDonorFilters={false}
                showDisabledSiteFilter
              />
            )}
          />
          <Route
            path={paths[2]}
            exact
            render={() => (
              <Grid container>
                <Grid item xs={12}>
                  <UmbrellaFoodDonorLocationView umbrellaFoodDonorId={umbrellaFoodDonorId} />
                </Grid>
              </Grid>
            )}
          />
          <Route
            path={paths[3]}
            exact
            render={() => (
              <Grid container>
                <Grid item xs={12}>
                  <UmbrellaFoodDonorStaffView umbrellaFoodDonorId={umbrellaFoodDonorId} />
                </Grid>
              </Grid>
            )}
          />
          <Route
            path={paths[4]}
            exact
            render={() => (
              <UmbrellaFoodDonorEditForm
                umbrellaFoodDonor={umbrellaFoodDonor}
                onSubmit={values => updateUmbrellaFoodDonor(values.id, values)}
              />
            )}
          />
        </Switch>
      </Suspense>
    </>
  );
};

export default UmbrellaFoodDonorView;
