import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import Bluebird from 'bluebird';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonWithLoading from '../../../../components/ButtonWithLoading';
import { FormContainer, GridRow, RowContent, RowTitle } from '../FormGrid';
import MinMaxTimePicker from '../../../../components/MinMaxTimePicker';
import NotesTypeField from '../../../../components/Common/NotesTypeField';
import RenderFormErrorText from '../../../../components/Form/RenderFormErrorText';
import RescuerField from './RescuerField';
import ReceivingAgencyField from './ReceivingAgencyField';
import snackbarHelper from '../../../../helpers/snackbarHelper';
import { getUserFullName } from '../../../../helpers/user';
import { Colors } from '../../../../assets/theme/Colors';
import { formatPhoneNumber } from '../../../../helpers/formatters';

const useStyles = makeStyles(theme => ({
  boxHeader: {
    margin: -theme.spacing(2),
    border: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5, 1, 0.5, 1),
    color: Colors.white,
    backgroundColor: theme.palette.error.main,
  },
  buttonsWrapper: {
    '& > :not(:first-child)': {
      [theme.breakpoints.only('xs')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
  },
  timePicker: {
    '& input': {
      textAlign: 'center',
    },
  },
}));

const validate = (values) => {
  const errors = {};

  if (!values.receiver_id) {
    errors.receiver = 'Required';
  }

  return errors;
};

const RescueEditAdminForm = ({
  user,
  rescue,
  isMobileView,
  rescuersList = [],
  receivingAgenciesList = [],
  updateRescue,
}) => {
  const classes = useStyles();
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const handleSubmit = values => Bluebird
    .try(() => setIsFormSubmitting(true))
    .then(() => updateRescue(values))
    .catch(err => {
      snackbarHelper.error(err.message);
      setIsFormSubmitting(false);
    });

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        receiver_id: rescue.receiver_id,
        receiver: rescue.receiver,
        pickup_begin: rescue.pickup_begin,
        pickup_end: rescue.pickup_end,
        rescuer_notes: rescue.rescuer_notes || '',
        rescuer_id: rescue.rescuer_id,
        rescuer: rescue.rescuer,
        adopter_id: rescue.adopter_id,
        adopter: rescue.adopter,
        released_by_id: rescue.released_by_id,
        releaser: rescue.releaser,
        cancelled_by_id: rescue.cancelled_by_id,
        canceller: rescue.canceller,
      }}
      validate={validate}
      render={({ handleSubmit, values, form, pristine, dirty, invalid }) => {
        const isAdopted = values.adopter && values.rescuer_id === null && values.released_by_id === null;
        const selectedRescuer = isAdopted
          ? rescuersList.find((rescuer) => rescuer.id === values.adopter_id)
          : rescuersList.find((rescuer) => rescuer.id === values.rescuer_id);

        return (
          <form onSubmit={handleSubmit} data-testid="receiver-location-add-form">
            <Field name="canceller" render={() => null} />

            <Field name="released_by_id" render={() => null} />

            <Box
              borderColor={rescue.cancelled_by_id !== null ? 'error.main' : 'text.primary'}
              borderRadius={4}
              border={1}
              p={2}
            >
              <Box className={classes.boxHeader} display={rescue.cancelled_by_id !== null ? 'block' : 'none'}>
                <Typography variant="subtitle2">
                  Rescue cancelled!
                </Typography>
              </Box>

              <FormContainer>
                <GridRow>
                  <RowTitle>
                    Receiving Agency:
                  </RowTitle>

                  <RowContent>
                    <ReceivingAgencyField form={form} receivingAgenciesList={receivingAgenciesList} />
                  </RowContent>
                </GridRow>

                <GridRow>
                  <RowTitle>
                    Pickup Window:
                  </RowTitle>

                  <Box alignItems="center" display="flex">
                    <Box>
                      <Field
                        label="When"
                        name="pickup_begin"
                        render={({ input }) => (
                          <MinMaxTimePicker
                            className={classes.timePicker}
                            name={input.name}
                            variant={isMobileView ? 'dialog' : 'inline'}
                            value={moment(input.value, 'HH:mm')}
                            maxTime={moment(values.pickup_end, 'HH:mm')}
                            onTimeChange={({ event, duration }) => {
                              const endTime = event.clone().add(duration);

                              form.batch(() => {
                                form.change('pickup_begin', event.format('HH:mm:ss'));
                                form.change('pickup_end', endTime.format('HH:mm:ss'));
                              });
                            }}
                          />
                        )}
                      />
                    </Box>

                    <Box mx={1}>
                      -
                    </Box>

                    <Box>
                      <Field
                        InputProps={{
                          textAlign: 'center',
                        }}
                        name="pickup_end"
                        render={({ input }) => (
                          <MinMaxTimePicker
                            showFieldErrors
                            className={classes.timePicker}
                            name={input.name}
                            variant={isMobileView ? 'dialog' : 'inline'}
                            value={moment(input.value, 'HH:mm')}
                            minTime={moment(values.pickup_begin, 'HH:mm:ss')}
                            onTimeChange={({ event, isValid }) => {
                              if (isValid) {
                                form.change('pickup_end', event.format('HH:mm:ss'));
                              }
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </GridRow>

                <GridRow>
                  <RowTitle>
                    Rescuer Notes:
                  </RowTitle>

                  <Grid item xs={12}>
                    <Field
                      parse={value => value}
                      fullWidth
                      name="rescuer_notes"
                      render={props => <NotesTypeField {...props} input={{ ...props.input }} />}
                      multiline
                      rowsMax={5}
                      variant="outlined"
                    />
                  </Grid>
                </GridRow>

                <GridRow>
                  <RowTitle>
                    Rescuer:
                  </RowTitle>

                  <RowContent>
                    <RescuerField form={form} rescuersList={rescuersList} />
                  </RowContent>
                </GridRow>

                {selectedRescuer && (
                  <GridRow>
                    <RowTitle>Rescuer Contact:</RowTitle>

                    {selectedRescuer?.phone && (
                      <RowContent>
                        <span>Phone: </span>
                        <a href={`tel:${selectedRescuer.phone}`}>{formatPhoneNumber(selectedRescuer.phone)}</a>
                        {selectedRescuer.phone_ext && <span>{` (ext: ${selectedRescuer.phone_ext})`}</span>}
                      </RowContent>
                    )}

                    {selectedRescuer?.email && (
                      <RowContent>
                        Email: <a href={`mailto:${selectedRescuer.email}`}>{selectedRescuer.email}</a>
                      </RowContent>
                    )}
                  </GridRow>
                )}

                <Field name="adopter" render={() => null} />

                <GridRow>
                  <Grid item>
                    <Box alignItems="center" display="flex" flexWrap="wrap" className={classes.buttonsWrapper}>
                      <ButtonWithLoading
                        disabled={pristine || !dirty || isFormSubmitting || invalid}
                        fullWidth={isMobileView}
                        color="primary"
                        type="submit"
                        isLoading={isFormSubmitting}
                        data-testid="rescue-edit-admin-save"
                      >
                        <Box style={{ color: Colors.white }}>Update</Box>
                      </ButtonWithLoading>

                      <Button
                        disabled={!!rescue.donor_deleted_at}
                        fullWidth={isMobileView}
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          form.batch(() => {
                            form.change('cancelled_by_id', values.cancelled_by_id ? null : user.id);
                            form.change('canceller', values.cancelled_by_id ? null : getUserFullName(user));
                          });
                        }}
                      >
                        {values.cancelled_by_id ? 'Un-cancel Rescue' : 'Cancel Rescue'}
                      </Button>
                    </Box>
                  </Grid>
                </GridRow>
              </FormContainer>

              <RenderFormErrorText
                formState={form.getState()}
                showValues={[
                  'receiver_id',
                  'receiver',
                  'pickup_begin',
                  'pickup_end',
                  'rescuer_notes',
                  'rescuer_id',
                  'rescuer',
                  'adopter_id',
                  'adopter',
                  'released_by_id',
                  'releaser',
                  'cancelled_by_id',
                  'canceller',
                ]}
              />
            </Box>
          </form>
        );
      }}
    />
  );
};

export default RescueEditAdminForm;
