import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { useIntl } from 'react-intl';
import Bluebird from 'bluebird';
import { assign, orderBy } from 'lodash';
import { Steps } from 'intro.js-react';
import { NoRescueCard } from '../RescuerRescuesDashboardMobileContainer';
import RescueMobileCard from '../../../../Rescue/RescueMobileCard';
import classNames from 'classnames';
import moment from 'moment';
import {
  filterRescuesListInfiniteScroll,
  getScheduleAvailableRescues,
  isRescueClaimed,
  showAdoptRescueButton,
  showClaimRescueButton,
} from '../../../../../helpers/RescuesHelper';
import ButtonWithLoading from '../../../../../components/ButtonWithLoading';
import { Box, makeStyles, Avatar, IconButton, Badge, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import useRescuerDashboardActions from '../../../../../hooks/useRescuerDashboardActions';
import useRescuerRescueActions from '../../../../../hooks/useRescuerRescueActions';
import useActiveUser from '../../../../../hooks/useActiveUser';
import { GroupedVirtuoso } from 'react-virtuoso';
import routes from '../../../../../routes';
import snackbarHelper from '../../../../../helpers/snackbarHelper';
import {
  PanTool as PanToolIcon,
  Favorite as FavoriteIcon,
  EventAvailable as EventAvailableIcon,
  FilterList as FilterListIcon,
} from '@material-ui/icons';
import { RESCUE_BTN_ADOPTED } from '../../../../rescueAdoptConfirmation/RescueAdoptConfirmation';
import useUIFilter from '../../../../../hooks/useUIFilter';
import { RESCUER_SCHEDULE_FILTER } from '../../Schedule/ScheduleMobile';
import { initialRescuerScheduleFilters } from '../../../../../reducers/ui';
import { infiniteScheduleTour, scheduleTour } from '../../../../../helpers/tours';
import { Colors } from '../../../../../assets/theme/Colors';
import { StyledSubHeader } from '../myFrus/MyFrusMobile';
import RescuerScheduleFiltersDialog from '../../../../../components/RescuersScheduleFilters/RescuerScheduleFiltersDialog';
import { DISTANCE_ALL_VALUE } from '../../../../../components/RescuersScheduleFilters/filters/DistanceSliderFilter';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
    padding: spacing(1, 1, 0),
    display: 'flex',
    flexDirection: 'column',
  },
  grid: {
    display: 'grid',
    gridRowGap: 16,
  },
  noData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  listSubheader: {
    ...typography['h5'],
    padding: 0,
  },
  listSubItem: {
    padding: spacing(1),
  },
  ul: {
    marginBottom: spacing(2),
    backgroundColor: 'inherit',
    padding: 0,
  },
  icon: {
    borderRadius: 12,
    width: 50,
    height: 50,
  },
  unclaimed: {
    backgroundColor: Colors.rescues.unclaimed.color,
    filter: 'brightness(0.9)',
  },
  claimed: {
    backgroundColor: Colors.rescues.claimed.color,
    filter: 'brightness(0.9)',
  },
}));

const ColorButton = withStyles(() => ({
  root: {
    zIndex: 1050,
    height: 56,
    width: 56,
    position: 'absolute',
    bottom: `calc(15px + ${getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-bottom')})`,
    right: 16,
    color: 'white',
    backgroundColor: Colors.appBar.main,
    '&:hover': {
      backgroundColor: Colors.appBar.light,
    },
  },
}))(IconButton);

const RescuerScheduleMobileWithInfiniteScroll = ({ history }) => {
  const classes = useStyles();
  const [rescuesInflight, setRescuesInflight] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const location = useLocation();
  const { formatMessage } = useIntl();

  const activeUser = useActiveUser();
  const rescuesEntities = useSelector(state => state.entities.rescues);
  const { getUIFilter, setUIFilter } = useUIFilter();
  const scheduleFilters = assign(initialRescuerScheduleFilters, getUIFilter(RESCUER_SCHEDULE_FILTER));
  const { fetchScheduleTabPaginated } = useRescuerDashboardActions();
  const { rescueClaim, rescueUnadopt } = useRescuerRescueActions();

  const showInitialInfiniteScrollTour = scheduleFilters.showInitialInfiniteScrollTour.value;
  const activeFiltersCount = Object.keys(scheduleFilters).reduce((acc, filterKey) => {
    const filterValue = scheduleFilters[filterKey].value;
    let countWeight = 0;
    switch (filterKey) {
      case 'showOnlyAvailableRescues':
        countWeight = filterValue === false ? 0 : 1;
        break;
      case 'days':
      case 'rescueSizes':
      case 'donorReceiverList':
      case 'query':
      case 'siteIds':
      case 'pickupCities':
      case 'dropoffCities':
        countWeight = filterValue.length === 0 ? 0 : 1;
        break;
      case 'distance':
        countWeight = filterValue === DISTANCE_ALL_VALUE ? 0 : 1; // 50
        break;
      case 'after':
      case 'before':
        countWeight = filterValue ? 1 : 0;
        break;
    }
    return acc + countWeight;
  }, 0);

  const rescuerAvailableRescues = getScheduleAvailableRescues(activeUser.id, rescuesEntities);
  const rescuesById = rescuerAvailableRescues.data.map(rescueId => rescuesEntities.byId[rescueId]);
  const filteredAvailableRescues = filterRescuesListInfiniteScroll(rescuesById, scheduleFilters);

  const { availableRescuesDayGroups, availableRescues, unclaimedRescuesGroups } = filteredAvailableRescues.reduce(
    (acc, curr) => {
      const rescueValue = isRescueClaimed(curr) ? 0 : 1;

      const isRescueFromTodayOrAfter = moment(curr.date, 'YYYYMMDD').isSameOrAfter(moment(), 'day');
      if (isRescueFromTodayOrAfter) {
        acc.availableRescues.push(curr);

        if (acc.availableRescuesDayGroups[curr.date]) {
          acc.availableRescuesDayGroups[curr.date]++;
          acc.unclaimedRescuesGroups[curr.date] = acc.unclaimedRescuesGroups[curr.date] + rescueValue;
        } else {
          acc.availableRescuesDayGroups[curr.date] = 1;
          acc.unclaimedRescuesGroups[curr.date] = rescueValue;
        }
      }

      return acc;
    },
    {
      availableRescues: [],
      availableRescuesDayGroups: {},
      unclaimedRescuesGroups: {},
    }
  );

  const availableFilteredRescuesSorted = orderBy(availableRescues, availableRescue => {
    return moment(`${availableRescue.date} ${availableRescue.pickup_begin}`, 'YYYYMMDD HH:mm:ss');
  }, ['asc']);
  const availableFilteredRescuesSortedCount = availableFilteredRescuesSorted.length;
  const availableRescuesCount = availableRescues.length;

  const groupCounts = Object.values(availableRescuesDayGroups);
  const groupBy = Object.keys(availableRescuesDayGroups);

  const isInflight =
    rescuesEntities.inflight || rescuerAvailableRescues.inflight || rescuerAvailableRescues.lastUpdated === null;

  const onAdoptClick = rescue => history.push(generatePath(routes.rescueAdoptConfirmation, { rescueId: rescue.id }));

  useEffect(() => {
    fetchScheduleTabPaginated(true, true, 1, true);
  }, [activeUser.id, fetchScheduleTabPaginated]);

  useEffect(() => {
    if (
      location.state &&
      location.state.adoptButton &&
      matchPath(location.state.prevPath, {
        path: routes.rescueAdoptConfirmation,
        exact: true,
      })
    ) {
      const { state } = location;
      history.replace(location.pathname, null); //remove rescueAdoptConfirmation

      if (state.adoptButton === RESCUE_BTN_ADOPTED) {
        const toastButton = {
          color: 'primary',
          variant: 'contained',
          label: 'Undo',
          onClick: () =>
            rescueUnadopt(
              {
                id: state.rescueId,
                pickup_spec_id: state.pickupSpecId,
              },
              true
            ),
        };

        return snackbarHelper.success('Rescue adopted', true, toastButton);
      }
    }
  }, []);

  const handleRescueClaim = rescue =>
    Bluebird.try(() => setRescuesInflight({ ...rescuesInflight, [rescue.id]: 'claim' }))
      .then(() => rescueClaim(rescue, true))
      .finally(() => setRescuesInflight({ ...rescuesInflight, [rescue.id]: null }));

  const isLastPageFetched =
    rescuerAvailableRescues.pagination.currentPage >= rescuerAvailableRescues.pagination.lastPage;
  const onEndReached = () => {
    if (!isInflight && !isLastPageFetched) {
      fetchScheduleTabPaginated(false, true, rescuerAvailableRescues.pagination.currentPage + 1, false);
    }
  };

  useEffect(() => {
    if (filteredAvailableRescues.length === 0) {
      onEndReached();
    }
  }, [filteredAvailableRescues]);

  const renderGroupContent = index => {
    const unclaimedRescuesLeft = unclaimedRescuesGroups[groupBy[index]];
    return (
      <div style={{ paddingBottom: 8 }}>
        <StyledSubHeader
          color={unclaimedRescuesLeft === 0 ? Colors.rescues.claimed.color : Colors.rescues.unclaimed.color}
          text={moment(groupBy[index], 'YYYYMMDD').format('dddd, MMMM Do')}
          subText={
            unclaimedRescuesLeft === 0
              ? formatMessage({ id: 'rescuer-dashboard-mobile.schedule.no-available-rescues' })
              : `${unclaimedRescuesLeft} `
                + `${formatMessage({ id: 'rescuer-dashboard-mobile.schedule.n-rescues-available' })}`
          }
          icon={
            <Avatar
              className={classNames(classes.icon, {
                [classes.unclaimed]: unclaimedRescuesLeft > 0,
                [classes.claimed]: unclaimedRescuesLeft === 0,
              })}
            >
              <EventAvailableIcon />
            </Avatar>
          }
        />
      </div>
    );
  };

  const renderFooter = () => {
    if (isInflight) {
      return <RescueMobileCard isLoading />;
    }
    if (isLastPageFetched) {
      return (
        <Box textAlign="center" pt={2} pb={9}>
          <Typography variant="subtitle2">That’s all for now.</Typography>
          <Typography variant="subtitle2">More rescues will be available tomorrow.</Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box className={classes.container}>
      <Steps
        enabled={!isInflight && showInitialInfiniteScrollTour}
        options={{
          nextToDone: true,
          hidePrev: true,
        }}
        onExit={() =>
          setUIFilter(RESCUER_SCHEDULE_FILTER, {
            ...scheduleFilters,
            showInitialInfiniteScrollTour: { value: false },
          })
        }
        initialStep={0}
        steps={infiniteScheduleTour}
      />

      {!isInflight && rescuesById.length === 0 && <NoRescueCard text="No available rescues." />}

      {!isInflight && rescuesById.length > 0 && availableRescuesCount === 0 && (
        <NoRescueCard text="There are no available rescues that match the current filter criteria." />
      )}

      <RescuerScheduleFiltersDialog
        availableRescues={rescuesById}
        filteredRows={availableFilteredRescuesSorted}
        isOpen={isDialogOpen}
        onDialogClose={() => setIsDialogOpen(false)}
      />
      <ColorButton onClick={() => setIsDialogOpen(true)} data-introid="schedule-filters">
        <Badge badgeContent={activeFiltersCount} color="secondary">
          <FilterListIcon />
        </Badge>
      </ColorButton>

      <Box display="flex" flex={1} flexDirection="column" data-introid="schedule-rescues-container">
        <GroupedVirtuoso
          style={{ width: '100%', height: '100%' }}
          totalCount={availableFilteredRescuesSortedCount}
          endReached={onEndReached}
          groupCounts={groupCounts}
          groupContent={renderGroupContent}
          components={{
            Footer: renderFooter,
          }}
          itemContent={index => {
            const rescue = availableFilteredRescuesSorted[index];
            const top = index === 0 ? 0 : 1;
            const bottom = index === availableRescuesCount ? 0 : 1;
            const isClaimed = isRescueClaimed(rescue);
            return (
              <Box pt={top} pb={bottom}>
                <RescueMobileCard
                  key={rescue.id}
                  rescue={rescue}
                  isClaimed={isClaimed}
                  isCancelled={rescue.cancelled_by_id !== null}
                  hideExpandPanel={isClaimed}
                  my={2}
                >
                  <Box display="grid" gridGap={8} data-introid="claim-rescue-buttons">
                    {showClaimRescueButton(rescue) && (
                      <ButtonWithLoading
                        fullWidth
                        startIcon={<PanToolIcon />}
                        isLoading={rescuesInflight[rescue.id] && rescuesInflight[rescue.id] === 'claim'}
                        disabled={!!rescuesInflight[rescue.id] || activeUser.paused_at}
                        variant="contained"
                        size="large"
                        onClick={() => handleRescueClaim(rescue)}
                      >
                        {formatMessage({ id: 'rescuer-dashboard-mobile.buttons.i-will-do-it' })}
                      </ButtonWithLoading>
                    )}
                    {showAdoptRescueButton(rescue) && (
                      <ButtonWithLoading
                        fullWidth
                        startIcon={<FavoriteIcon />}
                        isLoading={rescuesInflight[rescue.id] && rescuesInflight[rescue.id] === 'adopt'}
                        disabled={!!rescuesInflight[rescue.id] || activeUser.paused_at}
                        variant="contained"
                        size="large"
                        onClick={() => onAdoptClick(rescue)}
                      >
                        {formatMessage({ id: 'rescuer-dashboard-mobile.buttons.adopt' })}
                      </ButtonWithLoading>
                    )}
                  </Box>
                </RescueMobileCard>
              </Box>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default RescuerScheduleMobileWithInfiniteScroll;
