import React from 'react';
import { useRouteMatch, Link, generatePath, Route, Switch, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { Box, Typography, getContrastRatio, makeStyles } from '@material-ui/core';
import routes from '../routes';
import { Roles } from '../models/roles';
import { currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite } from '../services/auth';
import { Colors } from '../assets/theme/Colors';
import UsersListView from './UsersListView';
import DonorsLocationsListView from './DonorsLocationsListView';
import ReceiversListView from './ReceiversListView';
import RescuersListView from './RescuersListView';
import UsersUnassignedListView from './UsersUnassignedListView';
import InProgressRegistrationsListView from './InProgressRegistrationsListView';
import UmbrellaFoodDonorsListView from './UmbrellaFoodDonorsListView';
import FoodDonorsUnassignedListView from './FoodDonorsUnassignedListView';

const FOOD_DONORS_TAB = 'food-donors';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: Colors.gray,
    paddingLeft: 10,
    '&.active': {
      color: theme.palette.primary.main,
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        left: -3,
        top: 0,
        height: '100%',
        borderLeft: '4px solid',
        borderColor: theme.palette.primary.main,
      },
    },
  },
  subLink: {
    textDecoration: 'none',
    color: Colors.gray,
    marginLeft: 10,
    padding: '3px 8px',
    position: 'relative',
    '&.active': {
      color: theme.palette.primary.dark,
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
        filter:
          getContrastRatio(theme.palette.primary.main, '#fff') > 4.5
            ? 'brightness(4) saturate(0.1)'
            : 'brightness(1.5) saturate(0.7)',
        zIndex: -1,
      },
    },
  },
  container: {
    width: 'calc(100% - 170px)',
  },
  submenu: {
    overflow: 'hidden',
    maxHeight: 0,
    opacity: 0,
    transition: 'all 0.5s ease-in-out',
    '&.show': {
      maxHeight: '200px',
      opacity: 1,
      transition: 'max-height 1s ease-in-out, opacity 0.7s ease-in-out',
    },
  },
}));

const usersList = [
  {
    name: 'People',
    component: UsersListView,
    path: generatePath(routes.allUsers, { tab: 'users' }),
    roles: [Roles.Admin, Roles.NationalSiteDirector, Roles.SiteDirector, Roles.SiteCoordinator],
    tab: 'rescues',
  },
  {
    name: 'Food Donors',
    path: generatePath(routes.allUsers, { tab: FOOD_DONORS_TAB }),
    roles: [Roles.Admin, Roles.NationalSiteDirector, Roles.SiteDirector, Roles.SiteCoordinator],
    tab: FOOD_DONORS_TAB,
    subList: [
      {
        name: 'My Donors',
        component: DonorsLocationsListView,
        path: generatePath(routes.allUsers, { tab: FOOD_DONORS_TAB, subTab: 'my-donors' }),
        roles: [Roles.Admin, Roles.NationalSiteDirector, Roles.SiteDirector, Roles.SiteCoordinator],
        subTab: 'my-donors',
      },
      {
        name: 'Umbrella Donors',
        component: UmbrellaFoodDonorsListView,
        path: generatePath(routes.allUsers, { tab: FOOD_DONORS_TAB, subTab: 'umbrella-food-donors' }),
        roles: [Roles.Admin, Roles.NationalSiteDirector],
        subTab: 'umbrella-food-donors',
      },
      {
        name: 'Unassigned Food Donors',
        component: FoodDonorsUnassignedListView,
        path: generatePath(routes.allUsers, { tab: FOOD_DONORS_TAB, subTab: 'unassigned-food-donors' }),
        roles: [Roles.Admin, Roles.NationalSiteDirector],
        subTab: 'unassigned-food-donors',
      },
    ],
  },
  {
    name: 'Receiving Agencies',
    component: ReceiversListView,
    path: generatePath(routes.allUsers, { tab: 'receivers' }),
    roles: [Roles.Admin, Roles.NationalSiteDirector, Roles.SiteDirector, Roles.SiteCoordinator],
    tab: 'receivers',
  },
  {
    name: 'Rescuers',
    component: RescuersListView,
    path: generatePath(routes.allUsers, { tab: 'rescuers' }),
    roles: [Roles.Admin, Roles.NationalSiteDirector, Roles.SiteDirector, Roles.SiteCoordinator],
    tab: 'rescuers',
  },
  {
    name: 'Unassigned',
    component: UsersUnassignedListView,
    path: generatePath(routes.allUsers, { tab: 'unassigned-users' }),
    roles: [Roles.Admin, Roles.NationalSiteDirector],
    tab: 'unassigned-users',
  },
  {
    name: 'In Progress Registrations',
    component: InProgressRegistrationsListView,
    path: generatePath(routes.allUsers, { tab: 'in-progress-registrations' }),
    roles: [Roles.Admin, Roles.NationalSiteDirector],
    tab: 'in-progress-registrations',
  },
];

const AllUsersView = () => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const history = useHistory();
  const params = useParams();

  const userHasRoles = (roles = []) => currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite(roles)

  const userHasAcess = userHasRoles([
    Roles.Admin,
    Roles.NationalSiteDirector,
    Roles.SiteDirector,
    Roles.SiteCoordinator,
  ]);

  const users = usersList.filter((user) => userHasRoles(user.roles));

  const paths = users.map((user) => user.path);

  const getActiveTab = () => {
    const path = paths.indexOf(url);

    if (path < 0) {
      return Math.max(
        users.findIndex((user) => user.tab === params.tab),
        0
      );
    }

    return Math.max(path, 0);
  };

  const getActiveSubTab = () => {
    const usersWithSubTabs = users.find((user) => user.subList?.length > 0 && params.tab === user.tab);

    if (usersWithSubTabs) {
      const subTabsPaths = usersWithSubTabs.subList.filter((user) => userHasRoles(user.roles)).map((sub) => sub.path);
      const path = subTabsPaths.indexOf(url);

      return Math.max(path, 0);
    }

    return null;
  };

  if (!userHasAcess) {
    history.push(routes.index);
  }

  return (
    <>
      <Typography variant="h5">Users</Typography>
      <Box mt={5} display="flex" gridGap="16px">
        <Box minWidth="max-content">
          {users.map((user, index) => (
            <Box key={user.name}>
              <Typography variant="subtitle2" gutterBottom>
                <Link className={index !== getActiveTab() ? classes.link : `${classes.link} active`} to={user.path}>
                  {user.name}
                </Link>
              </Typography>
              {user.subList?.filter((u) => userHasRoles(u.roles)).length > 1 &&
                user.subList
                  .filter((u) => userHasRoles(u.roles))
                  .map((subUser, i) => (
                    <Box
                      className={params.tab === user.tab ? `${classes.submenu} show` : classes.submenu}
                      key={subUser.name}
                    >
                      <Typography variant="subtitle2" key={subUser.name} gutterBottom>
                        <Link
                          className={i !== getActiveSubTab() ? classes.subLink : `${classes.subLink} active`}
                          to={subUser.path}
                        >
                          {subUser.name}
                        </Link>
                      </Typography>
                    </Box>
                  ))}
            </Box>
          ))}
        </Box>
        <Box className={classes.container}>
          <Switch>
            {users.map((user, index) => {
              if (user.subList?.length > 0) {
                return user.subList
                  .filter((user) => userHasRoles(user.roles))
                  .map((subUser, i) => (
                    <Route
                      exact
                      path={i === 0 ? [generatePath(routes.allUsers, { tab: user.tab }), subUser.path] : subUser.path}
                      component={subUser.component}
                      key={subUser.name}
                    />
                  ));
              }

              return (
                <Route
                  exact
                  path={index === 0 ? [generatePath(routes.allUsers), user.path] : user.path}
                  component={user.component}
                  key={user.name}
                />
              );
            })}
          </Switch>
        </Box>
      </Box>
    </>
  );
};

export default AllUsersView;
