import React, { useState, useEffect } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { get } from 'lodash';
import { Button, CardContent, Grid, Breadcrumbs, Typography } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { BaseCard } from '../../components/Common/BaseCard';
import { GridRow, RowContent, RowTitle } from '../Rescue/RescueEdit/FormGrid';
import { formatAddress, formatPhoneNumber } from '../../helpers/formatters';
import routes from '../../routes';
import { fetchUnassignedFoodDonors } from '../../actions/foodDonors';
import { updateUnassignedFoodDonor } from '../../api/foodDonors';
import { fetchSitesIfNeeded } from '../../actions/sites';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import useNotificationService from '../../hooks/useNotificationService';

const UnassignedFoodDonor = ({ history, match, isMobileView }) => {
  const { params } = match;
  const foodDonorId = params.id;
  const { addErrorNotification, addSuccessNotification } = useNotificationService();
  const dispatch = useDispatch();
  const sites = useSelector(state => state.entities.sites);
  const sitesList = sites.allIds.map(id => sites.byId[id]);
  const sitesListFormatted = sitesList
    .filter(site => site.disabled_at === null)
    .map(site => {
      return {
        label: site.name,
        value: {
          site: site.name,
          id: site.id,
        },
      };
    });

  const foodDonors = useSelector(state => state.entities.foodDonors.unassigned.byId);
  const foodDonor = foodDonors[foodDonorId];
  const [selectedSite, setSelectedSite] = useState(null);

  useEffect(() => {
    dispatch(fetchSitesIfNeeded());
    dispatch(fetchUnassignedFoodDonors());
  }, [dispatch]);

  if (!foodDonor) {
    return 'loading...';
  }

  const handleSiteSelect = event => {
    setSelectedSite(event);
  };

  const handeUpdateFoodDonor = async id => {
    try {
      const response = await updateUnassignedFoodDonor(foodDonorId, id);
      const data = await response.json();
      if (data.location.site_id !== id) {
        return addErrorNotification('Unable to update Food Donor');
      }
      addSuccessNotification('Food Donor Updated!');
      return history.push(routes.unassignedFoodDonors);
    } catch (e) {
      return addErrorNotification(e || 'Unable to update Food Donor');
    }
  };

  return (
    <>
      {isMobileView ? (
        <Breadcrumbs aria-label="breadcrumb">
          <StyledRescuerBreadcrumb
            component={Link}
            to={generatePath(routes.mobileDashboard)}
            label="Home"
            icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
          />
          <StyledRescuerTextBreadcrumb
            component={Link}
            to={routes.unassignedFoodDonors}
            label="Unassigned Food Donors"
          />
          <StyledRescuerTextBreadcrumb component="a" href="#" label="Edit" />
        </Breadcrumbs>
      ) : (
        <Breadcrumbs aria-label="Breadcrumbs">
          <Link to={routes.unassignedFoodDonors}>Unassigned Food Donors</Link>
          <Typography color="textPrimary">Edit</Typography>
        </Breadcrumbs>
      )}
      <Grid container>
        <Grid item container md={12} lg={6} spacing={3}>
          <Grid item xs={12}>
            <BaseCard>
              <CardContent>
                <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                  <GridRow>
                    <RowTitle>Food Donor Name:</RowTitle>
                    <RowContent>{foodDonor.name}</RowContent>
                  </GridRow>

                  <GridRow>
                    <RowTitle>Address:</RowTitle>
                    <RowContent>
                      {formatAddress({
                        city: get(foodDonor, 'city', ''),
                        zip: get(foodDonor, 'zip_code', ''),
                        st: get(foodDonor, 'state', ''),
                      })}
                    </RowContent>
                  </GridRow>

                  {foodDonor.store_id && (
                    <GridRow>
                      <RowTitle>Store # or ID:</RowTitle>
                      <RowContent>{foodDonor.store_id}</RowContent>
                    </GridRow>
                  )}

                  <GridRow>
                    <RowTitle>Title:</RowTitle>
                    <RowContent>{foodDonor.title || 'N/A'}</RowContent>
                  </GridRow>

                  {foodDonor && foodDonor.email && (
                    <GridRow>
                      <RowTitle>Email:</RowTitle>
                      <RowContent>
                        <a href={`mailto:${foodDonor.email}`}>{foodDonor.email}</a>
                      </RowContent>
                    </GridRow>
                  )}

                  <GridRow>
                    <RowTitle>Phone:</RowTitle>
                    <RowContent>
                      {foodDonor.phone ? (
                        <a href={`tel:${foodDonor.phone}`}>{formatPhoneNumber(foodDonor.phone)}</a>
                      ) : (
                        'N/A'
                      )}
                    </RowContent>
                  </GridRow>

                  <GridRow>
                    <RowTitle>Assign Site:</RowTitle>
                    <Select
                      menuPosition="fixed"
                      placeholder="Select Site"
                      options={sitesListFormatted}
                      onChange={event => handleSiteSelect(event)}
                    />
                  </GridRow>
                </Grid>
              </CardContent>
            </BaseCard>
          </Grid>

          <Grid item container xs={12} spacing={3}>
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => handeUpdateFoodDonor(selectedSite.value.id)}
                disabled={!selectedSite}
              >
                Update Food Donor
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UnassignedFoodDonor;
