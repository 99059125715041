import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { uniqBy } from 'lodash';
import RescuesSchedule, { REDUX_KEY } from '../components/RescuesSchedule';
import { eventToScheduleEvent, getRescuePickupLocationFullName, rescueToScheduleEvent } from '../helpers/RescuesHelper';
import routes from '../routes';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { fetchSiteEventsList, fetchSiteEventsListIfNeeded, fetchSiteRescues } from '../actions/sites';
import { setUserCalendarOptions } from '../actions/calendarOptions';
import { getLastSiteRescueDate } from '../api/sites';
import useActiveUser from '../hooks/useActiveUser';
import { sortAlphabetically } from '../helpers/sorters';

const RescuesScheduleView = ({ bottomPadding, siteId, testId }) => {
  const calendarRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const activeUser = useActiveUser();
  const dispatch = useDispatch();
  const calendars = useSelector(state => state.calendarOptions);
  const rescues = useSelector(state => state.entities.sites.rescues);
  const events = useSelector(state => state.entities.sites.eventsList);
  const currentSite = useSelector(state => state.app.site);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: null,
  });

  const fetchSiteId = siteId || currentSite.id;
  const rescuesList = rescues.bySiteId[fetchSiteId] || [];
  const eventsList = events.bySiteId[fetchSiteId] || [];
  const eventsListWithoutCancelled = eventsList.filter((event) => !event.cancelled_at);

  const calendar = get(
    calendars,
    [activeUser.id, REDUX_KEY],
    isMobileView
      ? {
          view: 'listWeek',
          searchQuery: '',
          start: moment().format('YYYYMMDD'),
          end: moment().format('YYYYMMDD'),
          showTimeGrid: false,
          viewDateRange: {
            start: moment().startOf('week').toDate(),
            end: moment().toDate(),
          },
        }
      : {
          view: 'dayGridDay',
          searchQuery: '',
          start: moment().format('YYYYMMDD'),
          end: moment().endOf('week').format('YYYYMMDD'),
          showTimeGrid: false,
          viewDateRange: {
            start: moment().startOf('week').toDate(),
            end: moment().toDate(),
          },
        }
  );

  const [foodDonorFilter, setFoodDonorFilter] = useState('All');
  const [pickupLocationFilter, setPickupLocationFilter] = useState('All');
  const [searchQuery, setSearchQuery] = useState(calendar.searchQuery || '');
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [statusFilter, setStatusFilter] = useState(null);
  const [recurringFilter, setRecurringFilter] = useState(null);
  const [middleDate, setMiddleDate] = useState({});
  const [goToDate, setGoToDate] = useState(false);
  const locationState = location.state?.prevCalendarOptions;

  const setShowTimeGrid = () =>
    dispatch(
      setUserCalendarOptions(activeUser.id, REDUX_KEY, {
        ...calendar,
        showTimeGrid: !calendar.showTimeGrid,
        view: calendarRef.current ? calendarRef.current.getApi().view.type : calendar.view
      })
    );

  const onViewChange = view => {
    dispatch(
      setUserCalendarOptions(activeUser.id, REDUX_KEY, {
        ...calendar,
        view: view.type,
        viewDateRange: {
          start:
            view.type === 'dayGridMonth'
            ? moment(currentSite.created_at).startOf('month').toDate()
            : moment(currentSite.created_at).startOf('week').toDate(),
          end: calendar.viewDateRange.end,
      }
      })
    )
}

  useEffect(() => {
    const date = moment().format('YYYY-MM-DD');
    getLastSiteRescueDate(date, fetchSiteId)
      .then(res => res.json())
      .then(({ data }) => {
        let calendarMaxDate = moment().toDate();
        if (data[0]) {
          calendarMaxDate = moment(`${data[0].date} 23:59:59`).toDate();
        }

        dispatch(
          setUserCalendarOptions(activeUser.id, REDUX_KEY, {
            ...calendar,
            viewDateRange: {
              start:
                calendar.view === 'dayGridMonth'
                  ? moment(currentSite.created_at).startOf('month').toDate()
                  : moment(currentSite.created_at).startOf('week').toDate(),
              end: calendarMaxDate,
            },
          })
        );
      });
  }, [fetchSiteId]);

  // fires only on deps[] change
  useEffect(() => {
    if (fetchSiteId && dateFrom && dateTo) {
      dispatch(fetchSiteRescues(dateFrom, dateTo, fetchSiteId));
      dispatch(fetchSiteEventsList(fetchSiteId, false, dateFrom, dateTo));
    }
  }, [fetchSiteId, dateFrom, dateTo]);

  // set filters and scroll position from location state
  useEffect(() => {
    if (locationState?.foodDonorFilter) {
      setFoodDonorFilter(location.state.prevCalendarOptions.foodDonorFilter);
    }

    if (locationState?.pickupLocationFilter) {
      setPickupLocationFilter(location.state.prevCalendarOptions.pickupLocationFilter);
    }

    if (calendar.view === 'listWeek' && locationState?.scrollPosition) {
      const calendarScrollElement = document.querySelector('.fc-view-harness.fc-view-harness-active > div > div');
      if (calendarScrollElement) {
        calendarScrollElement.scrollTop = location.state.prevCalendarOptions.scrollPosition;
      }
    }
  }, [locationState, calendarRef.current]);

  // componentWillUnmount
  useEffect(() => {
    return () => {
      if (calendarRef.current) {
        const cAPI = calendarRef.current.getApi();
        dispatch(
          setUserCalendarOptions(activeUser.id, REDUX_KEY, {
            start: moment(cAPI.view.activeStart).format('YYYYMMDD'),
            end: moment(cAPI.view.activeEnd).format('YYYYMMDD'),
            view: cAPI.view.type,
          })
        );
      }
    }
  }, []);

  // we are re-fetching data only if we don't have them in the store
  const fetchRescues = (startDate, endDate) => {
    // Calculate the middle month date to restore the correct month when needed
    const diff = Math.abs(moment(startDate).diff(endDate));
    const middle = diff / 2 + Math.min(moment(startDate).valueOf(), moment(endDate).valueOf());
    setMiddleDate(moment(middle).format('YYYYMMDD'));

    if (moment(startDate).isBefore(moment(dateFrom))) {
      setDateFrom(moment(startDate).format('YYYYMMDD'));
    }

    if (moment(endDate).isAfter(moment(dateTo))) {
      setDateTo(moment(endDate).format('YYYYMMDD'));
    }
  };

  const handleSearchQueryChange = value => {
    dispatch(
      setUserCalendarOptions(activeUser.id, REDUX_KEY, {
        ...calendar,
        searchQuery: value
      })
    );
    return setSearchQuery(value);
  };

  const handleEventClick = event => {
    // get scroll position
    let scrollPosition = 0;
    if (calendar.view === 'listWeek') {
      const calendarScrollElement = document.querySelector('.fc-view-harness.fc-view-harness-active > div > div');
      scrollPosition = calendarScrollElement.scrollTop;
    }

    if (event._def.extendedProps.type === 'rescue') {
      return history.push(generatePath(routes.rescuesScheduleEdit, { rescueId: event.id }), {
        // pass filter and calendar options to location state
        prevCalendarOptions: {
          start: moment(event._instance.range.start).format('YYYYMMDD'),
          middleDate,
          scrollPosition,
          foodDonorFilter,
          pickupLocationFilter,
          dateRange: {
            startDate: dateRange.startDate ? moment(dateRange.startDate).format('YYYYMMDD') : null,
            endDate: dateRange.endDate ? moment(dateRange.endDate).format('YYYYMMDD') : null,
          },
          period: dateRange?.period || 'all',
        },
      });
    }

    if (event._def.extendedProps.type === 'event') {
      return history.push(generatePath(routes.event, { eventId: event.id }), {
        // pass filter and calendar options to location state
        prevCalendarOptions: {
          start: moment(event._instance.range.start).format('YYYYMMDD'),
          middleDate,
          scrollPosition,
          foodDonorFilter,
          pickupLocationFilter,
          dateRange: {
            startDate: dateRange.startDate ? moment(dateRange.startDate).format('YYYYMMDD') : null,
            endDate: dateRange.endDate ? moment(dateRange.endDate).format('YYYYMMDD') : null,
          },
          period: dateRange?.period || 'all',
        },
      });
    }

    return null;
  };

  const rescuesListFilteredByDate = dateRange.startDate
    ? rescuesList.filter((r) => moment(r.date).isBetween(dateRange.startDate, dateRange.endDate, undefined, '[]'))
    : rescuesList;

  const eventsListFilteredByDate = dateRange.startDate
    ? eventsListWithoutCancelled.filter((e) =>
        moment(e.date.value).isBetween(dateRange.startDate, dateRange.endDate, undefined, '[]')
      )
    : eventsListWithoutCancelled;

  const queryUppercase = searchQuery.toUpperCase().trim();
  const eventsFiltered = [
    ...rescuesListFilteredByDate.reduce((acc, curr) => {
      const rescueSchedule = rescueToScheduleEvent(curr);

      if (queryUppercase.length <= 2) {
        acc.push(rescueSchedule);
        return acc;
      } else {
        if (getRescuePickupLocationFullName(curr).toUpperCase().includes(queryUppercase) || curr.receiver.toUpperCase().includes(queryUppercase)) {
          acc.push(rescueSchedule);
          return acc;
        }
      }
      return acc;
    }, []),
    ...eventsListFilteredByDate.reduce((acc, curr) => {
      const eventSchedule = eventToScheduleEvent(curr);

      if (queryUppercase.length <= 2) {
        acc.push(eventSchedule);
        return acc;
      } else {
        if (curr.name.toUpperCase().includes(queryUppercase)) {
          acc.push(eventSchedule);
          return acc;
        }
      }
      return acc;
    }, []),
  ]
    .filter((event) => (statusFilter ? event.status === statusFilter : true))
    .filter((event) => (recurringFilter ? event.isRecurring : true));

  const handleDateRangeChange = (data) => {
    if (data.dateRange === null) {
      return setDateRange({ startDate: null, endDate: null });
    }

    // stops calendar from going to date on render if the locationState.period is present
    if (calendarRef.current && (!locationState?.period || goToDate)) {
      const cAPI = calendarRef.current.getApi();
      cAPI.gotoDate(moment(data.dateRange.startDate).toDate());
    }

    setGoToDate(true);

    return setDateRange({
      startDate: moment(data.dateRange.startDate, 'YYYYMMDD'),
      endDate: moment(data.dateRange.endDate, 'YYYYMMDD'),
      period: data.period,
    });
  };

  const uniqueFoodDonors = uniqBy(rescuesList, 'location_id').sort((a, b) =>
    sortAlphabetically('dsc', a.location, b.location)
  );
  const rescuesWithSelectedFoodDonor = rescuesList.filter((rescue) => rescue.location === foodDonorFilter);
  const uniquePickupLocations = uniqBy(rescuesWithSelectedFoodDonor, 'pickup_location_id');

  const onFoodDonorFilterChange = (choice) => {
    setFoodDonorFilter(choice);
    setPickupLocationFilter('All');
  };

  const onPickupLocationFilterChange = (choice) => {
    setPickupLocationFilter(choice);
  };

  const eventsFilteredByFoodDonoFilters = eventsFiltered
    .filter((event) => event.location === foodDonorFilter)
    .filter((event) => (pickupLocationFilter !== 'All' ? event.pickup_location_name === pickupLocationFilter : event));

  const calendarOptions = () => {
    // Set start date to middle month date to retain correct month view
    if (calendar.view === 'dayGridMonth' && locationState?.middleDate) {
      return { ...calendar, start: locationState.middleDate };
    }

    // Set start date to edited event date
    if (locationState?.start) {
      return { ...calendar, start: moment(locationState.start).format('YYYYMMDD') };
    }

    // Set default start date to current date
    return { ...calendar, start: moment().format('YYYYMMDD') };
  };

  if (isMobileView === null) {
    // wait for isMobile flag, prevent view update by useEffects
    return null;
  }

  return (
    <Box height={`calc(100% - ${2 * 8}px)`} mt={2} data-testid={testId}>
      <RescuesSchedule
        isMobileView={isMobileView}
        ref={calendarRef}
        bottomPadding={bottomPadding}
        events={foodDonorFilter !== 'All' ? eventsFilteredByFoodDonoFilters : eventsFiltered}
        isFetching={rescues.inflight || events.inflight}
        fetchRescues={fetchRescues}
        onSearchQueryChange={handleSearchQueryChange}
        defaultSearchQuery={searchQuery}
        calendarOptions={calendarOptions()}
        handleGridSwitchClick={setShowTimeGrid}
        handleViewChange={onViewChange}
        onEventClick={handleEventClick}
        onDateRangeChange={handleDateRangeChange}
        onFoodDonorFilterChange={onFoodDonorFilterChange}
        onPickupLocationFilterChange={onPickupLocationFilterChange}
        foodDonorFilter={foodDonorFilter}
        pickupLocationFilter={pickupLocationFilter}
        uniqueFoodDonors={uniqueFoodDonors}
        uniquePickupLocations={uniquePickupLocations}
        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
        recurringFilter={recurringFilter}
        setRecurringFilter={setRecurringFilter}
        locationState={locationState}
      />
    </Box>
  );
};

RescuesScheduleView.propTypes = {
  bottomPadding: PropTypes.number,
  siteId: PropTypes.number,
  testId: PropTypes.string,
};

RescuesScheduleView.defaultProps = {
  bottomPadding: 0,
  testId: 'rescues-schedule-view',
  siteId: undefined, //if not set, default app.site is used
};

export default RescuesScheduleView;
