import React from 'react';
import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import {
  ThumbUpAltOutlined as ThumbUpIcon,
  ThumbDownAltOutlined as ThumbDownIcon,
  ThumbUpAlt as ThumbUpAltIcon,
  ThumbDownAlt as ThumbDownAltIcon,
} from '@material-ui/icons';
import { Colors } from '../assets/theme/Colors';

const useStyles = makeStyles(() => ({
  icon: {
    color: Colors.white,
  },
  thumbUpIconContainer: {
    borderRadius: 5,
    padding: 1,
    backgroundColor: Colors.green.main,
    maxWidth: 'max-content',
  },
  thumbDownIconContainer: {
    borderRadius: 5,
    padding: 1,
    backgroundColor: Colors.red,
    maxWidth: 'max-content',
  },
  mobileTextReady: {
    color: Colors.green.main,
  },
  mobileTextNotReady: {
    color: Colors.red,
  },
  mobileIconReady: {
    color: Colors.green.main,
  },
  mobileIconNotReady: {
    color: Colors.red,
  },
}));

const DonorLinkPickupIndicator = ({ pickupReady, isMobile }) => {
  const classes = useStyles();

  // Mobile
  if (isMobile) {
    if (pickupReady) {
      return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <ThumbUpAltIcon className={classes.mobileIconReady} />
          <Typography variant="caption" className={classes.mobileTextReady}>Pickup Ready!</Typography>
        </Box>
      );
    }

    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <ThumbDownAltIcon className={classes.mobileIconNotReady} />
        <Typography variant="caption" className={classes.mobileTextNotReady}>No food yet!</Typography>
      </Box>
    );
  }

  // Desktop
  if (pickupReady) {
    return (
      <Tooltip title="Pickup is a go!">
        <Box display="flex" alignItems="center" className={classes.thumbUpIconContainer}>
          <ThumbUpIcon className={classes.icon} />
        </Box>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="No food yet!">
      <Box display="flex" alignItems="center" className={classes.thumbDownIconContainer}>
        <ThumbDownIcon className={classes.icon} />
      </Box>
    </Tooltip>
  );
};

export default DonorLinkPickupIndicator;
