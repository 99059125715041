import Bluebird from 'bluebird';
import * as featureFlagsApi from '../api/featureFlags';
import { shouldFetch } from '../helpers/cache';

export const REQUEST_FEATURE_FLAGS = 'REQUEST_FEATURE_FLAGS';
export const RECEIVE_FEATURE_FLAGS = 'RECEIVE_FEATURE_FLAGS';
export const REQUEST_FEATURE_FLAGS_FAILED = 'REQUEST_FEATURE_FLAGS_FAILED';

export const requestFeatureFlags = () => ({
  type: REQUEST_FEATURE_FLAGS,
});

export const receiveFeatureFlags = featureFlags => ({
  type: RECEIVE_FEATURE_FLAGS,
  featureFlags,
  receivedAt: Date.now(),
});

export const fetchFeatureFlagsFailed = () => ({
  type: REQUEST_FEATURE_FLAGS_FAILED,
});

export const fetchFeatureFlags = () => dispatch => {
  dispatch(requestFeatureFlags());

  return Bluebird
    .try(() => featureFlagsApi.getFeatureFlags())
    .then(res => res.json())
    .then(json => dispatch(receiveFeatureFlags(json.data)))
    .catch(() => dispatch(fetchFeatureFlagsFailed()));
};

function shouldFetchFeatureFlags(state) {
  const featureFlags = state.entities.featureFlags;

  if (featureFlags.inflight) {
    return false;
  }

  if (featureFlags.allIds.length === 0) {
    return true;
  }

  return shouldFetch(featureFlags.lastUpdated, { minutes: 5 });
}

export const fetchFeatureFlagsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchFeatureFlags(getState())) {
    return dispatch(fetchFeatureFlags());
  }

  return null;
};
