import React from 'react';
import { get } from 'lodash';
import { Box, Typography } from '@material-ui/core';
import HtmlTooltip from '../../../../../components/Common/HtmlTooltip';
import AchievementBadge from '../../../../../components/AchievementBadge';
import CircularProgressWithLabel from '../../../../../components/CircularProgressWithValue';

export const userBadges = (badgesList) => {
  const foundRegistrationCompletedBadge = (badgesList || []).find(badge => badge.type === 'REGISTRATION_COMPLETED');
  let filteredBadgesList = [];

  if (foundRegistrationCompletedBadge) {
    filteredBadgesList.push(foundRegistrationCompletedBadge)
  }

  filteredBadgesList = [
    ...filteredBadgesList,
    ...(badgesList || []).filter(badge => badge.type !== 'REGISTRATION_COMPLETED')
  ];

  return filteredBadgesList.reduce((acc, badge) => {
    if (badge.awarded) {
      acc.push(
        <Box maxWidth="100px" key={`${badge.id}`}>
          <HtmlTooltip withBorder title={badge.description}>
            <AchievementBadge color="secondary">
              <Box textAlign="center" display="flex" flexDirection="column" gridRowGap={16}>
                <img
                  style={{
                    width: '100%',
                    filter: 'drop-shadow(1px 4px 8px #ccc)',
                    borderRadius: '50%',
                    borderStyle: 'dashed',
                    boxShadow: 'rgb(255 255 255 / 75%) -1px 1px 0px 5px',
                  }}
                  src={badge.image_uri}
                  alt={badge.title}
                />
                <Typography variant="caption" component="span" color="textSecondary">
                  {badge.title}
                </Typography>
              </Box>
            </AchievementBadge>
          </HtmlTooltip>
        </Box>
      );
      return acc;
    }

    acc.push(
      <HtmlTooltip
        key={`${badge.id}`}
        withBorder
        title={
          <>
            <Typography variant="subtitle2" align="center" component="p">
              {badge.description}
            </Typography>
            <Typography variant="subtitle2" align="center" component="p">
              {get(badge, 'count', 0) > 0 && `${badge.count} / ${badge.threshold}`}
            </Typography>
          </>
        }
      >
        <Box display="flex" gridColumnGap={16} flexDirection="column" textAlign="center" gridRowGap={8}>
          <CircularProgressWithLabel
            value={(badge.count && (badge.count / badge.threshold) * 100) || 0}
            padding="5px"
            size={110}
            thickness={2.5}
          >
            <AchievementBadge>
              <Box textAlign="center" display="flex" flexDirection="column" gridRowGap={16}>
                <img
                  style={{
                    width: '100%',
                    filter: 'drop-shadow(1px 4px 8px #ccc) opacity(0.4) grayscale(1)',
                    borderRadius: '50%',
                    borderStyle: 'dashed',
                    boxShadow: 'rgb(255 255 255 / 75%) -1px 1px 0px 5px',
                  }}
                  src={badge.image_uri}
                  alt={badge.title}
                />
              </Box>
            </AchievementBadge>
          </CircularProgressWithLabel>
          <Typography variant="caption" component="span" color="textSecondary">
            {badge.title}
          </Typography>
        </Box>
      </HtmlTooltip>
    );
    return acc;
  }, []);
};

const MyBadges = ({ badgesList }) => (
  <Box display="flex" flexWrap="wrap" gridColumnGap={8 * 3} gridRowGap={8 * 3}>
    {userBadges(badgesList)}
    <Typography color="textSecondary" variant="body1">
      Badges are awarded based on the number of closed rescues.
    </Typography>
  </Box>
);

export default MyBadges;
