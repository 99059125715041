import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import Bluebird from 'bluebird';
import moment from 'moment';
import {
  Paper,
  Typography,
  Grid,
  Breadcrumbs,
  makeStyles,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { InfoOutlined, Home as HomeIcon } from '@material-ui/icons';
import routes from '../routes/index';
import NotificationPreferences from './NotificationPreferences';
import * as usersActions from '../actions/users';
import * as authService from '../services/auth';
import OverlayLoader from './OverlayLoader';
import { transformRole } from '../models/users';
import { Roles } from '../models/roles';
import ConfirmationDialog from './ConfirmationDialog';
import NotificationsContext from '../context/NotificationsContext/NotificationsContext';
import * as notificationsHelper from '../helpers/notifications';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../pages/rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import useHasActiveFeatureFlag from '../hooks/useFeatureFlags';
import { FF_PUSH_NOTIFICATIONS } from './FeatureFlagWrapper';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '16px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: 16,
    },
  },
  description: {
    marginBottom: 20,
  },
  unsubscribeCheckbox: {
    marginTop: 20,
  },
  subscribeCheckbox: {
    marginBottom: 10,
  },
  nsdUnsubscribeCheckbox: {
    marginTop: 10,
    marginBottom: 10,
  },
  message: {
    marginTop: 15,
  },
  backButton: {
    marginRight: 5,
  },
  infoIcon: {
    position: 'relative',
    top: 5,
    marginRight: 2.5,
  },
}));

const NotificationsPreferences = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasPushNotificationsFF = useHasActiveFeatureFlag(FF_PUSH_NOTIFICATIONS);
  const [showOverlay, setShowOverlay] = useState(false);
  const [pushNotificationEnabled, setPushNotificationEnabled] = useState(false);
  const notificationsContext = useContext(NotificationsContext);
  const currentlyLoggedInOrImpersonatingUser = authService.getCurrentlyLoggedInOrImpersonatingUser();
  const usersNotificationsPreferences = useSelector(state => state.entities.users.notificationsPreferences);
  const notificationsWithUserPreferences = usersNotificationsPreferences.byUserId[
    currentlyLoggedInOrImpersonatingUser.id
  ];
  const isLoading = usersNotificationsPreferences.inflight || notificationsWithUserPreferences === undefined;
  const isCommunicationUnsubscribed = currentlyLoggedInOrImpersonatingUser.communication_unsubscribed !== null;
  const isNsdCommunicationUnsubscribed = currentlyLoggedInOrImpersonatingUser.nsd_communication_unsubscribed !== null;
  const activeUserHasNsdRole = authService.hasRoleInAnySite(
    currentlyLoggedInOrImpersonatingUser,
    Roles.NationalSiteDirector
  );

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    dispatch(usersActions.fetchUserNotificationsPreferencesIfNeeded(currentlyLoggedInOrImpersonatingUser.id));
  }, []);

  useEffect(() => {
    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      serviceWorkerRegistration.pushManager.getSubscription().then((subscription) => {
        if (subscription) {
          setPushNotificationEnabled(true);
        }
      });
    });
  }, []);

  return (
    <>
      {isMobileView ? (
        <Breadcrumbs aria-label="Breadcrumbs">
          <StyledRescuerBreadcrumb
            component={Link}
            to={routes.mobileDashboard}
            label="Home"
            icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
          />
          <StyledRescuerTextBreadcrumb component={Link} to={routes.notifications} label="Notifications" />
          <StyledRescuerTextBreadcrumb component={Typography} label="Preferences" />
        </Breadcrumbs>
      ) : (
        <Breadcrumbs className={classes.breadcrumbs}>
          <StyledRescuerBreadcrumb
            component={Link}
            to={routes.index}
            label="Home"
            icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
          />

          <Link to={routes.notifications} color="inherit">
            Notifications
          </Link>

          <Typography color="textPrimary">Preferences</Typography>
        </Breadcrumbs>
      )}

      <OverlayLoader isLoading={isLoading || showOverlay}>
        <Paper className={classes.paper} elevation={1} style={{ marginBottom: 25 }}>
          <Typography variant="h5" color="textPrimary">
            Notification Preferences
          </Typography>

          <Typography color="textSecondary" className={classes.description}>
            On this page you can manage your notification preferences. Each notification can be managed independently.
            You can choose to receive notifications in a daily digest which means that all notifications from the
            entire day will be grouped into a single message.
          </Typography>

          {isCommunicationUnsubscribed && (
            <>
              <Typography color="secondary" className={classes.description}>
                <InfoOutlined fontSize="small" className={classes.infoIcon} />

                You are currently unsubscribed from all communication.
              </Typography>

              <FormControlLabel
                className={classes.subscribeCheckbox}
                label="Unsubscribe from all communication"
                control={
                  <Checkbox
                    checked={true}
                    onChange={() => confirmAlert({
                        title: 'Subscribe to all communication?',
                        message: `
                          Are you sure you want to subscribe to all communication? You will still have the ability to 
                          adjust delivery methods and turn off specific notifications.
                        `,
                      buttons: [
                        {
                          label: 'No',
                          color: 'primary',
                        },
                        {
                          label: 'Yes',
                          color: 'primary',
                          onClick: () => {
                            setShowOverlay(true);

                            return Bluebird
                              .try(() => dispatch(
                                usersActions.updateUser(
                                  currentlyLoggedInOrImpersonatingUser.id,
                                  {
                                    communication_unsubscribed: null,
                                  }
                                )
                              ))
                              .then(() => {
                                notificationsHelper.addNotification(
                                  'You have successfully subscribed for all communication.',
                                  notificationsContext
                                );

                                setShowOverlay(false);
                              });
                          },
                        },
                      ],
                      customUI: ({ title, message, onClose, buttons }) => (
                        <ConfirmationDialog
                          buttons={buttons}
                          closeDialog={onClose}
                          title={title}
                          message={message}
                        />
                      ),
                    })}
                    style={{ paddingTop: 2.5, paddingBottom: 2.5 }}
                  />
                }
              />
            </>
          )}

          {!isCommunicationUnsubscribed && (
            <>
              {isLoading && (
                <div>
                  <svg width="300" height="100">
                    <rect x="0" y="15" rx="4" ry="4" width="117" height="6" fill="#ecebeb" />
                    <rect x="0" y="30" rx="3" ry="3" width="85" height="6" fill="#ecebeb" />
                    <rect x="0" y="80" rx="3" ry="3" width="350" height="6" fill="#ecebeb" />
                  </svg>
                </div>
              )}

              {!isLoading && (
                <>
                  <Grid container spacing={3}>
                    {
                      notificationsWithUserPreferences
                        .filter(n => !n.hidden_from_ui)
                        .map(notificationWithUserPreferences => {
                          // checking if user has the any role which is defined in visible_in_ui_to_roles array
                          if (
                            !notificationWithUserPreferences
                              .visible_in_ui_to_roles
                              .map(role => transformRole(role))
                              .some(
                                role => currentlyLoggedInOrImpersonatingUser
                                  .role_assignments
                                  .map(ra => ra.role_name)
                                  .indexOf(role) >= 0
                              )
                          ) {
                            return null;
                          }

                          return (
                            <Grid item xs={12} key={notificationWithUserPreferences.type}>
                              <NotificationPreferences
                                user={currentlyLoggedInOrImpersonatingUser}
                                notification={notificationWithUserPreferences}
                                pushNotificationEnabled={pushNotificationEnabled}
                                hasPushNotificationsFF={hasPushNotificationsFF}
                              />
                            </Grid>
                          );
                        })
                    }
                  </Grid>

                  <FormControlLabel
                    className={classes.unsubscribeCheckbox}
                    label="Unsubscribe from all communication"
                    control={
                      <Checkbox
                        checked={false}
                        onChange={() => confirmAlert({
                          title: 'Unsubscribe from all communication?',
                          message: 'Are you sure you want to unsubscribe from all communication?',
                          buttons: [
                            {
                              label: 'No',
                              color: 'primary',
                            },
                            {
                              label: 'Yes',
                              color: 'primary',
                              onClick: () => {
                                setShowOverlay(true);

                                return Bluebird
                                  .try(() => dispatch(
                                    usersActions.updateUser(
                                      currentlyLoggedInOrImpersonatingUser.id,
                                      {
                                        communication_unsubscribed: moment().format('YYYY-MM-DD HH:mm:ss'),
                                      }
                                    )
                                  ))
                                  .then(() => {
                                    notificationsHelper.addNotification(
                                      'You have successfully unsubscribed from all communication.',
                                      notificationsContext
                                    );

                                    setShowOverlay(false);
                                  });
                              },
                            },
                          ],
                          customUI: ({ title, message, onClose, buttons }) => (
                            <ConfirmationDialog
                              buttons={buttons}
                              closeDialog={onClose}
                              title={title}
                              message={message}
                            />
                          ),
                        })}
                        style={{ paddingTop: 2.5, paddingBottom: 2.5 }}
                      />
                    }
                  />
                </>
              )}
            </>
          )}

          {activeUserHasNsdRole && (
            <>
              <br />

              {isNsdCommunicationUnsubscribed && (
                <FormControlLabel
                  className={classes.nsdUnsubscribeCheckbox}
                  label='Unsubscribe from all "National Site Director" role related communication'
                  control={
                    <Checkbox
                      checked={true}
                      onChange={() => confirmAlert({
                        title: 'Subscribe for all "National Site Director" role related communication?',
                        message: 'Are you sure you want to subscribe for all "National Site Director"'
                          + ' role related communication?',
                        buttons: [
                          {
                            label: 'No',
                            color: 'primary',
                          },
                          {
                            label: 'Yes',
                            color: 'primary',
                            onClick: async () => {
                              setShowOverlay(true);

                              await dispatch(
                                usersActions.updateUser(
                                  currentlyLoggedInOrImpersonatingUser.id,
                                  {
                                    nsd_communication_unsubscribed: null,
                                  }
                                )
                              );

                              notificationsHelper.addNotification(
                                'You have successfully subscribed for all "National Site Director"'
                                + ' role related communication.',
                                notificationsContext
                              );

                              setShowOverlay(false);
                            },
                          },
                        ],
                        customUI: ({ title, message, onClose, buttons }) => (
                          <ConfirmationDialog
                            buttons={buttons}
                            closeDialog={onClose}
                            title={title}
                            message={message}
                          />
                        ),
                      })}
                      style={{ paddingTop: 2.5, paddingBottom: 2.5 }}
                    />
                  }
                />
              )}

              {!isNsdCommunicationUnsubscribed && (
                <FormControlLabel
                  className={classes.nsdUnsubscribeCheckbox}
                  label='Unsubscribe from all "National Site Director" role related communication'
                  control={
                    <Checkbox
                      checked={false}
                      onChange={() => confirmAlert({
                        title: 'Unsubscribe from all "National Site Director" role related communication?',
                        message: 'Are you sure you want to unsubscribe from all "National Site Director"'
                          + ' role related communication?',
                        buttons: [
                          {
                            label: 'No',
                            color: 'primary',
                          },
                          {
                            label: 'Yes',
                            color: 'primary',
                            onClick: async () => {
                              setShowOverlay(true);

                              await dispatch(
                                usersActions.updateUser(
                                  currentlyLoggedInOrImpersonatingUser.id,
                                  {
                                    nsd_communication_unsubscribed: moment().format('YYYY-MM-DD HH:mm:ss'),
                                  }
                                )
                              );

                              notificationsHelper.addNotification(
                                'You have successfully unsubscribed from all "National Site Director"'
                                + ' role related communication.',
                                notificationsContext
                              );

                              setShowOverlay(false);
                            },
                          },
                        ],
                        customUI: ({ title, message, onClose, buttons }) => (
                          <ConfirmationDialog
                            buttons={buttons}
                            closeDialog={onClose}
                            title={title}
                            message={message}
                          />
                        ),
                      })}
                      style={{ paddingTop: 2.5, paddingBottom: 2.5 }}
                    />
                  }
                />
              )}
            </>
          )}
        </Paper>
      </OverlayLoader>
    </>
  );
};

export default NotificationsPreferences;
