import React, { useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link, useRouteMatch, useHistory } from 'react-router-dom';
import { Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import * as emailsActions from '../actions/emails';
import routes from '../routes';
import { getUserFullName } from '../helpers/user';
import { formatDateTime } from '../helpers/formatters';
import { convertUtcToLocal } from '../helpers/dates';
import { FormPaperContainer, GridRow, RowTitle, RowContent } from '../pages/Rescue/RescueEdit/FormGrid';

const useStyles = makeStyles(() => ({
  emailContent: {
    '& img': {
      width: 'auto',
      height: 'auto',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
}));

const EmailDetailsView = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const emailId = +match.params.emailId;
  const emails = useSelector(state => state.entities.emails);
  const email = emails.byId[emailId];
  const isLoading = emails.inflight || !email || !email.users;

  useEffect(() => {
    dispatch(emailsActions.fetchEmail(emailId));
  }, []);

  return (
    <>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Link color="inherit" to={routes.emails}>
          Sent Emails
        </Link>

        <Typography color="textPrimary">
          Email Details
        </Typography>
      </Breadcrumbs>

      <FormPaperContainer email>
        {isLoading && (
          <>
            <Skeleton width={65} />

            <Skeleton style={{ marginBottom: 25 }} />

            <Skeleton width={65} />

            <Skeleton style={{ marginBottom: 25 }} />

            <Skeleton width={65} />

            <Skeleton />
          </>
        )}

        {!isLoading && (
          <>
            <GridRow>
              <RowTitle>
                Subject:
              </RowTitle>

              <RowContent>
                {email.subject}
              </RowContent>
            </GridRow>

            <GridRow hidden={!email.reply_to}>
              <RowTitle>
                Reply To:
              </RowTitle>

              <RowContent>
                {email.reply_to}
              </RowContent>
            </GridRow>

            <GridRow>
              <RowTitle>
                Sent By:
              </RowTitle>

              <RowContent>
                {email.created_by && (
                  <Link
                    onClick={() => history.push(generatePath(routes.userEditNew, { id: email.created_by.id }))}
                    style={{cursor: 'pointer'}}
                  >
                    {getUserFullName(email.created_by)}
                  </Link>
                )}

                {!email.created_by && 'N/A'}
              </RowContent>
            </GridRow>

            <GridRow>
              <RowTitle>
                Sent At:
              </RowTitle>

              <RowContent>
                {formatDateTime(convertUtcToLocal(email.created_at.value))}
              </RowContent>
            </GridRow>

            <GridRow>
              <RowTitle>
                Recipients ({email.users.length}):
              </RowTitle>

              <RowContent>
                <ul style={{ margin: 0, paddingLeft: 20 }}>
                  {email.users.map(user => (
                    <li key={user.id}>
                      {getUserFullName(user)} ({user.email})
                    </li>
                  ))}
                </ul>
              </RowContent>
            </GridRow>

            <GridRow>
              <RowTitle>
                Content:
              </RowTitle>

              <RowContent>
                {email.body ? (
                  <div className={classes.emailContent} dangerouslySetInnerHTML={{ __html: email.body }} />
                ) : (
                  'N/A'
                )}
              </RowContent>
            </GridRow>
          </>
        )}
      </FormPaperContainer>
    </>
  );
};

export default EmailDetailsView;
