import React, { useEffect, useState } from 'react';
import { FormContext, useForm, Controller, useFieldArray } from 'react-hook-form';
import { DevTool } from 'react-hook-form-devtools';
import moment from 'moment';
import Bluebird from 'bluebird';
import { get } from 'lodash';
import {
  Box,
  Collapse,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  RadioGroup,
  Radio,
  FormLabel,
  FormGroup,
  Switch,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { NumberField, TextFieldWrapper as TextField } from '../../../../components/Form/MuiFormWrapper';
import FoodTypes from '../../../Donation/DonationManage/Components/FoodTypes/FoodTypes';
import RescueSizeSelector from '../../../Donation/DonationManage/Components/RescueSize/RescueSizeSelector';
import MinMaxTimePicker from '../../../../components/MinMaxTimePicker';
import AdopterSelector from '../../../../components/AdopterSelector';
import ReceiverChip from '../../../../components/ReceiverChip';
import { getReceiversSelectOptions, getRescuersSelectOptions } from '../../../../helpers/getters';
import RescuedFoodForm from '../../../../components/RescuedFoodForm';
import errorMessages from '../../../../assets/errorMessages';
import { mapRescueToPastRescueForm } from '../../../../models/pastRescue';
import useNotificationService from '../../../../hooks/useNotificationService';
import useUIFilter from '../../../../hooks/useUIFilter';
import { useSelector } from 'react-redux';
import DonationSizeSelector from '../../../Donation/DonationManage/Components/RescueSize/DonationSizeSelector';
import DonationSizeLegend from '../../../../containers/DonationSizeLegend';
import { sortAlphabetically } from '../../../../helpers/sorters';
import { newFoodTypes as foodTypesModel } from '../../../../models/foodTypes';
import RescuedItem from './Components/RescuedItem';
import CategoryButton from './Components/CategoryButton';
import { formatNumber } from '../../../../helpers/formatters';
import useSystemSettings from '../../../../hooks/useSystemSettings';
import { LBS_PER_MEAL } from '../../../../models/systemSettings';
import { getRescueWeight } from '../../../../helpers/RescuesHelper';
import RescuePhotoButton from '../../RescueClose/Components/RescuePhoto/RescuePhotoButton';
import UploadButton from '../../RescueClose/Components/RescuePhoto/UploadButton';
import { generateS3Url, s3FileUpload } from '../../../../api/upload';

const useStyles = makeStyles(({ typography }) => ({
  heading: {
    fontSize: typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  subheading: {
    display: 'flex',
    alignItems: 'center',
    fontSize: typography.pxToRem(13),
    flexBasis: '33.33%',
    flexShrink: 0,

    '& span': {
      paddingRight: 5,
    },
  },
  root: {
    marginBottom: '1rem',
  },
}));

const eventFormValidationResolver = (data, { foodDescriptionOtherId, unitTypeOtherId }) => {
  const errors = {};

  if (data.receiver_id === '') {
    errors.receiver_id = { message: errorMessages.REQUIRED.message };
  }

  if (data.completed === 'true' || data.completed === 'false') {
    if (data.closed_by_id === '') {
      errors.closed_by_id = { message: errorMessages.REQUIRED.message };
    }
    if (data.rescuer_id === '') {
      errors.rescuer_id = { message: errorMessages.REQUIRED.message };
    }
  }

  return {
    values: Object.keys(errors).length ? {} : data,
    errors: errors,
  };
};

const ACCORDION_RESCUE_CLOSE = 'ACCORDION_RESCUE_CLOSE';
const ACCORDION_RESCUE_DATA = 'ACCORDION_RESCUE_DETAILS';
const PAST_RESCUE_FORM_UI_FILTER = 'past-rescue-form';
export const NO_RESCUE_LBS_TO_REPORT = 'no_lbs_reported';
export const RESCUE_LBS_TOTAL = 'total_lbs';
export const RESCUE_LBS_BY_ITEM = 'lbs_per_rescued_food';

const PastRescueForm = ({
  rescue,
  isMobileView,
  rescuersList,
  cancelledByUsersList,
  rescueSizes,
  foodDescriptions,
  unitTypes,
  foodDescriptionOtherId,
  unitTypeOtherId,
  updateRescue,
  setWeight,
  setCategories,
}) => {
  const { setUIFilter, getUIFilter } = useUIFilter();
  const uiFilters = getUIFilter(PAST_RESCUE_FORM_UI_FILTER) || {
    showRescueSizeLegend: true,
  };
  const classes = useStyles();
  const [expanded, setExpanded] = useState(rescue.completed === true ? ACCORDION_RESCUE_CLOSE : ACCORDION_RESCUE_DATA);
  const [isPhotoUploading, setIsPhotoUploading] = useState();

  const rescuersListArray = getRescuersSelectOptions(rescuersList || []);
  const cancelledByUsersListArray = getRescuersSelectOptions(cancelledByUsersList || []);
  const receiversEntities = useSelector((state) => state.entities.sites.receivers);
  const receiversList = getReceiversSelectOptions(Object.values(receiversEntities.byId)).sort((a, b) =>
    sortAlphabetically('asc', b.label, a.label)
  );
  const formMethods = useForm({
    defaultValues: mapRescueToPastRescueForm(rescue),
    validationResolver: eventFormValidationResolver,
    validationContext: {
      foodDescriptionOtherId,
      unitTypeOtherId,
    },
  });
  const { addSuccessNotification, addErrorNotification } = useNotificationService();

  const {
    handleSubmit,
    control,
    errors,
    reset,
    register,
    getValues,
    setValue,
    clearError,
    watch,
    formState: { isSubmitting },
  } = formMethods;

  const { fields: photoFields, remove: removePhoto, prepend: prependPhoto, } = useFieldArray({
    control,
    name: 'photos',
  });

  useEffect(() => {
    register({ name: 'food_type_other' });
    register({ name: 'lbs' });
    register({ name: 'items' });
    register({ name: 'rescue_size_id' });
    register({ name: 'weight_lbs_override' });
  }, []);

  const isNoFoodChecked = watch('no_food');
  const rescueCompleteStatus = watch('completed');
  const foodTypes = watch('food_types');
  const items = watch('items');

  const isRescueCompleted = rescueCompleteStatus !== 'null';

  const [totalWeight, setTotalWeight] = useState({
    weight: rescueCompleteStatus === 'true' && !isNoFoodChecked ? getRescueWeight(rescue) : null,
    modifiedByUser: false,
  });

  const itemsWeight = items.reduce((total, item) => {
    const itemLbs = item.lbs_override || item.lbs || item.lbs_smart || 0;
    return total + itemLbs;
  }, 0);

  // Change displayed weight whenever the total weight changes
  useEffect(() => {
    setWeight(totalWeight.weight);
  }, [totalWeight]);

  // Chnage displayed categories whenever they change
  useEffect(() => {
    if (rescueCompleteStatus === 'true') {
      const categoriesArray = items.map((item) => {
        const description = foodDescriptions.find((d) => d.id === item.food_description_id);
        if (description) {
          return description.name;
        }
        return null;
      });

      return setCategories(
        categoriesArray
          .filter((c) => c !== null)
          .toString()
          .toLowerCase()
      );
    }

    return setCategories(foodTypes.toString());
  }, [foodTypes, items]);

  // Change total weight if the weight is based on the rescue size value
  useEffect(() => {
    if (
      !totalWeight.modifiedByUser &&
      rescueCompleteStatus === 'true' &&
      !isNoFoodChecked
    ) {
      setTotalWeight({ weight: getRescueWeight(rescue), modifiedByUser: false });
    }

    if (isNoFoodChecked) {
      setTotalWeight((prev) => ({ ...prev, weight: null }));
    }
  }, [rescueCompleteStatus, isNoFoodChecked]);

  useEffect(() => {
    if (rescueCompleteStatus === 'null') {
      setValue('closed_by_id', '');
      clearError('closed_by_id');
      setValue('close_comments', '');
    }

    if (rescueCompleteStatus !== 'true') {
      return setExpanded(ACCORDION_RESCUE_DATA);
    }

    // Create new items based on the selected categories if rescue status changes to Closed
    if (rescueCompleteStatus === 'true' && !items.length > 0) {
      const itemsArray = [];
      foodTypes.map((type) => {
        const foodTypeModel = foodTypesModel.find((ft) => ft.name === type);
        const description = foodDescriptions.find((d) => d.name === foodTypeModel?.label);
        if (description) {
          return itemsArray.push({
            food_description_id: description.id,
            lbs_override: 0,
            // API requires unit_id - TO DO: change when API is fixed
            unit_id: 2,
          });
        }
        return null;
      });
      const newItems = itemsArray.map((item) => ({
        ...item,
        lbs_override: Math.floor(getRescueWeight(rescue) / itemsArray.length),
      }));
      return setValue('items', newItems);
    }

    return setExpanded(ACCORDION_RESCUE_DATA);
  }, [rescueCompleteStatus]);

  const handleAccordionChange = panel => (event, isExpanded) => {
    return setExpanded(
      isExpanded ? panel : expanded === ACCORDION_RESCUE_CLOSE ? ACCORDION_RESCUE_DATA : ACCORDION_RESCUE_CLOSE
    );
  };

  const scrollTop = () => window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });

  const doUpload = (file, index) => {
    const handleFileUpload = ({ loaded, total }) =>
      setValue(`photos[${index}].progress`, parseInt((loaded / total) * 100, 10));

    return Bluebird.try(() => generateS3Url(file.name))
      .then(res => res.json())
      .then(res => res.data)

      .then(signedData =>
        Bluebird.try(() =>
          s3FileUpload({
            url: signedData.url,
            file: file.file,
            onUploadProgress: handleFileUpload,
          })
        ).then(() => setValue(`photos[${index}].s3Key`, signedData.key))
      );
  };

  const onSubmit = values => {
    return Bluebird.try(() => updateRescue(rescue.id, values))
      // .then(rescue => reset(mapRescueToPastRescueForm(rescue)))
      .then(() => {
        photoFields.length = 0;
        addSuccessNotification('Rescue has been updated.');
        return scrollTop();
      })
      .catch(err => {
        addErrorNotification(err)
        return scrollTop();
      });
  };

  const beforeSubmit = values => {

    if ((values.photos || []).length === 0) {
      return onSubmit(values);
    }

    setIsPhotoUploading(true);
    return Bluebird.all(
      (values.photos || []).filter(photo => !photo.s3Key).map((photo, index) => doUpload(photo, index))
    ).then(() => {
      setIsPhotoUploading(false);
      return onSubmit(getValues({ nest: true }));
    });
  };

  const readFilesNew = event => {
    event.preventDefault();
    const filesTmp = Array.from(event.target.files);
    const fileList = [];
    filesTmp.forEach(file => {
      fileList.push({
        file,
        photoBlob: URL.createObjectURL(file),
        name: file.name,
        progress: '',
        s3Key: '',
      });
    });

    prependPhoto(fileList);
  };

  const handleItemWeightChange = (value, index) => {
    const newValues = [...items];
    if (isNaN(Number(value.replaceAll(',', '')))) {
      return null;
    } else {
      newValues[index].lbs_override = Number(value.replaceAll(',', ''));
    }
    setValue('items', newValues);

    const newValuesWeight = newValues.reduce((total, item) => {
      const itemLbs = item.lbs_override || item.lbs || item.lbs_smart || 0;
      return total + itemLbs;
    }, 0);
    return setTotalWeight({ weight: Math.round(newValuesWeight), modifiedByUser: true });
  };

  const handleSliderChange = (value, item) => {
    const currentValue = Math.floor((value / 100) * totalWeight.weight);
    if (items.length === 1) {
      return setValue('items', [{ ...item, lbs_override: Number(currentValue) }]);
    }
    const filteredItems = items.filter((i) => i.food_description_id !== item.food_description_id);
    const filteredItemsWeight = filteredItems.reduce((total, i) => {
      const itemLbs = i.lbs_override || i.lbs || i.lbs_smart || 0;
      return total + itemLbs;
    }, 0);

    const remainingWeight = totalWeight.weight - (filteredItemsWeight + currentValue);

    const unassignedWeight = Math.floor(remainingWeight / (items.length - 1));
    const remainder = Math.abs(remainingWeight % (items.length - 1));
    const newItemsValues = items.map((i) => {
      const itemWeight = i.lbs_override || i.lbs || i.lbs_smart || 0;
      return i.food_description_id !== item.food_description_id
        ? {
            ...i,
            lbs_override: itemWeight + unassignedWeight < 0 ? 0 : itemWeight + unassignedWeight,
          }
        : { ...i, lbs_override: Number(currentValue) + remainder };
    });

    return setValue('items', newItemsValues);
  };

  const handleTotalWeightChange = (value) => {
    if (isNaN(Number(value.replaceAll(',', '')))) {
      return null;
    }
    const newItems = items.map((item) => ({
      ...item,
      lbs_override: Math.floor(value.replaceAll(',', '') / items.length),
    }));
    setTotalWeight({ weight: Number(value.replaceAll(',', '')), modifiedByUser: true });
    setValue('weight_lbs_override', Number(value.replaceAll(',', '')));
    return setValue('items', newItems);
  };

  const { items: rescuedFoodErrors, ...otherFormErrors } = errors;

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(beforeSubmit)}>
        <Box my={1} width={1} bgcolor="background.paper">
          <Controller
            control={control}
            fullWidth
            name="completed"
            as={({ value, onChange, ...rest }) => (
              <FormControl fullWidth variant="outlined" disabled={isSubmitting || isPhotoUploading}>
                <InputLabel htmlFor="past_rescue_status">Rescue Complete Status</InputLabel>
                <Select
                  label="Rescue Complete Status"
                  native={isMobileView}
                  inputProps={{
                    id: 'past_rescue_status',
                  }}
                  {...rest}
                  value={value}
                  onChange={event => onChange(event.target.value)}
                >
                  {[
                    { value: 'null', label: 'Not closed' },
                    { value: 'true', label: 'Closed' },
                    { value: 'false', label: 'Closed (no-show)' },
                  ].map(({ value, label }) =>
                    isMobileView ? (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ) : (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            )}
          />
        </Box>

        <Box mb={2}>
          <Accordion
            elevation={25}
            expanded={expanded === ACCORDION_RESCUE_DATA}
            onChange={handleAccordionChange(ACCORDION_RESCUE_DATA)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                className={classes.heading}
                color={Object.keys(otherFormErrors).length > 0 ? 'error' : 'initial'}
              >
                Rescue Data
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="grid" gridAutoFlow="row" gridRowGap={16} style={{ width: '100%' }}>
                <Controller
                  name="food_types"
                  control={control}
                  as={({ value, onChange }) => (
                    <FormControl>
                      <FormLabel>Categories</FormLabel>
                      <FormGroup style={{ maxHeight: '150px'}}>
                        {foodTypesModel.map((type) => (
                          <CategoryButton
                            key={type.name}
                            variant={() => {
                              if (rescueCompleteStatus === 'true') {
                                const description = foodDescriptions.filter(
                                  (description) => items.some((item) => item.food_description_id === description.id)
                                );
                                const foodType =  description.find((desc) => desc.name ===  type?.label)

                                return foodType ? 'contained' : 'outlined'
                              }
                              return (foodTypes || []).find((ft) => ft === type.name) ? 'contained' : 'outlined';
                            }}
                            onCategoryClick={() => {
                              // If rescue status is completed display categories based on rescued items
                              if (rescueCompleteStatus === 'true') {
                                const description = foodDescriptions.find(
                                  (description) => description.name === type?.label
                                );
                                if (items.find((item) => item.food_description_id === description.id)) {
                                  // Remove object from items
                                  const newValue = items.filter((item) => item.food_description_id !== description.id);
                                  const newTotalWeight = newValue.reduce((acc, curr) => acc + (curr?.lbs_override || curr?.lbs || 0), 0);
                                  setTotalWeight({ weight: newTotalWeight, modifiedByUser: false });
                                  return setValue('items', newValue);
                                }

                                if (description && rescueCompleteStatus === 'true') {
                                  // Add object to items if it is not already added in items array
                                  const isInItems = items.find((i) => i.food_description_id === description.id);
                                  if (!isInItems) {
                                    const remainingWeight = totalWeight.weight - itemsWeight
                                    return setValue('items', [
                                      ...items,
                                      {
                                        food_description_id: description.id,
                                        lbs_override: remainingWeight > 0 ? remainingWeight : 0,
                                        // API requires unit_id - TO DO: change when API is fixed
                                        unit_id: 2,
                                      },
                                    ]);
                                  }
                                }
                                // if rescue status is not completed display categories based on food types
                              } else {
                                const description = foodDescriptions.find(
                                  (description) => description.name === type?.label
                                );
                                if (value.find((ft) => ft === type.name)) {
                                  if (description) {
                                    // Remove object from items
                                    const newValue = items.filter((item) => item.food_description_id !== description.id);
                                    setValue('items', newValue);
                                  }
                                  return onChange(value.filter(ft => ft !== type.name));
                                }

                                if (description && rescueCompleteStatus === 'true') {
                                  // Add object to items if it is not already added in items array
                                  const isInItems = items.find((i) => i.food_description_id === description.id);
                                  if (!isInItems) {
                                    const remainingWeight = totalWeight.weight - itemsWeight
                                    setValue('items', [
                                      ...items,
                                      {
                                        food_description_id: description.id,
                                        lbs_override: remainingWeight > 0 ? remainingWeight : 0,
                                        // API requires unit_id - TO DO: change when API is fixed
                                        unit_id: 2,
                                      },
                                    ]);
                                  }
                                }
                                onChange([...value, type.name])
                              }
                            }}
                          >
                            {type.label}
                          </CategoryButton>
                        ))}
                      </FormGroup>
                    </FormControl>
                  )}
                />

                {items.length > 0 && rescueCompleteStatus === 'true' && (
                  <FormControl>
                    <FormLabel>Weight</FormLabel>
                    <FormControlLabel
                      label="No food available"
                      control={
                        <Controller
                          name="no_food"
                          control={control}
                          as={props => (
                            <Switch checked={props.checked} onChange={e => props.onChange(e.target.checked)} />
                          )}
                        />
                      }
                    />
                    {!isNoFoodChecked &&
                      items.map((item, index) => {
                        const description = foodDescriptions.find((d) => d.id === item?.food_description_id)
                        const itemLbs = Math.floor((item.lbs_override !== null ? item.lbs_override : item.lbs) || 0);
                        if (description) {
                          return (
                            <RescuedItem
                              key={item.food_description_id}
                              item={item}
                              index={index}
                              itemLbs={itemLbs}
                              description={description}
                              disabled={isSubmitting || isPhotoUploading}
                              totalWeight={totalWeight.weight}
                              onItemWeightChange={handleItemWeightChange}
                              onSliderChange={handleSliderChange}
                            />
                          );
                        }
                        return null;
                      })}
                    {!isNoFoodChecked && (
                      <Box mt={3}>
                        <Grid item xs={3}>
                          <TextField
                            id="weight-lbs"
                            name="weight_lbs"
                            value={formatNumber(totalWeight.weight)}
                            label="Total Rescue Weight"
                            placeholder="Total Weight"
                            variant="outlined"
                            required
                            inputProps={{
                              min: 0,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onChange={({ target }) => handleTotalWeightChange(target.value)}
                          />
                        </Grid>
                      </Box>
                    )}
                  </FormControl>
                )}

                <DonationSizeLegend
                  isOpen={!!uiFilters.showRescueSizeLegend}
                  onExpandChange={() =>
                    setUIFilter(PAST_RESCUE_FORM_UI_FILTER, {
                      showRescueSizeLegend: !uiFilters.showRescueSizeLegend,
                    })
                  }
                />

                <Grid container spacing={1}>
                  <Grid item sm={12} md>
                    <Controller
                      name="pickup_begin"
                      control={control}
                      as={({ value, onChange }) => (
                        <MinMaxTimePicker
                          inputVariant="outlined"
                          size="small"
                          fullWidth
                          label="Pickup Begin"
                          variant={isMobileView ? 'dialog' : 'inline'}
                          value={moment(value, 'HH:mm:ss')}
                          maxTime={moment(getValues('pickup_end'), 'HH:mm:ss')}
                          onTimeChange={({ event, duration }) => {
                            const endTime = event.clone().add(duration);
                            onChange(event.format('HH:mm:ss'));
                            setValue('pickup_end', endTime.format('HH:mm:ss'));
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={12} md>
                    <Controller
                      name="pickup_end"
                      control={control}
                      as={({ value, onChange }) => (
                        <MinMaxTimePicker
                          inputVariant="outlined"
                          size="small"
                          fullWidth
                          label="Pickup End"
                          variant={isMobileView ? 'dialog' : 'inline'}
                          showFieldErrors
                          value={moment(value, 'HH:mm:ss')}
                          minTime={moment(getValues('pickup_begin'), 'HH:mm:ss')}
                          onTimeChange={({ event, isValid }) => {
                            if (isValid) {
                              onChange(event.format('HH:mm'));
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Controller
                  as={TextField}
                  control={control}
                  required
                  id="rescuer_notes"
                  fullWidth
                  name="rescuer_notes"
                  label="Rescuer Notes"
                  variant="outlined"
                  size="small"
                />

                <Controller
                  name="receiver_id"
                  control={control}
                  error={!!errors.receiver_id}
                  helperText={get(errors, 'receiver_id.message', '')}
                  as={({ value, onChange, error, helperText }) => {
                    if (value === '') {
                      return (
                        <Box>
                          <AdopterSelector
                            placeholder="Receiving Agency"
                            isDisabled={isSubmitting || isPhotoUploading}
                            rescuersList={receiversList}
                            onChange={({ value }) => onChange(value.receiver_id)}
                          />
                          {error && <FormHelperText error>{helperText}</FormHelperText>}
                        </Box>
                      );
                    }

                    const receiverData = receiversList.find(
                      (receiver) => receiver.value.receiver_id === parseInt(value, 10));
                    
                    return (
                      <Box display="flex" flexDirection="column">
                        <Typography>Receiving Agency:</Typography>
                        <ReceiverChip
                          isMobile={isMobileView}
                          value={get(receiverData, 'label', `Unknown receiver #${value}`)}
                          onClick={() => onChange('')}
                        />
                        {error && <FormHelperText error>{helperText}</FormHelperText>}
                      </Box>
                    );
                  }}
                />

                <Controller
                  name="rescuer_id"
                  control={control}
                  error={!!errors.rescuer_id}
                  helperText={get(errors, 'rescuer_id.message', '')}
                  as={({ value, onChange, error, helperText }) => {
                    if (value === '') {
                      return (
                        <Box>
                          <AdopterSelector
                            placeholder="Rescuer"
                            isDisabled={isSubmitting || isPhotoUploading}
                            rescuersList={rescuersListArray}
                            onChange={({ value }) => onChange(value.id)}
                          />
                          {error && <FormHelperText error>{helperText}</FormHelperText>}
                        </Box>
                      );
                    }

                    const rescuerData = rescuersListArray.find(rescuer => rescuer.value.id === parseInt(value, 10));
                    return (
                      <Box display="flex" flexDirection="column">
                        <Typography>Rescuer:</Typography>
                        <ReceiverChip
                          isMobile={isMobileView}
                          value={get(
                            rescuerData,
                            'label',
                            rescue.claimer !== null && typeof rescue.claimer === 'object'
                              ? rescue.claimer.name
                              : rescue.claimer
                          )}
                          onClick={() => onChange('')}
                        />
                        {error && <FormHelperText error>{helperText}</FormHelperText>}
                      </Box>
                    );
                  }}
                />

                <Controller
                  name="cancelled_by_id"
                  control={control}
                  as={({ value, onChange }) => {
                    if (value === '') {
                      return (
                        <AdopterSelector
                          placeholder="Cancelled by"
                          isDisabled={isSubmitting || isPhotoUploading}
                          rescuersList={cancelledByUsersListArray}
                          onChange={({ value }) => onChange(value.id)}
                        />
                      );
                    }

                    const rescuerData = cancelledByUsersListArray.find(
                      rescuer => rescuer.value.id === parseInt(value, 10)
                    );
                    return (
                      <Box display="flex" flexDirection="column">
                        <Typography>Cancelled by:</Typography>
                        <ReceiverChip
                          isMobile={isMobileView}
                          value={get(rescuerData, 'label', `Unknown user #${value}`)}
                          onClick={() => onChange('')}
                        />
                      </Box>
                    );
                  }}
                />

                <Controller
                  name="closed_by_id"
                  control={control}
                  disabled={!isRescueCompleted}
                  error={!!errors.closed_by_id}
                  helperText={get(errors, 'closed_by_id.message', '')}
                  as={({ value, onChange, disabled, error, helperText }) => {
                    if (value === '') {
                      return (
                        <Box>
                          <AdopterSelector
                            placeholder="Closed by"
                            isDisabled={isSubmitting || isPhotoUploading || disabled}
                            rescuersList={rescuersListArray}
                            onChange={({ value }) => onChange(value.id)}
                          />
                          {error && <FormHelperText error>{helperText}</FormHelperText>}
                        </Box>
                      );
                    }

                    const rescuerData = rescuersListArray.find(rescuer => rescuer.value.id === parseInt(value, 10));
                    return (
                      <Box display="flex" flexDirection="column">
                        <Typography>Closed by:</Typography>
                        <ReceiverChip
                          isDisabled={disabled}
                          isMobile={isMobileView}
                          value={get(rescuerData, 'label', `Inactive user #${value}`)}
                          onClick={() => onChange('')}
                        />
                        {error && <FormHelperText error>{helperText}</FormHelperText>}
                      </Box>
                    );
                  }}
                />

                <Controller
                  as={TextField}
                  control={control}
                  disabled={!isRescueCompleted}
                  variant={isRescueCompleted ? 'outlined' : 'filled'}
                  id="close_comments"
                  fullWidth
                  name="close_comments"
                  label="Close comments"
                  size="small"
                  multiple
                  rows={3}
                />

                <Box>
                  {photoFields.length >= 1 && (
                    <Box
                      display="grid"
                      gridTemplateColumns={isMobileView ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'}
                      gridGap={isMobileView ? 8 : 16}
                      p={1}
                    >
                      {photoFields.map((file, index) => (
                        <RescuePhotoButton
                          index={index}
                          key={file.id}
                          file={file}
                          disabled={isSubmitting || isPhotoUploading}
                          removePhoto={removePhoto}
                          onClick={() => removePhoto(index)}
                        />
                      ))}
                    </Box>
                  )}
                  <Box>
                    <UploadButton disabled={isSubmitting || isPhotoUploading || !isRescueCompleted} onChange={readFilesNew} />
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting || isPhotoUploading}>
          SAVE
        </Button>

        {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
      </form>
    </FormContext>
  );
};

export default PastRescueForm;
