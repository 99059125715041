import React, { useEffect, useState } from 'react';
import { withStyles, Grid, ListItemText, ListItem, Box } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { donorLinkFilters } from '../../../helpers/RescuesHelper';
import FilterComponent from './FilterComponent';

const FilterButton = withStyles(({ palette }) => ({
  filter: {
    padding: 0,
    height: 55,
    minWidth: 50,
    '&.Mui-selected': {
      backgroundColor: `${palette.primary.main} !important`,
      color: '#fff',
    },
    '& .MuiGrid-container': {
      marginLeft: '4px',
      marginRight: '4px',
    },
  },
}))(({ children, classes, ...rest }) => (
  <ToggleButton className={classes.filter} {...rest}>
    {children}
  </ToggleButton>
));

export const getDonorLinkLabel = selectedFilter => {
  if (!selectedFilter) {
    return 'Donor Link: all';
  }

  return `Donor Link: ${selectedFilter}`;
};

const DonorLinkSelectorFilter = ({ value, handleFilterChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(value);
  const filters = [
    donorLinkFilters.dataReceived,
    donorLinkFilters.dataNotReceived,
    donorLinkFilters.donorLinkInactive,
    donorLinkFilters.donorLinkActive,
  ];

  useEffect(() => {
    handleFilterChange(selectedFilter);
  }, [selectedFilter]);

  return (
    <>
      <ListItem button onClick={() => setIsOpen(!isOpen)}>
        <ListItemText primary={getDonorLinkLabel(selectedFilter)} />
        {isOpen ? <ExpandMore /> : <ExpandLess />}
      </ListItem>
      <FilterComponent isOpen={isOpen}>
        <Box
          py={2}
          px={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          direction="row"
          flexGrow={1}
          flexBasis={0}
        >
          <ToggleButtonGroup
            size="medium"
            exclusive
            value={selectedFilter}
            onChange={(event, newValue) => setSelectedFilter(newValue)}
            aria-label="text alignment"
          >
            {filters.map((filter, index) => (
              <FilterButton key={index} value={filter}>
                <Grid container direction="column" justify="center" alignItems="center">
                  <Grid>{filter}</Grid>
                </Grid>
              </FilterButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      </FilterComponent>
    </>
  );
};

export default DonorLinkSelectorFilter;
