import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PagePaper from '../../../components/Common/PagePaper';

const useStyles = makeStyles(theme => ({
  box: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 400,
    },
  },
  emailBox: {
    width: 'max-content',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
    },
  },
  gridContainer: {
    position: 'relative',
  },
}));

export const FormPaperContainer = ({ children, testId, email }) => {
  const classes = useStyles();
  return (
    <Grid container data-testid={testId}>
      <Grid item xs={12}>
        <Box className={email ? classes.emailBox : classes.box}>
          <PagePaper>
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
                spacing={2}
                className={classes.gridContainer}
              >
                {children}
              </Grid>
          </PagePaper>
        </Box>
      </Grid>
    </Grid>
  );
};

export const FormContainer = ({ children }) => {
  return (
    <Grid container>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
        {children}
      </Grid>
    </Grid>
  );
};

export const GridRow = ({ children, hidden }) => {
  return !hidden ? (
    <Grid item>
      <Grid container direction="column" justify="flex-start" alignItems="stretch">
        {children}
      </Grid>
    </Grid>
  ) : null;
};

export const RowTitle = ({ children }) => (
  <Grid item>
    <Typography variant="subtitle1" component="div">
      <Box fontWeight={600}>{children}</Box>
    </Typography>
  </Grid>
);

export const RowContent = ({ children }) => (
  <Grid item>
    <Typography variant="body1" component="div">
      {children}
    </Typography>
  </Grid>
);
