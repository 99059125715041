import React, { useEffect, useContext, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import { Tooltip, Table, IconButton, TableBody, TableRow, TableCell, Box, Typography, makeStyles, FormHelperText } from '@material-ui/core';
import DonationContext from '../../../../../../context/DonationContext/DonationContext';
import { FREQUENCY_MONTHLY, FREQUENCY_ONCE, FREQUENCY_WEEKLY } from '../../../../../../models/donationsNew';
import { dayname, ordinalDomToString } from '../../../../../../models/donations';
import { TextFieldWrapper } from '../../../../../../components/Form/MuiFormWrapper';
import ReceiverSelector from '../../../../../../components/ReceiverSelector';
import ReceiverChip from '../../../../../../components/ReceiverChip';
import RescuersNumber from '../../RescuersNumber';
import {
  donationManageActions,
  getPickupSpecBaseDay,
  groupMonthlyPickups,
  groupWeeklyPickups,
} from '../../../../../../helpers/donations';
import { Delete as DeleteIcon, OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import MinMaxTimePicker from '../../../../../../components/MinMaxTimePicker';
import { getReceiversSelectOptions, getRescuersSelectOptions } from '../../../../../../helpers/getters';
import { FREQUENCY_EVERY_OTHER_WEEK } from '../../../../../../models/donationsNew';
import useActiveSite from '../../../../../../hooks/useActiveSite';
import AdopterSelector from '../../../../../../components/AdopterSelector';
import AllPickupsTheSameSeparator from '../AllPickupsTheSame/AllPickupsTheSameSeparator';
import moment from 'moment';
import { Roles } from '../../../../../../models/roles';
import { hasAnyRoleInCurrentlySelectedSite } from '../../../../../../services/auth';
import useActiveUser from '../../../../../../hooks/useActiveUser';
import RescuerNumberSelectorMobile from '../Mobile/RescuerNumberSelectorMobile';
import PickupSpecCardMobile from '../Mobile/PickupSpecCardMobile';
import { sortAlphabetically } from '../../../../../../helpers/sorters';
import { DONATION_TYPE_REQUEST, DONATION_TYPE_REQUEST_APPROVAL, DONATION_TYPE_SD } from '../../../../../../actions/donationNew';
import { RECEIVING_HOURS_HASH } from '../AllDaysTheSame/AllDaysTheSame';
import { TAB_PROFILE } from '../../../../../../containers/ReceiverDashboard';
import routes from '../../../../../../routes';
import { Colors } from '../../../../../../assets/theme/Colors';

const useStyles = makeStyles((theme) => ({
  warningText: {
    color: Colors.errorText,
    marginTop: theme.spacing(1),
    width: '100%',
  },
  infoText: {
    color: Colors.gray,
    marginTop: theme.spacing(1),
  },
  warningLink: {
    color: Colors.errorText,
    display: 'inline-flex',
  },
  infoLink: {
    color: Colors.gray,
    display: 'inline-flex',
  },
  newTabIcon: {
    fontSize: '12px',
  },
}));

const getUniqueRowKey = ({ pickup, frequency }) => {
  if (frequency === FREQUENCY_MONTHLY) {
    return `day-${pickup.day}_ordinal-${pickup.ordinal}_position-${pickup.position}`;
  }

  return `day-${pickup.day}_position-${pickup.position}`;
};

export const MobileView = ({ days, isLoading, rescuersList, rescues, frequency, isNotSCOrGreaterRoleUser, receiversList }) => {
  const { onFieldChange, donationType } = useContext(DonationContext);
  return days.map((day, index) => {
    return (
      <Fragment
        key={getUniqueRowKey({
          pickup: day[1][0],
          frequency,
        })}
      >
        {[DONATION_TYPE_SD].includes(donationType) && (
          <RescuerNumberSelectorMobile
            value={day[1].length}
            frequency={frequency}
            pickup={day[1][0]}
            day={day[1][0].day}
            ordinal={day[1][0].ordinal}
            onRescuersNumberChange={() =>
              onFieldChange(donationManageActions.add_pickup, getPickupSpecBaseDay(day[1][0], frequency))
            }
          />
        )}

        {day[1].map((pickup, index) => (
          <PickupSpecCardMobile
            key={index}
            pickup={pickup}
            isLoading={isLoading}
            frequency={frequency}
            isNotSCOrGreaterRoleUser={isNotSCOrGreaterRoleUser}
            receiversList={receiversList}
            rescuersList={rescuersList}
            rescue={rescues[index]}
            donationType={donationType}
            onPickupBeginUpdate={(pickup, value) =>
              onFieldChange(donationManageActions.set_pickup_begin, {
                ...getPickupSpecBaseDay(pickup, frequency),
                begin: value,
              })
            }
            onPickupEndUpdate={(pickup, value) =>
              onFieldChange(donationManageActions.set_pickup_end, {
                ...getPickupSpecBaseDay(pickup, frequency),
                end: value,
              })
            }
            onPickupNotesChange={(pickup, value) =>
              onFieldChange(donationManageActions.set_pickup_rescuer_notes, {
                ...getPickupSpecBaseDay(pickup, frequency),
                rescuer_notes: value,
              })
            }
            onReceiverChange={(pickup, value) =>
              onFieldChange(donationManageActions.set_pickup_receiver, {
                ...getPickupSpecBaseDay(pickup, frequency),
                receiver_id: value ? value.receiver_id : null,
              })
            }
            onAdopterChange={(pickup, value) =>
              onFieldChange(donationManageActions.set_pickup_adopter, {
                ...getPickupSpecBaseDay(pickup, frequency),
                adopter_id: value ? value.id : null,
              })
            }
            onRescuerChange={(pickup, value) =>
              onFieldChange(donationManageActions.set_pickup_rescuer, {
                ...getPickupSpecBaseDay(pickup, frequency),
                rescuer_id: value ? value.id : null,
              })
            }
            onPickupDelete={pickup =>
              onFieldChange(donationManageActions.remove_pickup, getPickupSpecBaseDay(pickup, frequency))
            }
          />
        ))}
      </Fragment>
    );
  });
};

export const DayColumn = ({ frequency, pickup, colspan }) => {
  const { day, ordinal } = pickup;
  switch (frequency) {
    case FREQUENCY_ONCE:
      return <></>;
    case FREQUENCY_EVERY_OTHER_WEEK:
    case FREQUENCY_WEEKLY:
      return <TableCell align="center">{dayname(day)}</TableCell>;
    case FREQUENCY_MONTHLY:
      return (
        <TableCell colSpan={colspan} align="center">
          {ordinalDomToString(day, ordinal)}
        </TableCell>
      );
  }
};

const DaySeparator = ({ frequency, pickup, rescuersCount }) => {
  const { onFieldChange } = useContext(DonationContext);

  return (
    <TableRow>
      <DayColumn frequency={frequency} colspan={2} pickup={pickup} />
      {frequency !== FREQUENCY_MONTHLY && <TableCell />}
      <TableCell />
      <TableCell />
      <TableCell>
        <RescuersNumber
          inputId={`rescuer-${pickup.day}`}
          value={rescuersCount}
          handleClick={() => {
            const fields = {
              day: pickup.day,
            };

            if (frequency === FREQUENCY_MONTHLY) {
              fields.ordinal = pickup.ordinal;
            }

            return onFieldChange(donationManageActions.add_pickup, fields);
          }}
        />
      </TableCell>
      <TableCell />
      <TableCell />
      {frequency !== FREQUENCY_ONCE && <TableCell />}
      <TableCell />
    </TableRow>
  );
};

const DayRow = ({
  pickup,
  frequency,
  disabled,
  rescuersCount,
  totalStackedPickups,
  isNotSCOrGreaterRoleUser,
  receivers,
  receiversEntities,
  rescuersList,
  rescue,
  locationsList,
}) => {
  const classes = useStyles();
  const { onFieldChange, isMobileView, donationType, errors } = useContext(DonationContext);
  const [rescuerNotesValue, setRescuerNotesValue] = useState(pickup.rescuer_notes || '');
  const [pickupBeginTimeValue, setPickupBeginTimeValue] = useState(pickup.pickup_begin.value);
  const [pickupEndTimeValue, setPickupEndTimeValue] = useState(pickup.pickup_end.value);
  const [pickupEndTimeValid, setPickupEndTimeValid] = useState(false);

  // update state value on redux data change
  useEffect(() => setRescuerNotesValue(pickup.rescuer_notes || ''), [pickup.rescuer_notes]);
  useEffect(() => setPickupBeginTimeValue(pickup.pickup_begin.value), [pickup.pickup_begin.value]);
  useEffect(() => {
    setPickupEndTimeValid(false);
    setPickupEndTimeValue(pickup.pickup_end.value);
  }, [pickup.pickup_end.value]);

  const receiverId = pickup?.receiver?.id;
  const selectedReceiver = locationsList?.byReceiverId[receiverId];
  const receiverLocation = selectedReceiver?.length > 0 ? selectedReceiver[0] : null;
  const weeklyHours = receiverLocation?.weekly_receiving_hours || [];

  const hasHoursForSelectedDays = weeklyHours.find((time) => pickup.day === time.day_of_week);
  const receivingHours = weeklyHours.filter((time) => pickup.day === time.day_of_week);

  const isInReceivingHours = receivingHours.length
    ? receivingHours.find(
        (data) =>
          moment(pickup.pickup_begin?.value, 'HH:mm:ss').isBetween(
            moment(data.start, 'HH:mm:ss'),
            moment(data.end, 'HH:mm:ss'),
            undefined,
            '[]'
          ) &&
          moment(pickup.pickup_end?.value, 'HH:mm:ss').isBetween(
            moment(data.start, 'HH:mm:ss'),
            moment(data.end, 'HH:mm:ss'),
            undefined,
            '[]'
          )
      )
    : false;

  const pickupBeginIsBeforeEnd = moment(pickup.pickup_begin.value, 'HH:mm:ss').isAfter(
    moment(pickup.pickup_end.value, 'HH:mm:ss')
  );

  const handleNotesChange = event => setRescuerNotesValue(event.target.value);
  const handleBeginChange = ({ event }) => setPickupBeginTimeValue(event.format('HH:mm:00'));

  const updatePickupBeginTime = () => {
    onFieldChange(donationManageActions.set_pickup_begin, {
      ...getPickupSpecBaseDay(pickup, frequency),
      begin: pickupBeginTimeValue,
    });
  };

  const updatePickupEndTime = () => {
    if (pickupEndTimeValid) {
      onFieldChange(donationManageActions.set_pickup_end, {
        ...getPickupSpecBaseDay(pickup, frequency),
        end: pickupEndTimeValue,
      });
    }
  };

  const handleEndChange = ({ event, isValid }) => {
    if (isValid) {
      setPickupEndTimeValue(event.format('HH:mm:00'));
    }
    setPickupEndTimeValid(isValid);
  };

  const handleNotesBlur = event =>
    onFieldChange(donationManageActions.set_pickup_rescuer_notes, {
      ...getPickupSpecBaseDay(pickup, frequency),
      rescuer_notes: event.target.value,
    });

  const handleReceiverChange = ({ value }) =>
    onFieldChange(donationManageActions.set_pickup_receiver, {
      ...getPickupSpecBaseDay(pickup, frequency),
      receiver_id: value ? value.receiver_id : null,
    });

  const handleAdopterChange = value =>
    onFieldChange(donationManageActions.set_pickup_adopter, {
      ...getPickupSpecBaseDay(pickup, frequency),
      adopter_id: value ? value.id : null,
    });

  const handleRescuerChange = value =>
    onFieldChange(donationManageActions.set_pickup_rescuer, {
      ...getPickupSpecBaseDay(pickup, frequency),
      rescuer_id: value ? value.id : null,
    });

  return (
    <>
      <TableRow>
        <DayColumn frequency={frequency} pickup={pickup} />
        {totalStackedPickups >= 1 && <TableCell align="center">{pickup.position}</TableCell>}
        <TableCell style={{ minWidth: 120 }}>
          <MinMaxTimePicker
            disabled={disabled}
            inputVariant="outlined"
            size="small"
            variant={isMobileView ? 'dialog' : 'inline'}
            value={moment(pickupBeginTimeValue, 'HH:mm:ss')}
            maxTime={moment(pickupEndTimeValue, 'HH:mm:ss')}
            onTimeChange={handleBeginChange}
            onClose={updatePickupBeginTime}
            hasHoursForSelectedDay={hasHoursForSelectedDays}
            weeklyHours={weeklyHours}
            pickupSpecs={[pickup]}
            showWarning={(!isInReceivingHours && hasHoursForSelectedDays) || (errors.pickups && pickupBeginIsBeforeEnd)}
          />
        </TableCell>
        <TableCell style={{ minWidth: 120 }}>
          <MinMaxTimePicker
            disabled={disabled}
            showFieldErrors
            inputVariant="outlined"
            size="small"
            variant={isMobileView ? 'dialog' : 'inline'}
            value={moment(pickupEndTimeValue, 'HH:mm:ss')}
            minTime={moment(pickupBeginTimeValue, 'HH:mm:ss')}
            onTimeChange={handleEndChange}
            onClose={updatePickupEndTime}
            hasHoursForSelectedDay={hasHoursForSelectedDays}
            weeklyHours={weeklyHours}
            pickupSpecs={[pickup]}
            showWarning={(!isInReceivingHours && hasHoursForSelectedDays) || (errors.pickups && pickupBeginIsBeforeEnd)}
          />
        </TableCell>

        {[DONATION_TYPE_SD, DONATION_TYPE_REQUEST_APPROVAL].includes(donationType) && (
          <TableCell>
            {rescuersCount < 2 && (
              <RescuersNumber
                disabled={disabled}
                inputId={`rescuer`}
                value={rescuersCount}
                handleClick={() => {
                  const fields = {
                    day: pickup.day,
                  };

                  if (frequency === FREQUENCY_MONTHLY) {
                    fields.ordinal = pickup.ordinal;
                  }

                  return onFieldChange(donationManageActions.add_pickup, fields);
                }}
              />
            )}
          </TableCell>
        )}

        <TableCell style={{ minWidth: 200 }}>
          <TextFieldWrapper
            disabled={disabled}
            rowsMax={3}
            multiline
            name={`notes`}
            variant="outlined"
            size="small"
            fullWidth
            value={rescuerNotesValue}
            onChange={handleNotesChange}
            onBlur={handleNotesBlur}
          />
        </TableCell>

        {[DONATION_TYPE_SD, DONATION_TYPE_REQUEST_APPROVAL].includes(donationType) && (
          <>
            <TableCell style={{ minWidth: 200 }}>
              {pickup.receiver == null ? (
                <ReceiverSelector
                  isDisabled={disabled || isNotSCOrGreaterRoleUser}
                  name={`receiver.${pickup.day}_${pickup.position}_${pickup.ordinal || 0}`}
                  inputId={`receiver_${pickup.day}_${pickup.position}_${pickup.ordinal || 0}`}
                  instanceId={`receiver_${pickup.day}_${pickup.position}_${pickup.ordinal || 0}`}
                  receivers={receivers}
                  onChange={handleReceiverChange}
                />
              ) : (
                <ReceiverChip
                  isDisabled={isNotSCOrGreaterRoleUser}
                  value={pickup.receiver.name}
                  onClick={handleReceiverChange}
                />
              )}
            </TableCell>

            {frequency !== FREQUENCY_ONCE && (
              <TableCell style={{ minWidth: 200 }}>
                {pickup.adopter == null ? (
                  <AdopterSelector
                    isDisabled={disabled || isNotSCOrGreaterRoleUser}
                    rescuersList={rescuersList}
                    onChange={({ value }) => handleAdopterChange(value)}
                  />
                ) : (
                  <ReceiverChip
                    isDisabled={isNotSCOrGreaterRoleUser}
                    value={pickup.adopter.name}
                    onClick={() => handleAdopterChange(null)}
                  />
                )}
              </TableCell>
            )}

            {frequency === FREQUENCY_ONCE && (
              <TableCell style={{ minWidth: 200 }}>
                {rescue?.rescuer == null ? (
                  <AdopterSelector
                    placeholder="Rescuer"
                    noOptionsText="There are no active rescuers in the Site"
                    isDisabled={disabled || isNotSCOrGreaterRoleUser}
                    rescuersList={rescuersList}
                    onChange={({ value }) => handleRescuerChange(value)}
                  />
                ) : (
                  <ReceiverChip
                    isDisabled={isNotSCOrGreaterRoleUser}
                    value={rescue.rescuer.name}
                    onClick={() => handleRescuerChange(null)}
                  />
                )}
              </TableCell>
            )}
          </>
        )}

        <TableCell>
          <Tooltip
            title={
              rescuersCount === 1 && frequency === FREQUENCY_ONCE
                ? "'One time only' requires at least one pickup"
                : 'Delete pickup'
            }
            placement="right"
            arrow
          >
            <span>
              <IconButton
                disabled={rescuersCount === 1 && frequency === FREQUENCY_ONCE}
                onClick={() =>
                  onFieldChange(donationManageActions.remove_pickup, getPickupSpecBaseDay(pickup, frequency))
                }
                color="primary"
                size="small"
                aria-label="delete"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </TableCell>
      </TableRow>
      {errors.pickups && pickupBeginIsBeforeEnd && (
        <Box height={20}>
          <Box style={{ position: 'absolute' }}>
            <FormHelperText data-testid="pickup_end-error" id="pickup_end" error>
              {errors.pickups}
            </FormHelperText>
          </Box>
        </Box>
      )}
      {!isInReceivingHours && hasHoursForSelectedDays && (
        <Box height={55}>
          <Box style={{position: 'absolute'}}>
            <Typography variant="subtitle2" className={classes.warningText}>
              Pickup window is outside of the receiving hours for {receiversEntities.byId[receiverId].name}. If this is incorrect, update the
              receiving hours on the{' '}
              <Link
                className={classes.warningLink}
                target="_blank"
                to={{
                  pathname: generatePath(routes.receiver, { id: receiverId, tab: TAB_PROFILE }),
                  hash: RECEIVING_HOURS_HASH,
                }}
              >
                Profile <OpenInNewIcon className={classes.newTabIcon} />
              </Link>{' '}
              the receiving agency page.
            </Typography>
          </Box>
        </Box>
      )}
      {selectedReceiver && !hasHoursForSelectedDays && (
        <Box height={55}>
          <Box style={{ position: 'absolute' }}>
            <Typography variant="subtitle2" className={classes.infoText}>
              Pickup window might be outside of the receiving hours for {receiversEntities.byId[receiverId].name}. Update the receiving hours on the{' '}
              <Link
                className={classes.infoLink}
                state={{ scroll: true }}
                target="_blank"
                to={{
                  pathname: generatePath(routes.receiver, { id: receiverId, tab: TAB_PROFILE }),
                  hash: RECEIVING_HOURS_HASH,
                }}
              >
                Profile <OpenInNewIcon className={classes.newTabIcon} />
              </Link>{' '}
              the receiving agency page.
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

const PickupsListGrid = () => {
  const activeSite = useActiveSite();
  const activeUser = useActiveUser();
  const {
    pickup_specs: pickups,
    frequency,
    rescue_size: rescueSize,
  } = useSelector((state) => state.ui.donation_new.draft);
  const rescues = useSelector(state => state.ui.donation_new.rescues);
  const { isLoading, isMobileView, hasFoodDonorDefined, donationType, onFieldChange } = useContext(DonationContext);
  const receiversEntities = useSelector(state => state.entities.sites.receivers);
  const rescuersEntities = useSelector(state => state.entities.sites.rescuers.bySiteId[activeSite.id]);
  const receiversList = getReceiversSelectOptions(Object.values(receiversEntities.byId)).sort((a, b) =>
    sortAlphabetically('asc', b.label, a.label)
  );
  const rescuersList = getRescuersSelectOptions(rescuersEntities || []).sort((a, b) =>
    sortAlphabetically('asc', b.label, a.label)
  );
  const locationsEntities = useSelector(state => state.entities.receivers.locations);
  const days = frequency === FREQUENCY_MONTHLY ? groupMonthlyPickups(pickups) : groupWeeklyPickups(pickups);

  const totalStackedPickups = days.filter(day => day[1].length > 1).length;

  const rescueSizes = useSelector(state => state.entities.rescueSizes);
  const rescueSizesArray = rescueSizes.sorted;

  const isNotSCOrGreaterRoleUser = !hasAnyRoleInCurrentlySelectedSite(activeUser, [
    Roles.NationalSiteDirector,
    Roles.Admin,
    Roles.SiteDirector,
    Roles.SiteCoordinator,
  ]);

  const rescueSizeValue = (() => {
    if (rescueSize) {
      return rescueSize.id;
    }

    // no default default rescue size fallback for DONATION REQUEST
    if (DONATION_TYPE_REQUEST === donationType) {
      return null;
    }

    if (rescueSizesArray && rescueSizesArray.length) {
      return rescueSizesArray[0].id;
    }

    return null;
  })();

  if (!Array.isArray(pickups) || pickups.length === 0) {
    return null;
  }

  if (isMobileView) {
    return (
      <MobileView
        days={days}
        frequency={frequency}
        receiversList={receiversList}
        rescuersList={rescuersList}
        rescues={rescues}
        isNotSCOrGreaterRoleUser={isNotSCOrGreaterRoleUser}
        isLoading={isLoading}
      />
    );
  }

  return (
    <>
      <Table>
        <AllPickupsTheSameSeparator
          totalStackedPickups={totalStackedPickups}
          frequency={frequency}
          allPickupsTheSame={false}
        />
        <TableBody>
          {days.map((day, index) => {
            return (
              <Fragment
                key={getUniqueRowKey({
                  pickup: day[1][0],
                  frequency,
                })}
              >
                {day[1].length > 1 && (
                  <DaySeparator rescuersCount={day[1].length} frequency={frequency} pickup={day[1][0]} />
                )}

                {day[1].map((pickup, index) => (
                  <DayRow
                    disabled={isLoading}
                    isNotSCOrGreaterRoleUser={isNotSCOrGreaterRoleUser}
                    totalStackedPickups={totalStackedPickups}
                    rescuersCount={day[1].length}
                    showRescuerSelector={day[1].length === 1}
                    key={`${getUniqueRowKey({ pickup, frequency })}_index-${index}`}
                    pickup={pickup}
                    frequency={frequency}
                    receivers={receiversList}
                    receiversEntities={receiversEntities}
                    rescuersList={rescuersList}
                    rescue={rescues[index]}
                    locationsList={locationsEntities}
                  />
                ))}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default PickupsListGrid;
