import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  Badge,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Help as HelpIcon, ExpandMore, } from '@material-ui/icons';
import ReactSelect from 'react-select';
import donorImage from '../../../assets/images/donor.png';
import receiverImage from '../../../assets/images/receiver.png';
import rescuerImage from '../../../assets/images/rescuer.png';
import unassignedUserImage from '../../../assets/images/unassigned-user.png';
import heatmapIcon from '../../../assets/svg/heatmapIcon.svg';
import { Colors } from '../../../assets/theme/Colors';
import DateAndGranularityPicker, { dateRangeOptionsList } from '../../../components/DateAndGranularityPicker/DateAndGranularityPicker';
import { getUserFullName } from '../../../helpers/user';
import { formatAddress } from '../../../helpers/formatters';
import { getChromaColor } from '../../../helpers/colors';
import { onLoad } from '@sentry/react';

export const MARKER_DONOR = 'donor';
export const MARKER_RECEIVER = 'receiver';
export const MARKER_RESCUER = 'rescuer';
export const MARKER_UNASSIGNED_USER = 'unassigned_useer';

const selectStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 101,
  }),
  container: (provided) => ({
    ...provided,
    maxWidth: 400,
  }),
  valueContainer: (styles) => ({ ...styles, minHeight: 50, minWidth: 200 }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: getChromaColor(data.color).backgroundColor,
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: getChromaColor(data.color).color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};

const useStyles = makeStyles(() => ({
  accordion: {
    '&.Mui-expanded': {
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  summary: {
    position: 'relative',
    fontWeight: 'bold',
    height: '40px',
    minHeight: '40px',
    '&.Mui-expanded': {
      minHeight: '50px',
    },
  },
  badge: {
    position: 'absolute',
    right: 50,
    top: (props) => (props.expanded ? 25 : 20),
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  heatmapIcon: {
    width: '28px',
    height: '28px',
    alignSelf: 'center',
  },
}));

const StyledList = withStyles(({ palette }) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: palette.background.paper,
  },
}))(List);

const SwitchOption = ({ handleSwitchToggle, visiblePins, switchType, label, markerUrl, disabled = false }) => (
  <ListItem>
    <ListItemIcon>
      <img src={markerUrl} alt={switchType} />
    </ListItemIcon>
    <ListItemText id={`switch-list-label-${switchType}`} primary={label} />
    <ListItemSecondaryAction>
      <Switch
        edge="end"
        disabled={disabled}
        onChange={handleSwitchToggle(switchType)}
        checked={visiblePins.indexOf(switchType) !== -1}
      />
    </ListItemSecondaryAction>
  </ListItem>
);

const MarkersSelector = ({
  visiblePins,
  handleSwitchToggle,
  isLoading,
  isAdmin,
  dateRange,
  onDateRangeChange,
  onAccordionChange,
  users,
  selectedUsers,
  handleUserChange,
  toggleHeatmap,
  expanded = false,
  badgeCounter = 0,
  heatmapActive = false,
}) => {
  const classes = useStyles({ expanded });
  const [inputValue, setInputValue] = useState('');
  const [usersOptions, setUsersOptions] = useState([]);
  const loadSuggestionsMinChars = 2;

  const getUserName = (option) => option.meta?.title || option.meta?.name || getUserFullName(option.meta);


  const getUserLabel = (option) => {
    const formattedAddress = option
      ? formatAddress({
          address: option.meta.address,
          city: option.meta.city,
          st: option.meta.st,
          zip: option.meta.zip,
        })
      : null;

    if (formattedAddress && !getUserName(option)) {
      return formattedAddress;
    }

    return getUserName(option);
  };

  useEffect(() => {
    if (inputValue.length <= loadSuggestionsMinChars) {
      return setUsersOptions([]);
    }

    const filteredOptions = users.filter((option) => {
      return getUserLabel(option).toLowerCase().includes(inputValue.toLowerCase());
    });

    return setUsersOptions(filteredOptions);
  }, [inputValue]);

  const onInputChange = (option, { action }) => {
    if (action === 'input-change') {
      setInputValue(option);
    } else {
      setInputValue('');
    }
  };

  const renderOption = (option, { selectValue = [] }) => {
    const isSelected = selectValue.find((user) => user.meta.id === option.meta.id);

    if (isSelected) {
      return getUserLabel(option);
    }

    if (!getUserName(option)) {
      return (
        <Typography gutterBottom variant="body1">
          {formatAddress({
            address: option.meta.address,
            city: option.meta.city,
            st: option.meta.st,
            zip: option.meta.zip,
          })}
        </Typography>
      );
    }

    return (
      <Box display="flex" flexDirection="column" pb={1}>
        <Typography variant="body1">{getUserName(option)}</Typography>
        {(option.meta._markerType === MARKER_DONOR || option.meta._markerType === MARKER_RECEIVER) && (
          <Typography color="textSecondary" variant="body2">
            {formatAddress({
              address: option.meta.address,
              city: option.meta.city,
              st: option.meta.st,
              zip: option.meta.zip,
            })}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Accordion className={classes.accordion} elevation={0} onChange={onAccordionChange} expanded={expanded}>
      <AccordionSummary className={classes.summary} expandIcon={<ExpandMore />}>
        Users
        <Badge className={classes.badge} badgeContent={badgeCounter} color="error" />
      </AccordionSummary>
      <Box p="8px 16px 8px">
        <ReactSelect
          styles={selectStyles}
          isDisabled={isLoading}
          placeholder="Search for a user or leave blank to show all users"
          value={selectedUsers}
          options={usersOptions}
          isMulti
          formatOptionLabel={renderOption}
          getOptionLabel={(option) => getUserLabel(option)}
          onInputChange={onInputChange}
          getOptionValue={(option) => option.meta.id}
          onChange={handleUserChange}
          noOptionsMessage={() =>
            inputValue.length <= loadSuggestionsMinChars ? 'Type the 3 first letters to see results' : 'User not found'
          }
        />
      </Box>
      <StyledList dense>
        <ListItem>
          <ListItemIcon>
            <img src={heatmapIcon} alt="Coverage" className={classes.heatmapIcon} />
          </ListItemIcon>
          <ListItemText id="heatmap" primary="Coverage" />
          <ListItemSecondaryAction>
            <Switch edge="end" disabled={isLoading} onChange={toggleHeatmap} checked={heatmapActive} />
          </ListItemSecondaryAction>
        </ListItem>
      </StyledList>
      <Divider />
      <StyledList dense>
        <SwitchOption
          disabled={isLoading}
          label="Donors"
          markerUrl={donorImage}
          switchType={MARKER_DONOR}
          handleSwitchToggle={handleSwitchToggle}
          visiblePins={visiblePins}
        />
        <SwitchOption
          disabled={isLoading}
          label="Receiving Agencies"
          markerUrl={receiverImage}
          switchType={MARKER_RECEIVER}
          handleSwitchToggle={handleSwitchToggle}
          visiblePins={visiblePins}
        />
        <SwitchOption
          disabled={isLoading}
          label={(
            <Box display="flex">
              <Typography variant="body2">Rescuers</Typography>
              <Tooltip
                style={{ marginLeft: '5px', paddingBottom: '2px' }}
                arrow
                placement="top"
                title="Only rescuers who have provided their full address will appear on the map."
              >
                <HelpIcon htmlColor={Colors.blue.main} />
              </Tooltip>
            </Box>
          )}
          markerUrl={rescuerImage}
          switchType={MARKER_RESCUER}
          handleSwitchToggle={handleSwitchToggle}
          visiblePins={visiblePins}
        />
        {isAdmin && <SwitchOption
          disabled={isLoading}
          label="Unassigned Users"
          markerUrl={unassignedUserImage}
          switchType={MARKER_UNASSIGNED_USER}
          handleSwitchToggle={handleSwitchToggle}
          visiblePins={visiblePins}
        />}
      </StyledList>
      <Divider />
      <Box p={2}>
        <DateAndGranularityPicker
          label="Registration Date Range"
          defaultDateRange={dateRange?.period || 'all'}
          showGranularity={false}
          showPeriod={dateRange.period === 'custom'}
          dateFullWidth
          showOnlyDatesInLabel
          dateRangeOptions={{
            lastWeek: dateRangeOptionsList.lastWeek,
            last7Days: dateRangeOptionsList.last7Days,
            last90Days: dateRangeOptionsList.last90Days,
            thisMTD: dateRangeOptionsList.thisMTD,
            thisQTD: dateRangeOptionsList.thisQTD,
            thisYTD: dateRangeOptionsList.thisYTD,
          }}
          showCustomDateRange
          showAllDateRange
          customDateRangeOptions={{
            maxDate: new Date(),
          }}
          returnDateObject={false}
          onChange={(data) => onDateRangeChange(data)}
        />
      </Box>
    </Accordion>
  );
};

export default MarkersSelector;
