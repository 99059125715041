import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Collapse, Typography, IconButton, FormHelperText, InputLabel } from '@material-ui/core';
import {
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import { useFormContext, Controller } from 'react-hook-form';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import { TimePicker } from '@material-ui/pickers';
import { Colors } from '../../../../../assets/theme/Colors';
import { TextFieldWrapper } from '../../../../../components/Form/MuiFormWrapper';
import AdopterSelector from '../../../../../components/AdopterSelector';
import ReceiverChip from '../../../../../components/ReceiverChip';
import { FREQUENCY_ONCE } from '../../../../../models/donationsNew';

const useStyles = makeStyles(({ spacing }) => ({
  rowGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 0.7fr',
    gridColumnGap: spacing(2),
  },
  rescuerSelectRowGrid: {
    display: 'grid',
    gridTemplateColumns: '2fr 0.7fr',
    gridColumnGap: spacing(1),
  },
}));

const EventShiftRowForm = ({
  isEditView,
  isMobileView,
  allowAdd,
  allowDelete,
  shift,
  index,
  handleAdd,
  handleRemove,
  rescuersList = [],
  eventShiftData = [],
}) => {
  const classes = useStyles();
  const [showVolunteers, setShowVolunteers] = useState(false);
  const {
    control,
    register,
    errors,
    getValues,
    setValue,
    formState: { isSubmitted },
  } = useFormContext();
  const maxVolunteersError = get(errors, `shifts[${index}].max_volunteers.message`);
  const beginError = get(errors, `shifts[${index}].begin.message`);
  const endError = get(errors, `shifts[${index}].end.message`);
  const [minVolunteers, setMinVolunteers] = useState(getValues(`shifts[${index}].min_volunteers`) || 0);
  const [maxVolunteers, setMaxVolunteers] = useState(getValues(`shifts[${index}].max_volunteers)`) || 0);
  const [rescuerSelectsNumber, setRescuerSelectsNumber] = useState([]);
  const registrations = eventShiftData?.registrations;
  const registeredRescuers =
    registrations &&
    registrations.map((registration) => ({
      id: registration.user.id,
      name: `${registration.user.firstname} ${registration.user.lastname}`,
    }));
  const [selectedRescuers, setSelectedRescuers] = useState(registeredRescuers || []);
  const formValues = getValues();

  useEffect(() => {
    if (registrations && registrations.length > minVolunteers && registrations.length > maxVolunteers) {
      return setRescuerSelectsNumber([...Array(registrations.length)]);
    }

    if (maxVolunteers > minVolunteers && minVolunteers > 0) {
      const rescuersArray = [...selectedRescuers];
      rescuersArray.length = maxVolunteers;
      setValue(`shifts[${index}].rescuers`, rescuersArray);
      setSelectedRescuers(rescuersArray);
      return setRescuerSelectsNumber([...Array(Number(maxVolunteers))]);
    }

    if (minVolunteers >= maxVolunteers && minVolunteers > 0) {
      const rescuersArray = [...selectedRescuers];
      rescuersArray.length = minVolunteers;
      setValue(`shifts[${index}].rescuers`, rescuersArray);
      setSelectedRescuers(rescuersArray);
      return setRescuerSelectsNumber([...Array(Number(minVolunteers))]);
    }

    const rescuersArray = [...selectedRescuers];
    rescuersArray.length = 1;
    return setRescuerSelectsNumber([1]);
  }, [minVolunteers, maxVolunteers]);

  useEffect(() => {
    setValue(`shifts[${index}].rescuers`, selectedRescuers);
  }, [selectedRescuers]);

  const handleRescuerChange = (value, i) => {
    if (!value) {
      const rescuersArray = [...selectedRescuers];
      rescuersArray[i] = null;
      setValue(`shifts[${index}].rescuers`, rescuersArray);
      return setSelectedRescuers(rescuersArray);
    }
    const rescuersArray = [...selectedRescuers];
    rescuersArray[i] = value;
    setValue(`shifts[${index}].rescuers`, rescuersArray);
    return setSelectedRescuers(rescuersArray);
  };

  if (maxVolunteersError && !showVolunteers) {
    setShowVolunteers(true);
  }

  return (
    <>
      <Controller name={`shifts[${index}].id`} defaultValue={shift.id} control={control} as="input" type="hidden" />
      <Controller name={`shifts[${index}].rescuers`} value={shift.rescuers} control={control} as="input" type="hidden" />
      <Controller name={`shifts[${index}].min_volunteers`} value={shift.in_volunteers} control={control} as="input" type="hidden" />
      <Controller name={`shifts[${index}].max_volunteers`} value={shift.max_volunteers} control={control} as="input" type="hidden" />
      <Box display="flex" flexDirection="column" data-testid={`shift-row_${index}`}>
        <Box className={classes.rowGrid}>
          <Controller
            name={`shifts[${index}].begin`}
            control={control}
            defaultValue={shift.begin}
            as={({ value, name }) => (
              <TimePicker
                error={!!beginError}
                helperText={beginError}
                value={value}
                name={name}
                inputVariant="outlined"
                variant={isMobileView ? 'dialog' : 'inline'}
                label="From"
                size="small"
                minutesStep={5}
                onChange={(event) => {
                  const { shifts } = getValues({ nest: true });
                  const { end } = getValues({ nest: true });
                  const durationDiff = moment.duration(shifts[index].end.diff(shifts[index].begin));
                  // Check if the shift end time is not after the event end time
                  const endTime = event.clone().add(durationDiff).isSameOrBefore(end) ? event.clone().add(durationDiff) : end;

                  setValue(`shifts[${index}].begin`, event, true);
                  setValue(`shifts[${index}].end`, endTime, true);
                }}
              />
            )}
          />
          <Controller
            name={`shifts[${index}].end`}
            control={control}
            defaultValue={shift.end}
            as={({ value, name }) => (
              <TimePicker
                value={value}
                name={name}
                error={!!endError}
                helperText={endError}
                inputVariant="outlined"
                variant={isMobileView ? 'dialog' : 'inline'}
                label="To"
                size="small"
                minutesStep={5}
                onChange={(event) => setValue(`shifts[${index}].end`, event, true)}
              />
            )}
          />
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              color="primary"
              disabled={!allowAdd}
              data-testid={`add-address_${index}`}
              size="small"
              onClick={handleAdd}
            >
              <AddCircleOutlineIcon />
            </IconButton>

            <IconButton
              disabled={!allowDelete}
              data-testid={`remove-address_${index}`}
              size="small"
              onClick={handleRemove}
            >
              <RemoveCircleOutlineIcon htmlColor={allowDelete ? Colors.errorText : undefined} />
            </IconButton>

            <IconButton
              data-testid={`show-volunteers_${index}`}
              size="small"
              onClick={() => setShowVolunteers(!showVolunteers)}
            >
              {showVolunteers ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        </Box>

        {isEditView && (
          <Box>
            {shift.activeRegistrations > 0 ? (
              <FormHelperText>Registered {formValues?.frequency !== FREQUENCY_ONCE ? 'adopters' : 'rescuers'}: {shift.activeRegistrations}</FormHelperText>
            ) : (
              <FormHelperText>
                No {formValues?.frequency !== FREQUENCY_ONCE ? 'adopters' : 'rescuers'} assigned
              </FormHelperText>
            )}
          </Box>
        )}

        <Collapse in={showVolunteers} data-testid={`volunteers-row_${index}`}>
          <Box mt={2}>
            <Typography variant="subtitle2" style={{ marginBottom: '0.35em' }}>
              Volunteers
            </Typography>

            <Box className={classes.rowGrid}>
              <TextFieldWrapper
                name={`shifts[${index}].min_volunteers`}
                defaultValue={shift.min_volunteers}
                label="Min"
                type="number"
                inputProps={{
                  min: 0,
                }}
                onChange={({ target }) => {
                  setValue(`shifts[${index}].min_volunteers`, target.value);
                  setMinVolunteers(target.value);
                }}
                variant="outlined"
                size="small"
              />

              <TextFieldWrapper
                name={`shifts[${index}].max_volunteers`}
                defaultValue={shift.max_volunteers}
                label="Max"
                type="number"
                inputProps={{
                  min: 0,
                }}
                onChange={({ target }) =>{ 
                  setValue(`shifts[${index}].max_volunteers`, target.value);
                  setMaxVolunteers(target.value);
                }}
                error={!!maxVolunteersError}
                variant="outlined"
                size="small"
              />
            </Box>
            {rescuerSelectsNumber.map((n, i) => (
              <Box className={classes.rescuerSelectRowGrid} key={i}>
                <Box>
                  <InputLabel component="label" shrink>
                    {formValues?.frequency !== FREQUENCY_ONCE ? 'Adopter' : 'Rescuer'}
                  </InputLabel>
                  {selectedRescuers && !selectedRescuers[i] ? (
                    <AdopterSelector
                      name={`shifts[${index}].rescuers`}
                      placeholder={
                        formValues?.frequency !== FREQUENCY_ONCE ? 'Adopter (optional)' : 'Rescuer (optional)'
                      }
                      noOptionsText="There are no active rescuers in the Site"
                      rescuersList={rescuersList}
                      onChange={({ value }) => handleRescuerChange(value, i)}
                    />
                  ) : (
                    <ReceiverChip
                      value={selectedRescuers && selectedRescuers[i] ? selectedRescuers[i].name : null}
                      onClick={() => handleRescuerChange(null, i)}
                    />
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Collapse>
        {maxVolunteersError && <FormHelperText error>{maxVolunteersError}</FormHelperText>}
      </Box>
    </>
  );
};

export default EventShiftRowForm;
