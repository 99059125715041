import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { fetchEnvironmentImpact } from '../../../actions/environmentImpact';
import MyEnviroImpact from '../../rescuerDashboard/rescuerDashboardDesktop/myFrus/Components/MyEnviroImpact';

const SCDashboardEnvironmentImpact = ({ siteId }) => {
  const dispatch = useDispatch();
  const environmentImpact = useSelector((state) => state.entities.environmentImpact);

  useEffect(() => {
    dispatch(fetchEnvironmentImpact({ sites_ids: siteId }));
  }, [dispatch]);

  return (
    <Box>
      <Box mt={1} mb={2}>
        <Typography variant="h6" color="textSecondary">
          Environmental Impact
        </Typography>
      </Box>
      <Box display="flex">
        <MyEnviroImpact isLoading={environmentImpact.inflight} environmentImpact={environmentImpact.all} />
      </Box>
    </Box>
  );
};

export default SCDashboardEnvironmentImpact;
