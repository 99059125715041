import React, { useState } from 'react';
import { Provider } from 'react-redux';
import moment from 'moment';
import {
  Box,
  InputLabel,
  makeStyles,
  MenuItem,
  MuiThemeProvider,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { mainTheme } from '../assets/theme';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import { store } from '../store';

const useStyles = makeStyles((theme) => ({
  radioGroupContainer: {
    paddingBottom: '2rem',
    [theme.breakpoints.up('sm')]: {
      minWidth: 600,
    },
  },
}));

export const generateKeyId = label => `confirmation-dialog-btn-${label.toLocaleLowerCase().replace(/[\s_]/g, '_')}`;
const ConfirmationDialog = props => {
  const { closeDialog, buttons, title, message, customMessage = undefined, dialogProps = {}, secondStep = {} } = props;
  const [deleteInputValue, setDeleteInputValue] = useState('');
  const [secondStepVisible, setSecondStepVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [additionNotes, setAdditionNotes] = useState(false);
  const classes = useStyles();

  const showDeleteInput = buttons.filter((button) => button.requireDeleteInput).length;

  const handleButtonClick = onClick => {
    // Check if second step is required and not yet activated
    if (onClick && secondStep.display && !secondStepVisible) {
      return setSecondStepVisible(true);
    }

    if (onClick) {
      onClick(selectedValue, additionNotes);
    }
    closeDialog();
  };

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={mainTheme}>
        <Dialog open aria-labelledby="draggable-dialog-title" {...dialogProps}>
          {title && (
            <DialogTitle data-testid="confirmation-dialog-title">
              {secondStepVisible && secondStep.title ? secondStep.title : title}
            </DialogTitle>
          )}
          {!secondStepVisible && (
            <DialogContent>
              {message && <DialogContentText data-testid="confirmation-dialog-text">{message}</DialogContentText>}
              {customMessage && customMessage}
              {showDeleteInput > 0 && (
                <Box display="flex" justifyContent="center">
                  <TextField
                    variant="outlined"
                    placeholder="DELETE"
                    value={deleteInputValue}
                    onChange={({ target }) => setDeleteInputValue(target.value)}
                  />
                </Box>
              )}
            </DialogContent>
          )}

          {secondStepVisible && (
            <DialogContent>
              {secondStep.message && (
                <DialogContentText data-testid="confirmation-dialog-text">{secondStep.message}</DialogContentText>
              )}

              {secondStep.selectOptions?.length && (
                <>
                  <Box className={classes.radioGroupContainer}>
                    <Select
                      labelId="delete-select-label"
                      label="Delete reason"
                      variant="outlined"
                      value={selectedValue || 'none'}
                      onChange={({ target }) => setSelectedValue(target.value)}
                    >
                      <MenuItem value="none" disabled>
                        <em>Delete reason</em>
                      </MenuItem>
                      {secondStep.selectOptions.map((button) => (
                        <MenuItem key={button.value} value={button.value}>{button.label}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    rowsMax={4}
                    placeholder="Additional notes (optional)"
                    inputProps={{ maxLength: 250 }}
                    onChange={({ target }) => setAdditionNotes(target.value)}
                  />
                </>
              )}

              {secondStep.tableData?.length && (
                <Box pt={2}>
                  <Typography variant="h6">{secondStep.tableTitle || "Upcoming Rescues"}</Typography>
                  <DialogContentText>Showing only first ten upcoming rescues.</DialogContentText>
                  <BaseMUIDataTable
                    tableId="#rescues/confirmationDialogTable"
                    data={secondStep.tableData.slice(0, 10)}
                    columns={[
                      {
                        name: 'date',
                        label: 'Date',
                        options: {
                          forceDisplay: true,
                          customBodyRender: (value) => moment(value, 'YYYYMMDD').format('MM/DD/YYYY'),
                        },
                      },
                      {
                        name: 'pickup_begin',
                        label: 'Pickup Begin',
                        options: {
                          forceDisplay: true,
                          customBodyRender: (value) => value || '-',
                        },
                      },
                      {
                        name: 'pickup_end',
                        label: 'Pickup End',
                        options: {
                          forceDisplay: true,
                          customBodyRender: (value) => value || '-',
                        },
                      },
                      {
                        name: 'receiver',
                        label: 'Receiving Agency',
                        options: {
                          forceDisplay: true,
                          customBodyRender: (value) => value || '-',
                        },
                      },
                      {
                        name: 'rescuer',
                        label: 'Rescuer',
                        options: {
                          forceDisplay: true,
                          customBodyRender: (value) => value || '-',
                        },
                      },
                    ]}
                    options={{
                      sortOrder: {
                        name: 'date',
                        direction: 'asc',
                      },
                      sort: false,
                      filter: false,
                      download: false,
                      search: false,
                      viewColumns: false,
                      pagination: false,
                      elevation: 0,
                      selectableRows: 'none',
                      responsive: 'simple',
                    }}
                  />
                </Box>
              )}
            </DialogContent>
          )}

          <DialogActions>
            {Array.isArray(buttons) &&
              buttons.length > 0 &&
              (secondStepVisible && !secondStep.buttons || !secondStepVisible) &&
              buttons.map((button) => {
                const { label, onClick, requireDeleteInput = false, ...rest } = button;
                return (
                  <Button
                    type="button"
                    variant={button?.variant || 'contained'}
                    key={generateKeyId(label)}
                    data-testid={button?.testId || generateKeyId(label)}
                    onClick={() => handleButtonClick(onClick)}
                    disabled={
                      (requireDeleteInput ? deleteInputValue !== 'DELETE' : false) ||
                      (secondStepVisible && secondStep.selectOptions?.length ? !selectedValue && !!onClick : false)
                    }
                    {...rest}
                  >
                    {label}
                  </Button>
                );
              })}

            {secondStepVisible &&
              secondStep.buttons?.length &&
              secondStep.buttons.map((button) => {
                const { label, onClick, requireDeleteInput = false, ...rest } = button;
                return (
                  <Button
                    type="button"
                    variant="contained"
                    key={generateKeyId(label)}
                    data-testid={generateKeyId(label)}
                    onClick={() => handleButtonClick(onClick)}
                    disabled={
                      (requireDeleteInput ? deleteInputValue !== 'DELETE' : false) ||
                      (secondStepVisible && secondStep.selectOptions?.length ? !selectedValue && !!onClick : false)
                    }
                    {...rest}
                  >
                    {label}
                  </Button>
                );
              })}

            {!buttons && (
              <Button autoFocus onClick={() => closeDialog()} color="primary" data-testid={generateKeyId('Cancel')}>
                Cancel
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    </Provider>
  );
};

ConfirmationDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  buttons: PropTypes.array,
  dialogProps: PropTypes.object,
};

export default ConfirmationDialog;
