const routes = {
  index: '/',
  contactUs: '/contact-us',
  contactList: '/contact-list/:tab?',
  eventsList: '/events/list',
  eventAdd: '/events/add',
  eventEdit: '/events/edit/:eventId',
  event: '/events/details/:eventId',
  eventSignup: '/events/signup/:eventId',
  dashboardFoodDonor: '/food-donor-dashboard/:tab?',
  dashboardReceiver: '/receiver-dashboard/:tab?',
  dashboardRescuer: '/rescuer-dashboard/:tab?',
  marketingHub: '/marketing-hub/:tab?',
  mobileDashboard: '/mobile-dashboard',
  mobileDashboardRescues: '/mobile-dashboard/rescues/:tab?',
  mobileDashboardBadges: '/mobile-dashboard/my-badges',
  mobileDashboardSiteGoals: '/mobile-dashboard/site-goals',
  mobileDashboardEnvironmentImpact: '/mobile-dashboard/environment-impact',
  mobileDashboardSchedule: '/mobile-dashboard/schedule',
  pendingDonations: '/donation/pending',
  pendingFoodDonorsRegistrations: '/food-donor/registration-request',
  mobileDashboardEventSignup: '/rescuer-dashboard/event/signup/:eventId?',
  mobileDashboardEvents: '/mobile-dashboard/events/:tab?',
  mobileDashboardPhotos: '/mobile-dashboard/photos',
  dashboardRescuerEvents: '/rescuer-dashboard/events/:eventId?/signup',
  dashboardSC: '/sc-dashboard/:tab?',
  siteGoalAdd: '/add-new-site-goal',
  dashboardSCEvents: '/sc-dashboard/events/:eventId?/signup',
  dashboardNSD: '/nsd-dashboard/:tab?',
  foodDonors: '/all-users/food-donors',
  foodDonorAdd: '/food-donors/add',
  foodDonor: '/food-donors/:foodDonorId/:tab?',
  foodDonorStaffAdd: '/food-donors/:foodDonorId/staff/add',
  foodDonorPickupLocationCreate: '/food-donors/:foodDonorId/pickup-locations/add',
  foodDonorPickupLocationEdit: '/food-donors/:foodDonorId/pickup-locations/:pickupLocationId/edit',
  umbrellaFoodDonor: '/umbrella-food-donors/:id/:tab?',
  umbrellaFoodDonors: '/all-users/food-donors/umbrella-food-donors',
  umbrellaFoodDonorsStaffAdd: '/umbrella-food-donors/:id/staff/add',
  umbrellaFoodDonorsAdd: '/umbrella-food-donors/add',
  umbrellaFoodDonorAddToUmbrella: '/umbrella-food-donors/:id/food-donors/add',
  receiverStaffAdd: '/receivers/:receiverId/staff/add',
  receiver: '/receivers/:id/:tab?',
  receivers: '/all-users/receivers',
  receivers_add: '/receivers/add',
  rescuers: '/all-users/rescuers',
  rescuersAdd: '/rescuers/add',
  donations: '/rescues-list/rescues',
  foodDonorRegistrationReview: '/food-donor-registration-request/:foodDonorId/verify',
  donation_request_verify: '/donations-request/:donationId/verify',
  donations_add: '/donors/:donor_id/locations/:location_id/donations/add',
  donations_add_2: '/donations/add',
  donation_add_new: '/donation/add',
  donation_add_new2: '/donors/:donor_id/locations/:location_id/donation/add',
  donor_link_donation_add: '/:location_id/donation/add',
  donation_add_from_map: '/:location_id/:pickup_location_id/:receiver_id/donation/add/',
  donation_requests: '/donation/requests',
  donation_create_request: '/donation/create-request',
  donation_edit_request: '/donation/:donationId/edit-request',
  donation_edit: '/donation/:donationId/edit',
  donor_link_donation_edit: '/:location_id/donation/:donationId/edit',
  donation: '/donations/:donationId',
  rescuesGallery: '/gallery',
  rescue: '/rescues/details/:rescueId',
  rescueAdoptConfirmation: '/rescues/adopt/:rescueId',
  rescue_edit: '/rescues/:rescueId',
  rescuesSchedule: '/rescues-list/schedule',
  rescuesScheduleEdit: '/schedule/:rescueId',
  rescues: '/rescues-list/upcoming-rescues',
  pastrescues: '/rescues-list/past-rescues',
  emails: '/emails',
  emailDetails: '/emails/:emailId',
  sms: '/sms',
  switchUser: '/switch-user',
  login: '/login',
  signup: '/signup',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  loggedout: '/loggedout',
  callback: '/callback',
  oAuthCallback: '/oauth/callback',
  notifications: '/notifications',
  notification: '/notifications/:notificationId',
  notificationsPreferences: '/notifications-preferences',
  map: '/map',
  completeRescuerRegistration: '/complete-rescuer-registration',
  completeRegistrationNew: '/completeRegistration/:accountType?',
  whatsnew: '/whatsnew',
  welcomePage: '/welcome-page',
  systemNotAvailable: '/system-not-available',
  profile: '/profile/:tab?',
  sites: '/sites',
  sitesAdd: '/sites/add',
  siteEdit: '/sites/:id/edit',
  users: '/all-users/users',
  unassignedUsers: '/all-users/unassigned-users',
  unassignedFoodDonors: '/all-users/food-donors/unassigned-food-donors',
  unassignedFoodDonorEdit: '/unassigned-food-donors/:id/edit',
  inProgressRegistrations: '/all-users/in-progress-registrations',
  usersAdd: '/users/add',
  userEdit: '/users/:id/edit',
  userEditNew: '/users/:id',
  settings: '/settings/:tab?',
  rescueAdd: '/rescue/add',
  rescueClose: '/rescue/close/:rescueId',
  rescueCloseEnviro: '/rescue/close/:rescueId/environmental-impact',
  rescueCloseNoShow: '/rescue/close/noshow/:rescueId',
  reports: '/reports/:tab?',
  reportRescues: '/reports/rescue',
  reportReceiver: '/reports/receiving-agency',
  reportRescuedFood: '/reports/rescued-food',
  reportGrowth: '/reports/growth',
  reportVolume: '/reports/volume',
  sendEmail: '/send-email',
  sendSms: '/send-sms',
  termsOfService: '/terms-of-service/:type(food-donor|rescuer)',
  privacyPolicy: '/privacy-policy',
  quicksightReports: '/reports/quicksight-reports',
  idMode: '/id-mode',
  rescuesList: '/rescues-list/:tab?',
  allUsers: '/all-users/:tab?/:subTab?',
};

export default routes;
