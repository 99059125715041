import React, { useEffect } from 'react';
import { useRouteMatch, generatePath, Route, Switch } from 'react-router-dom';
import routes from '../../../../routes';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import RescueMobileCard from '../../../Rescue/RescueMobileCard';
import useRescuerDashboardActions from '../../../../hooks/useRescuerDashboardActions';
import MyFrusMobile from './myFrus/MyFrusMobile';
import MyRescuesMobile from './myRescues/MyRescuesMobile';
import RescuerScheduleMobileWithInfiniteScroll from './rescuerSchedule/RescuerScheduleMobileWithInfiniteScroll';
import { makeStyles } from '@material-ui/core/styles';
import { PersonPin as PersonPinIcon, ViewList as ViewListIcon, DateRange as DateRangeIcon } from '@material-ui/icons';
import useActiveUser from '../../../../hooks/useActiveUser';
import {
  getMyRescues,
  getRecentUnclosedRescues,
  getScheduleAvailableRescues,
  getTodayUnclosedRescues,
} from '../../../../helpers/RescuesHelper';
import RescuesBreadcrumb from './Components/RescuesBreadcrumb';
import MobileWrapper from '../../../../components/Common/MobileBottomNavWrapper';

export const RESCUER_MOBILE_DASHBOARD_TAB_MY_FRUS = 'my-frus';
export const RESCUER_MOBILE_DASHBOARD_TAB_MY_RESCUES = 'my-rescues';
export const RESCUER_MOBILE_DASHBOARD_TAB_SCHEDULE = 'schedule';

export const paths = [
  generatePath(routes.mobileDashboardRescues, { tab: RESCUER_MOBILE_DASHBOARD_TAB_MY_FRUS }),
  generatePath(routes.mobileDashboardRescues, { tab: RESCUER_MOBILE_DASHBOARD_TAB_MY_RESCUES }),
  generatePath(routes.mobileDashboardRescues, { tab: RESCUER_MOBILE_DASHBOARD_TAB_SCHEDULE }),
];

export function RescuesLoader({ highlighted, rows }) {
  return (
    <Box display="grid" gridRowGap={16} width="100%">
      {[...Array(rows || 10).keys()].map(index => (
        <RescueMobileCard key={index} highlighted={highlighted} isLoading />
      ))}
    </Box>
  );
}

export function NoRescueCard({ text }) {
  return (
    <Box
      boxShadow={25}
      p={2}
      css={{
        width: '100%',
        borderRadius: 16,
        backgroundColor: '#fff',
        position: 'relative',
      }}
    >
      <Typography color="textSecondary" variant="body1">
        {text}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '1.8rem',
  },
}));

const RescuerRescuesDashboardMobileContainer = () => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const activeUser = useActiveUser();
  const rescuesEntities = useSelector(state => state.entities.rescues);
  const featureFlagsEntities = useSelector(state => state.entities.featureFlags);
  const { formatMessage } = useIntl();

  const myFrusCount =
    getTodayUnclosedRescues(activeUser.id, rescuesEntities).length +
    getRecentUnclosedRescues(activeUser.id, rescuesEntities).length;

  const availableRescues = getScheduleAvailableRescues(activeUser.id, rescuesEntities);
  const myRescuesCount = getMyRescues(activeUser.id, rescuesEntities).length;
  const scheduleCount = availableRescues.data.length;

  const { fetchMyFrusTab } = useRescuerDashboardActions();

  const getActiveTab = () => {
    let path = paths.indexOf(url);

    return Math.max(0, path); // no negatives numbers
  };

  useEffect(() => {
    if (url !== paths[0] && url !== paths[1]) {
      // my frus & my rescues
      fetchMyFrusTab(false, true); //quietMode, force
    }
  }, [fetchMyFrusTab]);

  if (featureFlagsEntities.inflight) {
    return null;
  }

  const bottomTabs = [
    {
      label: formatMessage({ id: 'rescuer-dashboard-mobile.bottom-navigation.my-food-rescue-us' }),
      url: paths[0],
      icon: <PersonPinIcon classes={{ root: classes.icon }} />,
      badgeCounter: myFrusCount,
      testId: 'rescuerDesktopTab0',
    },
    {
      label: formatMessage({ id: 'rescuer-dashboard-mobile.bottom-navigation.my-rescues' }),
      url: paths[1],
      icon: <ViewListIcon classes={{ root: classes.icon }} />,
      badgeCounter: myRescuesCount,
      testId: 'rescuerDesktopTab1',
    },
    {
      label: formatMessage({ id: 'rescuer-dashboard-mobile.bottom-navigation.schedule' }),
      url: paths[2],
      icon: <DateRangeIcon classes={{ root: classes.icon }} />,
      badgeCounter: scheduleCount,
      testId: 'rescuerDesktopTab2',
    },
  ];

  return (
    <MobileWrapper
      containerId="rescuer-dashboard-mobile-div"
      breadcrumb={<RescuesBreadcrumb />}
      activeTab={getActiveTab()}
      bottomTabs={bottomTabs}
      pageContent={
        <Switch>
          <Route
            path={[generatePath(routes.mobileDashboardRescues), generatePath(routes.dashboardRescuer), paths[0]]}
            exact
            component={MyFrusMobile}
          />
          <Route path={paths[1]} exact component={MyRescuesMobile} />
          <Route
            path={paths[2]}
            exact
            component={RescuerScheduleMobileWithInfiniteScroll}
          />
        </Switch>
      }
    />
  );
};

export default RescuerRescuesDashboardMobileContainer;
