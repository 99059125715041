import React, { Component } from 'react';
import { Checkbox, Link, FormControlLabel, Box, Tooltip } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Delete as DeleteIcon } from '@material-ui/icons';
import moment from 'moment';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import TableActionButton from './TableActionButton';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import { getMuiTableDataIndex } from '../helpers/getters';
import { getUserFullName } from '../helpers/user';
import { isUndefinedOrNull } from '../helpers';
import { userRegistrationFilterOptions } from '../helpers/MuiTableCustomFilters';
import { formatPhoneNumber, formatCsvFileName } from '../helpers/formatters';
import { TableFilterList } from 'mui-datatables';
import { Roles } from '../models/roles';
import { currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite } from '../services/auth';
import DonorLinkIcon from './DonorLinkIcon';

class DonorsLocationsTable extends Component {
  getMuiTheme = theme => {
    return createMuiTheme({
      ...theme,
      overrides: {
        ...theme.overrides,
        MUIDataTableHeadCell: {
          root: {
            ...theme.overrides.MUIDataTableHeadCell.root,
            '&:last-child': {
              width: 250,
            },
          },
        },
      },
    });
  };

  render() {
    const {
      donorsLocations,
      onDeleteClick,
      onRowClick,
      onPrimaryContactUserClick,
      tableId,
      customToolbar,
      customFilters = {
        showPendingRegistrationFilter: false,
      },
      isLoading,
      hasDonorLinkFF,
    } = this.props;

    const isAdmin = currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
      Roles.Admin,
      Roles.NationalSiteDirector,
      Roles.SiteDirector,
    ]);

    const primaryContactCustomBodyRender = (value, tableMeta, csv = false) => {
      const primaryContactFirstName = donorsLocations[getMuiTableDataIndex(tableMeta)].primary_contact_firstname;
      const primaryContactLastName = donorsLocations[getMuiTableDataIndex(tableMeta)].primary_contact_lastname;

      if (!primaryContactFirstName && !primaryContactLastName) {
        return null;
      }

      const userFullName = getUserFullName({
        firstname: primaryContactFirstName,
        lastname: primaryContactLastName,
      });

      if (csv) {
        return userFullName;
      }

      return (
        <Link
          href="#"
          onClick={event => {
            event.preventDefault();

            return onPrimaryContactUserClick(donorsLocations[getMuiTableDataIndex(tableMeta)].primary_contact_id);
          }}
        >
          {userFullName}
        </Link>
      );
    };

    const primaryContactPhoneCustomBodyRender = (value, tableMeta, csv = false) => {
      const donorLocation = donorsLocations[getMuiTableDataIndex(tableMeta)];

      if (!donorLocation.primary_contact_id) {
        return null;
      }

      const formattedPhoneNumber = formatPhoneNumber(donorLocation.primary_contact_phone);

      if (csv) {
        return formattedPhoneNumber;
      }

      return <Link href={`tel:${donorLocation.primary_contact_phone}`}>{formattedPhoneNumber}</Link>;
    };

    const makesDonationsCustomBodyRender = value => (value === 1 ? 'Making Donations' : 'Not Making Donations');

    const createdAtCustomBodyRender = value =>
      !isUndefinedOrNull(value) ? moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY') : null;

    const vefificationStatusBodyRender = value => {
      switch (value) {
        case 'pending':
          return 'Pending';
        default:
          return '';
      }
    };

    const donorLinkCustomBodyRender = (value, tableMeta, csv = false) => {
      if (csv) {
        return value ? 'Donor Link' : '-';
      }

      if (value) {
        const donorLocation = donorsLocations[getMuiTableDataIndex(tableMeta)];
        const isDonorLinkActive = donorLocation.donor_link_active;

        return (
          <Box display="flex" justifyContent="center">
            <Tooltip
              arrow
              placement="top"
              title={isDonorLinkActive ? 'This donor uses Donor Link' : 'Donor Link is inactive'}
            >
              <span>
                <DonorLinkIcon size={24} isActive={isDonorLinkActive} />
              </span>
            </Tooltip>
          </Box>
        );
      }

      return '-';
    };

    const frequencyCustomBodyRender = value => {
      if (value) {
        const capitalized = value.charAt(0).toUpperCase() + value.slice(1);
        return capitalized;
      }
      return '-';
    };


    return (
      <MuiThemeProvider theme={this.getMuiTheme}>
        <BaseMUIDataTable
          isLoading={isLoading}
          tableId={tableId}
          title="Food Donors"
          data={donorsLocations}
          columns={[
            {
              name: 'id',
              label: 'ID',
              options: {
                filter: false,
                sort: false,
                display: false,
              },
            },
            {
              name: 'donor_name',
              label: 'Parent Organization',
              options: {
                filter: false,
                sort: false,
                display: false,
              },
            },
            {
              name: 'name',
              label: 'Name',
              options: {
                filter: false,
                sort: true,
              },
            },
            {
              name: 'address1',
              label: 'Address',
              options: {
                filter: false,
                sort: false,
              },
            },
            {
              name: 'city',
              label: 'City',
              options: {
                filter: true,
                sort: true,
              },
            },
            {
              name: 'st',
              label: 'ST',
              options: {
                filter: true,
                sort: false,
              },
            },
            {
              name: 'zip',
              label: 'Zip Code',
              options: {
                filter: true,
                sort: true,
              },
            },
            {
              name: 'primary_contact',
              label: 'Contact',
              options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => primaryContactCustomBodyRender(value, tableMeta),
                customBodyRenderCSV: (value, tableMeta) => primaryContactCustomBodyRender(value, tableMeta, true),
              },
            },
            {
              name: 'primary_contact_email',
              label: 'Contact email',
              options: {
                filter: false,
                searchable: true,
                display: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                  const donorLocation = donorsLocations[getMuiTableDataIndex(tableMeta)];

                  if (!donorLocation.primary_contact_id) {
                    return null;
                  }

                  return (
                    <Link href={`mailto:${donorLocation.primary_contact_email}`}>
                      {donorLocation.primary_contact_email}
                    </Link>
                  );
                },
              },
            },
            {
              name: 'primary_contact_phone',
              label: 'Contact phone',
              options: {
                filter: false,
                display: false,
                sort: false,
                customBodyRender: (value, tableMeta) => primaryContactPhoneCustomBodyRender(value, tableMeta),
                customBodyRenderCSV: (value, tableMeta) => primaryContactPhoneCustomBodyRender(value, tableMeta, true),
              },
            },
            {
              name: 'makes_donations',
              label: 'Making Donations',
              options: {
                display: false,
                filterList: ['Making Donations'],
                filterOptions: {
                  names: ['Making Donations', 'Not Making Donations'],
                },
                customBodyRender: value => makesDonationsCustomBodyRender(value),
                customBodyRenderCSV: value => makesDonationsCustomBodyRender(value),
              },
            },
            {
              name: 'created_at',
              label: 'Completed Registration',
              options: {
                filter: true,
                display: false,
                customBodyRender: value => createdAtCustomBodyRender(value),
                customBodyRenderCSV: value => createdAtCustomBodyRender(value),
                ...userRegistrationFilterOptions,
              },
            },
            {
              name: 'verification_status',
              label: 'Verification status',
              options: {
                filter: false,
                display: false,
                filterType: 'checkbox',
                customBodyRender: value => vefificationStatusBodyRender(value),
                filterOptions: {
                  logic: (status, filter) => status !== 'Pending',
                  names: ['Pending Registration'],
                },
              },
            },

            ...(hasDonorLinkFF
              ? [
                  {
                    name: 'donor_link',
                    label: 'Donor Link',
                    options: {
                      filter: true,
                      sort: true,
                      display: true,
                      sortCompare: (order) => (row1, row2) => {
                        if (row1.data && row2.data) {
                          const donor1 = donorsLocations.find((fd) => fd.id === row1.rowData[0]);
                          const donor2 = donorsLocations.find((fd) => fd.id === row2.rowData[0]);
                          return (
                            (!!donor1?.donor_link_active - !!donor2?.donor_link_active) *
                            (order === 'asc' ? 1 : -1)
                          );
                        }
                        return (!!row1.data - !!row2.data) * (order === 'asc' ? 1 : -1);
                      },
                      customBodyRender: (value, tableMeta) => donorLinkCustomBodyRender(value, tableMeta),
                      customBodyRenderCSV: (value, tableMeta) => donorLinkCustomBodyRender(value, tableMeta, true),
                      filterOptions: {
                        names: ['No', 'Yes'],
                        logic: (option, filters) => {
                          if (!filters || filters.length === 0) {
                            return false;
                          }

                          if (option === '-') {
                            return !filters.includes('No');
                          }

                          if (option) {
                            return !filters.includes('Yes');
                          }

                          return true;
                        },
                      },
                      customFilterListOptions: {
                        render: (value) => `Donor Link: ${value}`,
                      },
                    },
                  },
                ]
              : []),
            {
              name: 'frequency',
              label: 'Donation Frequency',
              options: {
                filter: true,
                display: true,
                customBodyRender: value => frequencyCustomBodyRender(value),
                customBodyRenderCSV: value => frequencyCustomBodyRender(value),
              },
            },
            {
              name: 'actions',
              label: 'Actions',
              options: {
                download: false,
                filter: false,
                sort: false,
                display: true,
                customBodyRender: (value, tableMeta) => (
                  <TableActionsButtonsWrapper>
                    <TableActionButton
                      title="Edit Food Donor"
                      onClick={() =>
                        onRowClick(
                          donorsLocations[getMuiTableDataIndex(tableMeta)].donor_id,
                          donorsLocations[getMuiTableDataIndex(tableMeta)].id
                        )
                      }
                    />
                    {isAdmin && (
                      <TableActionButton
                        title="Delete Food Donor"
                        icon={DeleteIcon}
                        onClick={() =>
                          onDeleteClick(
                            donorsLocations[getMuiTableDataIndex(tableMeta)].name,
                            donorsLocations[getMuiTableDataIndex(tableMeta)].id
                          )
                        }
                      />
                    )}
                  </TableActionsButtonsWrapper>
                ),
              },
            },
          ]}
          options={{
            customToolbar: customToolbar,
            responsive: 'simple',
            selectableRows: 'none',
            downloadOptions: {
              filename: formatCsvFileName('Food Donors'),
            },
          }}
          components={{
            TableFilterList: ({ filterList, filterUpdate, ...restProps }) => {
              const verificationIndex = 12;
              const updatedList = [...filterList];
              const verificationChecked = filterList[verificationIndex].length > 0;

              updatedList[verificationIndex] = [];

              return (
                <Box display="flex" flexDirection="row">
                  <TableFilterList {...restProps} filterList={updatedList} filterUpdate={filterUpdate} />

                  {customFilters.showPendingRegistrationFilter && (
                    <Box marginTop="5px">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={verificationChecked}
                            onChange={() => {
                              filterUpdate(verificationIndex, 'pending');
                            }}
                          />
                        }
                        label="Pending Registration"
                      />
                    </Box>
                  )}
                </Box>
              );
            },
          }}
        />
      </MuiThemeProvider>
    );
  }
}

export default DonorsLocationsTable;
