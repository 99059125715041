import React from 'react';
import ConfirmationDialog from './ConfirmationDialog';

const QuickResponseTeamPopup = ({ show, onClose, onAccept }) => {
  const buttons = [
    {
      label: 'No Thanks',
      testId: 'qrt-popup-cancel',
    },
    {
      label: 'Join The Team',
      color: 'primary',
      onClick: () => onAccept(),
    },
  ];

  const message = `Congratulations on completing your tenth rescue! 
    As an experienced rescuer, we'd love to invite you to join our Quick Response Team. 
    This team is contacted for occasional last-minute opportunities that come up due to unexpected changes, 
    and while we may reach out to you, there's no pressure to accept—you can decide what works for you each time. 
    You can also leave the team at any point by visiting your profile page and updating your preferences.`;

  const title = 'Join the Quick Response Team';

  if (!show) {
    return null;
  }

  return (
    <ConfirmationDialog
      dialogProps={{
        fullWidth: true,
      }}
      buttons={buttons}
      closeDialog={onClose}
      message={message}
      title={title}
    />
  );
};

export default QuickResponseTeamPopup;
