import { Button, Grid, InputAdornment, makeStyles, Slider, TextField } from '@material-ui/core';
import React from 'react'
import { Colors } from '../../../../../assets/theme/Colors';
import { formatNumber } from '../../../../../helpers/formatters';

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: '28px',
    cursor: 'default',
    '&:hover': {
      backgroundColor: Colors.secondaryColor,
    },
  },
}));

const RescuedItem = ({
  isMobileView = false,
  index,
  itemLbs,
  description,
  disabled,
  onItemWeightChange,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} alignItems="center" style={{ marginTop: '1rem' }}>
      <Grid data-introid="item-lbs" item xs={isMobileView ? 3 : 2}>
        <TextField
          name={`items[${index}].lbs`}
          id={`items[${index}].lbs`}
          data-testid={`items[${index}].lbs`}
          value={formatNumber(itemLbs)}
          placeholder="Lbs"
          InputProps={{ endAdornment: <InputAdornment position="end">lbs</InputAdornment>, min: 0 }}
          disabled={disabled}
          onChange={({ target }) => onItemWeightChange(target.value, index)}
        />
      </Grid>
      <Grid item xs={isMobileView ? 4 : 3}>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          disabled={disabled}
          disableRipple
          disableFocusRipple
          disableElevation
          fullWidth
          className={classes.button}
          style={isMobileView ? { fontSize: '12px' } : {}}
          data-testid={description.name}
        >
          {description.name}
        </Button>
      </Grid>
    </Grid>
  );
};

export default RescuedItem;
