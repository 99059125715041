import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, Link } from 'react-router-dom';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DashboardBadge from '../../../../components/Common/TabBadgeCounter';
import useHasActiveFeatureFlag from '../../../../hooks/useFeatureFlags';
import { FF_EVENTS, FF_MARKETING_HUB } from '../../../../components/FeatureFlagWrapper';
import {
  getMyFrusMyEvents,
  getRecentRescues,
  getTodayRescues,
  getUpcomingRescues,
  getMyRescues,
  getScheduleAvailableRescues,
} from '../../../../helpers/RescuesHelper';
import { paths } from '../RescuerDashboardDesktopContainer';
import useActiveUser from '../../../../hooks/useActiveUser';

const useStyles = makeStyles({
  container: {
    paddingRight: '30px',
    paddingLeft: '30px',
  },
});

const RescuerDashboardDesktopAppBar = () => {
  const classes = useStyles();
  const activeUser = useActiveUser();
  const rescuesEntities = useSelector((state) => state.entities.rescues);
  const eventsEntities = useSelector((state) => state.entities.events);
  const displayLinkedAccounts = useSelector((state) => state.entities.marketingHub.displayLinkedAccounts);
  const hasActiveMarketingHubFeatureFlag = useHasActiveFeatureFlag(FF_MARKETING_HUB);
  const hasActiveEventsFeatureFlag = useHasActiveFeatureFlag(FF_EVENTS);

  const myFrusCount =
    getTodayRescues(activeUser.id, rescuesEntities).length +
    getRecentRescues(activeUser.id, rescuesEntities).length +
    getUpcomingRescues(activeUser.id, rescuesEntities).length +
    getMyFrusMyEvents(eventsEntities, activeUser.id).events.length;

  const myRescuesCount = getMyRescues(activeUser.id, rescuesEntities).length;
  const scheduleCount = getScheduleAvailableRescues(activeUser.id, rescuesEntities).data.length;
  const eventsCount = eventsEntities.allIds.length;

  const tabs = [
    { label: 'My Dashboard', url: paths[0], badgeCounter: myFrusCount, testId: 'rescuerDesktopTab0' },
    { label: 'My Rescues', url: paths[1], badgeCounter: myRescuesCount, testId: 'rescuerDesktopTab1' },
    {
      label: 'Schedule',
      url: paths[2],
      badgeCounter: scheduleCount,
      testId: 'rescuerDesktopTab2',
    },
  ];

  if (hasActiveEventsFeatureFlag) {
    tabs.push({
      label: 'Events',
      url: paths[3],
      badgeCounter: eventsCount,
      testId: 'rescuerDesktopEventsTab',
    });
  }

  if (displayLinkedAccounts && hasActiveMarketingHubFeatureFlag) {
    tabs.push({
      label: 'Site News',
      url: paths[4],
      testId: 'rescuerDesktopSiteNewsTab',
    });
  }

  const { url } = useRouteMatch();

  const getActiveTab = () => {
    const currentTab = tabs.map((t) => t.url).indexOf(url);

    return Math.max(currentTab, 0);
  };

  const RenderTabWithBadge = ({ label, url, badgeCounter, testId }) => (
    <Tab
      className={classes.container}
      component={Link}
      to={url}
      label={
        <div>
          <DashboardBadge badgeContent={badgeCounter} color="primary">
            {label}
          </DashboardBadge>
        </div>
      }
      key={testId}
      data-testid={testId}
    />
  );

  return (
    <AppBar position="static">
      <Tabs value={getActiveTab()} variant="scrollable" aria-label="rescuer dashboard tabs" indicatorColor="primary">
        {tabs.map(RenderTabWithBadge)}
      </Tabs>
    </AppBar>
  );
};

export default RescuerDashboardDesktopAppBar;
