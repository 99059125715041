import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Bluebird from 'bluebird';
import { Box } from '@material-ui/core';
import { get, uniqBy } from 'lodash';
import PastRescuesTable from '../components/PastRescuesTable';
import useUIFilter from '../hooks/useUIFilter';
import useActiveUser from '../hooks/useActiveUser';
import { hasAnyRoleInCurrentlySelectedSite } from '../services/auth';
import { Roles } from '../models/roles';
import { fetchPastRescues, updatePastRescues } from '../actions/pastRescues';
import { fetchSitesIfNeeded } from '../actions/sites';
import * as authService from '../services/auth';
import useIsAdminInSelectedSite from '../hooks/useIsAdminInSelectedSite';
import PastRescuesListForm, { dateRangeOptions } from './PastRescuesListForm';
import BulkEditReviewAndSave from '../components/BulkEditReviewAndSave';
import useNotificationService from '../hooks/useNotificationService';
import { setUserTableOptions } from '../actions/tableOptions';
import { renderPickupLocationNameOrAdress } from '../helpers/RescuesHelper';
import { RescuesFromSiteFilterTypes } from './FutureRescuesListView';
import { sortAlphabetically } from '../helpers/sorters';

export const PAST_RESCUES_REPORT_UI_FILTER = 'report-past_rescues';
const TABLE_ID = '#pastrescues/rescues';

const PastRescuesListView = ({
  umbrellaFoodDonorId,
  filter,
  filterColumns = [],
  showCopyRescueButton = true,
  renderNamesAsLinks = true,
  removedFilters = [],
  displayFoodDonorFilters = true,
  showOnlyPickupFilter = false,
  showDisabledSiteFilter = false,
}) => {
  const activeUser = useActiveUser();
  const dispatch = useDispatch();
  const location = useLocation();
  const pastRescuesEntities = useSelector(state => state.entities.pastRescues);
  const sitesEntities = useSelector(state => state.entities.sites);
  const tableOptions = useSelector((state) => state.tableOptions);
  let pastRescuesList = Object.values(pastRescuesEntities.byId);
  const { setUIFilter, getUIFilter } = useUIFilter();
  const [inReview, setInReview] = useState(false);
  const [editedRescues, setEditedRescues] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rescuesFromType, setRescuesFromType] = useState(RescuesFromSiteFilterTypes.all);
  const { addSuccessNotification, addErrorNotification } = useNotificationService();

  const sitesWithSDSCAccess = authService.getUserSitesByRoles(activeUser.role_assignments, [
    Roles.SiteDirector,
    Roles.SiteCoordinator,
  ]);

  const isAdmin = useIsAdminInSelectedSite();
  const sitesList = isAdmin
    ? Object.values(sitesEntities.byId)
    : Object.values(sitesEntities.byId).filter(site => sitesWithSDSCAccess.includes(site.id));

  const filters = getUIFilter(PAST_RESCUES_REPORT_UI_FILTER) || {
    sites: [],
    period: dateRangeOptions.last90Days.value,
    dateRange: null,
  };

  if (typeof filter === 'function') {
    pastRescuesList = showDisabledSiteFilter ? pastRescuesList.filter(filter(rescuesFromType)) : pastRescuesList.filter(filter);
  }

  // if user selected sites, use it and submit
  // otherwise - check if user is an admin -> then send an empty array to receive ALL sites
  // if user is not an admin, send an array with the sites ids in which user is SD/SC
  const getSitesToFetch = sitesToFetch => {
    if (isAdmin && sitesToFetch.length === 0) {
      return [];
    }
    if (!isAdmin) {
      if (sitesToFetch.length === 0) {
        return sitesWithSDSCAccess;
      }
    }

    return sitesToFetch.map(site => site.id);
  };

  const handleFiltersChange = values => {
    const sitesToFetch = get(values, 'sites', []);

    setUIFilter(PAST_RESCUES_REPORT_UI_FILTER, {
      period: values.period,
      dateRange: {
        startDate: values.dateRange.startDate,
        endDate: values.dateRange.endDate,
      },
      sites: sitesToFetch,
    });

    return dispatch(
      fetchPastRescues(
        getSitesToFetch(sitesToFetch),
        values.dateRange.startDate,
        values.dateRange.endDate,
        umbrellaFoodDonorId
      )
    );
  };

  useEffect(() => {
    dispatch(fetchSitesIfNeeded());
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && location.state?.prevScrollPosition) {
      window.scrollTo({ top: location.state.prevScrollPosition });
    }
  }, [isLoading]);

  const scrollTop = () => window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });

  const handleSaveClick = (data) => {
    setIsLoading(true);
    return Bluebird.try(() =>
      dispatch(updatePastRescues(data, filters, getSitesToFetch(filters.sites))).then(() => {
        setInReview(false);
        setEditMode(false);
        setEditedRescues([]);
        setIsLoading(false);
        addSuccessNotification('Rescue(s) updated.');
        scrollTop();
      })
    ).catch((error) => {
      setIsLoading(false);
      addErrorNotification(error, error.message);
      scrollTop();
    });
  };

  const uniqueFoodDonors = uniqBy(pastRescuesList, 'location_id').sort((a, b) =>
    sortAlphabetically('dsc', a.location, b.location)
  );
  const getRescueFoodDonorType = () => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    const columnIndex = get(previousTableState, 'columns', []).findIndex((c) => c.name === 'location');

    const foodDonorType = get(previousTableState, ['filterList', columnIndex, 0], 'All');

    // Clear food donor filter if the option is no longer selectable
    if (!uniqueFoodDonors.map((rescue) => rescue.location).includes(foodDonorType)) {
      const previousFilters = get(previousTableState, 'filterList', []);
      previousFilters[columnIndex] = [];
      return 'All';
    }

    return get(previousTableState, ['filterList', columnIndex, 0], 'All');
  };

  const rescuesWithSelectedFoodDonor = pastRescuesList.filter((rescue) => rescue.location === getRescueFoodDonorType());
  const uniquePickupLocations = showOnlyPickupFilter
    ? uniqBy(pastRescuesList, 'pickup_location_id')
    : uniqBy(rescuesWithSelectedFoodDonor, 'pickup_location_id');

  const getRescuePickupLocationType = () => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    const columnIndex = get(previousTableState, 'columns', []).findIndex((c) => c.name === 'pickup_location_name');

    // Clear pickup filter if the option is no longer selectable
    const pickupLocationOptions = uniquePickupLocations.map((rescue) => renderPickupLocationNameOrAdress(rescue))
    const pickupLocationType = get(previousTableState, ['filterList', columnIndex, 0], 'All');
    if (!pickupLocationOptions.includes(pickupLocationType)) {
      const previousFilters = get(previousTableState, 'filterList', []);
      previousFilters[columnIndex] = [];
      return 'All';
    }

    return pickupLocationType;
  };

  const onFoodDonorFilterChange = (choice) => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    const columnIndex = get(previousTableState, 'columns', []).findIndex((c) => c.name === 'location');
    const pickupLocationColumnIndex = get(previousTableState, 'columns', []).findIndex(
      (c) => c.name === 'pickup_location_name'
    );
    const previousFilters = get(previousTableState, 'filterList', []);

    if (columnIndex < 0 || previousFilters.length === 0) {
      return;
    }

    if (choice === 'All') {
      previousFilters[columnIndex] = [];
      previousFilters[pickupLocationColumnIndex] = [];
      dispatch(
        setUserTableOptions(activeUser.id, TABLE_ID, {
          ...previousTableState,
          filterList: previousFilters,
        })
      );
    } else {
      previousFilters[columnIndex] = [choice];
      previousFilters[pickupLocationColumnIndex] = [];
      dispatch(
        setUserTableOptions(activeUser.id, TABLE_ID, {
          ...previousTableState,
          filterList: previousFilters,
        })
      );
    }
  };

  const onPickupLocationFilterChange = (choice) => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    const columnIndex = get(previousTableState, 'columns', []).findIndex((c) => c.name === 'pickup_location_name');
    const previousFilters = get(previousTableState, 'filterList', []);

    if (columnIndex < 0 || previousFilters.length === 0) {
      return;
    }

    if (choice === 'All') {
      previousFilters[columnIndex] = [];
      dispatch(
        setUserTableOptions(activeUser.id, TABLE_ID, {
          ...previousTableState,
          filterList: previousFilters,
        })
      );
    } else {
      previousFilters[columnIndex] = [choice];
      dispatch(
        setUserTableOptions(activeUser.id, TABLE_ID, {
          ...previousTableState,
          filterList: previousFilters,
        })
      );
    }
  };

  const onRescuesFromFilterChange = (value) => {
    setRescuesFromType(value);
  };

  if (sitesEntities.lastUpdated === null || sitesEntities.inflight) {
    return 'Loading sites...';
  }

  return (
    <>
      {inReview && (
        <BulkEditReviewAndSave
          onExit={() => setInReview(false)}
          onSave={handleSaveClick}
          dialogOpen={inReview}
          editedRescues={editedRescues}
          rescues={pastRescuesList}
          showSiteColumn={sitesList.length > 1}
          isLoading={isLoading}
        />
      )}

      <Box my={1} p={2} bgcolor="background.paper" boxShadow={25}>
        <PastRescuesListForm
          disabled={pastRescuesEntities.inflight}
          onSubmit={handleFiltersChange}
          defaultValues={filters}
          isAdmin={isAdmin}
          sitesList={sitesList}
          foodDonorFilterValue={getRescueFoodDonorType()}
          onFoodDonorFilterChange={(value) => onFoodDonorFilterChange(value)}
          uniqueFoodDonors={uniqueFoodDonors}
          uniquePickupLocations={uniquePickupLocations}
          pickupLocationFilterValue={getRescuePickupLocationType()}
          onPickupLocationFilterChange={(value) => onPickupLocationFilterChange(value)}
          displayFoodDonorFilters={displayFoodDonorFilters}
          showOnlyPickupFilter={showOnlyPickupFilter}
          showDisabledSiteFilter={showDisabledSiteFilter}
          onRescuesFromFilterChange={onRescuesFromFilterChange}
          rescuesFromType={rescuesFromType}
        />
      </Box>

      <div>
        <PastRescuesTable
          tableId="#pastrescues/rescues"
          rescues={pastRescuesList}
          isLoading={pastRescuesEntities.inflight}
          showSiteColumn={sitesList.length > 1}
          filterColumns={filterColumns}
          renderRescuerAsProfileLink={sitesList.length > 1 || isAdmin}
          showEditRescueButton={hasAnyRoleInCurrentlySelectedSite(activeUser, [
            Roles.Admin,
            Roles.NationalSiteDirector,
            Roles.SiteDirector,
            Roles.SiteCoordinator,
          ])}
          showCopyRescueButton={showCopyRescueButton}
          renderNamesAsLinks={renderNamesAsLinks}
          handleReviewClick={() => setInReview(true)}
          editedRescues={editedRescues}
          setEditedRescues={setEditedRescues}
          editMode={editMode}
          setEditMode={setEditMode}
          removedFilters={removedFilters}
        />
      </div>
    </>
  );
};

export default PastRescuesListView;
