const collator = new Intl.Collator('en', {
  usage: 'sort',
  sensitivity: 'base',
});

export const sortArrayOfObjects = (list, sortBy) =>
  list.sort((prev, next) => collator.compare(sortBy(prev), sortBy(next)));

export const sortByTime = (order, time1, time2) => {
  const fakeDate = '2020-01-01';
  if (order === 'asc') {
    return new Date(`${fakeDate} ${time2}`).getTime() > new Date(`${fakeDate} ${time1}`).getTime() ? 1 : -1;
  }
  return new Date(`${fakeDate} ${time1}`).getTime() > new Date(`${fakeDate} ${time2}`).getTime() ? 1 : -1;
};

export const sortAlphabetically = (order, value1, value2) => {
  if (!(value1 && value2)) {
    return !value1 ? 1 : -1;
  }

  if (order === 'asc') {
    return value2.localeCompare(value1, 'en', { sensitivity: 'base' });
  }
  return value1.localeCompare(value2, 'en', { sensitivity: 'base' });
};

export const sortByDateAndTime = (order, date1, date2) => {
  if (order === 'asc') {
    return new Date(date2).getTime() > new Date(date1).getTime() ? 1 : -1;
  }
  return new Date(date1).getTime() > new Date(date2).getTime() ? 1 : -1;
};

const longestCommonPrefix = (a, b) => {
  let i = 0;
  while (i < a.length && i < b.length && a[i] === b[i]) {
      i++;
  }
  return i;
};

export const sortBySimilar = (a, b, target) => {
  const prefixA = longestCommonPrefix(a.toLowerCase(), target?.toLowerCase() || '');
  const prefixB = longestCommonPrefix(b.toLowerCase(), target?.toLowerCase() || '');
  return prefixB - prefixA;
};
