import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'react-final-form';
import { Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import NotesTypeField from './NotesTypeField';
import { Colors } from '../../assets/theme/Colors';

const LocationNotes = ({
  label = 'Internal Location Notes',
  tooltipLabel = 'These notes are not visible to the food donor.',
}) => {
  return (
    <Grid container direction="row" justify="space-between" alignItems="center" spacing={1}>
      <Grid item xs>
        <Field
          name="notes"
          fullWidth
          multiline
          rowsMax={5}
          component={NotesTypeField}
          type="textarea"
          variant="outlined"
          label={label}
        />
      </Grid>
      <Grid item xs={1}>
        <Tooltip arrow placement="top" title={tooltipLabel}>
          <HelpIcon htmlColor={Colors.blue.main} />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default LocationNotes;
