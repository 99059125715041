import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useParams, generatePath } from 'react-router';
import {
  Box,
  Typography,
  Button,
  FormControlLabel,
  FormGroup,
  Accordion,
  AccordionSummary,
  Grid,
  MobileStepper,
  Switch,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  Help as HelpIcon,
  EmojiObjectsIcon as LightbulbIcon,
} from '@material-ui/icons';
import { useFieldArray, FormContext, useForm } from 'react-hook-form';
import { DevTool } from 'react-hook-form-devtools';
import Bluebird from 'bluebird';
import { closeRescue, fetchRescue, fetchRescueIfNeeded } from '../../../actions/rescues';
import { fetchUnitsIfNeeded } from '../../../actions/units';
import { sortArrayOfObjects } from '../../../helpers/sorters';
import { fetchFoodDescriptionsIfNeeded } from '../../../actions/foodDescriptions';
import { TextFieldWrapper as TextField } from '../../../components/Form/MuiFormWrapper';
import errorMessages from '../../../assets/errorMessages';
import ButtonWithLoading from '../../../components/ButtonWithLoading';
import useActiveUser from '../../../hooks/useActiveUser';
import routes from '../../../routes';
import snackbarHelper from '../../../helpers/snackbarHelper';
import UploadButton from './Components/RescuePhoto/UploadButton';
import RescuePhotoButton from './Components/RescuePhoto/RescuePhotoButton';
import { generateS3Url, s3FileUpload } from '../../../api/upload';
import RescueMobileCard from '../RescueMobileCard';
import {
  RESCUE_LBS_TOTAL,
  RESCUE_LBS_BY_ITEM,
} from '../RescueEdit/PastRescueForm/PastRescueForm';
import CategoryButton from '../RescueEdit/PastRescueForm/Components/CategoryButton';
import { newFoodTypes as foodTypesModel } from '../../../models/foodTypes';
import RescuedItem from '../RescueEdit/PastRescueForm/Components/RescuedItem';
import { fetchRescueSizesIfNeeded } from '../../../actions/rescueSizes';
import ThankYouScreen from './Components/ThankYouScreen';
import { formatNumber } from '../../../helpers/formatters';
import { setRescueCloseStep, setTourStepper } from '../../../actions/ui';
import { closeRescueTour } from '../../../helpers/tours';
import useSystemSettings from '../../../hooks/useSystemSettings';
import { LBS_PER_MEAL } from '../../../models/systemSettings';
import { fetchSystemSettingsIfNeeded } from '../../../actions/systemSettings';
import { RESCUE_SIZE_BICYCLE_NAME } from '../../Donation/DonationManage/donationValidator';

const validationResolver = (data, validationContext) => {
  const errors = {};

  if (!validationContext.isNoShowRoute && !data.no_food && validationContext.isRescueItemFormVisible) {
    if (!data.qty) {
      errors.qty = { message: errorMessages.REQUIRED.message };
    }

    if (data.qty && data.qty <= 0) {
      errors.qty = { message: errorMessages.INVALID.message };
    }

    if (!data.unit_id) {
      errors.unit_id = { message: errorMessages.REQUIRED.message };
    }

    if (!data.lbs) {
      errors.lbs = { message: errorMessages.REQUIRED.message };
    }

    if (
      validationContext.unitOther &&
      parseInt(data.unit_id, 10) === validationContext.unitOther.id &&
      !data.unit_other
    ) {
      errors.unit_other = { message: errorMessages.REQUIRED.message };
    }

    if (!data.food_description_id) {
      errors.food_description_id = { message: errorMessages.REQUIRED.message };
    }

    if (
      validationContext.foodDescriptionOther &&
      parseInt(data.food_description_id, 10) === validationContext.foodDescriptionOther.id &&
      !data.food_description_other
    ) {
      errors.food_description_other = { message: errorMessages.REQUIRED.message };
    }
  }

  return {
    values: Object.keys(errors).length ? {} : data,
    errors,
  };
};

const useStyles = makeStyles((theme) => ({
  bicycleButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: '28px',
  },
  trueWeightSwitch: {
    margin: '0 10px',
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M9 21c0 .5.4 1 1 1h4c.6 0 1-.5 1-1v-1H9zm3-19C8.1 2 5 5.1 5 9c0 2.4 1.2 4.5 3 5.7V17c0 .5.4 1 1 1h6c.6 0 1-.5 1-1v-2.3c1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#f86c2c',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M14 11V8c4.56-.58 8-3.1 8-6H2c0 2.9 3.44 5.42 8 6v3c-3.68.73-8 3.61-8 11h6v-2H4.13c.93-6.83 6.65-7.2 7.87-7.2s6.94.37 7.87 7.2H16v2h6c0-7.39-4.32-10.27-8-11m-2 11c-1.1 0-2-.9-2-2 0-.55.22-1.05.59-1.41C11.39 17.79 16 16 16 16s-1.79 4.61-2.59 5.41c-.36.37-.86.59-1.41.59"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#aab4be',
      borderRadius: 20 / 2,
    },
  },
}));

const RescueClose = ({ isMobileView }) => {
  const classes = useStyles();
  const commentRef = useRef();
  const [isSubmitting, setISubmitting] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [showThankYouScreen, setShowThankYouScreen] = useState(false);
  const isInitialPageVisit = useRef(true);

  const { rescueId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const rescues = useSelector(state => state.entities.rescues);
  const units = useSelector(state => state.entities.units);
  const foodDescriptions = useSelector(state => state.entities.foodDescriptions);
  const rescueCloseStep = useSelector(state => state.ui.rescueCloseStep);
  const tourStepper = useSelector(state => state.ui.tourStepper);
  const rescueSizes = useSelector(state => state.entities.rescueSizes.byId);
  const user = useActiveUser();
  const [isRescueItemFormVisible, setShowAddRescueItemForm] = useState(false);

  const rescue = rescues.byId[rescueId] || {};
  const isRescueClosed = rescue.id && rescue.closed_by_id !== null;
  const isRescueDonorLink = !!rescue.location_donor_link_active;
  const unitTypes = sortArrayOfObjects(Object.values(units.byId), u => u.name);
  const unitOther = unitTypes.find(ut => ut.name === 'Other');
  const foodDescriptionsTypes = sortArrayOfObjects(Object.values(foodDescriptions.byId), u => u.name);
  const foodDescriptionOther = foodDescriptionsTypes.find(ut => ut.name === 'Other');

  const lbsPerMeal = useSystemSettings(LBS_PER_MEAL);
  const rescueSize = Object.values(rescueSizes).find((size) => size.id === rescue.rescue_size_id);
  const rescueSizeLbs = rescueSize?.meals_per_rescue ? rescueSize.meals_per_rescue * lbsPerMeal : null;
  const [totalWeight, setTotalWeight] = useState();

  const [activeStep, setActiveStep] = useState(0);
  const [initialRender, setInitialRender] = useState(true);
  const [categorySelectedForTour, setCategorySelectedForTour] = useState(false);
  const [trueWeight, setTrueWeight] = useState(false);
  const [usedBicycle, setUsedBicycle] = useState(null);
  const isRescueSizeBicycle = rescueSize?.name === RESCUE_SIZE_BICYCLE_NAME;


  const steps = {
    ...(isRescueSizeBicycle && { bicycle: 0 }),
    category: isRescueSizeBicycle ? 1 : 0,
    trueWeight: isRescueSizeBicycle ? 2 : 1,
    weight: isRescueSizeBicycle ? 3 : 2,
  };

  const isNoShowRoute = useRouteMatch({
    path: routes.rescueCloseNoShow,
  });

  const scrollFormAdd = () => {
    const yOffset = -120;
    const element = document.getElementById('form-add-row');

    // i.e. close rescue as a no-show form
    if (!element) {
      return false;
    }

    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(fetchRescueIfNeeded(rescueId));
    dispatch(fetchUnitsIfNeeded());
    dispatch(fetchFoodDescriptionsIfNeeded());
    dispatch(fetchRescueSizesIfNeeded());
    dispatch(fetchSystemSettingsIfNeeded());
  }, [dispatch, rescueId]);

  useEffect(() => {
    if (!isInitialPageVisit.current && isRescueItemFormVisible) {
      scrollFormAdd();
    }

    isInitialPageVisit.current = false;
  }, [isRescueItemFormVisible]);

  useEffect(() => {
    if (initialRender) {
      dispatch(setRescueCloseStep(0));
    }
  }, []);

  useEffect(() => {
    if (!initialRender) {
      if (isRescueSizeBicycle) {
        if (activeStep < rescueCloseStep + 1) {
          return handleNext();
        }

        return setActiveStep(rescueCloseStep + 1);
      }

      if (activeStep < rescueCloseStep) {
        return handleNext();
      }

      return setActiveStep(rescueCloseStep);
    }
    return setInitialRender(false);
  }, [rescueCloseStep]);

  const formMethods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      items: [],
      photos: [],
      comments: null,
      no_food: false,
      food_types: rescue.food_types || [],
    },
    validationResolver,
    validationContext: {
      unitOther,
      foodDescriptionOther,
      isRescueItemFormVisible,
      isNoShowRoute,
    },
  });
  const { handleSubmit, register, triggerValidation, getValues, setValue, control, watch } = formMethods;
  const { fields: itemFields, append: appendItem, remove: removeItem, prepend: prependItem } = useFieldArray({
    control,
    name: 'items',
  });

  const { fields: photoFields, remove: removePhoto, prepend: prependPhoto } = useFieldArray({
    control,
    name: 'photos',
  });
  const watchLbsTotalBy = watch('lbs_total_by');
  const items = watch('items');

  useEffect(() => {
    if (watchLbsTotalBy === RESCUE_LBS_BY_ITEM && itemFields.length === 0) {
      setShowAddRescueItemForm(true);
    }
  }, [watchLbsTotalBy]);

  useEffect(() => {
    register({ name: 'food_types' });
    register({ name: 'items' });
    if (isMobileView) {
      register({ name: 'weight_lbs' });
      register({ name: 'lbs_total_by' });
    }
  }, [isMobileView]);

  const foodTypes = watch('food_types');

  useEffect(() => {
    if (rescue.lbs || rescueSizeLbs) {
      setTotalWeight(rescue.lbs || rescueSizeLbs);
      if (!foodTypes.length) {
        setValue('food_types', rescue.food_types || []);
      }
    }
  }, [rescue, rescueSizeLbs]);

  useEffect(() => {
    if (isNoShowRoute) {
      setActiveStep(steps.weight);
    }
  }, [isNoShowRoute]);

  useEffect(() => {
    if (categorySelectedForTour && !tourStepper.enabled) {
      setValue('food_types', []);
      setCategorySelectedForTour(false);
      setActiveStep(steps.category);
    }
  }, [tourStepper.enabled]);

  const isNoFoodChecked = watch('no_food');

  const doUpload = (file, index) => {
    const handleFileUpload = ({ loaded, total }) =>
      setValue(`photos[${index}].progress`, parseInt((loaded / total) * 100, 10));

    return Bluebird.try(() => generateS3Url(file.name))
      .then(res => res.json())
      .then(res => res.data)

      .then(signedData =>
        Bluebird.try(() =>
          s3FileUpload({
            url: signedData.url,
            file: file.file,
            onUploadProgress: handleFileUpload,
          })
        ).then(() => setValue(`photos[${index}].s3Key`, signedData.key))
      );
  };

  const onSubmit = values => {
    // by default we are fallback to noShow
    let message = 'Thank you for letting us know!';
    const data = {
      closed_by_id: user.id,
      completion: {
        no_food: !!values.no_food,
        lbs_type: values.lbs_total_by ? values.lbs_total_by : null,
        completed: false,
        comments: values.comments,
        used_bicycle: usedBicycle,
        true_weight: trueWeight,

        // only pass weight lbs when its not undefined (so FF is enabled) and value is provided, otherwise null
        weight_lbs:
          !values.no_food &&
          watch('lbs_total_by') === RESCUE_LBS_TOTAL &&
          values.weight_lbs !== undefined &&
          values.weight_lbs !== ''
            ? +values.weight_lbs
            : null,
      },
      photos: (values.photos || []).map(photo => ({
        name: photo.name,
        key: photo.s3Key,
      })),
    };

    if (!isNoShowRoute) {
      message = 'Thank you for rescuing food!';
      data.completion.completed = true;
      data.completion.rescued_food = (values.items || []).map(ri => ({
        qty: ri.qty,
        unit_id: ri.unit_id,
        unit_other: ri.unit_other,
        lbs: !watch('lbs_total_by') === RESCUE_LBS_TOTAL || ri.lbs === '' ? null : +ri.lbs,
        food_description_id: ri.food_description_id,
        food_description_other: ri.food_description_other,
      }));
    }

    // in case when "No Food Available" checkbox was checked, let's make sure to clear rescued food related data
    if (values.no_food) {
      data.completion.rescued_food = [];
      data.completion.lbs = null;
      data.completion.lbs_type = null;
      data.photos = [];
    }

    return Bluebird.try(() => dispatch(closeRescue(rescueId, data)))
      .then(() => {
        dispatch(setRescueCloseStep(0));
        if (isMobileView) {
          return setShowThankYouScreen(true);
        }

        snackbarHelper.success(message);

        if (!isNoShowRoute && !isNoFoodChecked && itemFields.length > 0) {
          return history.push(generatePath(routes.rescueCloseEnviro, { rescueId }));
        }

        return history.push(routes.index);
      })
      .catch(err => {
        switch (err.code) {
          case errorMessages.ERR_RESCUE_ALREADY_CLOSED.code:
            snackbarHelper.error(errorMessages.ERR_RESCUE_ALREADY_CLOSED.message);

            dispatch(fetchRescue(rescueId)); // re-fetch rescue to fix (possibly) wrong FE state

            break;
          default:
            snackbarHelper.error(err.message || 'Unknown error');

            break;
        }
      })
      .finally(() => {
        setISubmitting(false);
      });
  };

  const beforeSubmit = values => {
    setISubmitting(true);

    if ((values.photos || []).length === 0) {
      return onSubmit(values);
    }

    return Bluebird.all(
      (values.photos || []).filter(photo => !photo.s3Key).map((photo, index) => doUpload(photo, index))
    )
      .then(() => onSubmit(getValues({ nest: true })));
  };

  const readFilesNew = event => {
    event.preventDefault();
    const filesTmp = Array.from(event.target.files);
    const fileList = [];
    filesTmp.forEach(file => {
      fileList.push({
        file,
        photoBlob: URL.createObjectURL(file),
        name: file.name,
        progress: '',
        s3Key: '',
      });
    });

    prependPhoto(fileList);
  };

  const handleCategoryClick = (type) => {
    if (foodTypes.find((ft) => ft === type.name)) {
      return setValue(
        'food_types',
        foodTypes.filter((ft) => ft !== type.name)
      );
    }
    return setValue('food_types', [...foodTypes, type.name]);
  };

  const handleItemWeightChange = (value, index) => {
    const newValues = [...items];
    if (isNaN(Number(value.replaceAll(',', '')))) {
      return null;
    } else {
      newValues[index].lbs = Number(value.replaceAll(',', ''));
    }
    setValue('items', newValues);
    // Reset weight lbs value
    setValue('weight_lbs', '');

    const newValuesWeight = newValues.reduce((total, item) => {
      const itemLbs = item.lbs || 0;
      return total + itemLbs;
    }, 0);
    return setTotalWeight(Math.round(newValuesWeight));
  };

  const handleTotalWeightChange = (value) => {
    if (isNaN(Number(value.replaceAll(',', '')))) {
      return null;
    }

    if (items.length === 0) {
      setValue('lbs_total_by', RESCUE_LBS_TOTAL);
      setValue('weight_lbs', Number(value.replaceAll(',', '')));
    }

    setTotalWeight(Number(value.replaceAll(',', '')));

    const newItems = items.map((item) => ({
      ...item,
      lbs: Math.floor(value.replaceAll(',', '') / items.length),
    }));
    return setValue('items', newItems);
  };

  const handleNext = () => {
    if (activeStep + 1 === steps.weight) {
      const itemsArray = [];
      foodTypes.forEach((type) => {
        const model = foodTypesModel.find((ft) => ft.name === type);
        const description = foodDescriptionsTypes.find((description) => description.name === model?.label);
        if (description) {
          // API requires unit_id and qty - TO DO: change when API is fixed
          return itemsArray.push({ food_description_id: description.id, unit_id: 1, qty: 1 });
        }
        return null;
      });
      const remainder = Math.abs(totalWeight % itemsArray.length);
      const newItems = itemsArray.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            lbs: totalWeight > 0 && itemsArray.length ? Math.floor(totalWeight / itemsArray.length) + remainder : 0,
          };
        }

        return {
          ...item,
          lbs: totalWeight > 0 && itemsArray.length ? Math.floor(totalWeight / itemsArray.length) : 0,
        };
      });
      setValue('items', newItems);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setIsExpanded(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderNextButton = () => {
    const isDisabled =
      isRescueClosed ||
      rescue.cancelled_by_id !== null ||
      (activeStep === steps.bicycle && isRescueSizeBicycle && usedBicycle !== false && usedBicycle !== true);
    if (activeStep === steps.weight || isNoFoodChecked) {
      return (
        <ButtonWithLoading
          data-testid="close-rescue-button"
          isLoading={isSubmitting}
          disabled={isDisabled}
          type="submit"
          color="primary"
          size="small"
          aria-label="save"
        >
          Close
        </ButtonWithLoading>
      );
    }

    if (activeStep === steps.category && !foodTypesModel.filter((type) => foodTypes.includes(type.name)).length) {
      return (
        <Button
          data-testid="close-rescue-next"
          size="small"
          onClick={handleNext}
          disabled={activeStep === steps.weight || isDisabled}
        >
          Skip
          <KeyboardArrowRightIcon />
        </Button>
      );
    }

    return (
      <Button
        data-introid="close-rescue-next"
        data-testid="close-rescue-next"
        size="small"
        onClick={handleNext}
        disabled={activeStep === steps.weight || isDisabled}
      >
        Next
        <KeyboardArrowRightIcon />
      </Button>
    );
  };

  const handleTourClick = () => {
    // Preselect a category if there is none selected for tour purpose (filter out the fresh category)
    if (foodTypes.filter((ft) => ft !== 'fresh')?.length < 1) {
      setValue('food_types', [foodTypesModel[0].name]);
      setCategorySelectedForTour(true);
    }
    if (isNoFoodChecked) {
      setValue('no_food', false);
    }
    dispatch(setTourStepper(true, closeRescueTour));
  };

  if (!Object.keys(rescue).length || (!rescue.lbs && !rescueSizeLbs)) {
    return '...loading';
  }

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(beforeSubmit)}>
        <Accordion
          elevation={25}
          expanded={isExpanded}
          onChange={() => setIsExpanded(!isExpanded)}
          style={{ borderRadius: '16px', maxWidth: '720px' }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{height: isExpanded ? '5px' : 'auto'}}>
            <Typography variant="subtitle1">Rescue Info</Typography>
          </AccordionSummary>
            <RescueMobileCard isLoading={!Object.keys(rescue).length} rescue={rescue} hideExpandPanel noShadow />
        </Accordion>

        <Box
          boxShadow={25}
          py={2}
          my={2}
          mb={8}
          px={2}
          maxWidth="720px"
          bgcolor="background.paper"
          borderRadius="16px"
        >
          {!isNoShowRoute && (
            <>
              {(activeStep === steps.category || activeStep === steps.bicycle) && (
                <Box pb={2} display="flex" justifyContent="space-between">
                  <FormControlLabel
                    data-introid="no-food"
                    label="No food available"
                    control={<Switch name="no_food" inputRef={register} value="true" />}
                  />
                  {activeStep === steps.category && (
                    <IconButton onClick={() => handleTourClick()}>
                      <HelpIcon color="secondary" />
                    </IconButton>
                  )}
                </Box>
              )}
              {activeStep === steps.bicycle && !isNoFoodChecked && (
                <>
                  <Typography variant="subtitle1">Did you use a bicycle for this rescue?</Typography>
                  <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
                    <Button
                      className={classes.bicycleButton}
                      variant={usedBicycle === false ? 'contained' : 'outlined'}
                      color="secondary"
                      onClick={() => setUsedBicycle(false)}
                    >
                      No
                    </Button>
                    <Button
                      className={classes.bicycleButton}
                      variant={usedBicycle ? 'contained' : 'outlined'}
                      color="secondary"
                      onClick={() => setUsedBicycle(true)}
                    >
                      Yes
                    </Button>
                  </Box>
                </>
              )}
              {activeStep === steps.category && !isNoFoodChecked && (
                <>
                  <Typography variant="subtitle1">What did you rescue? (Select all that apply)</Typography>
                  <FormGroup
                    data-introid="categories"
                    data-testid="categories"
                    style={{ maxHeight: isMobileView ? '200px' : '150px', marginTop: '1rem' }}
                  >
                    {foodTypesModel.map((type) => (
                      <CategoryButton
                        key={type.name}
                        name={type.label}
                        variant={(foodTypes || []).find((ft) => ft === type.name) ? 'contained' : 'outlined'}
                        onCategoryClick={() => handleCategoryClick(type)}
                        disabled={isRescueClosed || rescue.cancelled_by_id !== null}
                      >
                        {type.label}
                      </CategoryButton>
                    ))}
                  </FormGroup>
                </>
              )}

              {activeStep === steps.trueWeight && !isNoFoodChecked && (
                <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
                  <Typography variant="subtitle1" color={trueWeight ? 'textPrimary' : 'textSecondary'}>
                    The contents of this rescue have been weighed, and I would like to provide that information.
                  </Typography>
                  <FormControlLabel
                    data-introid="true-weight"
                    control={
                      <Switch
                        name="true_weight"
                        inputRef={register}
                        checked={!trueWeight}
                        value={trueWeight}
                        onChange={() => setTrueWeight(!trueWeight)}
                        className={classes.trueWeightSwitch}
                      />
                    }
                  />
                  <Typography variant="subtitle1" color={!trueWeight ? 'textPrimary' : 'textSecondary'}>
                    I would like to enter my best guess as to the weight and contents of this rescue.
                  </Typography>
                </Box>
              )}
            </>
          )}

          {(activeStep === steps.weight || isNoFoodChecked) && (
            <>
              {!isNoShowRoute && !isNoFoodChecked && (
                <>
                  <Typography variant="subtitle1">
                    {isRescueDonorLink
                      ? 'Please confirm rescue details'
                      : trueWeight
                        ? 'Please provide rescue details'
                        : 'Please provide your best estimate of your rescue weight'}
                  </Typography>

                  <Box mt={3}>
                    <Grid container>
                      <Grid item xs={7}>
                        <TextField
                          data-introid="weight-lbs"
                          data-testid="weight-lbs"
                          id="weight-lbs"
                          name="weight_lbs"
                          value={totalWeight ? formatNumber(totalWeight) : null}
                          label={isRescueDonorLink ? 'Rescue Weight' : trueWeight ? 'Total Weight' : 'Estimated Weight'}
                          placeholder="Total Weight"
                          variant="outlined"
                          required
                          InputLabelProps={{ shrink: true }}
                          onChange={({ target }) => handleTotalWeightChange(target.value)}
                          disabled={isRescueDonorLink && totalWeight}
                        />
                      </Grid>

                      {(isRescueDonorLink && totalWeight) && (
                        <Grid item xs={5}>
                          <Tooltip arrow placement="top" title="This total was reported by the food donor">
                            <IconButton style={{ padding: 15 }}>
                              <HelpIcon color="secondary" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Box>

                  {items.map((item, index) => {
                  const description = foodDescriptionsTypes.find((d) => d.id === item?.food_description_id);
                  const itemLbs = Math.floor(item.lbs || 0);
                    if (description) {
                      return (
                        <RescuedItem
                          isMobileView={isMobileView}
                          key={item.food_description_id}
                          index={index}
                          itemLbs={itemLbs}
                          description={description}
                          onItemWeightChange={handleItemWeightChange}
                        />
                      );
                    }
                    return null;
                  })}

                  <Box pt={2} pb={1} px={1}>
                    <Typography component="h4">Upload some photos (optional)</Typography>
                  </Box>

                  <Box data-introid="upload-photo" boxShadow={25} bgcolor="background.paper" p={1}>
                    {photoFields.length >= 1 && (
                      <Box
                        display="grid"
                        gridTemplateColumns={isMobileView ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'}
                        gridGap={isMobileView ? 8 : 16}
                        p={1}
                      >
                        {photoFields.map((file, index) => (
                          <RescuePhotoButton
                            index={index}
                            key={file.id}
                            file={file}
                            disabled={isSubmitting}
                            removePhoto={removePhoto}
                            onClick={() => removePhoto(index)}
                          />
                        ))}
                      </Box>
                    )}
                    <Box width={1} p={1}>
                      <UploadButton disabled={isSubmitting} onChange={readFilesNew} />
                    </Box>
                  </Box>
                </>
              )}

              <Box
                data-introid="rescue-comments"
                mb={{ xs: 10, sm: 1 }}
                p={2}
                mt={1}
                boxShadow={25}
                bgcolor="background.paper"
              >
                <TextField
                  data-testid="rescue-comments"
                  multiline
                  rows={3}
                  ref={commentRef}
                  disabled={isSubmitting}
                  fullWidth
                  name="comments"
                  id="outlined-basic"
                  label="Comments"
                  placeholder="Is there anything we should know?"
                  variant="outlined"
                  inputRef={register}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </>
          )}

          <Box mt={2}>
            <MobileStepper
              variant="progress"
              steps={Object.values(steps).length}
              position="static"
              LinearProgressProps={{
                style: {
                  width: '100%',
                }
              }}
              activeStep={activeStep}
              style={{ backgroundColor: 'white' }}
              nextButton={renderNextButton()}
              backButton={
                <Button
                  data-introid="close-rescue-back"
                  data-testid="close-rescue-back"
                  size="small"
                  onClick={handleBack}
                  disabled={
                    activeStep === 0 ||
                    (rescueSize?.name !== RESCUE_SIZE_BICYCLE_NAME && activeStep === steps.category) ||
                    isNoShowRoute
                  }
                >
                  <KeyboardArrowLeftIcon />
                  Back
                </Button>
              }
            />
          </Box>

          {(isRescueClosed || rescue.cancelled_by_id !== null) && (
            <Box p={2} textAlign="center">
              <Typography variant="h5">This rescue is already closed</Typography>
            </Box>
          )}
        </Box>

        <ThankYouScreen showThankYouScreen={showThankYouScreen} />

        {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
      </form>
    </FormContext>
  );
};

export default RescueClose;
