import React from 'react';
import routes from '../../../routes';
import { useParams, matchPath } from 'react-router';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import get from 'lodash/get';

const RenderDashboardBreadcrumb = ({ rescueId }) => (
  <Breadcrumbs aria-label="Breadcrumbs">
    <Link color="inherit" to={routes.index}>
      Dashboard
    </Link>

    <Typography color="textPrimary">{rescueId}</Typography>
  </Breadcrumbs>
);

const RescueEditBreadcrumb = () => {
  const { rescueId } = useParams();
  const location = useLocation();

  if (!get(location, 'state.prevPath')) {
    return <RenderDashboardBreadcrumb rescueId={rescueId} />;
  }

  const {
    state: { prevPath },
  } = location;

  let origin = matchPath(prevPath, {
    path: routes.rescues,
    exact: true,
  })
    ? 'Upcoming Rescues'
    : undefined;

  origin = matchPath(prevPath, {
    path: routes.rescuesSchedule,
    exact: true,
  })
    ? 'Schedule'
    : origin;

  origin = matchPath(prevPath, {
    path: routes.mobileDashboardSchedule,
    exact: true,
  })
    ? 'Schedule'
    : origin;

  origin =
    matchPath(prevPath, {
      path: routes.dashboardRescuer,
      exact: true,
    }) ||
    matchPath(prevPath, {
      path: routes.dashboardSC,
      exact: true,
    })
      ? 'Dashboard'
      : origin;

  if (!origin) {
    return <RenderDashboardBreadcrumb rescueId={rescueId} />;
  }

  return (
    <Breadcrumbs aria-label="Breadcrumbs">
      <Link color="inherit" to={{ pathname: prevPath, state: location.state }}>
        {origin}
      </Link>

      <Typography color="textPrimary">Rescue Details</Typography>
    </Breadcrumbs>
  );
};

export default RescueEditBreadcrumb;
