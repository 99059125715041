import { Box, Button } from '@material-ui/core';
import React from 'react';

const UtilityButtons = ({ disabled, fitMapBounds, clearFilters }) => (
  <Box p={2} bgcolor="background.paper">
    <Box px={2}>
      <Button disabled={disabled} variant="outlined" color="secondary" fullWidth onClick={fitMapBounds}>
        center map
      </Button>
    </Box>
    <Box px={2} pt={2}>
      <Button disabled={disabled} variant="outlined" color="primary" fullWidth onClick={clearFilters}>
        clear filters
      </Button>
    </Box>
  </Box>
);

export default UtilityButtons;
