import moment from 'moment';
import get from 'lodash/get';
import { Colors } from '../assets/theme/Colors';
import { fetchRescuerClaimedAndAdoptedRescuesIfNeeded, fetchRescuerPastRescuesIfNeeded } from '../actions/rescues';
import { refreshRescuerMenuBadgeCounters } from '../actions/misc';
import { fetchRescuerEventsIfNeeded } from '../actions/rescuers';
import { initialRescuerEventsState } from '../reducers/events';
import { convertMetersToMiles } from './math';
import isPast from 'date-fns/isPast';
import { formatAddress } from './formatters';
import { DISTANCE_ALL_VALUE } from '../components/RescuersScheduleFilters/filters/DistanceSliderFilter';
import { sortByDateAndTime } from './sorters';
import { newFoodTypes as foodTypesModel } from '../models/foodTypes';
import { FREQUENCY_ONCE } from '../models/donationsNew';

export const donorLinkFilters = {
  dataReceived: 'Data Received',
  dataNotReceived: 'Data Not Received',
  donorLinkActive: 'Donor Link Active',
  donorLinkInactive: 'Donor Link Inactive',
};

export const filterRescuesList = (list, filters) => {
  const uniqueFoodDonors = [...new Set(getUniqueDonors(list).map(r => r.title))];
  const uniqueReceivers = [...new Set(getUniqueReceiver(list).map(r => r.title))];
  const uniqueSites = [...new Set(list.map(r => r.site_id))];

  const filterBySite = rescue => {
    const { siteIds } = filters;
    const siteIdsFilter = get(siteIds, 'value', []).reduce((filteredSites, filteredSiteId) => {
      if (uniqueSites.includes(filteredSiteId)) {
        filteredSites.push(filteredSiteId);
      }

      return filteredSites;
    }, []);

    if (siteIdsFilter.length === 0) {
      return true;
    }

    return siteIdsFilter.includes(rescue.site_id);
  };
  const filterByQuery = rescue => {
    const { query } = filters;
    if (query.value.length === 0) {
      return rescue;
    }
    return (
      (rescue.donor && rescue.donor.toLowerCase().includes(query.value.toLowerCase())) ||
      (rescue.receiver && rescue.receiver.toLowerCase().includes(query.value.toLowerCase()))
    );
  };

  const filterByDay = rescue => {
    const { days } = filters;

    if (days.value.length === 0) {
      return true;
    }

    return days.value.includes(moment(rescue.date, 'YYYYMMDD').format('ddd'));
  };

  const filterByRescueSize = rescue => {
    const { rescueSizes } = filters;

    if (rescueSizes.value.length === 0) {
      return true;
    }

    return rescueSizes.value.includes(rescue.rescue_size_id);
  };

  const filterByDate = rescue => {
    const { date } = filters;

    if (date.value === null) {
      return true;
    }

    return moment(date.value).isSame(moment(rescue.date, 'YYYYMMDD'));
  };

  const filterByTime = rescue => {
    const { after, before } = filters;

    if (after.value === null && before.value === null) {
      return rescue;
    }

    if (after.value && before.value === null) {
      return moment(after.value, 'HH:mm:ss').isBefore(moment(rescue.pickup_end, 'HH:mm'));
    }

    if (before.value && after.value === null) {
      return moment(before.value, 'HH:mm:ss').isAfter(moment(rescue.pickup_begin, 'HH:mm'));
    }

    return (
      moment(after.value, 'HH:mm:ss').isBefore(moment(rescue.pickup_end, 'HH:mm')) &&
      moment(before.value, 'HH:mm:ss').isAfter(moment(rescue.pickup_begin, 'HH:mm'))
    );
  };

  const filterByDonorReceiverList = rescue => {
    const { donorReceiverList } = filters;
    const donorReceiverFilter = get(donorReceiverList, 'value', []).reduce((acc, row) => {
      if (uniqueFoodDonors.includes(row.title) || uniqueReceivers.includes(row.title)) {
        acc.push(row.title);
      }
      return acc;
    }, []);

    if (donorReceiverFilter.length === 0) {
      return rescue;
    }

    return donorReceiverFilter.includes(rescue.location) || donorReceiverFilter.includes(rescue.receiver);
  };
  const filterByDistance = rescue => {
    const { distance } = filters;

    if (distance.value === DISTANCE_ALL_VALUE) {
      return rescue;
    }

    return convertMetersToMiles(rescue.distance) <= distance.value;
  };

  const filterByAvailability = rescue => {
    if (get(filters, 'showOnlyAvailableRescues.value', false)) {
      return !isRescueClaimed(rescue);
    }

    return true;
  };

  const filterByPickupCity = rescue => {
    const { pickupCities } = filters;

    if (pickupCities) {
      if ((pickupCities.value || []).length === 0) {
        return true;
      }

      return (pickupCities.value || []).includes(`${rescue.pickup_city}, ${rescue.pickup_st}`);
    }

    return true;
  };

  const filterByDropoffCity = rescue => {
    const { dropoffCities } = filters;

    if (dropoffCities) {
      if ((dropoffCities.value || []).length === 0) {
        return true;
      }

      return (dropoffCities.value || []).includes(`${rescue.dropoff_city}, ${rescue.dropoff_st}`);
    }

    return true;
  };

  const filterByDonorLink = rescue => {
    const { donorLink } = filters;
    if (donorLink?.value) {
      switch (donorLink.value) {
        case donorLinkFilters.dataReceived:
          return !!rescue.rescue_confirmed;
        case donorLinkFilters.dataNotReceived:
          return rescue.location_donor_link_active && !rescue.rescue_confirmed;
        case donorLinkFilters.donorLinkInactive:
          return !rescue.location_donor_link_active;
        case donorLinkFilters.donorLinkActive:
          return rescue.location_donor_link_active;
        default:
          return true;
      }
    }

    return true;
  };

  if (!filters || Object.keys(filters).length === 0) {
    return list;
  }

  return list.filter(
    rescue =>
      filterByQuery(rescue) &&
      filterByDay(rescue) &&
      filterByDate(rescue) &&
      filterByTime(rescue) &&
      filterByRescueSize(rescue) &&
      filterByDonorReceiverList(rescue) &&
      filterBySite(rescue) &&
      filterByDistance(rescue) &&
      filterByAvailability(rescue) &&
      filterByPickupCity(rescue) &&
      filterByDropoffCity(rescue) &&
      filterByDonorLink(rescue)
  );
};

export const filterRescuesListInfiniteScroll = (list, filters) => {
  const uniqueFoodDonors = [...new Set(getUniqueDonors(list).map(r => r.title))];
  const uniqueReceivers = [...new Set(getUniqueReceiver(list).map(r => r.title))];
  const uniqueSites = [...new Set(list.map(r => r.site_id))];

  const filterBySite = rescue => {
    const { siteIds } = filters;
    const siteIdsFilter = get(siteIds, 'value', []).reduce((filteredSites, filteredSiteId) => {
      if (uniqueSites.includes(filteredSiteId)) {
        filteredSites.push(filteredSiteId);
      }

      return filteredSites;
    }, []);

    if (siteIdsFilter.length === 0) {
      return true;
    }

    return siteIdsFilter.includes(rescue.site_id);
  };
  const filterByQuery = rescue => {
    const { query } = filters;
    if (query.value.length === 0) {
      return rescue;
    }
    return (
      (rescue.donor && rescue.donor.toLowerCase().includes(query.value.toLowerCase())) ||
      (rescue.receiver && rescue.receiver.toLowerCase().includes(query.value.toLowerCase()))
    );
  };

  const filterByDay = rescue => {
    const { days } = filters;

    if (days.value.length === 0) {
      return true;
    }

    return days.value.includes(moment(rescue.date, 'YYYYMMDD').format('ddd'));
  };

  const filterByTime = rescue => {
    const { after, before } = filters;

    if (after.value === null && before.value === null) {
      return rescue;
    }

    if (after.value && before.value === null) {
      return moment(after.value, 'HH:mm:ss').isBefore(moment(rescue.pickup_end, 'HH:mm'));
    }

    if (before.value && after.value === null) {
      return moment(before.value, 'HH:mm:ss').isAfter(moment(rescue.pickup_begin, 'HH:mm'));
    }

    return (
      moment(after.value, 'HH:mm:ss').isBefore(moment(rescue.pickup_end, 'HH:mm')) &&
      moment(before.value, 'HH:mm:ss').isAfter(moment(rescue.pickup_begin, 'HH:mm'))
    );
  };

  const filterByRescueSize = rescue => {
    const { rescueSizes } = filters;

    if (rescueSizes.value.length === 0) {
      return true;
    }

    return rescueSizes.value.includes(rescue.rescue_size_id);
  };

  const filterByDonorReceiverList = rescue => {
    const { donorReceiverList } = filters;
    const donorReceiverFilter = get(donorReceiverList, 'value', []).reduce((acc, row) => {
      if (uniqueFoodDonors.includes(row.title) || uniqueReceivers.includes(row.title)) {
        acc.push(row.title);
      }
      return acc;
    }, []);

    if (donorReceiverFilter.length === 0) {
      return rescue;
    }

    return donorReceiverFilter.includes(rescue.location) || donorReceiverFilter.includes(rescue.receiver);
  };
  const filterByDistance = rescue => {
    const { distance } = filters;

    if (distance.value === DISTANCE_ALL_VALUE) {
      return rescue;
    }

    return convertMetersToMiles(rescue.distance) <= distance.value;
  };

  const filterByAvailability = rescue => {
    if (get(filters, 'showOnlyAvailableRescues.value', false)) {
      return !isRescueClaimed(rescue);
    }

    return true;
  };

  const filterByPickupCity = rescue => {
    const { pickupCities } = filters;

    if (pickupCities) {
      if ((pickupCities.value || []).length === 0) {
        return true;
      }

      return (pickupCities.value || []).includes(`${rescue.pickup_city}, ${rescue.pickup_st}`);
    }

    return true;
  };

  const filterByDropoffCity = rescue => {
    const { dropoffCities } = filters;

    if (dropoffCities) {
      if ((dropoffCities.value || []).length === 0) {
        return true;
      }

      return (dropoffCities.value || []).includes(`${rescue.dropoff_city}, ${rescue.dropoff_st}`);
    }

    return true;
  };

  const filterByDonorLink = rescue => {
    const { donorLink } = filters;
    if (donorLink?.value) {
      switch (donorLink.value) {
        case donorLinkFilters.dataReceived:
          return !!rescue.rescue_confirmed;
        case donorLinkFilters.dataNotReceived:
          return rescue.location_donor_link_active && !rescue.rescue_confirmed;
        case donorLinkFilters.donorLinkInactive:
          return !rescue.location_donor_link_active;
        case donorLinkFilters.donorLinkActive:
          return rescue.location_donor_link_active;
        default:
          return true;
      }
    }

    return true;
  };

  if (!filters || Object.keys(filters).length === 0) {
    return list;
  }

  return list.filter(
    rescue =>
      filterByQuery(rescue) &&
      filterByDay(rescue) &&
      filterByTime(rescue) &&
      filterByRescueSize(rescue) &&
      filterByDonorReceiverList(rescue) &&
      filterBySite(rescue) &&
      filterByDistance(rescue) &&
      filterByAvailability(rescue) &&
      filterByPickupCity(rescue) &&
      filterByDropoffCity(rescue) &&
      filterByDonorLink(rescue)
  );
};

export const daysList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const sortDays = (a, b) => daysList.indexOf(a) - daysList.indexOf(b);

export const getRescueFoodTypes = rescue => {
  if (!rescue) {
    return '-';
  }

  if (!Array.isArray(rescue.food_types)) {
    return rescue.food_types;
  }

  const foodTypes = [...rescue.food_types];
  const otherFoodTypeIndex = foodTypes.findIndex(foodType => foodType === 'other');
  const otherFoodType = rescue.food_type_other;

  // let's check two things over there: 1) if we have "other" food type checked and "food_type_other" provided
  // if so, we need to replace the "other" keyword with the actual provided "food_type_other"
  if (otherFoodTypeIndex !== -1 && otherFoodType) {
    foodTypes[otherFoodTypeIndex] = otherFoodType;
  }

  return foodTypes.join(', ');
};

export const getRescuedFoodTypesLabel = (rescue) => {
  if (!rescue) {
    return '-';
  }

  const foodTypes = rescue?.food_types || [];
  const foodTypesLabels = foodTypesModel.reduce((curr, acc) => {
    if (foodTypes.includes(acc.name)) {
      curr.push(acc.label);
    }

    return curr;
  }, []);

  return foodTypesLabels.join(', ');
};

export const getRescuedItemsFoodTypes = (rescue, foodDescriptions) => {
  if (!rescue) {
    return '-';
  }

  const items = rescue?.rescued_food || [];
  const description = foodDescriptions.filter((desc) => items.some((item) => item.food_description_id === desc.id));

  const itemsLabel = foodTypesModel.reduce((curr, acc) => {
    if (description.find((desc) => desc.name === acc.label)) {
      curr.push(acc.label);
    }

    return curr;
  }, []);

  return itemsLabel.join(', ');
};

export const getRescuePickupAddress = rescue =>
  formatAddress({
    address: rescue.pickup_address,
    city: rescue.pickup_city,
    st: rescue.pickup_st,
    zip: rescue.pickup_zip,
  });

export const getRescueDropoffAddress = rescue =>
  formatAddress({
    address: rescue.dropoff_address,
    city: rescue.dropoff_city,
    st: rescue.dropoff_st,
    zip: rescue.dropoff_zip,
  });

export const getUniqueReceiver = rescue => {
  return [...new Set(rescue.map(row => row.receiver))]
    .filter(row => row !== null)
    .map(unique => {
      return {
        groupName: 'Receiving Agency',
        type: 'receiver',
        title: unique,
      };
    });
};

export const getUniqueDonors = rescue => {
  return [...new Set(rescue.map(row => row.location))]
    .filter(row => row !== null)
    .map(unique => {
      return {
        groupName: 'Donors',
        type: 'location',
        title: unique,
      };
    });
};

export const getMyFrusMyBadges = (eventsEntities, activeUserId) =>
    get(eventsEntities, `byRescuerId[${activeUserId}]`, initialRescuerEventsState);

export const getMyFrusMyEvents = (eventsEntities, activeUserId) =>
  get(eventsEntities, `byRescuerId[${activeUserId}]`, initialRescuerEventsState);

export const getMyRescues = (rescuerId, rescues) => {
  return get(rescues, ['claimedAndAdoptedIds', rescuerId, 'data'], []).reduce((acc, id) => {
    const rescue = rescues.byId[id];

    if (rescue.closed_by_id === null) {
      acc.push(rescue);
    }

    return acc;
  }, []).sort(sortRescuesByDateAndPickupBegin);
};

export const getScheduleAvailableRescues = (rescuerId, rescues) => {
  if (!rescues.availableIds[rescuerId] || !rescues.availableIds[rescuerId]) {
    return {
      inflight: false,
      data: [],
      pagination: {},
      lastUpdated: null,
    };
  }

  return {
    inflight: rescues.availableIds[rescuerId].inflight || false,
    data: rescues.availableIds[rescuerId].data || [],
    pagination: rescues.availableIds[rescuerId].pagination || {},
    lastUpdated: rescues.availableIds[rescuerId].lastUpdated || null,
  };
};

export const getTodayRescues = (rescuerId, rescues, pure = false) => {
  const today = moment().format('YYYYMMDD');

  if (pure) {
    return rescues.filter(rescue => rescue.date === today).sort(sortRescuesByDateAndPickupBegin);
  }

  return get(rescues, ['claimedAndAdoptedIds', rescuerId, 'data'], [])
    .reduce((acc, curr) => {
      const rescue = rescues.byId[curr];
      if (rescue.date === today) {
        acc.push(rescue);
      }
      return acc;
    }, [])
    .sort(sortRescuesByDateAndPickupBegin);
};

export const getTodayUnclosedRescues = (rescuerId, rescues, pure = false) => {
  const today = moment().format('YYYYMMDD');

  if (pure) {
    return rescues.filter(rescue => rescue.date === today).sort(sortRescuesByDateAndPickupBegin);
  }

  return get(rescues, ['claimedAndAdoptedIds', rescuerId, 'data'], [])
    .reduce((acc, curr) => {
      const rescue = rescues.byId[curr];
      if (rescue.date === today && !rescue.closed_by_id) {
        acc.push(rescue);
      }
      return acc;
    }, [])
    .sort(sortRescuesByDateAndPickupBegin);
};

export const getRecentRescues = (rescuerId, rescues, pure = false, maxRows = 5) => {
  const today = moment().startOf('day');
  let rescuesData = rescues || [];
  if (!pure) {
    rescuesData = get(rescues, ['pastIds', rescuerId, 'data'], []).map(id => rescues.byId[id]);
  }

  // sort rescues by date and pickup begin time
  const allRescues = rescuesData
    .filter(rescue => today.isAfter(rescue.date, 'YYYYMMDD'))
    .sort(sortRescuesByDateAndPickupBegin);

  if (maxRows) {
    return rescuesData.slice(0, maxRows);
  }

  return rescuesData;
};

export const getRecentUnclosedRescues = (rescuerId, rescues, pure = false, maxRows = 5) => {
  let rescuesData = rescues || [];
  if (!pure) {
    rescuesData = get(rescues, ['pastIds', rescuerId, 'data'], []).map(id => rescues.byId[id]);
  }

  if (maxRows) {
    return rescuesData.slice(0, maxRows).filter((rescue) => !rescue.closed_by_id && !rescue.cancelled_by_id);
  }

  return rescuesData.filter((rescue) => rescue.completed === null && !rescue.cancelled_by_id);
};

export const getUpcomingRescues = (rescuerId, rescues, pure = false, maxRows = 5) => {
  let today = moment().format('YYYYMMDD');

  if (pure) {
    // YYYY-MM-DD format (!)
    today = moment().format('YYYY-MM-DD');
    return rescues.filter(rescue => moment(rescue.date, 'YYYY-MM-DD').isAfter(today));
  }

  // alas traveler! be aware that these dates are in YYYYMMDD format, instead of YYYY-MM-DD (bear with me...)
  const rescuesData = get(rescues, ['claimedAndAdoptedIds', rescuerId, 'data'], []).reduce((acc, id) => {
    const rescue = rescues.byId[id];

    if (rescue.date !== today) {
      acc.push(rescue);
    }
    return acc;
  }, []).sort(sortRescuesByDateAndPickupBegin);

  if (maxRows) {
    return rescuesData.slice(0, maxRows);
  }

  return rescuesData;
};

export const getUpcomingRescuesIncludingToday = (rescuerId, rescues) =>
  get(rescues, ['claimedAndAdoptedIds', rescuerId, 'data'], [])
    .map(id => rescues.byId[id])
    .filter(rescue => moment(rescue.date, 'YYYYMMDD').isSameOrAfter(moment().format('YYYYMMDD')));

const isRescuePast = (rescue) => {
  const rescueDateTime = moment(`${rescue.date} ${rescue.pickup_end}`, 'YYYYMMDD HH:mm:ss');
  return moment().isSameOrAfter(rescueDateTime, 'second');
};

export const showUnClaimRescueButtons = (rescue, user) => {
  return (
    rescue.cancelled_by_id === null &&
    !isRescuePast(rescue) &&
    rescue.rescuer_id !== null &&
    rescue.rescuer_id === user.id &&
    rescue.closed_by_id === null
  );
};

export const showClaimRescueButton = rescue => {
  return (
    rescue.cancelled_by_id === null &&
    moment(rescue.date, 'YYYYMMDD').isSameOrAfter(moment(), 'day') &&
    rescue.closed_by_id === null &&
    rescue.rescuer_id === null &&
    (rescue.adopter_id === null || rescue.released_by_id !== null)
  );
};

export const showAdoptRescueButton = rescue => {
  return (
    rescue.cancelled_by_id === null &&
    moment(rescue.date, 'YYYYMMDD').isSameOrAfter(moment(), 'day') &&
    rescue.frequency !== 0 &&
    rescue.adopter_id === null &&
    rescue.rescuer_id === null
  );
};

export const showReleaseRescueButton = (rescue, user) => {
  return (
    rescue.cancelled_by_id === null &&
    !isRescuePast(rescue) &&
    rescue.adopter_id === user.id &&
    rescue.rescuer_id === null &&
    rescue.released_by_id !== user.id
  );
};

export const showUnAdoptRescueButton = (rescue, user) => {
  return (
    rescue.cancelled_by_id === null &&
    !isRescuePast(rescue) &&
    rescue.adopter_id === user.id &&
    rescue.rescuer_id === null
  );
};

export const showCloseRescueButtons = (rescue, user) => {
  return (
    rescue.cancelled_by_id === null &&
    (rescue.rescuer_id === user?.id || rescue.claimer_id === user?.id) &&
    rescue.closed_by_id === null &&
    moment(rescue.date, 'YYYYMMDD').isSameOrBefore(moment(), 'day')
  );
};

export const showICouldnotMakeIt = (rescue, user) => {
  return (
    rescue.cancelled_by_id === null &&
    (rescue.rescuer_id === user?.id || rescue.claimer_id === user?.id) &&
    rescue.closed_by_id === null &&
    isRescuePast(rescue)
  );
};

export const getRescuePosition = rescue => {
  if (!rescue || (!rescue.position || !rescue.positions)) {
    return 'N/A';
  }

  return `${rescue.position}/${rescue.positions}`;
};

export const getRescueAdopter = rescue => {
  if (!rescue.adopter_id) {
    return null;
  }
  return { id: rescue.adopter_id, name: rescue.adopter };
};

export const getRescueRescuer = rescue => {
  if (!rescue.rescuer_id) {
    return null;
  }
  return { id: rescue.rescuer_id, name: rescue.rescuer };
};

export const getRescueReleaser = rescue => {
  if (!rescue.released_by_id) {
    return null;
  }

  return { id: rescue.released_by_id, name: rescue.releaser };
};

export const isRescueReleased = rescue => rescue.released_by_id !== null;

export const getRescueClaimer = rescue => {
  const rescuer = getRescueRescuer(rescue);
  const adopter = getRescueAdopter(rescue);

  // fist checking rescuer so if rescue is released by adopter a claimer is returned
  if (rescuer) {
    return {
      ...rescuer,
      claimerType: 'rescuer',
    };
  }

  if (adopter && !isRescueReleased(rescue)) {
    return {
      ...adopter,
      claimerType: 'adopter',
    };
  }

  return null;
};

export const isRescueClaimed = rescue => getRescueClaimer(rescue) !== null;
export const isRescueClosed = rescue => rescue.closed_by_id !== null;
export const isRescueCancelled = rescue => rescue.cancelled_by_id !== null;

export const rescueToScheduleEvent = rescue => {
  const getRescueEventColor = event => {
    if (event.closed_by_id !== null) {
      return {
        backgroundColor: Colors.rescues.closed.color,
        textColor: Colors.rescues.closed.contrastText,
        borderColor: Colors.rescues.closed.borderColor,
        status: 'Closed',
      };
    }

    if (event.cancelled_by_id !== null) {
      return {
        backgroundColor: Colors.rescues.cancelled.color,
        textColor: Colors.rescues.cancelled.contrastText,
        borderColor: Colors.rescues.cancelled.borderColor,
        status: 'Cancelled',
      };
    }

    if (isRescueClaimed(rescue)) {
      return {
        backgroundColor: Colors.rescues.claimed.color,
        textColor: Colors.rescues.claimed.contrastText,
        borderColor: Colors.rescues.claimed.borderColor,
        status: 'Claimed',
      };
    }

    return {
      backgroundColor: Colors.rescues.unclaimed.color,
      textColor: Colors.rescues.unclaimed.contrastText,
      borderColor: Colors.rescues.unclaimed.borderColor,
      status: 'Unclaimed',
    };
  };

  const eventMomentDate = moment(rescue.date, 'YYYYMMDD');
  const eventDate = eventMomentDate.toDate();
  eventDate.setHours(23, 59, 59);

  return {
    id: rescue.id,
    title: `${getRescuePickupLocationFullName(rescue)} => ${rescue.receiver || 'No receiver selected'}`,
    start: `${eventMomentDate.format('YYYY-MM-DD')} ${rescue.pickup_begin}`,
    end: `${eventMomentDate.format('YYYY-MM-DD')} ${rescue.pickup_end}`,
    allDay: false,
    type: 'rescue',
    isPast: isPast(eventDate),
    isCancelled: rescue.cancelled_by_id !== null,
    isClosed: rescue.closed_by_id !== null,
    isRecurring: rescue.frequency !== FREQUENCY_ONCE,
    claimer: getRescueClaimer(rescue),
    location: rescue.location,
    location_id: rescue.location_id,
    pickup_location_name: rescue.pickup_location_name || renderPickupLocationNameOrAdress(rescue),
    pickup_location_id: rescue.pickup_location_id,
    ...getRescueEventColor(rescue),
  };
};

export const eventToScheduleEvent = event => {
  const getColor = () => ({
    backgroundColor: Colors.event.color,
    textColor: Colors.event.contrastText,
    borderColor: Colors.event.borderColor,
  });

  const eventMomentDate = moment(event.date.value, event.date.format);
  const eventDate = eventMomentDate.toDate();
  eventDate.setHours(23, 59, 59);

  return {
    id: event.id,
    title: event.name,
    start: `${eventMomentDate.format('YYYY-MM-DD')} ${event.begin.value}`,
    end: `${eventMomentDate.format('YYYY-MM-DD')} ${event.end.value}`,
    isPast: isPast(eventDate),
    isRecurring: event.frequency !== FREQUENCY_ONCE,
    allDay: false,
    type: 'event',
    status: 'Event',
    ...getColor(event),
  };
};

export const fetchRescuerDashboardData = (siteId, rescuerId, quietMode = false) => dispatch => {
  // WARNING keep those calls in sync with actions/misc.js refreshRescuerDesktopBadgeCounters method
  // otherwise badge counters can be not in sync with tabs content
  dispatch(fetchRescuerEventsIfNeeded(rescuerId, quietMode));
  dispatch(
    fetchRescuerClaimedAndAdoptedRescuesIfNeeded(
      rescuerId,
      moment().format('YYYYMMDD'),
      moment()
        .add(7, 'd')
        .format('YYYYMMDD'),
      quietMode
    )
  );
  dispatch(fetchRescuerPastRescuesIfNeeded(rescuerId, quietMode));
  dispatch(refreshRescuerMenuBadgeCounters(rescuerId));
};

export const getRescuePickupLocationFullName = rescue => {
  if (!rescue) {
    return null;
  }

  if (!rescue.pickup_location_name) {
    return rescue.location;
  }

  return `${rescue.location} - ${rescue.pickup_location_name}`;
};

export const renderPickupLocationNameOrAdress = (rescue) =>
  rescue.pickup_location_name ||
  formatAddress({
    address: rescue.pickup_address,
    city: rescue.pickup_city,
    st: rescue.pickup_st,
    zip: rescue.pickup_zip,
  });

export const renderDonationPickupLocationNameOrAdress = (donation) =>
  donation.pickup_location_name ||
  formatAddress({
    address: donation.pickup_location_address,
    city: donation.pickup_location_city,
    st: donation.pickup_location_st,
    zip: donation.pickup_location_zip,
  });

export const sortRescuesByDateAndPickupBegin = (a, b) =>
  sortByDateAndTime(
    'desc',
    `${moment(a.date, 'YYYYMMDD').format('YYYY-MM-DD')} ${a.pickup_begin}`,
    `${moment(b.date, 'YYYYMMDD').format('YYYY-MM-DD')} ${b.pickup_begin}`
  );

export const getRescueEstimatedLbs = (rescueSize, lbsPerMealValue) =>
  rescueSize ? Math.round(rescueSize.meals_per_rescue * lbsPerMealValue) : null;

export const getUnitBasedOnDescription = (currentFoodTypeId, foodTypes = [], unitsTypes = []) => {
  const allowedUnitsForEachDescription = foodTypes.reduce((prev, curr) => {
    if (curr.name === 'Beverages') {
      return {
        ...prev,
        [curr.id]: {
          ...curr,
          units: unitsTypes.filter(
            (unit) => unit.name === 'Boxes' || unit.name === 'Case(s)' || unit.name === 'Gallon(s)' || unit.name === 'Grocery Bag(s)'
          ),
        },
      };
    }
    if (curr.name === 'Baked Goods') {
      return {
        ...prev,
        [curr.id]: {
          ...curr,
          units: unitsTypes.filter(
            (unit) =>
              unit.name === 'Boxes' || unit.name === 'Aluminum Tin(s)' || unit.name === 'To-Go Container(s)' || unit.name === 'Grocery Bag(s)'
          ),
        },
      };
    }
    if (curr.name === 'Produce') {
      return {
        ...prev,
        [curr.id]: {
          ...curr,
          units: unitsTypes.filter(
            (unit) =>
              unit.name === 'Boxes' ||
              unit.name === 'Aluminum Tin(s)' ||
              unit.name === 'To-Go Container(s)' ||
              unit.name === 'Grocery Bag(s)'
          ),
        },
      };
    }
    if (curr.name === 'Meats') {
      return {
        ...prev,
        [curr.id]: {
          ...curr,
          units: unitsTypes.filter(
            (unit) =>
              unit.name === 'Boxes' || unit.name === 'Aluminum Tin(s)' || unit.name === 'To-Go Container(s)' || unit.name === 'Grocery Bag(s)'
          ),
        },
      };
    }
    if (curr.name === 'Non-perishable') {
      return {
        ...prev,
        [curr.id]: {
          ...curr,
          units: unitsTypes.filter((unit) => unit.name === 'Boxes' || unit.name === 'Case(s)' || unit.name === 'Grocery Bag(s)'),
        },
      };
    }
    if (curr.name === 'Prepared/Ready to Eat') {
      return {
        ...prev,
        [curr.id]: {
          ...curr,
          units: unitsTypes.filter(
            (unit) =>
              unit.name === 'Boxes' || unit.name === 'Aluminum Tin(s)' || unit.name === 'To-Go Container(s)' || unit.name === 'Grocery Bag(s)'
          ),
        },
      };
    }
    if (curr.name === 'Non-food') {
      return {
        ...prev,
        [curr.id]: {
          ...curr,
          units: unitsTypes.filter(
            (unit) => unit.name === 'Boxes' || unit.name === 'Case(s)' || unit.name === 'Gallon(s)' || unit.name === 'Grocery Bag(s)'
          ),
        },
      };
    }
    if (curr.name === 'Other') {
      return {
        ...prev,
        [curr.id]: {
          ...curr,
          units: unitsTypes.filter(
            (unit) => unit.name !== 'Other'
          ),
        },
      };
    }
    return prev;
  }, []);
  return allowedUnitsForEachDescription[currentFoodTypeId]
    ? allowedUnitsForEachDescription[currentFoodTypeId].units
    : null;
};

export const getEstimatedLbsValues = (quantity, unitId, foodDescriptionId, lbsEstimationValues = []) => {
  const baseValue =
    lbsEstimationValues &&
    lbsEstimationValues.find(
      (value) => value.unit.id === parseInt(unitId, 10) && value.food_description.id === parseInt(foodDescriptionId, 10)
    );

  if (baseValue) {
    const value = baseValue.lbs * parseInt(quantity, 10);
    return Math.round(value);
  }
  return 0;
};

export const getRescueWeight = (rescue = {}) => {
  if (rescue?.calculated_lbs) {
    return rescue.calculated_lbs;
  }

  return 0;
};

export const calculatedLbsSource = (value) => {
  if (value === 'food items lbs. provided by R/SD') {
    return 'Rescuer and SD Edit';
  }

  if (
    value === 'total lbs. provided by rescuer' ||
    value === 'food items lbs. provided by R'
  ) {
    return 'Rescuer';
  }

  if (
    value === 'total lbs. override by SD' ||
    value === 'custom lbs. provided by SD' ||
    value === 'food items lbs. provided by SD'
  ) {
    return 'SD Override';
  }

  if (value === 'vehicle size estimates') {
    return 'Size Estimate';
  }

  return false;
};
