import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import { isSafari } from 'react-device-detect';
import {
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { HighlightOff as HighlightOffIcon, Cancel as CancelIcon } from '@material-ui/icons';
import OverlayLoader from './OverlayLoader';
import FeatureFlagWrapper, { FF_EVENTS } from './FeatureFlagWrapper';
import { Colors } from '../assets/theme/Colors';
import { TextFieldWrapper } from './Form/MuiFormWrapper';
import DateAndGranularityPicker from './DateAndGranularityPicker/DateAndGranularityPicker';
import { renderPickupLocationNameOrAdress } from '../helpers/RescuesHelper';
import '../assets/scss/fullCalendar.css';

const TOP_PADDING = 100;
const BUTTONS_ROW = 42;
export const REDUX_KEY = '#rescues/schedule';

const eventContentRender = props => {
  const {
    event,
    timeText,
    backgroundColor,
    textColor,
    borderColor,
    view: { type },
  } = props;

  const isCancelled = event.extendedProps.isCancelled;
  const isRecurring = event.extendedProps.isRecurring;
  const claimer = event.extendedProps.claimer;

  let baseStyles = {
    whiteSpace: 'normal',
    cursor: 'pointer',
    padding: 2,
    filter: event.extendedProps.isPast ? 'brightness(0.8)' : 'unset',
    textDecoration: isCancelled ? 'line-through' : 'none',
    textDecorationColor: isCancelled ? backgroundColor : 'none',
  };

  switch (type) {
    case 'timeGridDay':
    case 'timeGridWeek':
      return (
        <div
          style={{
            ...baseStyles,
            backgroundColor: isCancelled ? Colors.white : backgroundColor,
            color: isCancelled ? Colors.black : textColor,
            border: (() => {
              if (isRecurring) {
                return `2px solid ${borderColor}`
              }

              if (isCancelled) {
                return `1px solid ${backgroundColor}`;
              }

              return 'initial';
            })(),
            height: '100%',
            width: '100%',
            position: 'absolute',
            marginTop: -1,
            marginLeft: -1,
          }}
        >
          <div className="fc-event-main-frame">
            <div className="fc-event-time">{timeText}</div>
            <div className="fc-event-title-container">
              <div className="fc-event-title fc-sticky">{event.title}</div>
            </div>
          </div>
        </div>
      );
    case 'dayGridDay':
    case 'dayGridWeek':
      return (
        <div
          style={{
            ...baseStyles,
            backgroundColor: isCancelled ? Colors.white : backgroundColor,
            color: isCancelled ? Colors.black : textColor,
            border: (() => {
              if (isRecurring) {
                return `2px solid ${borderColor}`
              }

              if (isCancelled) {
                return `1px solid ${backgroundColor}`;
              }

              return 'initial';
            })(),
            width: '100%',
          }}
        >
          <strong className="fc-event-time">{timeText}</strong>
          <span>{event.title}</span>
          
          {claimer && (
            <div>
              <strong>{claimer.claimerType[0].toUpperCase() + claimer.claimerType.substring(1)}</strong>: {claimer.name}
            </div>
          )}
        </div>
      );
    case 'dayGridMonth':
      return (
        <div
          style={{
            ...baseStyles,
            backgroundColor: isCancelled ? Colors.white : backgroundColor,
            color: isCancelled ? Colors.black : textColor,
            border: (() => {
              if (isRecurring) {
                return `2px solid ${borderColor}`
              }

              if (isCancelled) {
                return `1px solid ${backgroundColor}`;
              }

              return 'initial';
            })(),
            width: '100%',
          }}
        >
          <strong className="fc-event-time">{timeText}</strong>
          <span>{event.title}</span>

          {claimer && (
            <div>
              <strong>{claimer.claimerType[0].toUpperCase() + claimer.claimerType.substring(1)}</strong>: {claimer.name}
            </div>
          )}
        </div>
      );
    case 'listWeek':
      return (
        <div
          style={{
            ...baseStyles,
            backgroundColor: isCancelled ? Colors.white : backgroundColor,
            color: isCancelled ? Colors.black : textColor,
            border: (() => {
              if (isRecurring) {
                return `2px solid ${borderColor}`
              }

              if (isCancelled) {
                return `1px solid ${backgroundColor}`;
              }

              return 'initial';
            })(),
            width: '100%',
          }}
        >
          <span>{event.title}</span>

          {claimer && (
            <div>
              <strong>{claimer.claimerType[0].toUpperCase() + claimer.claimerType.substring(1)}</strong>: {claimer.name}
            </div>
          )}
        </div>
      );
  }
};

const handleEventMount = ({ view: { type }, event, el }, isMobileView) => {
  if (type === 'listWeek' && isMobileView && el) {
    el.querySelectorAll('.fc-list-event-time').forEach(node => {
      const [starTime, endTime] = node.innerHTML.split('-');
      node.innerHTML = `${starTime} ${endTime}`;
    });
  }

  return undefined;
};

const dayHeaderContentRender = ({ text, sideText, view: { type } }) => {
  if (type === 'listWeek') {
    return (
      <a className="fc-list-day-text">
        {text}, {sideText}
      </a>
    );
  }

  return undefined;
};

const getButtonTexts = isMobileView =>
  isMobileView
    ? {
        today: 'This week',
      }
    : {
        // fallback to default names
      };

const getHeaderProps = (isMobileView, showTimeGrid) => {
  if (isMobileView) {
    return {
      start: 'title',
      center: '',
      end: 'today prev,next',
    };
  }

  return {
    start: '',
    center: 'title',
    end: `month,week,day,list today prev,next`,
  };
};

const EventLegend = ({ text, onFilterClick, statusFilter, styles = {}, iconColor = Colors.white }) => (
  <Box
    css={{
      ...styles,
      padding: '2px 8px',
      borderRadius: 4,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      ...(statusFilter &&
        statusFilter !== text && {
          transform: 'translateX(-100%)',
          position: 'absolute',
          opacity: 0,
        }),
      transition: 'all 0.3s ease-out',
    }}
    onClick={
      !statusFilter
        ? () => {
            onFilterClick(text);
          }
        : () => {
            onFilterClick(null);
          }
    }
  >
    {statusFilter === text && (
      <IconButton size="small" onClick={() => onFilterClick(null)} style={{ marginRight: '8px' }}>
        <CancelIcon style={{ fontSize: '14px', color: iconColor }} />
      </IconButton>
    )}
    <Typography variant="caption"> {text}</Typography>
  </Box>
);

const RescuesSchedule = forwardRef(
  (
    {
      events,
      isFetching,
      bottomPadding,
      fetchRescues,
      isMobileView,
      handleGridSwitchClick,
      onSearchQueryChange,
      defaultSearchQuery,
      handleViewChange,
      calendarOptions,
      onEventClick,
      onDateRangeChange,
      foodDonorFilter,
      onFoodDonorFilterChange,
      pickupLocationFilter,
      onPickupLocationFilterChange,
      uniqueFoodDonors,
      uniquePickupLocations,
      setStatusFilter,
      statusFilter,
      setRecurringFilter,
      recurringFilter,
      locationState,
    },
    ref
  ) => {
    const handleWindowResize = () => {
      if (!ref.current) {
        return;
      }

      const cAPI = ref.current.getApi();
      cAPI.batchRendering(() => {
        let newHeight = window.innerHeight - TOP_PADDING - bottomPadding;
        if (!isMobileView) {
          newHeight = newHeight - BUTTONS_ROW;
        }

        cAPI.setOption('height', newHeight);
        if (isMobileView) {
          cAPI.changeView('listWeek');
        }
      });
    };

    const onViewClick = (grid, event) => {
      const buttons = document.querySelectorAll('.fc-button');
      buttons.forEach(button => button.classList.remove('fc-button-active'));

      event.target.classList.add('fc-button-active');
      if (!ref.current) {
        return;
      }
      const cAPI = ref.current.getApi();
      cAPI.changeView(grid);

      handleViewChange({ type: grid });
    };

    const getCustomButtons = showTimeGrid => {
      const viewType = showTimeGrid ? 'time' : 'day';
      return {
        month: {
          text: 'month',
          click: event => onViewClick('dayGridMonth', event),
        },
        week: {
          text: 'week',
          click: event => onViewClick(`${viewType}GridWeek`, event),
        },
        day: {
          text: 'day',
          click: event => onViewClick(`${viewType}GridDay`, event),
        },
        list: {
          text: 'list',
          click: event => onViewClick('listWeek', event),
        },
      };
    };

    useEffect(() => {
      if (!ref.current) {
        return;
      }
      const cAPI = ref.current.getApi();

      const gridType = calendarOptions.showTimeGrid ? 'time' : 'day';
      let newView = cAPI.view.type;

      if (newView.includes('GridWeek')) {
        newView = `${gridType}GridWeek`;
        const viewButton = document.querySelector('.fc-week-button');
        if (viewButton) {
          viewButton.classList.add('fc-button-active');
        }
      }

      if (newView.includes('GridDay')) {
        newView = `${gridType}GridDay`;
        const viewButton = document.querySelector('.fc-day-button');
        if (viewButton) {
          viewButton.classList.add('fc-button-active');
        }
      }

      if (newView.includes('GridMonth')) {
        const viewButton = document.querySelector('.fc-month-button');
        if (viewButton) {
          viewButton.classList.add('fc-button-active');
        }
      }

      if (newView.includes('listWeek')) {
        const viewButton = document.querySelector('.fc-list-button');
        if (viewButton) {
          viewButton.classList.add('fc-button-active');
        }
      }

      cAPI.batchRendering(() => {
        cAPI.changeView(isMobileView ? 'listWeek' : newView);
        cAPI.setOption('headerToolbar', getHeaderProps(isMobileView, calendarOptions.showTimeGrid));
      });
    }, [isMobileView, calendarOptions.showTimeGrid]);

    return (
      <OverlayLoader isLoading={isFetching}>
        <Box
          display={isMobileView ? 'none' : 'flex'}
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="grid" gridAutoFlow="column" overflow="hidden" position="relative" gridColumnGap={5}>
            <EventLegend
              styles={{
                backgroundColor: Colors.rescues.unclaimed.color,
                color: Colors.rescues.unclaimed.contrastText,
              }}
              text="Unclaimed"
              onFilterClick={setStatusFilter}
              statusFilter={statusFilter}
            />
            <EventLegend
              styles={{
                backgroundColor: Colors.rescues.claimed.color,
                color: Colors.rescues.claimed.contrastText,
              }}
              text="Claimed"
              onFilterClick={setStatusFilter}
              statusFilter={statusFilter}
            />
            <EventLegend
              styles={{
                backgroundColor: Colors.rescues.closed.color,
                color: Colors.rescues.closed.contrastText,
              }}
              text="Closed"
              onFilterClick={setStatusFilter}
              statusFilter={statusFilter}
            />
            <EventLegend
              styles={{
                backgroundColor: '#fff',
                color: '#000',
                border: `1px solid ${Colors.rescues.cancelled.color}`,
                textDecoration: 'line-through',
                textDecorationColor: Colors.rescues.cancelled.color,
              }}
              text="Cancelled"
              onFilterClick={setStatusFilter}
              statusFilter={statusFilter}
              iconColor={Colors.black}
            />
            <FeatureFlagWrapper
              flagName={FF_EVENTS}
              renderOn={() => (
                <EventLegend
                  styles={{
                    backgroundColor: Colors.event.color,
                    color: Colors.event.contrastText,
                  }}
                  text="Event"
                  onFilterClick={setStatusFilter}
                  statusFilter={statusFilter}
                />
              )}
            />
            <EventLegend
              styles={{
                backgroundColor: '#FFFFFFF',
                color: '#000000',
                border: '2px solid #000000',
              }}
              text="Recurring"
              onFilterClick={setRecurringFilter}
              statusFilter={recurringFilter}
              iconColor={Colors.black}
            />
          </Box>
          <Box display="flex" flexDirection="row" gridColumnGap={8}>
            <Box bgcolor="background.paper">
              <TextFieldWrapper
                value={defaultSearchQuery}
                onChange={event => onSearchQueryChange(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => onSearchQueryChange('')}>
                        <HighlightOffIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search..."
                variant="outlined"
                name="query"
                size="small"
              />
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={calendarOptions.showTimeGrid}
                  onChange={handleGridSwitchClick}
                  name="events"
                  color="primary"
                />
              }
              label="Show Time Grid"
            />
          </Box>
          <Box my={2} width="100%" flexBasis="100%">
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
              <>
                <Grid item>
                  <FormControl variant="outlined" size="small" style={{ minWidth: 140 }}>
                    <InputLabel shrink>Food Donor</InputLabel>
                    <Select
                      label="Food Donor"
                      value={foodDonorFilter}
                      onChange={(event) => {
                        onFoodDonorFilterChange(event.target.value);
                      }}
                    >
                      <MenuItem value="All" data-name="all">
                        All
                      </MenuItem>
                      {uniqueFoodDonors.map((item) => (
                        <MenuItem key={item.location_id} value={item.location}>
                          {item.location}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item>
                  <Tooltip Tooltip title={foodDonorFilter === 'All' ? 'Please select a Food Donor first.' : ''}>
                    <FormControl variant="outlined" size="small" style={{ minWidth: 140 }}>
                      <InputLabel shrink>Pickup Location</InputLabel>
                      <Select
                        label="Pickup Location"
                        disabled={foodDonorFilter === 'All'}
                        value={pickupLocationFilter}
                        onChange={(event) => {
                          onPickupLocationFilterChange(event.target.value);
                        }}
                      >
                        <MenuItem value="All" data-name="all">
                          All
                        </MenuItem>
                        {uniquePickupLocations.map((item) => (
                          <MenuItem key={item.pickup_location_id} value={renderPickupLocationNameOrAdress(item)}>
                            {renderPickupLocationNameOrAdress(item)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Tooltip>
                </Grid>
              </>
              <DateAndGranularityPicker
                showGranularity={false}
                showCustomDateRange
                showAllDateRange
                defaultDateRange={locationState?.period || 'all'}
                customDateRangeValue={
                  locationState?.dateRange?.startDate && locationState?.dateRange?.endDate
                    ? locationState.dateRange
                    : null
                }
                onChange={(data) => onDateRangeChange(data)}
              />
            </Grid>
          </Box>
        </Box>
        <FullCalendar
          ref={ref}
          noEventsContent={() => (isFetching ? 'Fetching rescues...' : 'No rescues found')}
          height={window.innerHeight - TOP_PADDING - bottomPadding - (isMobileView ? 0 : BUTTONS_ROW)}
          headerToolbar={getHeaderProps(isMobileView, calendarOptions.showTimeGrid || false)}
          customButtons={getCustomButtons(calendarOptions.showTimeGrid || false)}
          buttonText={getButtonTexts(isMobileView)}
          allDaySlot={false}
          datesSet={({ start, end }) => fetchRescues(start, end)}
          eventClick={({ event }) => onEventClick(event)}
          initialView={calendarOptions.view || 'dayGridDay'}
          initialDate={calendarOptions.start ? moment(calendarOptions.start, 'YYYYMMDD').toDate() : new Date()}
          plugins={[dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          viewDidMount={({ view }) => handleViewChange(view)}
          validRange={
            calendarOptions.viewDateRange || {
              start: new Date(),
              end: new Date(),
            }
          }
          stickyHeaderDates={!isSafari}
          eventClassNames={() => (isMobileView ? ['fc-event-mobile'] : [])}
          dayHeaderContent={dayHeaderContentRender}
          eventContent={eventContentRender}
          eventDidMount={event => handleEventMount(event, isMobileView)}
          windowResize={handleWindowResize}
        />
      </OverlayLoader>
    );
  }
);

RescuesSchedule.propTypes = {
  isMobileView: PropTypes.bool,
  calendarOptions: PropTypes.object.isRequired,
};

RescuesSchedule.defaultProps = {
  isMobileView: false,
  calendarOptions: {},
};

export default RescuesSchedule;
