import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Dialog,
  Toolbar,
  Button,
  Grid,
  Paper,
  InputBase,
  Chip,
  Typography,
  List,
  Divider,
} from '@material-ui/core';
import moment from 'moment';
import FilterListIcon from '@material-ui/icons/FilterList';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RescuerScheduleDateFilter from './RescuerScheduleDateFilter';
import classNames from 'classnames';
import DistanceSliderFilter, { DISTANCE_ALL_VALUE, getDistanceLabel } from './filters/DistanceSliderFilter';
import DaysSelectorFilter from './filters/DaysSelectorFilter';
import DonorReceiverFilter from './filters/DonorReceiverFilter';
import SiteSelectorFilter from './filters/SiteSelectorFilter';
import { get } from 'lodash';
import RescueAvailabilityFilter from './filters/RescueAvailabilityFilter';
import RescueSizeFilter from './filters/RescueSizeFilter';
import DateSelectorFilter from './filters/DateSelectorFilter';
import CitySelectorFilter from "./filters/CitySelectorFilter";
import DonorLinkSelectorFilter from './filters/DonorLinkSelectorFilter';
import TimeSelectorFilter from './filters/TimeSelectorFilter';

class RescuerScheduleFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDialogOpen: false,
    };
  }

  renderDaysLabel = () => {
    const { rescuerScheduleFilters, classes } = this.props;

    if (rescuerScheduleFilters.days.value.length === 7) {
      return 'Days: all';
    }

    return (
      <Box alignItems="left" display="flex">
        <Box>{`Days: `}</Box>
        <Box>
          <ul className={classes.daysList}>
            {rescuerScheduleFilters.days.value.map((day, i, arr) => (
              <React.Fragment key={`${day}_wrapper`}>
                <li
                  className={classNames({
                    [classes.selectedDay]: moment(rescuerScheduleFilters.date.value).format('ddd') === day,
                  })}
                  key={day}
                >
                  {day}
                </li>
                {arr.length - 1 !== i && <li key={`${day}+delimiter`}>,</li>}
              </React.Fragment>
            ))}
          </ul>
        </Box>
      </Box>
    );
  };

  updateScheduleFilter = (key, value) => {
    const { setScheduleFilter } = this.props;

    return setScheduleFilter(key, { value: value });
  };

  closeModal = () => this.setState({ isDialogOpen: false });

  render() {
    const {
      classes,
      setScheduleFilter,
      rescuerScheduleFilters,
      rows,
      donorReceiverList,
      isMobileView,
      maxDate,
      sitesWithRescuerRoles,
      pickupCities,
      dropoffCities,
      hasActiveDonorLinkFF,
    } = this.props;
    const { isDialogOpen } = this.state;
    const currentDay = moment(rescuerScheduleFilters.date.value).toDate();
    const isNextDateDisabled = currentDay >= maxDate;

    return (
      <div className={classes.container}>
        <Grid>
          <Paper className={classes.searchWrapper}>
            <Paper className={classes.searchContainer}>
              {rescuerScheduleFilters.query.value.length === 0 && (
                <IconButton className={classes.searchIconButton} aria-label="clear search filter">
                  <SearchIcon />
                </IconButton>
              )}
              {rescuerScheduleFilters.query.value.length > 0 && (
                <IconButton
                  className={classes.searchIconButton}
                  aria-label="menu"
                  onClick={() => setScheduleFilter('query', { value: '' })}
                >
                  <HighlightOffIcon />
                </IconButton>
              )}
              <InputBase
                value={rescuerScheduleFilters.query.value}
                onChange={event => setScheduleFilter('query', { value: event.target.value })}
                className={classes.searchInput}
                inputProps={{ 'aria-label': 'filter results' }}
              />
            </Paper>
          </Paper>
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              data-introid="schedule-filters"
              variant="contained"
              color="primary"
              startIcon={<FilterListIcon />}
              onClick={() => this.setState({ isDialogOpen: true })}
            >
              Filters
            </Button>
          </Grid>

          <Grid item hidden={rescuerScheduleFilters.distance.value === DISTANCE_ALL_VALUE}>
            <Chip
              icon={
                <CancelIcon
                  className={classes.removeFilter}
                  onClick={() => setScheduleFilter('distance', { value: DISTANCE_ALL_VALUE })}
                />
              }
              className={classes.filter}
              label={getDistanceLabel(rescuerScheduleFilters.distance.value)}
              variant="outlined"
              color="default"
              style={{ height: 36 }}
            />
          </Grid>
          <Grid item hidden={rescuerScheduleFilters.rescueSizes.value.length === 0}>
            <Chip
              icon={
                <CancelIcon
                  className={classes.removeFilter}
                  onClick={() => setScheduleFilter('rescueSizes', { value: [] })}
                />
              }
              className={classes.filter}
              label={`Vehicle Size(s): ${rescuerScheduleFilters.rescueSizes.value.length}`}
              variant="outlined"
              color="default"
              style={{ height: 36 }}
            />
          </Grid>

          {rescuerScheduleFilters.days.value.length > 0 && (
            <Grid item>
              <Chip
                icon={
                  <CancelIcon
                    className={classes.removeFilter}
                    onClick={() => setScheduleFilter('days', { value: [] })}
                  />
                }
                className={classes.filter}
                label={this.renderDaysLabel()}
                variant="outlined"
                color="default"
                style={{ height: 36 }}
              />
            </Grid>
          )}
          <Grid item hidden={!rescuerScheduleFilters.date.value}>
            <Chip
              icon={
                <CancelIcon
                  className={classes.removeFilter}
                  onClick={() => setScheduleFilter('date', { value: null })}
                />
              }
              className={classes.filter}
              label={`Date: ${moment(rescuerScheduleFilters.date.value).format('DD/MM')}`}
              variant="outlined"
              color="default"
              style={{ height: 36 }}
            />
          </Grid>

          {rescuerScheduleFilters?.after?.value && (
            <Grid item>
              <Chip
                icon={
                  <CancelIcon
                    className={classes.removeFilter}
                    onClick={() => setScheduleFilter('after', { value: null })}
                  />
                }
                className={classes.filter}
                label={`Pickup after: ${moment(rescuerScheduleFilters.after.value, 'HH:mm:ss').format('hh:mm a')}`}
                variant="outlined"
                color="default"
                style={{ height: 36 }}
              />
            </Grid>
          )}

          {rescuerScheduleFilters?.before?.value && (
            <Grid item>
              <Chip
                icon={
                  <CancelIcon
                    className={classes.removeFilter}
                    onClick={() => setScheduleFilter('before', { value: null })}
                  />
                }
                className={classes.filter}
                label={`Pickup before: ${moment(rescuerScheduleFilters.before.value, 'HH:mm:ss').format('hh:mm a')}`}
                variant="outlined"
                color="default"
                style={{ height: 36 }}
              />
            </Grid>
          )}

          <Grid item hidden={!rescuerScheduleFilters?.donorLink?.value}>
            <Chip
              icon={
                <CancelIcon
                  className={classes.removeFilter}
                  onClick={() => setScheduleFilter('donorLink', { value: null })}
                />
              }
              className={classes.filter}
              label={`Donor Link: ${rescuerScheduleFilters?.donorLink?.value}`}
              variant="outlined"
              color="default"
              style={{ height: 36 }}
            />
          </Grid>
        </Grid>

        {isMobileView && (<RescuerScheduleDateFilter disableNextDate={isNextDateDisabled} />)}

        <Dialog fullScreen={isMobileView} fullWidth maxWidth="sm" open={isDialogOpen} onClose={this.closeModal}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                {`Filters (${rows.length} results)`}
              </Typography>
              <Button autoFocus color="inherit" onClick={this.closeModal}>
                save
              </Button>
            </Toolbar>
          </AppBar>

          <List>
            <DistanceSliderFilter
              value={rescuerScheduleFilters.distance.value}
              handleSliderChange={value => this.updateScheduleFilter('distance', value)}
            />
            <Divider />

            <CitySelectorFilter
              label="Pickup City"
              cities={pickupCities}
              value={get(rescuerScheduleFilters, 'pickupCities.value', [])}
              handleChange={value => this.updateScheduleFilter('pickupCities', value)}
            />

            <Divider />

            <CitySelectorFilter
              label="Dropoff City"
              cities={dropoffCities}
              value={get(rescuerScheduleFilters, 'dropoffCities.value', [])}
              handleChange={value => this.updateScheduleFilter('dropoffCities', value)}
            />

            <Divider />

            <DaysSelectorFilter
              disableNextDat={isNextDateDisabled}
              value={rescuerScheduleFilters.days.value}
              handleDaysChange={value => this.updateScheduleFilter('days', value)}
            />
            <Divider />
            <DonorReceiverFilter
              options={donorReceiverList}
              value={rescuerScheduleFilters.donorReceiverList.value}
              handleDonorReceiverChange={value => this.updateScheduleFilter('donorReceiverList', value)}
            />
            <Divider />
            <RescueSizeFilter
              value={rescuerScheduleFilters.rescueSizes.value}
              handleSizesChange={value => this.updateScheduleFilter('rescueSizes', value)}
            />
            <Divider />
            {sitesWithRescuerRoles.length > 1 && (
              <>
                <SiteSelectorFilter
                  sitesWithRescuerRoles={sitesWithRescuerRoles}
                  value={get(rescuerScheduleFilters, 'siteIds.value', [])}
                  handleSiteIdsChange={value => this.updateScheduleFilter('siteIds', value)}
                />
                <Divider />
              </>
            )}
            <DateSelectorFilter
              disableNextDate={isNextDateDisabled}
              value={rescuerScheduleFilters.date.value}
              handleDateChange={(value) => this.updateScheduleFilter('date', value)}
            />
            <Divider />
            <TimeSelectorFilter
              afterValue={get(rescuerScheduleFilters, 'after.value', false)}
              beforeValue={get(rescuerScheduleFilters, 'before.value', false)}
              handleAfterChange={(value) => this.updateScheduleFilter('after', value)}
              handleBeforeChange={(value) => this.updateScheduleFilter('before', value)}
            />
            <Divider />
            {hasActiveDonorLinkFF && (
              <>
                <DonorLinkSelectorFilter
                  value={get(rescuerScheduleFilters, 'donorLink.value', false)}
                  handleFilterChange={value => this.updateScheduleFilter('donorLink', value)}
                />
                <Divider />
              </>
            )}
            <RescueAvailabilityFilter
              value={get(rescuerScheduleFilters, 'showOnlyAvailableRescues.value', false)}
              handleAvailabilityChange={value => this.updateScheduleFilter('showOnlyAvailableRescues', value)}
            />
          </List>
        </Dialog>
      </div>
    );
  }
}

const styles = theme => ({
  searchWrapper: {
    marginBottom: 15,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    padding: '10px 10px 10px 10px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  searchContainer: {
    backgroundColor: '#fff',
    display: 'flex',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flex: 1,
  },
  searchIconButton: {
    padding: 10,
  },
  daysList: {
    float: 'left',
    listStyle: 'none',
    paddingLeft: 5,
    padding: 0,
    margin: 0,
    '& li': {
      display: 'inline-block',
    },
  },
  selectedDay: {
    color: theme.palette.primary.main,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  removeFilter: {
    cursor: 'pointer',
    color: theme.palette.grey[400],
  },
  filter: {
    textAlign: 'left',
    padding: '6px 16px',
    fontSize: '0.875rem',
    fontWeight: 500,
    userSelect: 'none',
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.45)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    lineHeight: 1.75,
    fontFamily: 'Roboto, Helvetica, Arial',
    borderRadius: 4,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },
});

export default withStyles(styles)(RescuerScheduleFilters);
