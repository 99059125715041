import React, { useState } from 'react';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Clear as ClearIcon } from '@material-ui/icons';
import { Grid, IconButton, ListItem, ListItemText, makeStyles, Tooltip } from '@material-ui/core';
import moment from 'moment';
import FilterComponent from './FilterComponent';
import MinMaxTimePicker from '../../MinMaxTimePicker';

const useStyles = makeStyles(() => ({
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
}));

const TimeSelectorFilter = ({ afterValue, beforeValue, handleAfterChange, handleBeforeChange }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [after, setAfter] = useState(afterValue || null);
  const [before, setBefore] = useState(beforeValue || null);

  const getLabel = (selectedAfter, selectedBefore) => {
    if (!selectedAfter && !selectedBefore) {
      return 'Pickup Window: all';
    }

    if (selectedAfter && !selectedBefore) {
      return `Pickup Window: after ${moment(afterValue, 'HH:mm:ss').format('hh:mma')}`;
    }

    if (!selectedAfter && selectedBefore) {
      return `Pickup Window: before ${moment(beforeValue, 'HH:mm:ss').format('hh.mma')}`;
    }

    return `Pickup Window: from ${moment(afterValue, 'HH:mm:ss').format('hh:mma')} to ${moment(beforeValue, 'HH:mm:ss').format(
      'hh:mma'
    )}`;
  };

  const handleAfterTimeChange = ({ event, isValid }) => {
    if (isValid) {
      setAfter(event.format('HH:mm:00'));
    }
  };

  const handleAfterTimeUpdate = () => {
    handleAfterChange(after);
  };

  const clearAfter = () => {
    setAfter(null);
    handleAfterChange(null);
  };

  const handleBeforeTimeChange = ({ event, isValid }) => {
    if (isValid) {
      setBefore(event.format('HH:mm:00'));
    }
  };

  const handleBeforeTimeUpdate = () => {
    handleBeforeChange(before);
  };

  const clearBefore = () => {
    setBefore(null);
    handleBeforeChange(null);
  };

  return (
    <>
      <ListItem button onClick={() => setIsOpen(!isOpen)}>
        <ListItemText primary={getLabel(afterValue, beforeValue)} />
        {isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </ListItem>
      <FilterComponent isOpen={isOpen}>
        <Grid spacing={2} container direction="row" justify="center" alignItems="center">
          <Grid item className={classes.gridItem}>
            <MinMaxTimePicker
              fullWidth
              inputVariant="outlined"
              size="small"
              label="After"
              variant="inline"
              value={after ? moment(after, 'HH:mm:ss') : null}
              maxTime={before ? moment(before, 'HH:mm:ss') : moment().set({ hour: '23', minute: '55', second: '00' })}
              onTimeChange={handleAfterTimeChange}
              onClose={handleAfterTimeUpdate}
            />
            <IconButton size="small" onClick={clearAfter}>
              <ClearIcon />
            </IconButton>
          </Grid>
          <Grid item className={classes.gridItem}>
            <MinMaxTimePicker
              fullWidth
              inputVariant="outlined"
              size="small"
              label="Before"
              variant="inline"
              value={before ? moment(before, 'HH:mm:ss') : null}
              minTime={after ? moment(after, 'HH:mm:ss') : moment().set({ hour: '00', minute: '05', second: '00' })}
              onTimeChange={handleBeforeTimeChange}
              onClose={handleBeforeTimeUpdate}
              showFieldErrors
            />
            <IconButton size="small" onClick={clearBefore}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      </FilterComponent>
    </>
  );
};

export default TimeSelectorFilter;
