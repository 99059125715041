import { daysList } from '../helpers/RescuesHelper';

export const getFullDayByDayIndex = dayIndex =>
  ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].find(
    (dayName, index) => index === parseInt(dayIndex, 10)
  );

export const getDayIndexByShortName = shortDayName =>
  daysList.findIndex(day => day.toLowerCase() === shortDayName.toLowerCase());

export const dayname = daynum => {
  switch (daynum) {
    case 0:
      return 'Sun';
    case 1:
      return 'Mon';
    case 2:
      return 'Tue';
    case 3:
      return 'Wed';
    case 4:
      return 'Thu';
    case 5:
      return 'Fri';
    case 6:
      return 'Sat';
    default:
      break;
  }
  return '---';
};

export const ordinalDomToString = (dow, ordinal) => {
  switch (ordinal) {
    case 1:
      return `1st ${dayname(dow)}`;
    case 2:
      return `2nd ${dayname(dow)}`;
    case 3:
      return `3rd ${dayname(dow)}`;
    case 4:
      return `4th ${dayname(dow)}`;
    case -1:
      return `last ${dayname(dow)}`;
    default:
      break;
  }

  return '??? ' + dayname(dow);
};

export const mapFormToQuickCreateOneTimeDonationPOST = donation => {
  return {
    location_id: parseInt(donation.food_donor_id, 10),
    pickup_location_id: parseInt(donation.pickup_location_id, 10),
    slug: donation.description,
    food_size: null,
    positions: 1,
    food_types: donation.food_types,
    food_type_other: donation.food_type_other,
    rescue_size_id: parseInt(donation.rescue_size_id, 10),
    donation_size_id: parseInt(donation.donation_size_id, 10),
    lbs: donation.lbs ? +donation.lbs : null,
    schedule_spec: {
      frequency: 0,
      start_date: donation.start_date,
      days_of_week: {
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
      },
      days_of_month: {
        fri: [],
        mon: [],
        sat: [],
        sun: [],
        thu: [],
        tue: [],
        wed: [],
      },
    },
    pickup_specs: [
      {
        day: '',
        position: 1,
        pickup_name: 'position',
        pickup_notes: '<note>',
        pickup_begin: donation.pickup_begin,
        pickup_end: donation.pickup_end,
        receiver_id: donation.receiver_id ? parseInt(donation.receiver_id, 10) : null,
        rescuer_id: donation.rescuer_id ? parseInt(donation.rescuer_id, 10) : null,
        rescuer_notes: donation.notes,
      },
    ],
  };
};

export const mapFormDonationsPATCH = (donations = []) =>
  donations.map((donation) => {
    const mappedDonation = {
      donation_id: donation.id,
      lbs: donation?.lbs,
    };

    if (donation.pause_date !== undefined) {
      mappedDonation.pause_date = donation.pause_date;
    }

    if (donation.resume_date !== undefined) {
      mappedDonation.resume_date = donation.resume_date;
    }

    return mappedDonation;
  });
