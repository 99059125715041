import React, { useEffect, useRef, useState, Fragment, useContext } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import GoogleMap from 'google-map-react';
import { get, uniqBy } from 'lodash';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent as MuiDialogContent,
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Divider,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import useSupercluster from 'use-supercluster';
import moment from 'moment';
import * as siteActions from '../../actions/sites';
import donorImage from '../../assets/images/donor.png';
import receiverImage from '../../assets/images/receiver.png';
import rescuerImage from '../../assets/images/rescuer.png';
import unassignedUserImage from '../../assets/images/unassigned-user.png';
import { getUserFullName } from '../../helpers/user';
import * as authService from '../../services/auth';
import { Roles } from '../../models/roles';
import useActiveUser from '../../hooks/useActiveUser';
import MarkersSelector, {
  MARKER_DONOR,
  MARKER_RECEIVER,
  MARKER_RESCUER,
  MARKER_UNASSIGNED_USER,
} from './Components/MarkersSelector';
import {
  renderLocationInfoWindow,
  renderRescuerInfoWindow,
  renderSiteZipCode,
  renderUnassignedUserTooltip,
} from './MapInfoWindows';
import { MapMenuPanelContainer } from './Components/MapMenuPanel';
import MapSiteSelector from './Components/MapSiteSelector';
import { fetchSystemSettings } from '../../actions/systemSettings';
import { formatAddress, formatPhoneNumber } from '../../helpers/formatters';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { getChromaColor, getColorByIndex } from '../../helpers/colors';
import { getMap, getMapUnassignedUsers } from '../../api/map';
import { getRescuePickupLocationFullName } from '../../helpers/RescuesHelper';
import HtmlTooltip from '../../components/Common/HtmlTooltip';
import { convertMilesToMeters } from '../../helpers/math';
import NotificationsContext from '../../context/NotificationsContext/NotificationsContext';
import useNotificationService from '../../hooks/useNotificationService';
import MapDrivingDirections from './Components/MapDrivingDirections';
import ReceiverNeedsSelector from './Components/ReceiverNeedsSelector';
import { newFoodTypes } from '../../models/foodTypes';
import { fetchFoodDescriptionsIfNeeded } from '../../actions/foodDescriptions';
import UtilityButtons from './Components/UtilityButtons';

const SITES_ACCORDION = 'sites';
const USERS_ACCORDION = 'users';
const AGENCY_DETAILS_ACCORDION = "agency_details";

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
    maxHeight: '50vh',
  },
}))(MuiDialogContent);

const Table = withStyles(({ spacing }) => ({
  root: {
    padding: spacing(2),
  },
}))(MuiTable);

const defaultProps = {
  center: {
    lat: 39.0915821,
    lng: -94.8565884,
  },
  defaultZoom: 5,
  minZoom: 5,
  maxZoom: 18,
  greatPlaceCoords: { lat: 59.724465, lng: 30.080121 },
};

const MAX_UNASSIGNED_USERS_TOOLTIP_ROWS = 2;

let markers = [];
let siteAreasPolygons = [];
let siteAreasCircles = [];
let heatmapArray;
let heatmapLayer;

const Marker = ({ children }) => children;

const Map = () => {
  const mapApiRef = useRef();
  const dispatch = useDispatch();
  const systemSettings = useSelector(state => state.entities.systemSettings);
  const sites = useSelector(state => state.entities.sites);
  const foodDescriptionsEntities = useSelector(state => state.entities.foodDescriptions);
  const foodDescriptions = Object.values(foodDescriptionsEntities.byId);
  const categories = foodDescriptions.filter((fd) => newFoodTypes.some((ft) => ft.label === fd.name));
  const activeUser = useActiveUser();
  const { addErrorNotification } = useNotificationService();

  const [dialogList, setDialogList] = useState([]);
  const [mapApi, setMapApi] = useState();
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [unassignedUsersMarkers, setUnassignedUsersMarkers] = useState([]);
  const [visibleUnassignedUsersMarkers, setVisibleUnassignedUsersMarkers] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(defaultProps.defaultZoom);
  const [isSiteAreaVisible, setSiteAreaVisibility] = useState(false);
  const [isSiteAreaStrokeVisible, setSiteAreaStrokeVisibility] = useState(false);
  const [visiblePins, setVisiblePins] = useState([MARKER_DONOR, MARKER_RECEIVER]);
  const [routeValue, setRouteValue] = useState({ origin: {}, destination: {}, directions: {} });
  const [showDrivingDirections, setShowDrivingDirections] = useState(true);
  const [dateRange, setDateRange] = useState({});
  const [openAccordion, setOpenAccordion] = useState(SITES_ACCORDION);
  const [selectedReceiverDays, setSelectedReceiverDays] = useState([]);
  const [receiverBeforeHours, setReceiverBeforeHours] = useState(null);
  const [receiverAfterHours, setReceiverAfterHours] = useState(null);
  const [receiverNeeds, setReceiverNeeds] = useState([]);
  const [filterMarkers, setFilterMarkers] = useState(false);
  const [visibleMarkers, setVisibleMarkers] = useState([]);
  const [heatmapActive, setHeatmapActive] = useState(false);
  const routeRef = useRef(routeValue);
  const directionRef = useRef(null);
  const sitesWithSDSCAccess = authService.getUserSitesByRoles(activeUser.role_assignments, [
    Roles.SiteDirector,
    Roles.SiteCoordinator,
  ]);
  const notificationsContext = useContext(NotificationsContext);
  const receiverNeedsBadgeCounter =
    (selectedReceiverDays.length ? 1 : 0) +
    (receiverBeforeHours ? 1 : 0) +
    (receiverAfterHours ? 1 : 0) +
    (receiverNeeds.length ? 1 : 0);

  useEffect(() => {
    routeRef.current = routeValue;
  }, [routeValue]);

  // Set and update heatmapArray
  useEffect(() => {
    if (!mapApi) {
      return;
    }

    const { maps } = mapApi;
    const visibleMarkersPositions = visibleMarkers.map((marker) => (new maps.LatLng(marker.meta.lat, marker.meta.long)));

    if (typeof heatmapArray !== 'object') {
      heatmapArray = new maps.MVCArray(visibleMarkersPositions);
      return;
    }

    // Clear heatmap data and set new one each time visibleMarkers change
    heatmapArray.clear();
    visibleMarkersPositions.forEach((marker) => {
      heatmapArray.push(marker);
    });
  }, [visibleMarkers]);

  useEffect(() => {
    if (notificationsContext.notifications.length > 0) {
      notificationsContext.notifications.forEach(notification =>
        notificationsContext.removeNotification(notification.id)
      );
    }
  }, []);

  const { clusters, supercluster } = useSupercluster({
    points: visibleUnassignedUsersMarkers,
    bounds,
    zoom,
    options: { radius: 100, maxZoom: defaultProps.maxZoom, minZoom: defaultProps.minZoom },
  });

  const setMapCircleMouseOverEvent = (siteAreaZipCircle, site, zipCode) => {
    const { maps, map } = mapApi;
    const div = document.createElement('div');

    const infoWindow = new maps.InfoWindow({
      content: null,
    });

    siteAreaZipCircle.addListener('mouseover', event => {
      ReactDOM.render(renderSiteZipCode({ site, zipCode }), div);
      infoWindow.setContent(div);
      infoWindow.setPosition(event.latLng);
      infoWindow.open(map);

      siteAreaZipCircle.setOptions({
        fillOpacity: 0.3,
      });
    });

    siteAreaZipCircle.addListener('mouseout', () => {
      infoWindow.close();
      siteAreaZipCircle.setOptions({
        fillOpacity: 0,
      });
    });
  };

  const createMapCircle = (site, zipCode) => {
    const { maps } = mapApi;
    const siteColor = get(site, 'color', '#000');
    const siteAreaZipCircle = new maps.Circle({
      strokeColor: getChromaColor(siteColor).color,
      strokeOpacity: 0.5,
      strokeWeight: 3,
      fillColor: getChromaColor(siteColor).color,
      fillOpacity: 0,
      zIndex: 99, // keep zIndex grater than zIndex from Polygon
      center: {
        lat: zipCode.lat,
        lng: zipCode.long,
      },
      radius: convertMilesToMeters(siteRadiusArea),
    });

    setMapCircleMouseOverEvent(siteAreaZipCircle, site, zipCode);

    return siteAreaZipCircle;
  };

  const drawCircle = (point, radius) => {
    const { maps } = mapApi;

    const d2r = Math.PI / 180; // degrees to radians
    const r2d = 180 / Math.PI; // radians to degrees
    const earthRadius = 6378137.0; // radius of the earth in meters
    const polygonPoints = 32; // number of points in the polygon circle

    point = new maps.LatLng(point.lat, point.lng);

    // find the radius in lat/lon
    const rlat = (radius / earthRadius) * r2d;
    const rlng = rlat / Math.cos(point.lat() * d2r);

    const circlePoints = [];
    let totalPolygonPoints = polygonPoints + 1;

    for (let i = 0; i < totalPolygonPoints; i++) {
      const theta = Math.PI * (i / (polygonPoints / 2));
      const ey = point.lng() + rlng * Math.cos(theta); // center a + radius x * cos(theta)
      const ex = point.lat() + rlat * Math.sin(theta); // center b + radius y * sin(theta)

      circlePoints.push(new maps.LatLng(ex, ey));
    }

    return circlePoints;
  };

  const isAdmin = authService.hasAnyRoleInCurrentlySelectedSite(activeUser, [Roles.Admin, Roles.NationalSiteDirector]);
  const sitesList = isAdmin
    ? Object.values(sites.byId)
    : Object.values(sites.byId).filter(site => sitesWithSDSCAccess.includes(site.id));
  const siteRadiusArea = Object.values(systemSettings.byId).reduce(
    (acc, curr) => (curr.name === 'attach_site_radius_in_miles' ? parseInt(curr.value, 10) : acc),
    15
  );

  useEffect(() => {
    if (!mapApi) {
      return;
    }
    removeAllMarkers();

    const hasSelectedAnySite = selectedSites.length > 0;
    const sitesToDisplay = hasSelectedAnySite ? selectedSites : sitesList;
    const reducedSelectedSites = sitesToDisplay.reduce(
      (acc, curr, idx) => {
        // if user defined sites, get only them, otherwise get all sites
        if (hasSelectedAnySite || !isAdmin) {
          acc.allIds.push(curr.id);
        }

        if (!curr.hasOwnProperty('color')) {
          curr = {
            ...curr,
            color: getColorByIndex(idx),
          };
        }

        acc.bySiteId[curr.id] = curr;

        return acc;
      },
      {
        allIds: [],
        bySiteId: {},
      }
    );

    getMap(reducedSelectedSites.allIds)
      .then(res => res.json())
      .then(result => result.data)
      .then(sitesData => {
        for (const siteData of sitesData) {
          for (const foodDonorData of siteData.food_donors) {
            // Filter food donors by selected date range
            const foodDonorDataFiltered = dateRange.period !== 'all' ? foodDonorData.pickup_locations.filter((donor) =>
              moment(donor.created_at).isBetween(
                dateRange.startDate,
                dateRange.endDate,
                undefined,
                []
              )
            )
            : foodDonorData.pickup_locations;

            for (const donorPickupLocation of foodDonorDataFiltered) {
              if (donorPickupLocation.lat && donorPickupLocation.long) {
                const pickupLocationFullName = getRescuePickupLocationFullName({
                  pickup_location_name: donorPickupLocation.name,
                  location: foodDonorData.name,
                });

                addMarker({
                  id: `donor_pl_${donorPickupLocation.id}`,
                  lat: donorPickupLocation.lat,
                  lng: donorPickupLocation.long,
                  _markerType: MARKER_DONOR,
                  title: pickupLocationFullName,
                  imageUrl: donorImage,
                  site_id: siteData.id,
                  site_name: siteData.name,
                  donor_id: foodDonorData.id,
                  donor_name: foodDonorData.name,
                  ...donorPickupLocation,
                });
              }
            }
          }

          for (const receiverData of siteData.receivers) {
            // Filter receivers by selected date range
            const freceiverDataFiltered = dateRange.period !== 'all' ? receiverData.receiver_locations.filter((receiver) =>
              moment(receiver.created_at).isBetween(
                dateRange.startDate,
                dateRange.endDate,
                undefined,
                []
              )
            )
            : receiverData.receiver_locations;

            for (const receiverDataLocation of freceiverDataFiltered) {
              if (receiverDataLocation.lat && receiverDataLocation.long) {
                addMarker({
                  id: `receiver_pl_${receiverDataLocation.id}`,
                  lat: receiverDataLocation.lat,
                  lng: receiverDataLocation.long,
                  _markerType: MARKER_RECEIVER,
                  imageUrl: receiverImage,
                  site_id: siteData.id,
                  site_name: siteData.name,
                  receiver_id: receiverData.id,
                  ...receiverDataLocation,
                });
              }
            }
          }
          // Filter rescuers by selected date range
          const siteRescuersFiltered = dateRange.period !== 'all' ? siteData.rescuers.filter((rescuer) =>
                  moment(rescuer.completed_registration).isBetween(
                    dateRange.startDate,
                    dateRange.endDate,
                    undefined,
                    []
                  )
          )
          : siteData.rescuers

          for (const rescuerData of siteRescuersFiltered) {
            for (const rescuerDataLocation of rescuerData.rescuer_locations) {
              if (rescuerDataLocation.lat && rescuerDataLocation.long) {
                addMarker({
                  id: `rescuer_location_${rescuerDataLocation.id}`,
                  lat: rescuerDataLocation.lat,
                  lng: rescuerDataLocation.long,
                  _markerType: MARKER_RESCUER,
                  firstname: rescuerData.firstname,
                  lastname: rescuerData.lastname,
                  completed_registration: rescuerData.completed_registration,
                  phone: rescuerData.phone,
                  email: rescuerData.email,
                  imageUrl: rescuerImage,
                  sites: rescuerData.sites.map(siteRow => reducedSelectedSites.bySiteId[siteRow.id]),
                  ...rescuerDataLocation,
                });
              }
            }
          }

          fitMapBounds();

          const { maps } = mapApi;
          const selectedSiteData = reducedSelectedSites.bySiteId[siteData.id];
          if (selectedSiteData) {
            let paths = [];
            for (const zipCode of siteData.zip_codes) {
              const drawedCircle = drawCircle(
                {
                  lat: zipCode.lat,
                  lng: zipCode.long,
                },
                convertMilesToMeters(siteRadiusArea)
              );

              paths.push(drawedCircle);

              siteAreasCircles.push(createMapCircle(selectedSiteData, zipCode));
            }

            if (paths.length > 0) {
              const siteAreaPolygon = new maps.Polygon({
                strokeColor: selectedSiteData.color,
                strokeOpacity: 0.5,
                strokeWeight: 0,
                fillColor: selectedSiteData.color,
                fillOpacity: 0.5,
                paths: paths,
                zIndex: 50,
                cursor: 'hand',
              });

              siteAreasPolygons.push(siteAreaPolygon);

              toggleSiteAreaVisibility(isSiteAreaVisible);
            }
          }
        }
      })
      .then(() => setFilterMarkers(!filterMarkers));
  }, [mapApi, activeUser.id, selectedSites.length, dateRange]);

  useEffect(() => {
    dispatch(siteActions.fetchSitesIfNeeded(false));
    dispatch(fetchSystemSettings());
    dispatch(fetchFoodDescriptionsIfNeeded());
  }, [dispatch]);

  useEffect(() => {
    if (!mapApi) {
      return;
    }

    // Get Unassigned Markers
    let unassignedUsers = [];
    const tempRows = [];

    if (visiblePins.includes(MARKER_UNASSIGNED_USER) && !unassignedUsersMarkers.length) {
      getMapUnassignedUsers()
        .then(res => res.json())
        .then(result => result.data)
        .then(users => {
          // Filter unassigned users by selected date range
          const filteredUsers =
            dateRange.period !== 'all'
              ? users.filter((user) =>
                  moment(user.completed_registration).isBetween(dateRange.startDate, dateRange.endDate, undefined, [])
                )
              : users;

          filteredUsers.forEach(user => {
            tempRows.push({
              type: MARKER_UNASSIGNED_USER,
              properties: {
                cluster: false,
                id: user.id,
                meta: { ...user, _markerType: MARKER_UNASSIGNED_USER },
              },
              geometry: {
                type: 'Point',
                coordinates: [user.long, user.lat],
              },
            });
          });
        })
        .then(() => {
          setUnassignedUsersMarkers(tempRows);
        });
    }

    // Filter unnasigned markers
    const selectedUnassignedUser = (unassignedUsersMarkers.length ? unassignedUsersMarkers : tempRows).find((marker) => {
      const markerSelected = selectedUsers.find((user) => user.meta.id === marker.properties.meta.id);

        return markerSelected;
      }
    );

    if (selectedUnassignedUser) {
      unassignedUsers = [selectedUnassignedUser];
    } else {
      unassignedUsers = unassignedUsersMarkers.length ? unassignedUsersMarkers : tempRows;
    }

    if (dateRange.period !== 'all') {
      unassignedUsers = unassignedUsers.filter((user) =>
        moment(user.properties.meta.completed_registration).isBetween(dateRange.startDate, dateRange.endDate, undefined, [])
      );
    }

    if (!visiblePins.includes(MARKER_UNASSIGNED_USER)) {
      unassignedUsers = [];
    }

    setVisibleUnassignedUsersMarkers(unassignedUsers);

    // Remove all markers
    markers.forEach((marker) => marker.setMap(null));

    const showMarkers = markers
      // Receiver Selected Days
      .filter((marker) => {
        const markerIsReceiver = marker.meta._markerType === MARKER_RECEIVER;
        if (selectedReceiverDays.length && markerIsReceiver) {
          const daysFound = selectedReceiverDays.every((day) =>
            (marker.meta?.weekly_receiving_hours || []).find((wrh) => wrh.day_of_week.toString() === day)
          );

          return daysFound;
        }

        return true;
      })

      // Receiver After Hours
      .filter((marker) => {
        const markerIsReceiver = marker.meta._markerType === MARKER_RECEIVER;
        if (receiverAfterHours && markerIsReceiver) {
          if (selectedReceiverDays.length) {
            const selectedDaysHoursFound = selectedReceiverDays.every((day) =>
              (marker.meta?.weekly_receiving_hours || []).filter((wrh) => wrh.day_of_week.toString() === day)
                .find(
                  (wrh) =>
                    moment(wrh.start, 'HH:mm:ss').isSameOrAfter(moment(receiverAfterHours, 'HH:mm:ss')) ||
                    moment(receiverAfterHours, 'HH:mm:ss').isBefore(moment(wrh.end, 'HH:mm:ss'))
                )
            );

            return selectedDaysHoursFound;
          }

          const allDaysHoursFound = marker.meta?.weekly_receiving_hours?.length ? marker.meta?.weekly_receiving_hours.every((wrh) =>
                  moment(wrh.start, 'HH:mm:ss').isSameOrAfter(moment(receiverAfterHours, 'HH:mm:ss')) ||
                  moment(receiverAfterHours, 'HH:mm:ss').isBefore(moment(wrh.end, 'HH:mm:ss'))
              )
            : false;

          return allDaysHoursFound;
        }
        return true;
      })

      // Receiver Before Hours
      .filter((marker) => {
        const markerIsReceiver = marker.meta._markerType === MARKER_RECEIVER;
        if (receiverBeforeHours && markerIsReceiver) {
          if (selectedReceiverDays.length) {
            const selectedDaysHoursFound = selectedReceiverDays.every((day) =>
              (marker.meta?.weekly_receiving_hours || []).filter((wrh) => wrh.day_of_week.toString() === day)
                .find((wrh) =>
                    moment(moment(wrh.end, 'HH:mm:ss'), 'HH:mm:ss').isBefore(moment(receiverBeforeHours, 'HH:mm:ss')) ||
                    moment(moment(wrh.start, 'HH:mm:ss'), 'HH:mm:ss').isBefore(moment(receiverBeforeHours, 'HH:mm:ss'))
                )
            );

            return selectedDaysHoursFound;
          }

          // Check if marker has weekly_receiving_hours or else return false, to prevent every() from returning true for empty arrays
          const allDaysHoursFound = marker.meta?.weekly_receiving_hours?.length ? marker.meta?.weekly_receiving_hours.every((wrh) =>
                  moment(moment(wrh.start, 'HH:mm:ss'), 'HH:mm:ss').isBefore(moment(receiverBeforeHours, 'HH:mm:ss')) &&
                  moment(moment(wrh.end, 'HH:mm:ss'), 'HH:mm:ss').isSameOrAfter(moment(receiverBeforeHours, 'HH:mm:ss'))
              )
            : false;

          return allDaysHoursFound;
        }
        return true;
      })

      // Receiver Needs
      .filter((marker) => {
        const markerIsReceiver = marker.meta._markerType === MARKER_RECEIVER;
        if (receiverNeeds.length && markerIsReceiver) {
          // Check if marker has receiver_needs or else return false to prevent every() from returning true for empty arrays
          if (marker.meta?.receiver_needs?.length) {
            const receiverNeedFound = receiverNeeds.every((need) =>
              (marker.meta?.receiver_needs || []).find((n) => n.food_description_id === need.id && n.rating >= need.rating)
            );
            return receiverNeedFound;
          }

          return false;
        }

        return true;
      })
      // Hide markers when FD is selected as driving origin
      .filter((marker) => {
        if (
          marker.meta._markerType === MARKER_DONOR &&
          routeValue.origin._markerType === MARKER_DONOR &&
          marker.meta.id !== routeValue.origin.id
        ) {
          return false;
        }
        return true;
      })
      // Hide markers from the same type as selected user
      .filter(marker => {
        const markerSelected = selectedUsers.find((user) => user.meta._markerType === marker.meta._markerType);
        if (markerSelected && markerSelected.meta.id !== marker.meta.id) {
          return false;
        }

        return true;
      });

    // Add filtered markers back if correct pins are toggled
    const markersToShow = showMarkers.filter((marker) => visiblePins.includes(marker.meta._markerType));

    // Add markers to map when the heatmap isn't toggled
    if (!heatmapActive) {
      markersToShow.forEach((marker) => marker.setMap(mapApi?.map));
    }
    setVisibleMarkers([...markersToShow, ...unassignedUsers.map((user) => user.properties)]);
  }, [
    selectedReceiverDays,
    receiverAfterHours,
    receiverBeforeHours,
    receiverNeeds,
    visiblePins,
    selectedUsers,
    dateRange,
    filterMarkers,
    routeValue,
  ]);

  const hideMarkers = type => {
    markers.forEach(marker => {
      if (marker.meta._markerType === type) {
        marker.setMap(null);
      }
    });
  };

  const handleSiteChange = sites => {
    if (sites === null) {
      return setSelectedSites([]);
    }

    // we need to append color here to present it in site selector as well
    return setSelectedSites(
      (sites || []).map((site, idx) => ({
        ...site,
        color: getColorByIndex(idx),
      }))
    );
  };

  const getColorByUser = (user) => {
    if (user.meta._markerType === MARKER_DONOR) {
      return '#339988';
    }

    if (user.meta._markerType === MARKER_RECEIVER) {
      return '#EB9A29';
    }

    if (user.meta._markerType === MARKER_RESCUER) {
      return '#EF4B28';
    }

    if (user.meta._markerType === MARKER_UNASSIGNED_USER) {
      return '#7428EF';
    }

    return '#003b4c';
  };

  const removeAllMarkers = () => {
    markers.forEach(marker => marker.setMap(null));
    siteAreasPolygons.forEach(siteArea => siteArea.setMap(null));
    siteAreasCircles.forEach(siteArea => siteArea.setMap(null));

    markers = [];
    siteAreasPolygons = [];
    siteAreasCircles = [];
  };

  const showMarkers = type => {
    markers.forEach(marker => {
      if (marker.meta._markerType === type) {
        marker.setMap(mapApi.map);
      }
    });
  };

  const removeVisibleMarkers = () => {
    visibleMarkers.forEach(marker => {
      if (marker.meta._markerType !== MARKER_UNASSIGNED_USER) {
        marker.setMap(null);
      }
    });
  };

  const showVisibleMarkers = () => {
    visibleMarkers.forEach(marker => {
      if (marker.meta._markerType !== MARKER_UNASSIGNED_USER) {
        marker.setMap(mapApi.map);
      }
    });
  };

  const toggleSiteAreaVisibility = isVisible => {
    if (isVisible) {
      siteAreasPolygons.forEach(siteArea => siteArea.setMap(mapApi.map));
      toggleSiteAreaZipCodesStroke(isSiteAreaStrokeVisible);
      return;
    }

    siteAreasPolygons.forEach(siteArea => siteArea.setMap(null));
    toggleSiteAreaZipCodesStroke(false);
  };

  const toggleSiteAreaZipCodesStroke = isVisible => {
    siteAreasCircles.forEach(siteArea => siteArea.setMap(isVisible ? mapApi.map : null));
  };

  useEffect(() => {
    toggleSiteAreaVisibility(isSiteAreaVisible);
  }, [isSiteAreaVisible]);

  useEffect(() => {
    toggleSiteAreaZipCodesStroke(isSiteAreaStrokeVisible);
  }, [isSiteAreaStrokeVisible]);

  const renderRoute = (values) => {
    const { map, maps } = mapApi;
    const directionsService = new maps.DirectionsService();

    // Check if the directions are rendered and clear them if so
    if (directionRef.current !== null) {
      directionRef.current.setMap(null);
      directionRef.current = null;
    }

    directionRef.current = new maps.DirectionsRenderer();
    directionRef.current.setMap(map);

    const route = {
      origin: { lat: values.origin.lat, lng: values.origin.lng },
      destination: { lat: values.destination.lat, lng: values.destination.lng },
      travelMode: 'DRIVING',
    };

    directionsService.route(route, (response, status) => {
      if (status !== 'OK') {
        addErrorNotification(`Directions request failed due to ${status}`);
      } else {
        directionRef.current.setDirections(response); // Add route to the map
        const directionsData = response.routes[0].legs[0]; // Get data about the mapped route
        if (!directionsData) {
          addErrorNotification('Directions request failed');
        } else {
          setRouteValue({ ...routeRef.current, directions: directionsData });
        }
      }
    });
  };

  const clearRoute = () => {
    if (directionRef.current !== null) {
      directionRef.current.setMap(null);
      directionRef.current = null;
    }

    const isDonorsMarkersToggled = visiblePins.indexOf(MARKER_DONOR) !== -1;
    if (isDonorsMarkersToggled) {
      markers.forEach(marker => {
        if (marker.meta._markerType === MARKER_DONOR) {
          marker.setMap(mapApi.map);
        }
      });
    }
    setRouteValue({ origin: {}, destination: {}, directions: {} });
  };

  const CloseDrivingDirections = () => {
    clearRoute();
    setShowDrivingDirections(false);
  };

  const fitMapBounds = () => {
    let isMarkersVisible = false;
    const { map, maps } = mapApi;
    const bounds = new maps.LatLngBounds();

    markers.forEach(marker => {
      if (marker.map) {
        isMarkersVisible = true;
        bounds.extend(marker.getPosition());
      }
    });

    if (isMarkersVisible) {
      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
      map.setZoom(map.getZoom() - 1);
    } else {
      map.panTo(defaultProps.center);
      map.setZoom(defaultProps.defaultZoom);
    }
  };

  const handleMarkerToggle = markerType => () => {
    const currentIndex = visiblePins.indexOf(markerType);
    const newChecked = [...visiblePins];

    if (currentIndex === -1) {
      newChecked.push(markerType);
      showMarkers(markerType);
    } else {
      newChecked.splice(currentIndex, 1);

      if (markerType !== MARKER_UNASSIGNED_USER) {
        hideMarkers(markerType);
      }
    }

    setVisiblePins(newChecked);
  };

  const handleDrivingDirectionChange = (location) => {
    const locationIsArray = Array.isArray(location);
    const originArray = Object.keys(routeRef.current.origin);
    const destinationArray = Object.keys(routeRef.current.destination);

    setShowDrivingDirections(true);

    if (locationIsArray) {
      setRouteValue({ origin: location[0], destination: location[1], directions: {} });
      return renderRoute({ origin: location[0], destination: location[1] });
    }

    if (!originArray.length) {
      return setRouteValue({ origin: location, destination: {}, directions: {} });
    }

    if (originArray.length && !destinationArray.length) {
      setRouteValue({ ...routeRef.current, destination: location });
      return renderRoute({ ...routeRef.current, destination: location });
    }

    clearRoute();

    return setRouteValue({ origin: location, destination: {}, directions: {} });
  };

  const fitUserMapBounds = (users, action) => {
    const { map, maps } = mapApi;

    const userBounds = new maps.LatLngBounds();
    if (users.length && action === 'select-option') {
      users.forEach((user) => {
        const userLatLng = new maps.LatLng(user.meta.lat, user.meta.long);
        userBounds.extend(userLatLng);
      });
      map.setCenter(userBounds.getCenter());
      map.fitBounds(userBounds);
      map.setZoom(map.getZoom() - 1);
    }
  };

  const handleUserChange = (users, { action }) => {
    if (users === null) {
      if (selectedUsers.length) {
        CloseDrivingDirections();
        fitUserMapBounds([]);
        return setSelectedUsers([]);
      }
      return null;
    }

    // Set user(s) map bounds
    fitUserMapBounds(users, action);

    // Set driving directions
    if (users.length && users.length <= 2 && action === 'select-option') {
      const foodDonorsAndReceivers = users
        .filter((user) => user.meta._markerType === MARKER_DONOR || user.meta._markerType === MARKER_RECEIVER)
        .map((user) => user?.meta);

      if (foodDonorsAndReceivers.length > 1) {
        handleDrivingDirectionChange(foodDonorsAndReceivers);
      }

      if (foodDonorsAndReceivers.length === 1 && users.length === 1) {
        handleDrivingDirectionChange(foodDonorsAndReceivers[0]);
      }
    } else {
      CloseDrivingDirections();
    }

    return setSelectedUsers(
      (users || []).map((user) => ({
        ...user,
        color: getColorByUser(user),
      }))
    );
  };

  const addMarker = location => {
    const { map, maps } = mapApi;

    const infoWindow = new maps.InfoWindow({
      content: null,
    });

    const marker = new maps.Marker({
      position: {
        lat: location.lat,
        lng: location.lng,
      },
      map: visiblePins.includes(location._markerType) ? map : null,
      icon: location.imageUrl,
      meta: location,
    });

    marker.addListener('mouseover', () => {
      const div = document.createElement('div');

      switch (location._markerType) {
        case MARKER_RESCUER:
          ReactDOM.render(renderRescuerInfoWindow(location), div);
          break;
        case MARKER_RECEIVER:
          ReactDOM.render(
            renderLocationInfoWindow({
              text: location.name,
              address: formatAddress({
                address: location.address,
                city: location.city,
                st: location.state,
                zip: location.zip_code,
              }),
              contact: {
                email: location.primary_contact_email,
                phone: get(location, 'primary_contact.phone'),
                name: location.primary_contact ? getUserFullName(location.primary_contact) : null,
              },
            }),
            div
          );
          break;
        case MARKER_DONOR:
          ReactDOM.render(
            renderLocationInfoWindow({
              text: location.title,
              address: formatAddress({
                address: location.address,
                city: location.city,
                st: location.state,
                zip: location.zip_code,
              }),
              contact: {
                email: location.primary_contact_email,
                phone: get(location, 'primary_contact.phone'),
                name: location.primary_contact ? getUserFullName(location.primary_contact) : null,
              },
            }),
            div
          );
          break;
      }

      infoWindow.setContent(div);
      infoWindow.open(map, marker);
    });

    marker.addListener('mouseout', () => infoWindow.close());

    marker.addListener('click', () => handleDrivingDirectionChange(location));

    markers.push(marker);
  };

  const handleDateRangeChange = (data) => {
    const range = {
      startDate: data.dateRange ? data.dateRange.startDate : null,
      endDate: data.dateRange ? data.dateRange.endDate : null,
      period: data.period ? data.period : null,
    };

    setDateRange(range);
  };

  const reverseDirections = () => {
    const reverseRouteValue = { origin: {...routeRef.current.destination}, destination: {...routeRef.current.origin}, directions: {} };
    setRouteValue(reverseRouteValue);
    renderRoute(reverseRouteValue);
  };

  const handleDayChange = (event) => {
    const day = event.target.value;
    setSelectedReceiverDays((prev) => {
      const selected = prev.find((d) => d === day);
      if (selected) {
        return prev.filter((d) => d !== day);
      }
      return [...prev, day];
    });
  };

  const handleNeedsChange = (need) => {
    setReceiverNeeds([
      ...receiverNeeds.filter((n) => n.id !== need.id),
      ...(need.rating ? [{ name: need.name, rating: need.rating, id: need.id }] : []),
    ]);
  };

  const handleAccordionChange = (accordion) => {
    if (openAccordion !== accordion) {
      return setOpenAccordion(accordion);
    }

    return setOpenAccordion(false);
  };

  const clearFilters = () => {
    if (dateRange?.period !== 'all') {
      handleDateRangeChange({ endDate: null, period: 'all', startDate: null });
    }
    setReceiverAfterHours(null);
    setReceiverBeforeHours(null);
    setSelectedReceiverDays([]);
    setReceiverNeeds([]);
    setSelectedSites([]);
    setSelectedUsers([]);
  };

  const toggleHeatmap = () => {
    if (!mapApi) {
      return;
    }

    if (heatmapActive) {
      setHeatmapActive(false);
      showVisibleMarkers();
      if (typeof heatmapLayer === 'object') {
        heatmapLayer.setData([]);
        heatmapLayer.setMap(null);
      }
      return;
    }

    const { map, maps } = mapApi;

    heatmapLayer = new maps.visualization.HeatmapLayer({
      data: heatmapArray,
      radius: 20,
      opacity: 0.6,
    });

    setShowDrivingDirections();

    setHeatmapActive(true);
    removeVisibleMarkers();
    heatmapLayer.setMap(map);
  };

  if (sites.inflight || !sites.allIds.length) {
    return 'Loading...';
  }

  return (
    <Box
      css={{
        margin: '-13px -24px 0px -20px',
        height: 'calc(100vh - 55px)',
      }}
    >
      <GoogleMap
        ref={mapApiRef}
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.defaultZoom}
        hoverDistance={20}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
        }}
        options={{
          fullscreenControl: false,
          zoomControl: false,
          maxZoom: defaultProps.maxZoom,
          minZoom: defaultProps.minZoom,
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={api => setMapApi(api)}
        heatmapLibrary
      >
        <Dialog maxWidth="lg" onClose={() => setDialogList([])} open={dialogList.length > 0}>
          <DialogTitle disableTypography onClose={() => setDialogList([])}>
            <Typography variant="h6">Unassigned Users</Typography>
            <IconButton
              aria-label="close"
              onClick={() => setDialogList([])}
              style={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Name
                  </TableCell>
                  <TableCell component="th" scope="row">
                    E-mail
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Phone#
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Zip Code
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dialogList.map(unassignedUser => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={unassignedUser.id}>
                    <TableCell>{getUserFullName(unassignedUser)}</TableCell>
                    <TableCell>{unassignedUser.email || '-'}</TableCell>
                    <TableCell>{formatPhoneNumber(unassignedUser.phone) || '-'}</TableCell>
                    <TableCell>{unassignedUser.zip_code}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
        </Dialog>
        {!heatmapActive &&
          clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } = cluster.properties;

            if (isCluster) {
              const tooltipTitle = () => {
                // this tooltip will be only visible when map is not fully zoomed in
                // and cluster contains markers (users) from > 1 zip code
                if (
                  mapApi.map.getZoom() < defaultProps.maxZoom &&
                  uniqBy(supercluster.getLeaves(cluster.id, 99999), user => user.properties.meta.zip_code).length !== 1
                ) {
                  return <Typography variant="body2">Zoom in to see unassigned users list.</Typography>;
                }

                return supercluster
                  .getLeaves(cluster.id, MAX_UNASSIGNED_USERS_TOOLTIP_ROWS)
                  .reduce((acc, curr, index, { length }) => {
                    const totalLeaves = cluster.properties.point_count;
                    const leavesHidden = totalLeaves - MAX_UNASSIGNED_USERS_TOOLTIP_ROWS;

                    if (curr.type === MARKER_UNASSIGNED_USER) {
                      acc.push(
                        <Fragment key={`ua-${curr.properties.id}-tooltip`}>
                          {renderUnassignedUserTooltip(curr.properties.meta)}
                        </Fragment>
                      );
                    }

                    if (length - 1 !== index) {
                      acc.push(
                        <Divider key={`ua-${curr.properties.id}-divider`} style={{ marginTop: 4, marginBottom: 4 }} />
                      );
                    } else if (leavesHidden > 0) {
                      acc.push(<Divider key={`ua-${curr.properties.id}-divider`} />);
                      acc.push(
                        <Box py={1} key={`ua-${curr.properties.id}-text`}>
                          <Typography gutterBottom variant="body2">
                            There are <strong>{leavesHidden}</strong> more unassigned user(s) in this area.
                          </Typography>
                          <Typography align="center" variant="subtitle2">
                            Click on marker to see all.
                          </Typography>
                        </Box>
                      );
                    }

                    return acc;
                  }, []);
              };
              return (
                <Marker key={`ua_cluster-${cluster.id || cluster.properties.id}`} lat={latitude} lng={longitude}>
                  <HtmlTooltip withBorder title={tooltipTitle()}>
                    <div
                      className="cluster-marker"
                      style={{
                        backgroundColor: '#fff',
                        color: '#000',
                        border: '4px solid #7428ef',
                        width: `${35 + (pointCount / visibleUnassignedUsersMarkers.length) * 10}px`,
                        height: `${35 + (pointCount / visibleUnassignedUsersMarkers.length) * 10}px`,
                      }}
                      onClick={() => {
                        if (mapApi.map.getZoom() === defaultProps.maxZoom) {
                          setDialogList(
                            supercluster.getLeaves(cluster.id, 999999).map(({ properties }) => properties.meta)
                          );
                        }
                        const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
                        mapApi.map.setZoom(expansionZoom);
                        mapApi.map.panTo({ lat: latitude, lng: longitude });
                      }}
                    >
                      {pointCount}
                    </div>
                  </HtmlTooltip>
                </Marker>
              );
            }

            return (
              <Marker key={`ua-${cluster.properties.id}`} lat={latitude} lng={longitude}>
                <HtmlTooltip title={renderUnassignedUserTooltip(cluster.properties.meta)}>
                  <img className="unassigned-user-marker" src={unassignedUserImage} alt="unassigned user" />
                </HtmlTooltip>
              </Marker>
            );
          })}
      </GoogleMap>

      {showDrivingDirections && (
        <MapDrivingDirections
          CloseDrivingDirections={CloseDrivingDirections}
          routeValue={routeValue}
          reverseDirections={reverseDirections}
        />
      )}
      <>
        <MapMenuPanelContainer boxShadow={25} bgcolor="background.paper">
          <MapSiteSelector
            expanded={openAccordion === SITES_ACCORDION}
            onAccordionChange={() => handleAccordionChange(SITES_ACCORDION)}
            isLoading={!mapApi}
            sitesList={sitesList}
            selectedSite={selectedSites}
            handleSiteChange={handleSiteChange}
            setSiteAreaVisibility={setSiteAreaVisibility}
            isSiteAreaVisible={isSiteAreaVisible}
            setSiteAreaStrokeVisibility={setSiteAreaStrokeVisibility}
            isSiteAreaStrokeVisible={isSiteAreaStrokeVisible}
            badgeCounter={selectedSites.length}
          />

          <Divider />

          <MarkersSelector
            expanded={openAccordion === USERS_ACCORDION}
            onAccordionChange={() => handleAccordionChange(USERS_ACCORDION)}
            isAdmin={isAdmin}
            isLoading={!mapApi}
            visiblePins={visiblePins}
            handleSwitchToggle={handleMarkerToggle}
            handleFitMapBounds={fitMapBounds}
            dateRange={dateRange}
            onDateRangeChange={handleDateRangeChange}
            badgeCounter={(dateRange?.period !== 'all' ? 1 : 0) + selectedUsers.length}
            users={visibleMarkers}
            selectedUsers={selectedUsers}
            handleUserChange={handleUserChange}
            toggleHeatmap={toggleHeatmap}
            heatmapActive={heatmapActive}
          />

          <Divider />

          <ReceiverNeedsSelector
            expanded={openAccordion === AGENCY_DETAILS_ACCORDION}
            onAccordionChange={() => handleAccordionChange(AGENCY_DETAILS_ACCORDION)}
            handleCheckboxChange={handleDayChange}
            selectedDays={selectedReceiverDays}
            afterValue={receiverAfterHours}
            beforeValue={receiverBeforeHours}
            handleAfterChange={(after) => setReceiverAfterHours(after)}
            handleBeforeChange={(before) => setReceiverBeforeHours(before)}
            categories={categories}
            needs={receiverNeeds}
            handleNeedsChange={handleNeedsChange}
            badgeCounter={receiverNeedsBadgeCounter}
          />

          <Divider />

          <UtilityButtons
            disabled={!mapApi || visiblePins.length === 0}
            fitMapBounds={fitMapBounds}
            clearFilters={clearFilters}
          />
        </MapMenuPanelContainer>

        <Box
          boxShadow={25}
          bgcolor="background.paper"
          style={{
            padding: 8,
            position: 'absolute',
            bottom: 20,
            right: 10,
            zIndex: 5,
            display: 'grid',
            gridAutoFlow: 'row',
            gridRowGap: 16,
          }}
        >
          <IconButton size="small" onClick={() => mapApi.map.setZoom(mapApi.map.getZoom() + 1)}>
            <AddIcon />
          </IconButton>
          <IconButton size="small" onClick={() => mapApi.map.setZoom(mapApi.map.getZoom() - 1)}>
            <RemoveIcon />
          </IconButton>
        </Box>
      </>
    </Box>
  );
};

export default Map;
