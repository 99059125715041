import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import { AppBar, Box, Breadcrumbs, Tab, Grid, Tabs, Typography, Paper } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import Bluebird from 'bluebird';
import routes from '../routes';
import UserForm from '../components/UserForm';
import * as usersActions from '../actions/users';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import { getUserFullName } from '../helpers/user';
import RescuerUpcomingRescues from '../pages/Profile/RescuerUpcomingRescues';
import { getRecentRescues, getUpcomingRescuesIncludingToday } from '../helpers/RescuesHelper';
import {
  fetchRescuerClaimedAndAdoptedRescuesIfNeeded,
  fetchRescuerPastRescuesIfNeeded,
} from '../actions/rescues';
import NotificationPreferences from '../components/NotificationPreferences';
import { transformRole, transformRoleToApi } from '../models/users';
import { withRescuerRescueActions } from '../hooks/withRescuerRescueActions';
import RescuerRecentRescues from '../pages/Profile/RescuerRecentRescues';
import { fetchRescuerLocationTypesIfNeeded } from '../actions/rescuerLocations';
import ACLService from '../services/acl';
import VerificationPauseRescueConfirmationDialog from '../components/VerificationPauseRescueConfirmationDialog';
import VerificationResumeRescueConfirmationDialog from '../components/VerificationResumeRescueConfirmationDialog';
import { getFoodDonorPickupLocations } from '../api/foodDonors';
import { deleteFoodDonorPickupLocationContact } from '../actions/foodDonors';

class UserEditView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'manage',
      showVerificationPauseRescueDialog: false,
      showVerificationResumeRescueDialog: false,
    };
  }

  componentDidMount() {
    const { fetchUser, refreshData, fetchUserNotifications, match, fetchLocationTypes } = this.props;

    fetchUser(match.params.id);
    refreshData(match.params.id);
    fetchUserNotifications(match.params.id);
    fetchLocationTypes();
  }

  submitCallback = (userId, data) => {
    const { updateUser, fetchUser, deletePickupLocationContact } = this.props;

    // If the user role for donor location staff or donor location admin is removed,
    // also remove them from the pickup locations contact if his data is present there
    const donorLocationStaff = transformRoleToApi(Roles.DonorLocationStaff);
    const donorLocationAdmin = transformRoleToApi(Roles.DonorLocationAdmin);
    const detachedDonorLocationStaffRoles = data?.roles_to_detach?.filter(
      (role) => role.role_name === donorLocationStaff || donorLocationAdmin
    );

    if (detachedDonorLocationStaffRoles?.length) {
      detachedDonorLocationStaffRoles.map(async (role) => {
        try {
          const foodDonorPickupLocations = await getFoodDonorPickupLocations(role.donor_location_id)
            .then((response) => response.json())
            .then((json) => json.data);

          const userIsPickupLocationContact = foodDonorPickupLocations.filter(
            (location) => location.primary_contact_id === userId
          );

          if (userIsPickupLocationContact?.length) {
            userIsPickupLocationContact.map(async (location) => {
              await deletePickupLocationContact(location.donor_location_id, location.id);
            });
          }
        } catch (error) {
          console.error(error);
        }
      });
    }

    // Update User
    return Bluebird
      .try(() => updateUser(userId, data))
      .then(() => fetchUser(userId)); // update store as updateUser does not updates entities.users entity
  };

  onUnclaimRescueButtonClick = rescue => {
    const { match, rescuerRescueActions: { rescueUnclaim } } = this.props;

    return rescueUnclaim(rescue, false, { id : parseInt(match.params.id, 10)});
  };

  onReleaseRescueButtonClick = rescue => {
    const { match, rescuerRescueActions: { releaseRescue } } = this.props;

    return releaseRescue(rescue, false, { id: parseInt(match.params.id, 10) })
  };

  onPauseButtonClick = () => {
    this.setState({ showVerificationPauseRescueDialog: true });
  };

  onResumeButtonClick = () => {
    this.setState({ showVerificationResumeRescueDialog: true });
  };

  render() {
    const { tab, showVerificationPauseRescueDialog, showVerificationResumeRescueDialog } = this.state;
    const {
      users,
      match,
      rescues,
      refreshData,
      site,
      activeUser,
      rescuerLocationTypes,
      updateUser,
      fetchUser,
      sendInvitationEmail,
      location,
    } = this.props;
    const user = users.byId[match.params.id];
    const hasSDorAdminRole = authService.hasAnyRoleInCurrentlySelectedSite(
      activeUser,
      [
        Roles.Admin,
        Roles.NationalSiteDirector,
        Roles.SiteCoordinator,
        Roles.SiteDirector,
      ]
    );
    const hasEditedUserRescuerRole = authService.hasAnyRoleInCurrentlySelectedSite(user, [Roles.Rescuer]);

    if (users.inflight || !user || users.notificationsPreferences.inflight) {
      return <div>loading user...</div>;
    }
    const notificationsWithUserPreferences = users.notificationsPreferences.byUserId[user.id] || [];

    const hasUsersListAccess = ACLService.hasAccess(activeUser, ACLService.resources.usersList, site.id);

    const userIsRescuer = authService.hasRoleInAnySite(user, Roles.Rescuer);

    const userIsUnsubscribedFromNotifications = !!user?.communication_unsubscribed;

    const {
      state: { prevPath },
    } = location;

    const handlePause = () => {
      return Bluebird
      .try(() => updateUser(user.id, { paused: true }))
      .then(() => fetchUser(user.id)); // update store as updateUser does not updates entities.users entity
    };

    const handleResume = () => {
      return Bluebird
      .try(() => updateUser(user.id, { paused: false }))
      .then(() => fetchUser(user.id)); // update store as updateUser does not updates entities.users entity
    };

    const renderBreadcrumbs = () => {
      if (prevPath === routes.rescuers) {
        return (
          <Breadcrumbs aria-label="Breadcrumbs">
            <Link color="inherit" to={routes.rescuers}>
              Rescuers
            </Link>

            <Typography color="textPrimary">{getUserFullName(user)}</Typography>
          </Breadcrumbs>
        );
      }

      if (prevPath === routes.unassignedUsers) {
        return (
          <Breadcrumbs aria-label="Breadcrumbs">
            <Link color="inherit" to={routes.unassignedUsers}>
              Unassigned Users
            </Link>

            <Typography color="textPrimary">{getUserFullName(user)}</Typography>
          </Breadcrumbs>
        );
      }

      return (
        <Breadcrumbs>
          {hasUsersListAccess ? (
            <Link color="inherit" to={routes.users}>
              People
            </Link>
          ) : (
            <Typography color="textPrimary">People</Typography>
          )}

          <Typography color="textPrimary">{getUserFullName(user)}</Typography>
        </Breadcrumbs>
      );
    };

    return (
      <>
        {renderBreadcrumbs()}
        <AppBar position="static">
          <Tabs value={tab} onChange={(event, newValue) => this.setState({ tab: newValue })}>
            <Tab label="Profile" value="manage" />

            {/* tabs selector only visible when user is a rescuer */}
            {authService.hasRoleInCurrentlySelectedSite(user, Roles.Rescuer) && [
              <Tab key="upcoming" label="Upcoming Rescues" value="upcoming-rescues" />,
              <Tab key="past-rescues" label="Past Rescues" value="past-rescues" />,
            ]}

            {hasSDorAdminRole && <Tab label="Communication preferences" value="communication" />}
          </Tabs>
        </AppBar>

        <Box hidden={tab !== 'manage'}>
          <UserForm
            sendInvitationEmail={sendInvitationEmail}
            site={site}
            pauseCallback={this.onPauseButtonClick}
            resumeCallback={this.onResumeButtonClick}
            ShowPauseRescue={hasSDorAdminRole && hasEditedUserRescuerRole}
            user={user}
            submitCallback={this.submitCallback}
            showIsActiveCheckbox
            rescuerLocationTypes={rescuerLocationTypes}
            showAddressFields={hasSDorAdminRole && hasEditedUserRescuerRole}
            showRoleAssignmentsTable={authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite(
              [Roles.Admin, Roles.NationalSiteDirector]
            )}
            showVerifyEmailButton={authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
              Roles.Admin,
              Roles.NationalSiteDirector,
              Roles.SiteDirector,
              Roles.SiteCoordinator,
              ]) && user.completed_registration !== null
            }
            showInviteEmailButton={authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
              Roles.Admin,
              Roles.NationalSiteDirector,
              Roles.SiteDirector,
              Roles.SiteCoordinator,
              ]) && user.completed_registration === null
            }
            showCommunicationCheckboxes={authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite(
              [Roles.Admin]
            )}
            showQrtCheckbox={userIsRescuer}
          />
        </Box>

        <Box hidden={tab !== 'upcoming-rescues'}>
          <RescuerUpcomingRescues
            handleRescueRelease={this.onReleaseRescueButtonClick}
            handleRescueUnclaim={this.onUnclaimRescueButtonClick}
            upcomingRescues={getUpcomingRescuesIncludingToday(user.id, rescues)}
            refreshData={() => refreshData(user.id)}
            user={user}
            isLoading={rescues.inflight}
            upcomingRescuesTitle="Showing all upcoming rescues."
            noUpcomingRescuesText="This user does not have upcoming rescues."
          />
        </Box>

        <Box hidden={tab !== 'past-rescues'}>
          <RescuerRecentRescues
            recentRescues={getRecentRescues(user.id, rescues, false, false)}
            isLoading={rescues.inflight}
            recentRescuesTitle="Showing all past rescues."
            noRecentRescuesText="This user does not have past rescues."
          />
        </Box>

        <Box hidden={tab !== 'communication'}>
          {!userIsUnsubscribedFromNotifications &&
            notificationsWithUserPreferences
              .filter(n => !n.hidden_from_ui)
              .map(notificationWithUserPreferences => {
                // checking if user has the any role which is defined in visible_in_ui_to_roles array
                if (
                  !notificationWithUserPreferences.visible_in_ui_to_roles
                    .map(role => transformRole(role))
                    .some(role => user.role_assignments.map(ra => ra.role_name).indexOf(role) >= 0)
                ) {
                  return null;
                }

                return (
                  <Grid item xs={12} key={notificationWithUserPreferences.type}>
                    <NotificationPreferences user={user} notification={notificationWithUserPreferences} />
                  </Grid>
                );
              })}

          {userIsUnsubscribedFromNotifications && (
            <Paper style={{ padding: '16px 24px' }}>
              <Typography color="secondary">
                <InfoOutlined fontSize="small" style={{ position: 'relative', top: 5, marginRight: 2.5 }} />
                User is currently unsubscribed from all communication.
              </Typography>
            </Paper>
          )}
        </Box>

        {showVerificationPauseRescueDialog && (
          <VerificationPauseRescueConfirmationDialog 
            onCloseDialog={() => this.setState({ showVerificationPauseRescueDialog: false })}
            onPauseButtonClick={handlePause}
            isSD={hasSDorAdminRole}
          />
        )}

        {showVerificationResumeRescueDialog && (
          <VerificationResumeRescueConfirmationDialog 
            onCloseDialog={() => this.setState({ showVerificationResumeRescueDialog: false })}
            onResumeButtonClick={handleResume}
            isSD={hasSDorAdminRole}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  app: { loggedInUser, impersonating, site },
  entities: { users, rescues, rescuerLocations },
}) => ({
  activeUser: impersonating || loggedInUser,
  rescuerLocationTypes: rescuerLocations.locationTypes.byId,
  site,
  users,
  rescues,
});
const mapDispatchToProps = dispatch => ({
  fetchUser: userId => dispatch(usersActions.fetchUserIfNeeded(userId)),
  updateUser: (userId, data) => dispatch(usersActions.updateUser(userId, data)),
  deletePickupLocationContact: (foodDonorId, locationId) => dispatch(deleteFoodDonorPickupLocationContact(foodDonorId, locationId)),
  fetchUserNotifications: userId => dispatch(usersActions.fetchUserNotificationsPreferencesIfNeeded(userId)),
  fetchLocationTypes: () => dispatch(fetchRescuerLocationTypesIfNeeded()), // required when Admin/SD tries to edit rescuer profile
  sendInvitationEmail: (userId, siteId) => dispatch(usersActions.resendUserInvitationEmail(userId, siteId)),
  refreshData: (rescuerId) => {
    dispatch(
      fetchRescuerClaimedAndAdoptedRescuesIfNeeded(
        rescuerId,
        moment().format('YYYYMMDD'),
        null,
        false
      )
    );
    dispatch(fetchRescuerPastRescuesIfNeeded(rescuerId, false));
  },
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withRescuerRescueActions(UserEditView))
);
