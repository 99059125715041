import React, { useEffect } from 'react';
import { Breadcrumbs } from '@material-ui/core';
import { generatePath, Link, Route, Switch, useLocation } from 'react-router-dom';
import { matchPath, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import NSDDashboardDesktopAppBar from './Components/NSDDashboardDesktopAppBar';
import NSDAtAGlance from './Components/NSDAtAGlance';
import NSDInsights from './Components/NSDInsights';
import NSDLeaderboards from './Components/NSDLeaderboards';
import { setDefaultDashboard } from '../../../actions/uiPersisted';
import routes from '../../../routes';
import NSDEnvironmentImpact from './Components/NSDEnvironmentImpact';

export const NSD_DESKTOP_DASHBOARD_TAB_INSIGHTS = 'insights';
export const NSD_DESKTOP_DASHBOARD_TAB_LEADERBOARDS = 'leaderboards';
export const NSD_DESKTOP_DASHBOARD_TAB_ENVIRONMENT_IMPACT = 'environment-impact';

export const paths = [
  generatePath(routes.dashboardNSD),
  generatePath(routes.dashboardNSD, { tab: NSD_DESKTOP_DASHBOARD_TAB_LEADERBOARDS }),
  generatePath(routes.dashboardNSD, { tab: NSD_DESKTOP_DASHBOARD_TAB_INSIGHTS }),
  generatePath(routes.dashboardNSD, { tab: NSD_DESKTOP_DASHBOARD_TAB_ENVIRONMENT_IMPACT }),
];

const NSDDashboardDesktop = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = useParams();

  useEffect(() => {
    if (
      matchPath(pathname, {
        path: routes.dashboardNSD,
        exact: true,
      })
    ) {
      dispatch(setDefaultDashboard({ route: routes.dashboardNSD, params: params }));
    }
  }, [pathname]);

  return (
    <div data-testid="nsd-dashboard-desktop-div">
      <NSDDashboardDesktopAppBar />

      <div>
        <Switch>
          <Route exact path={paths[0]} component={NSDAtAGlance} />
          <Route path={paths[1]} component={NSDLeaderboards} />
          <Route path={paths[2]} component={NSDInsights} />
          <Route path={paths[3]} component={NSDEnvironmentImpact} />
        </Switch>
      </div>
    </div>
  );
};

export default NSDDashboardDesktop;
