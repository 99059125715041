import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  Badge,
  Box,
  Divider,
  FormGroup,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Clear as ClearIcon, ExpandMore } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import { Rating } from '@material-ui/lab';
import { daysList } from '../../../helpers/RescuesHelper';
import MinMaxTimePicker from '../../../components/MinMaxTimePicker';

const useStyles = makeStyles(() => ({
  accordion: {
    '&.Mui-expanded': {
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  summary: {
    position: 'relative',
    fontWeight: 'bold',
    height: '40px',
    minHeight: '40px',
    '&.Mui-expanded': {
      minHeight: '50px',
    },
  },
  picker: {
    '& input': {
      padding: '6px 14px',
    },
  },
  badge: {
    position: 'absolute',
    right: 50,
    top: (props) => (props.expanded ? 25 : 20),
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  day: {
    marginRight: 5,
  },
  dayCheckbox: {
    paddingRight: 1,
  },
}));

const ReceiverNeedsSelector = ({
  afterValue,
  beforeValue,
  handleAfterChange,
  handleBeforeChange,
  handleCheckboxChange,
  handleNeedsChange,
  onAccordionChange,
  expanded = false,
  selectedDays = [],
  categories = [],
  needs = [],
  badgeCounter = 0,
}) => {
  const classes = useStyles({ expanded });
  const [after, setAfter] = useState(afterValue || null);
  const [before, setBefore] = useState(beforeValue || null);

  const handleAfterTimeChange = ({ event, isValid }) => {
    if (isValid) {
      setAfter(event.format('HH:mm:00'));
    }
  };

  const handleAfterTimeUpdate = () => {
    handleAfterChange(after);
  };

  const clearAfter = () => {
    setAfter(null);
    handleAfterChange(null);
  };

  const handleBeforeTimeChange = ({ event, isValid }) => {
    if (isValid) {
      setBefore(event.format('HH:mm:00'));
    }
  };

  const handleBeforeTimeUpdate = () => {
    handleBeforeChange(before);
  };

  const clearBefore = () => {
    setBefore(null);
    handleBeforeChange(null);
  };

  return (
    <Accordion className={classes.accordion} elevation={0} onChange={onAccordionChange} expanded={expanded}>
      <AccordionSummary className={classes.summary} expandIcon={<ExpandMore />}>
        Agency Details
        <Badge className={classes.badge} badgeContent={badgeCounter} color="error" />
      </AccordionSummary>
      <Box px={2}>
        <Box mb={1}>
          <Typography variant="body2">Agency Hours</Typography>
        </Box>
        <Box>
          <FormGroup row>
            {daysList.map((day, dayIndex) => {
              const dayName = day.toLowerCase();
              return (
                <FormControlLabel
                  key={day}
                  className={classes.day}
                  control={
                    <Checkbox
                      name={dayName}
                      className={classes.dayCheckbox}
                      checked={!!selectedDays.find((d) => d === dayIndex.toString())}
                      onChange={handleCheckboxChange}
                      value={dayIndex}
                      size="small"
                    />
                  }
                  label={<Typography variant="caption">{day}</Typography>}
                />
              );
            })}
          </FormGroup>
        </Box>

        <Box display="flex" gridGap="16px" py={1}>
          <Box display="flex">
            <MinMaxTimePicker
              className={classes.picker}
              inputVariant="outlined"
              size="small"
              label="After"
              variant="inline"
              value={afterValue ? moment(afterValue, 'HH:mm:ss') : null}
              maxTime={before ? moment(before, 'HH:mm:ss') : moment().set({ hour: '23', minute: '55', second: '00' })}
              onTimeChange={handleAfterTimeChange}
              onClose={handleAfterTimeUpdate}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <IconButton size="small" onClick={clearAfter}>
              <ClearIcon size="small" onClick={clearAfter} />
            </IconButton>
          </Box>

          <Box display="flex">
            <MinMaxTimePicker
              className={classes.picker}
              inputVariant="outlined"
              size="small"
              label="Before"
              variant="inline"
              value={beforeValue ? moment(beforeValue, 'HH:mm:ss') : null}
              minTime={after ? moment(after, 'HH:mm:ss') : moment().set({ hour: '00', minute: '05', second: '00' })}
              onTimeChange={handleBeforeTimeChange}
              onClose={handleBeforeTimeUpdate}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <IconButton size="small" onClick={clearBefore}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
        {selectedDays.length > 0 && (
          <Box>
            <Typography variant="caption" color="textSecondary">
              Agencies without hours will not appear. Add hours on the Agency Profile page.
            </Typography>
          </Box>
        )}
        <Divider />

        <Box my={1}>
          <Typography variant="body2">Agency Needs</Typography>
        </Box>

        <Box>
          <Typography variant="caption">Select a rating to match food to an agency based on their needs.</Typography>
        </Box>
        <Grid container>
          {categories.map((category) => {
            const need = needs.find((n) => n.name === category.name);
            return (
              <Grid item xs={4} key={category.id}>
                <Typography variant="body2" component="legend">
                  {category.name !== 'Prepared/Ready to Eat' ? category.name : 'Prepared'}
                </Typography>
                <Rating
                  name={category.name}
                  size="small"
                  value={need ? need.rating : null}
                  onChange={(e, newValue) => {
                    handleNeedsChange({
                      name: category.name,
                      rating: newValue !== null ? newValue : 0,
                      id: category.id,
                    });
                  }}
                />
              </Grid>
            )})}
        </Grid>
      </Box>
      <Divider />
    </Accordion>
  );
};

export default ReceiverNeedsSelector;
