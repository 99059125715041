import React, { Component } from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Field } from 'react-final-form';
import { Checkbox, TextField } from 'final-form-material-ui';
import moment from 'moment';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import Bluebird from 'bluebird';
import {
  Grid,
  withStyles,
  Box,
  Typography,
  InputLabel,
  FormControlLabel,
  Button,
  TextField as TextFieldMaterial,
} from '@material-ui/core';
import { get, debounce } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import SelectMultiple from './SelectMultiple';
import * as zipCodesApi from '../api/zipCodes';
import {
  getFormFieldError,
  isEmailValid,
  isFormFieldInvalid,
  isPhoneMaskValid,
  getPasswordsValidationErrors,
} from '../helpers/validators';
import * as usersApi from '../api/users';
import { getPhoneNumberFromMask, getPrimaryContactSelectOption } from '../helpers/getters';
import { Colors } from '../assets/theme/Colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { Help as HelpIcon, Mail as MailIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import FRUSTextFieldWrapper from './Common/FRUSTextField';
import errorMessages from '../assets/errorMessages';
import ButtonWithLoading from './ButtonWithLoading';
import { RESEND_EMAIL_BUTTON_STATE } from './UserForm';
import RescuerSitesSwitchDialog from './RescuerSitesSwitchDialog';

export const getPrimaryContactUserForm = user => ({
  primary_contact_id: user ? user.id : null,
  primary_contact_first_name: user ? user.firstname : null,
  primary_contact_last_name: user ? user.lastname : null,
  primary_contact_phone: user ? user.phone : null,
  primary_contact_zip_code: user ? user.zip_code : null,
  primary_contact_email: user ? user.email : null,
});

export const isEmailChanged = (user, email) => user && email !== get(user, 'email');

export const getSelectPrimaryContactUserValidationErrors = (
  values,
  validatePassword = false,
  validators,
  validateZipCode = false
) => {
  let errors = {};

  // if user is selected from the list, do not validate user fields
  if (values.primary_contact_id) {
    return errors;
  }

  if (validatePassword) {
    errors = {
      ...errors,
      ...getPasswordsValidationErrors(values, 'primary_contact_password', 'primary_contact_password_confirmation'),
    };
  }

  if (!values.primary_contact_first_name) {
    errors.primary_contact_first_name = 'Required';
  }

  if (!values.primary_contact_last_name) {
    errors.primary_contact_last_name = 'Required';
  }

  if (!values.primary_contact_email) {
    errors.primary_contact_email = 'Required';
  }

  if (!values.primary_contact_phone) {
    errors.primary_contact_phone = 'Required';
  }

  if (values.primary_contact_email && !isEmailValid(values.primary_contact_email)) {
    errors.primary_contact_email = errorMessages.ERR_INVALID_EMAIL.message;
  }

  if (validators && validators.validatePhone && !values.primary_contact_phone) {
    errors.primary_contact_phone = 'Required';
  }

  if (validateZipCode && !values.primary_contact_zip_code) {
    errors.primary_contact_zip_code = 'Required';
  }

  return errors;
};

const filter = createFilterOptions({
  stringify: option => option.label,
});

class SelectPrimaryContactUser extends Component {
  constructor(props) {
    super(props);

    const { user, emailLabel } = props;
    const isUpdate = !!user;

    this.getSelectedUserValue();
    this.state = {
      isAutocompleteOpen: false,
      loadSuggestionsMinChars: 2,
      selectedUser: user ? { label: user.email, value: user.id } : null,
      selectedZipCode: isUpdate ? { label: user.zip_code, value: user.zip_code } : null,
      zipCodePlaceholder: 'Select zip code',
      contactPlaceholder: emailLabel,

      isEmailFieldChanged: false,
      isPhoneFieldChanged: false,
      fetchingUsers: false,
      userList: [],
    };
  }

  // todo: remove?
  // using `value` prop in form may make submit button enabled by default, test it after you use this prop!
  getSelectedUserValue = () => {
    const { value, formApi } = this.props;
    if (!value) {
      return null;
    }
    return this.fetchUser(value).then(user => {
      this.setState({
        selectedUser: { label: user.email, value: user.id },
      });

      formApi.batch(() => {
        formApi.change('primary_contact_email', get(user, 'email', ''));
        formApi.change('primary_contact_first_name', get(user, 'firstname', ''));
        formApi.change('primary_contact_last_name', get(user, 'lastname', ''));
        formApi.change('primary_contact_phone', get(user, 'phone', ''));
      });
    });
  };

  // fires onUserSelect when user selected from the dropdown list
  handleChangeUser = input => (option, event) => {
    const { formApi, onUserSelect, showUserSelector } = this.props;

    input.onChange(option !== null ? get(option, 'meta.email', option.label) : null);

    this.setState({
      selectedUser: option,
      isAutocompleteOpen: false,
      userList: [],
    });

    // clear user fields data when email changed
    if (option && option.meta) {
      formApi.batch(() => {
        formApi.change('primary_contact_id', get(option, 'meta.id', null));
        formApi.change('primary_contact_first_name', get(option, 'meta.firstname', ''));
        formApi.change('primary_contact_last_name', get(option, 'meta.lastname', ''));
        formApi.change('primary_contact_phone', get(option, 'meta.phone', ''));
        formApi.change('primary_contact_zip_code', get(option, 'meta.zip_code', ''));
      });
    }

    if (showUserSelector && option && option.meta) {
      onUserSelect(option, event);
    }
  };

  clearContactFields = () => {
    const { formApi } = this.props;
    formApi.batch(() => {
      formApi.change('primary_contact_id', undefined);
      formApi.change('primary_contact_email', undefined);
      formApi.change('primary_contact_first_name', undefined);
      formApi.change('primary_contact_last_name', undefined);
      formApi.change('primary_contact_phone', undefined);
      formApi.change('primary_contact_zip_code', undefined);
    });

    this.setState({
      isEmailFieldChanged: true,
      isPhoneFieldChanged: true,
    });
  };

  allowAddUser = () => {
    const { formApi, values } = this.props;

    // make form fields editable and allow type firstName, lastName (...) if custom email address and primary_contact_id is not set
    if (values.primary_contact_id && formApi.getFieldState('primary_contact_email').dirty) {
      formApi.batch(() => {
        formApi.change('primary_contact_id', undefined);
        formApi.change('primary_contact_first_name', undefined);
        formApi.change('primary_contact_last_name', undefined);
        formApi.change('primary_contact_phone', undefined);
        formApi.change('primary_contact_zip_code', undefined);
      });
    }
  };

  handleChangeZipCode = value =>
    this.setState({
      selectedZipCode: value,
    });

  zipCodeLoadingMessage = value => {
    const { loadSuggestionsMinChars } = this.state;

    if (value.inputValue.length <= loadSuggestionsMinChars) {
      return null;
    }

    return 'Loading...';
  };

  loadZipCodes = value => {
    const { loadSuggestionsMinChars } = this.state;

    if (value.length <= loadSuggestionsMinChars) {
      return new Promise(resolve => {
        resolve([]);
      });
    }

    return Bluebird.try(() => zipCodesApi.getZipCodes(value))
      .then(res => res.json())
      .then(res =>
        res.data.map(zipCode => ({
          label: `${zipCode.zip_code}, ${zipCode.city}, ${zipCode.state}`,
          value: zipCode.zip_code,
        }))
      );
  };

  fetchUser = userId => {
    return Bluebird.try(() => usersApi.getUser(userId))
      .then(res => res.json())
      .then(res => res.user);
  };

  fetchUsers = value => {
    const { loadSuggestionsMinChars } = this.state;
    this.setState({
      fetchingUsers: true,
      userList: [],
    });
    if (value.length <= loadSuggestionsMinChars) {
      return new Promise(resolve => {
        resolve([]);
        this.setState({
          fetchingUsers: false,
          userList: [],
        });
      });
    }

    return Bluebird.try(() => usersApi.getUsers({ narrow: true, search: value }))
      .then(res => res.json())
      .then(res => {
        this.setState({
          fetchingUsers: false,
          userList: res.data.map(user => getPrimaryContactSelectOption(user)),
        });
      });
  };

  debounceFetch = debounce(this.fetchUsers, 100);

  switchSite = async (value) => {
    const { setSwitchSites, rescuerSites, upcomingRescues, showSiteChange = false } = this.props;

    if (!showSiteChange) {
      return null;
    }

    setSwitchSites(null);

    if (!value || !value.value) {
      return null;
    }

    // alright, at this point we have user-selected zipcode in our hands
    // the logic around determining zip code sites, is API-driven (15 miles radius, etc)
    // so, let's call API to get selected zip code details - including sites within radius
    const getZipCodeResponse = await zipCodesApi.getZipCode(value.value);
    const getZipCodeResponseJSON = await getZipCodeResponse?.json();
    let foundSites = [];

    if (
      getZipCodeResponseJSON
      && getZipCodeResponseJSON.data
      && getZipCodeResponseJSON.data.sites
      && Array.isArray(getZipCodeResponseJSON.data.sites)
      && getZipCodeResponseJSON.data.sites.length > 0
    ) {
      foundSites = getZipCodeResponseJSON.data.sites;
    }

    // check if the selected zip code is already one of the rescuer sites zip code
    if (
      rescuerSites.length > 0
      && foundSites.length > 0
      && rescuerSites.every((rescuerSite) => foundSites.find(foundSite => foundSite.id === rescuerSite.id))
    ) {
      return null;
    }

    const changeSiteMessage = (() => {
      if (foundSites.length <= 0) {
        return 'Currently there are no sites matching this area, '
          + 'do you want to be moved to the supporter community and leave your current site?';
      }

      let formattedSitesNames;

      switch (foundSites.length) {
        case 1:
          formattedSitesNames = foundSites[0]?.name;
          break;
        case 2:
          formattedSitesNames = foundSites.map(foundSite => foundSite.name).join(' and ');
          break;
        default:
          // i.e. >2 found sites
          // if a site name contains a comma, wrap it in quotes
          const siteNames = foundSites.map(
            foundSite => foundSite.name.includes(',') ? `"${foundSite.name}"` : foundSite.name
          );
          const lastSite = siteNames.pop();

          formattedSitesNames = `${siteNames.join(', ')}, and ${lastSite}`;
          break;
      }

      return `This area is covered by ${formattedSitesNames}. Would you like to switch sites?`;
    })();

    if (rescuerSites.length > 1) {
      return confirmAlert({
        title: 'Change site?',
        message: changeSiteMessage,
        buttons: [
          {
            label: 'No',
            color: 'secondary',
          },
          {
            label: 'Yes',
            color: 'primary',
            onClick: (sites) => {
              setSwitchSites({
                new_sites: foundSites || [],
                sites_to_leave: sites,
              });
            },
          },
        ],
        customUI: ({ title, message, onClose, buttons }) => (
          <RescuerSitesSwitchDialog
            buttons={buttons}
            closeDialog={onClose}
            title={title}
            message={message}
            sites={rescuerSites}
            upcomingRescues={upcomingRescues}
            secondStep={{
              display: true,
              message: 'Please select the sites that you want to leave when switching to the new site.',
              buttons: [
                {
                  label: 'Cancel',
                  color: 'secondary',
                },
                {
                  label: 'Continue',
                  color: 'primary',
                  requireSiteSelection: true,
                  onClick: (sites) => {
                    setSwitchSites({
                      new_sites: foundSites || [],
                      sites_to_leave: sites,
                    });
                  },
                },
              ],
            }}
            thirdStep={{
              display: true,
              message: 'Switching sites will unclaim these rescues, would you like to continue? ',
              tableTitle: `Upcoming rescues`,
              buttons: [
                {
                  label: 'No',
                  color: 'secondary',
                },
                {
                  label: 'Yes',
                  color: 'primary',
                  onClick: (sites) => {
                    setSwitchSites({
                      new_sites: foundSites || [],
                      sites_to_leave: sites,
                    });
                  },
                },
              ],
            }}
          />
        ),
      });
    }

    return confirmAlert({
      title: 'Change site?',
      message: changeSiteMessage,
      buttons: [
        {
          label: 'No',
          color: 'secondary',
        },
        {
          label: 'Yes',
          color: 'primary',
          onClick: () => {
            setSwitchSites({
              new_sites: foundSites || [],
              sites_to_leave: rescuerSites,
            });
          },
        },
      ],
      customUI: ({ title, message, onClose, buttons }) => (
        <RescuerSitesSwitchDialog
          buttons={buttons}
          closeDialog={onClose}
          title={title}
          message={message}
          upcomingRescues={upcomingRescues}
          secondStep={{
            display: !!upcomingRescues.length,
            message: 'Switching sites will unclaim these rescues, would you like to continue? ',
            tableTitle: `Upcoming rescues`,
          }}
        />
      ),
    });
  };

  render() {
    const {
      user,
      classes,
      firstNameLabel,
      lastNameLabel,
      emailLabel,
      phoneLabel,
      zipCodeLabel,
      showVerifyPhoneNumberButton,
      showVerifyEmailButton,
      showInviteEmailButton,
      disableReSendEmailButtonState,
      handleResendVerificationEmail,
      handleSendInvitation,
      showCommunicationCheckboxes,
      values,
      openVerificationNumber,
      helpText,
      showZipCodeField,
      showUserSelector,
      onUserEmailChange,
      showPasswordFieldsOnEmailChange,
      showTitleField,
    } = this.props;
    const {
      selectedUser,
      selectedZipCode,
      zipCodePlaceholder,
      userList,
      fetchingUsers,
      isEmailFieldChanged,
      isPhoneFieldChanged,
      isAutocompleteOpen,
    } = this.state;

    const emailField = (
      <Field
        name="primary_contact_email"
        parse={value => (!value ? '' : value.replace(/\s/g, ''))}
        fullWidth
        render={({ input, meta }) => {
          const showError = isFormFieldInvalid(meta);

          return (
            <Box mt={1}>
              <Autocomplete
                name={input.name}
                inputValue={input.value || ''}
                freeSolo
                disableClearable={!showUserSelector}
                autoSelect
                autoHighlight
                value={selectedUser}
                options={userList}
                open={isAutocompleteOpen}
                onChange={(event, newValue) => {
                  let option = newValue;

                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return;
                  }

                  // Create a new value from the user input
                  if (newValue && newValue.inputValue) {
                    option = {
                      label: newValue.inputValue,
                    };
                  }

                  if (newValue && newValue.inputValue !== null) {
                    this.handleChangeUser(input)(option, event);
                  }
                }}
                onInputChange={(event, value, reason) => {
                  onUserEmailChange(event, value, reason);
                  if (reason === 'clear') {
                    this.clearContactFields();
                  }

                  this.setState({
                    isEmailFieldChanged: isEmailChanged(user, value),
                  });
                }}
                loading={fetchingUsers}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  if (fetchingUsers) {
                    return [
                      {
                        label: 'Loading...',
                        inputValue: null,
                      },
                    ];
                  }

                  // Suggest the creation of a new value
                  // This must stay to allow user use new value
                  if (params.inputValue !== '' && userList.every(u => u.meta.email !== params.inputValue)) {
                    filtered.push({
                      inputValue: params.inputValue,
                      label: `Use "${params.inputValue}" (create new user)`,
                    });
                  }

                  return filtered;
                }}
                getOptionSelected={(option, value) => option.label === value.label}
                getOptionLabel={option => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }

                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }

                  return get(option, 'meta.email', option.label);
                }}
                getOptionDisabled={() => fetchingUsers}
                disableCloseOnSelect={fetchingUsers}
                renderOption={option => option.label}
                renderInput={params => (
                  <TextFieldMaterial
                    {...params}
                    name={input.name}
                    label={emailLabel}
                    onBlur={event => input.onBlur(event)}
                    onFocus={event => input.onFocus(event)}
                    error={showError}
                    helperText={showError ? getFormFieldError(meta) : ''}
                    onKeyDown={event => {
                      // this fixes dropdown user select closing when space is pressed
                      if (event.keyCode === 32) {
                        event.preventDefault();
                      }
                    }}
                    onChange={event => {
                      meta.data.error = undefined;
                      input.onChange(event);
                      this.setState({
                        isAutocompleteOpen: true,
                      });

                      // allow type firstName, lastName (...) if custom email address
                      this.allowAddUser();

                      if (showUserSelector) {
                        this.debounceFetch(event.target.value);
                      }
                    }}
                    className={classNames({
                      [classes.invalidField]: showError,
                    })}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {fetchingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                className={classNames({
                  [classes.invalidField]: showError,
                })}
                styles={{
                  control: base => ({
                    ...base,
                    borderColor: showError ? Colors.errorText : base.borderColor,
                  }),
                }}
              />
            </Box>
          );
        }}
      />
    );

    return (
      <>
        <Box>
          <Field style={{ display: 'none' }} name="primary_contact_id" component={TextField} type="text" />

          <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
            <Grid item xs={showVerifyEmailButton || showInviteEmailButton ? 8 : 12} style={{ marginTop: 8 }}>
              {helpText ? (
                <Grid container direction="row" justify="space-between" alignItems="center" spacing={1}>
                  <Grid item xs>
                    {emailField}
                  </Grid>

                  <Grid item xs={1}>
                    <Tooltip arrow placement="top" title={helpText}>
                      <HelpIcon htmlColor={Colors.blue.main} />
                    </Tooltip>
                  </Grid>
                </Grid>
              ) : (
                emailField
              )}

              {user && user.email_verified && !isEmailFieldChanged && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="primary">
                    Email verified at: {moment(user.email_verified).format('MM/DD/YYYY h:mm A')}
                  </Typography>
                </Grid>
              )}

              {user && !user.email_verified && (
                <Typography variant="body2" color="primary">
                  Not verified
                </Typography>
              )}
            </Grid>

            {showVerifyEmailButton && (
              <Grid item xs={4}>
                <ButtonWithLoading
                  fullWidth
                  isLoading={disableReSendEmailButtonState === RESEND_EMAIL_BUTTON_STATE.inflight}
                  disabled={
                    disableReSendEmailButtonState === RESEND_EMAIL_BUTTON_STATE.disabled ||
                    (user.email_verified && user.email === values.primary_contact_email) ||
                    fetchingUsers ||
                    isAutocompleteOpen
                  }
                  onClick={handleResendVerificationEmail}
                  variant="contained"
                  color="primary"
                  className={classNames(classes.button, classes.buttonRight)}
                  data-testid="profile-verify-phone-button"
                >
                  Re-send email
                </ButtonWithLoading>
              </Grid>
            )}

            {showInviteEmailButton && (
              <Grid item xs={4}>
                <ButtonWithLoading
                  startIcon={<MailIcon fontSize="small" />}
                  fullWidth
                  isLoading={disableReSendEmailButtonState === RESEND_EMAIL_BUTTON_STATE.inflight}
                  disabled={
                    disableReSendEmailButtonState === RESEND_EMAIL_BUTTON_STATE.disabled ||
                    user.completed_registration !== null ||
                    fetchingUsers ||
                    isAutocompleteOpen
                  }
                  onClick={handleSendInvitation}
                  variant="contained"
                  color="primary"
                  className={classNames(classes.button, classes.buttonRight)}
                  data-testid="profile-invite-email-button"
                >
                  {user.invitation_sent && 'Re-send invite'}

                  {!user.invitation_sent && 'Send invite'}
                </ButtonWithLoading>
                {user.invitation_sent && (
                  <Typography align="center" variant="body2" color="primary">
                    {moment(user.invitation_sent).format('MM/DD/YYYY h:mm A')}
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
        </Box>

        {isEmailFieldChanged && showPasswordFieldsOnEmailChange && (
          <>
            <Box mt={1}>
              <Field
                InputProps={{
                  autoComplete: 'chrome-off',
                }}
                fullWidth
                name="primary_contact_password"
                component={FRUSTextFieldWrapper}
                type="password"
                label="Password"
              />
            </Box>

            <Box mt={1}>
              <Field
                InputProps={{
                  autoComplete: 'chrome-off',
                }}
                fullWidth
                name="primary_contact_password_confirmation"
                component={FRUSTextFieldWrapper}
                type="password"
                label="Password Confirmation"
              />
            </Box>
          </>
        )}

        <Box mt={1}>
          <Field
            InputProps={{
              disabled: !!values.primary_contact_id || fetchingUsers || isAutocompleteOpen,
              autoComplete: 'chrome-off',
            }}
            fullWidth
            name="primary_contact_first_name"
            component={FRUSTextFieldWrapper}
            type="text"
            label={firstNameLabel}
          />
        </Box>

        <Box mt={1}>
          <Field
            InputProps={{
              disabled: !!values.primary_contact_id || fetchingUsers || isAutocompleteOpen,
              autoComplete: 'chrome-off',
            }}
            fullWidth
            name="primary_contact_last_name"
            component={FRUSTextFieldWrapper}
            type="text"
            label={lastNameLabel}
          />
        </Box>

        {showTitleField && (
          <Box mt={1}>
            <Field size="small" name="title" fullWidth component={FRUSTextFieldWrapper} type="text" label="Title" />
          </Box>
        )}

        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={showVerifyPhoneNumberButton ? 9 : 12} style={{ marginTop: 8 }}>
            <Field
              fullWidth
              InputProps={{
                disabled: !!values.primary_contact_id || fetchingUsers || isAutocompleteOpen,
              }}
              name="primary_contact_phone"
              type="text"
              label={phoneLabel}
              render={({ input, meta }) => {
                const showError = isFormFieldInvalid(meta);

                return (
                  <InputMask
                    mask="999-999-9999"
                    value={input.value}
                    id="phone-input"
                    required
                    onChange={event => {
                      input.onChange(event);
                      this.setState({
                        isPhoneFieldChanged: user && getPhoneNumberFromMask(event.target.value) !== get(user, 'phone'),
                      });
                      meta.data.error = null;
                    }}
                    onBlur={input.onBlur}
                  >
                    <TextFieldMaterial
                      name={input.name}
                      fullWidth
                      InputProps={{
                        disabled: !!values.primary_contact_id || fetchingUsers || isAutocompleteOpen,
                      }}
                      label={phoneLabel}
                      margin="normal"
                      className={classNames({
                        [classes.invalidField]: showError,
                      })}
                      style={{ marginTop: 0, marginBottom: 0 }}
                      error={showError}
                      helperText={showError ? getFormFieldError(meta) : ''}
                    />
                  </InputMask>
                );
              }}
            />
          </Grid>

          {showVerifyPhoneNumberButton && (
            <Grid item xs={3}>
              <Button
                disabled={
                  (user.phone_verified && user.phone === values.primary_contact_phone) ||
                  !isPhoneMaskValid(values.primary_contact_phone) ||
                  fetchingUsers ||
                  isAutocompleteOpen
                }
                onClick={() => openVerificationNumber(values.primary_contact_phone)}
                variant="contained"
                color="primary"
                className={classNames(classes.button, classes.buttonRight)}
                data-testid="profile-verify-phone-button"
              >
                Verify
              </Button>
            </Grid>
          )}

          {user && user.phone_verified && !isPhoneFieldChanged && (
            <Typography variant="body2" color="primary">
              Phone verified at: {moment(user.phone_verified).format('MM/DD/YYYY h:mm A')}
            </Typography>
          )}

          {user && !user.phone_verified && (
            <Typography variant="body2" color="primary">
              Not verified
            </Typography>
          )}
        </Grid>

        {showZipCodeField && (
          <Box my={1}>
            <InputLabel shrink id="react-select-multiple">
              {zipCodeLabel}
            </InputLabel>

            <Field
              name="primary_contact_zip_code"
              render={({ input, meta }) => (
                <SelectMultiple
                  meta={meta}
                  isDisabled={!!values.primary_contact_id || fetchingUsers || isAutocompleteOpen}
                  inputId="zip-code-select"
                  value={selectedZipCode}
                  loadingMessage={this.zipCodeLoadingMessage}
                  loadOptions={this.loadZipCodes}
                  onChange={async value => {
                    await this.switchSite(value);

                    input.onChange(value !== null ? value.value : null);

                    return this.handleChangeZipCode(value);
                  }}
                  onMenuOpen={() =>
                    this.setState({
                      zipCodePlaceholder: 'Type the 3 first digits of the zip code',
                    })
                  }
                  onMenuClose={() =>
                    this.setState({
                      zipCodePlaceholder: 'Select Zip Code',
                    })
                  }
                  selectPlaceholder={zipCodePlaceholder}
                  isMulti={false}
                />
              )}
            />
          </Box>
        )}

        {showCommunicationCheckboxes && (
          <>
            <Box>
              <FormControlLabel
                control={
                  <Field
                    disabled={!!values.primary_contact_id || fetchingUsers || isAutocompleteOpen}
                    name="comm_email"
                    type="checkbox"
                    color="primary"
                    component={Checkbox}
                  />
                }
                label="Can receive e-mails"
                labelPlacement="end"
              />
            </Box>

            <Box>
              <FormControlLabel
                control={
                  <Field
                    disabled={!!values.primary_contact_id || fetchingUsers || isAutocompleteOpen}
                    name="comm_sms"
                    type="checkbox"
                    color="primary"
                    component={Checkbox}
                  />
                }
                label="Can receive sms"
                labelPlacement="end"
              />
            </Box>
          </>
        )}
      </>
    );
  }
}

SelectPrimaryContactUser.defaultProps = {
  firstNameLabel: 'Primary Contact First Name *',
  lastNameLabel: 'Primary Contact Last Name *',
  emailLabel: 'Primary Contact Email *',
  phoneLabel: 'Primary Contact Phone#',
  zipCodeLabel: 'Zip Code',
  showZipCodeField: true,
  showUserSelector: false,
  showPasswordFieldsOnEmailChange: false,
  onUserSelect: user => {},
  onUserEmailChange: (event, value, reason) => {},
};

SelectPrimaryContactUser.propTypes = {
  formApi: PropTypes.object,
  user: PropTypes.object,
  values: PropTypes.object.isRequired,
  onUserSelect: PropTypes.func,
  isResendEmailButtonLoading: PropTypes.bool,
};

const styles = theme => ({
  invalidField: {
    background: '#f443360f',
    borderRadius: 2,
  },
  paper: {
    backgroundColor: theme.palette.grey[100],
  },
  tab: {
    fontWeight: 600,
  },
  box: {
    width: '100%',
  },
  link: {
    cursor: 'pointer',
  },
  buttonRight: {
    float: 'right',
  },
  button: {
    marginTop: theme.spacing(1),
  },
});

export default withStyles(styles)(SelectPrimaryContactUser);
