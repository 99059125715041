import React, { Component } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Breadcrumbs, Button, Grid, Typography, withWidth } from '@material-ui/core';
import { Add, Home as HomeIcon } from '@material-ui/icons';
import * as sitesActions from '../actions/sites';
import { switchCurrentSite } from '../actions/app';
import SitesTable from '../components/SitesTable';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import routes from '../routes';
import * as uiPersistedActions from '../actions/uiPersisted';
import { StyledRescuerBreadcrumb } from '../pages/rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import { resetCurrentTablesState } from '../actions/ui';

class SitesListView extends Component {
  componentDidMount() {
    const { fetchSites } = this.props;

    fetchSites();
  }

  handleSdClick = id => {
    const { history } = this.props;
    return history.push(generatePath(routes.userEdit, { id }));
  };

  renderCustomToolbar = () => {
    const { history } = this.props;

    if (
      authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
        Roles.Admin,
        Roles.NationalSiteDirector,
      ])
    ) {
      return (
        <Button
          startIcon={<Add fontSize="small" />}
          type="button"
          variant="contained"
          color="primary"
          size="small"
          onClick={() => history.push(routes.sitesAdd)}
          data-testid="add-new-site-button"
        >
          Add New Site
        </Button>
      );
    }

    return null;
  };

  render() {
    const { switchSite, resetDefaultDashboard, resetTablesState, isMobileView } = this.props;
    let { sites } = this.props;

    if (!sites || sites.length === 0) {
      return <div>no sites</div>;
    }

    // if currently logged in user is not admin or nsd, we need to filter out the sites list
    if (
      !authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
        Roles.Admin,
        Roles.NationalSiteDirector,
      ])
    ) {
      const availableSitesIds = authService.getCurrentlyLoggedInOrImpersonatingUserAvailableSitesIds();

      sites = sites.filter(site => availableSitesIds.indexOf(site.id) !== -1);

      // if SD/SC on mobile, filter sites to the ones in which user has SD/SC
      if (
        isMobileView
        && authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
          Roles.SiteDirector,
          Roles.SiteCoordinator,
        ])
      ) {
        const activeUser = authService.getCurrentlyLoggedInOrImpersonatingUser();
        const sitesWithSDSCAccess = authService.getUserSitesByRoles(
          activeUser.role_assignments,
          [
            Roles.SiteDirector,
            Roles.SiteCoordinator,
          ]
        );

        sites = sites.filter(site => sitesWithSDSCAccess.includes(site.id));
      }
    }

    return (
      <>
        {isMobileView ? (
          <Breadcrumbs aria-label="Breadcrumbs">
            <StyledRescuerBreadcrumb
              component={Link}
              to={routes.mobileDashboard}
              label="Home"
              icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
            />
          </Breadcrumbs>
        ) : (
          <Breadcrumbs aria-label="Breadcrumbs">
            <Typography color="textPrimary">Sites</Typography>
          </Breadcrumbs>
      )}

        <Grid container>
          <Grid item xs={12}>
            <SitesTable
              tableId="#sites/sites"
              sites={sites}
              customToolbar={this.renderCustomToolbar}
              onRowClick={site => {
                switchSite(site);
                resetDefaultDashboard();
                resetTablesState();
              }}
              onSDClick={this.handleSdClick}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({ entities: { sites } }, { width }) => ({
  sites: Object.values(sites.byId),
  isMobileView: width === 'xs',
});

const mapDispatchToProps = dispatch => ({
  fetchSites: () => dispatch(sitesActions.fetchSitesIfNeeded()),
  switchSite: site => dispatch(switchCurrentSite(site)),
  resetDefaultDashboard: () => dispatch(uiPersistedActions.resetDefaultDashboard()),
  resetTablesState: () => dispatch(resetCurrentTablesState()),
});

export default compose(
  withWidth({ initialWidth: 'lg' }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SitesListView);
