import React, { useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import Bluebird from 'bluebird';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { confirmAlert } from 'react-confirm-alert';
import BaseMUIDataTable from '../../../../components/BaseMUIDataTable/BaseMUIDataTable';
import TableActionsButtonsWrapper from '../../../../components/TableActionsButtonsWrapper';
import TableActionButton from '../../../../components/TableActionButton';
import routes from '../../../../routes';
import { getMuiTableDataIndex } from '../../../../helpers/getters';
import { formatDate, formatTime } from '../../../../helpers/formatters';
import { sortByDateAndTime, sortByTime } from '../../../../helpers/sorters';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import useHasActiveFeatureFlag from '../../../../hooks/useFeatureFlags';
import { FF_COMMUNITY_AND_ENGAGEMENT } from '../../../../components/FeatureFlagWrapper';

const EventsTable = ({ eventsList, tableId, customToolbar = () => null, onCancelEventButtonClick, ...props }) => {
  const [isCanceling, setIsCanceling] = useState(false);
  const hasCommunityEngagementFF = useHasActiveFeatureFlag(FF_COMMUNITY_AND_ENGAGEMENT);

  const frequencyCustomBodyRender = (value) => {
    switch (value) {
      case 0:
        return 'one-time';
      case 1:
        return 'weekly';
      case 2:
        return 'monthly';
      case 3:
        return 'every other week';
      default:
        return 'unknown';
    }
  };

  return (
    <BaseMUIDataTable
      {...props}
      isLoading={isCanceling}
      tableId={tableId}
      title=""
      data={eventsList}
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            filter: false,
            display: false,
          },
        },
        {
          name: 'name',
          label: 'Name',
          options: {
            filter: true,
            display: true,
          },
        },
        {
          name: 'frequency',
          label: 'Frequency',
          options: {
            customBodyRender: value => frequencyCustomBodyRender(value),
            filter: true,
            display: true,
          },
        },
        {
          name: 'display_days',
          label: 'Day(s)',
          options: {
            customBodyRender: value => value || '-',
          },
        },
        {
          name: 'start_date',
          label: 'Start Date',
          options: {
            sortCompare: order => (row1, row2) => sortByDateAndTime(order, row1.data?.value, row2.data?.value),
            customBodyRender: value => value ? formatDate(value.value) : '-',
          },
        },
        {
          name: 'end_date',
          label: 'End Date',
          options: {
            sortCompare: order => (row1, row2) => {
              if (row1.data?.value && row2.data?.value) {
                return sortByDateAndTime(order, row1.data?.value, row2.data?.value);
              }

              return (!!row1.data?.value - !!row2.data?.value) * (order === 'asc' ? -1 : 1);
            },
            customBodyRender: value => value ? formatDate(value.value) : '-',
          },
        },
        {
          name: 'begin',
          label: 'Start time',
          options: {
            sortCompare: order => (row1, row2) => sortByTime(order, row1.data.value, row2.data.value),
            customBodyRender: value => formatTime(value.value),
            filter: true,
            display: true,
          },
        },
        {
          name: 'end',
          label: 'End time',
          options: {
            sortCompare: order => (row1, row2) => sortByTime(order, row1.data.value, row2.data.value),
            customBodyRender: value => formatTime(value.value),
            filter: true,
            display: true,
          },
        },
        {
          name: 'cancelled_at',
          label: 'Canceled',
          options: {
            customBodyRender: (value) => (value ? 'Canceled' : '-'),
            filter: true,
            display: true,
          },
        },
        {
          name: 'actions',
          label: 'Actions',
          options: {
            download: false,
            filter: false,
            sort: false,
            display: true,
            customBodyRender: (value, tableMeta) => {
              const eventData = eventsList[getMuiTableDataIndex(tableMeta)];

              return (
                <TableActionsButtonsWrapper>
                  <TableActionButton
                    title="Edit Event"
                    buttonProps={{
                      component: Link,
                      to: generatePath(routes.eventEdit, { eventId: eventData.id }),
                    }}
                  />
                  {hasCommunityEngagementFF && (
                    <TableActionButton
                      disabled={eventData ? !!eventData.cancelled_at : false}
                      title="Cancel Event"
                      icon={DeleteIcon}
                      onClick={() => {
                        confirmAlert({
                          title: 'Cancel this event?',
                          message: 'Are you sure you want to cancel this event?',
                          buttons: [
                            {
                              label: 'No',
                              color: 'primary',
                            },
                            {
                              label: 'Yes',
                              color: 'primary',
                              onClick: () => {
                                setIsCanceling(true);

                                return Bluebird
                                  .try(() => onCancelEventButtonClick(eventData))
                                  .then(() => setIsCanceling(false)
                                );
                              },
                            },
                          ],
                          customUI: ({ title, message, onClose, buttons }) => (
                            <ConfirmationDialog
                              buttons={buttons}
                              closeDialog={onClose}
                              title={title}
                              message={message}
                            />
                          ),
                        });
                      }}
                    />
                  )}
                </TableActionsButtonsWrapper>
              );
            },
          },
        },
      ]}
      options={{
        customToolbar: customToolbar,
        responsive: 'simple',
        selectableRows: 'none',
      }}
    />
  );
};

export default EventsTable;
