import React from 'react';
import { Dialog, AppBar, Typography, Toolbar, Button, List, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { assign, get, uniq } from 'lodash';
import DistanceSliderFilter from './filters/DistanceSliderFilter';
import DaysSelectorFilter from './filters/DaysSelectorFilter';
import DonorReceiverFilter from './filters/DonorReceiverFilter';
import SiteSelectorFilter from './filters/SiteSelectorFilter';
import RescueAvailabilityFilter from './filters/RescueAvailabilityFilter';
import { initialRescuerScheduleFilters } from '../../reducers/ui';
import { RESCUER_SCHEDULE_FILTER } from '../../pages/rescuerDashboard/rescuerDashboardMobile/Schedule/ScheduleMobile';
import useUIFilter from '../../hooks/useUIFilter';
import { getUniqueDonors, getUniqueReceiver } from '../../helpers/RescuesHelper';
import { getUserSitesByRoles } from '../../services/auth';
import { Roles } from '../../models/roles';
import useActiveUser from '../../hooks/useActiveUser';
import RescueSizeFilter from './filters/RescueSizeFilter';
import CitySelectorFilter from './filters/CitySelectorFilter';
import DonorLinkSelectorFilter from './filters/DonorLinkSelectorFilter';
import useHasActiveFeatureFlag from '../../hooks/useFeatureFlags';
import { FF_DONOR_LINK } from '../FeatureFlagWrapper';
import TimeSelectorFilter from './filters/TimeSelectorFilter';

const useStyles = makeStyles(theme => ({
  searchWrapper: {
    marginBottom: 15,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    padding: '10px 10px 10px 10px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  searchContainer: {
    backgroundColor: '#fff',
    display: 'flex',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flex: 1,
  },
  searchIconButton: {
    padding: 10,
  },
  daysList: {
    float: 'left',
    listStyle: 'none',
    paddingLeft: 5,
    padding: 0,
    margin: 0,
    '& li': {
      display: 'inline-block',
    },
  },
  selectedDay: {
    color: theme.palette.primary.main,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  removeFilter: {
    cursor: 'pointer',
    color: theme.palette.grey[400],
  },
  filter: {
    textAlign: 'left',
    padding: '6px 16px',
    fontSize: '0.875rem',
    fontWeight: 500,
    userSelect: 'none',
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.45)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    lineHeight: 1.75,
    fontFamily: 'Roboto, Helvetica, Arial',
    borderRadius: 4,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },
}));

const RescuerScheduleFiltersDialog = ({ isOpen = false, onDialogClose, filteredRows = [], availableRescues }) => {
  const classes = useStyles();
  const activeUser = useActiveUser();
  const { setUIFilter, getUIFilter } = useUIFilter();
  const rescuerScheduleFilters = assign(initialRescuerScheduleFilters, getUIFilter(RESCUER_SCHEDULE_FILTER));
  const sitesWithRescuerRoles = getUserSitesByRoles(activeUser.role_assignments, Roles.Rescuer);

  const uniqueDonors = getUniqueDonors(availableRescues);
  const uniqueReceivers = getUniqueReceiver(availableRescues);
  const donorReceiverList = [...uniqueDonors, ...uniqueReceivers];
  const pickupCities = uniq(availableRescues.map(ar => `${ar.pickup_city}, ${ar.pickup_st}`));
  const dropoffCities = uniq(availableRescues.map(ar => `${ar.dropoff_city}, ${ar.dropoff_st}`));
  const hasActiveDonorLinkFF = useHasActiveFeatureFlag(FF_DONOR_LINK);

  const updateScheduleFilter = (key, value) => {
    setUIFilter(RESCUER_SCHEDULE_FILTER, {
      ...rescuerScheduleFilters,
      [key]: { value: value },
    });
  };

  return (
    <Dialog fullScreen fullWidth maxWidth="sm" open={isOpen} onClose={onDialogClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {`Filters (${filteredRows.length} results)`}
          </Typography>
          <Button autoFocus color="inherit" onClick={onDialogClose}>
            save
          </Button>
        </Toolbar>
      </AppBar>

      <List>
        <DistanceSliderFilter
          value={rescuerScheduleFilters.distance.value}
          handleSliderChange={value => updateScheduleFilter('distance', value)}
        />

        <Divider />

        <CitySelectorFilter
          label="Pickup City"
          cities={pickupCities}
          value={get(rescuerScheduleFilters, 'pickupCities.value', [])}
          handleChange={value => updateScheduleFilter('pickupCities', value)}
        />

        <Divider />

        <CitySelectorFilter
          label="Dropoff City"
          cities={dropoffCities}
          value={get(rescuerScheduleFilters, 'dropoffCities.value', [])}
          handleChange={value => updateScheduleFilter('dropoffCities', value)}
        />

        <Divider />

        <DaysSelectorFilter
          value={rescuerScheduleFilters.days.value}
          handleDaysChange={value => updateScheduleFilter('days', value)}
        />
        <Divider />

        <TimeSelectorFilter
          afterValue={get(rescuerScheduleFilters, 'after.value', false)}
          beforeValue={get(rescuerScheduleFilters, 'before.value', false)}
          handleAfterChange={(value) => updateScheduleFilter('after', value)}
          handleBeforeChange={(value) => updateScheduleFilter('before', value)}
        />
        <Divider />
        <DonorReceiverFilter
          options={donorReceiverList}
          value={rescuerScheduleFilters.donorReceiverList.value}
          handleDonorReceiverChange={value => updateScheduleFilter('donorReceiverList', value)}
        />
        <Divider />
        <RescueSizeFilter
          value={rescuerScheduleFilters.rescueSizes.value}
          handleSizesChange={value => updateScheduleFilter('rescueSizes', value)}
        />
        <Divider />
        {sitesWithRescuerRoles.length > 1 && (
          <>
            <SiteSelectorFilter
              sitesWithRescuerRoles={sitesWithRescuerRoles}
              value={get(rescuerScheduleFilters, 'siteIds.value', [])}
              handleSiteIdsChange={value => updateScheduleFilter('siteIds', value)}
            />
            <Divider />
          </>
        )}

        {hasActiveDonorLinkFF && (
          <>
            <DonorLinkSelectorFilter
              value={get(rescuerScheduleFilters, 'donorLink.value', false)}
              handleFilterChange={value => updateScheduleFilter('donorLink', value)}
            />
            <Divider />
          </>
        )}
        <RescueAvailabilityFilter
          value={get(rescuerScheduleFilters, 'showOnlyAvailableRescues.value', false)}
          handleAvailabilityChange={value => updateScheduleFilter('showOnlyAvailableRescues', value)}
        />
      </List>
    </Dialog>
  );
};

export default RescuerScheduleFiltersDialog;
