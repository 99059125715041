import React  from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link,
} from '@material-ui/core';
import routes from '../routes';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import TableActionButton from './TableActionButton';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import { getRescueAdopter, getRescueRescuer, getRescueReleaser, isRescueClaimed } from '../helpers/RescuesHelper';
import { formatTime } from '../helpers/formatters';
import { getMuiTableDataIndex } from '../helpers/getters';
import RescuesTableStatusBox, { useRescuesTableRowColorStyles } from './Common/RescuesTableStatusBox';

const useStyles = makeStyles(() => ({
  toolPalette: {
    height: 'auto',
    padding: '2px 2px 2px 2px',
  },
  toolPaletteCell: {
    padding: '2px 2px 2px 2px',
    border: 'none',
    display: 'flex',
  },
}));

const SiteCoordinatorUnclaimedRescuesTable = ({ tableId, rescues, isLoading }) => {
  const history = useHistory();
  const classes = useStyles();
  const tableStyles = useRescuesTableRowColorStyles();

  const onEdit = rescue => history.push(generatePath(routes.rescue_edit, { rescueId: rescue.id }));

  const renderUserProfileLink = user => {
    if (!user) {
      return '-';
    }

    return (
      <Link
        onClick={() => history.push(generatePath(routes.userEditNew, { id: user.id }))}
        style={{ cursor: 'pointer' }}
      >
        {user.name}
      </Link>
    );
  };

  return (
    <MuiThemeProvider theme={theme => createMuiTheme({
      ...theme,
      overrides: {
        ...theme.overrides,
        MUIDataTableHeadCell: {
          root: {
            ...theme.overrides.MUIDataTableHeadCell.root,
            '&:last-child': {
              width: 130,
            },
          },
        },
      },
    })}>
      <BaseMUIDataTable
        isLoading={isLoading}
        tableId={tableId}
        data={rescues}
        columns={[
          {
            name: 'id',
            label: 'ID',
            options: {
              filter: false,
              sort: false,
              display: false,
            },
          },
          {
            name: 'date',
            label: 'Date',
            options: {
              sort: true,
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                return (
                  <>
                    <span>
                      {moment(value, 'YYYYMMDD').format('MM/DD/YYYY')}
                    </span>

                    <RescuesTableStatusBox rescue={rescue} />
                  </>
                );
              },
            },
          },
          {
            name: 'day',
            label: 'Day',
            options: {
              customBodyRender: (value, tableMeta) =>
                moment(rescues[getMuiTableDataIndex(tableMeta)].date, 'YYYYMMDD').format('dddd'),
            },
          },
          {
            name: 'pickup_begin',
            label: 'Pickup Begin',
            options: {
              filter: false,
              customBodyRender: value => formatTime(value),
            },
          },
          {
            name: 'pickup_end',
            label: 'Pickup End',
            options: {
              filter: false,
              customBodyRender: value => formatTime(value),
            },
          },
          {
            name: 'location',
            label: 'Food Donor',
            options: {
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue) {
                  return false;
                }

                return (
                  <Link
                    onClick={() => history.push(
                      generatePath(
                        routes.foodDonor,
                        {
                          foodDonorId: rescue.dropoff_location_id,
                        }
                      )
                    )}
                    style={{ cursor: 'pointer' }}
                  >
                    {value || ''}
                  </Link>
                );
              },
            },
          },
          {
            name: 'receiver',
            label: 'Receiving Agency',
            options: {
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue) {
                  return false;
                }

                return (
                  <Link
                    onClick={() => history.push(generatePath(routes.receiver, { id: rescue.receiver_id }))}
                    style={{ cursor: 'pointer' }}
                  >
                    {value || ''}
                  </Link>
                );
              },
            },
          },
          {
            name: '_position',
            label: 'Position',
            options: {
              filter: false,
            },
          },
          {
            name: 'slug',
            label: 'Description',
            options: {
              filter: false,
              customBodyRender: value => value || '-',
            },
          },
          {
            name: 'notes',
            label: 'Pickup Notes',
            options: {
              customBodyRender: value => value || '-',
              filter: false,
              sort: false,
              display: false,
            },
          },
          {
            name: 'adopter',
            label: 'Adopter',
            options: {
              customFilterListOptions: {
                render: value => `Adopted by: ${value === '-' ? 'unadopted' : value}`,
              },
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue) {
                  return '-';
                }

                return renderUserProfileLink(getRescueAdopter(rescue));
              },
            },
          },
          {
            name: 'rescuer',
            label: 'Rescuer',
            options: {
              filter: true,
              customFilterListOptions: {
                render: value => `Rescued by: ${value}`,
              },
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue) {
                  return '-';
                }

                return renderUserProfileLink(getRescueRescuer(rescue));
              },
            },
          },
          {
            name: 'claimer',
            label: 'Claimer',
            options: {
              filter: true,
              customFilterListOptions: {
                render: value => `Claimed by: ${value === '-' ? 'unclaimed' : value}`,
              },
              customBodyRender: value => renderUserProfileLink(value),
            },
          },
          {
            name: 'releaser',
            label: 'Released By',
            options: {
              empty: true,
              filter: false,
              searchable: false,
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue || !rescue.releaser) {
                  return '-';
                }

                return renderUserProfileLink(getRescueReleaser(rescue));
              },
            },
          },
          {
            name: 'actions',
            label: 'Actions',
            options: {
              download: false,
              Empty: true,
              filter: false,
              searchable: false,
              viewColumns: false,
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue) {
                  return false;
                }

                return (
                  <Table className={classes.toolPalette}>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.toolPaletteCell}>
                          <TableActionsButtonsWrapper>
                            <TableActionButton title="Edit Rescue" onClick={() => onEdit(rescue)} />
                          </TableActionsButtonsWrapper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                );
              },
            },
          },
        ]}
        options={{
          sortOrder: {
            name: 'date',
            direction: 'asc',
          },
          setRowProps: (row, dataIndex) => {
            const rescue = rescues[dataIndex];

            let colorClass = classes.unclaimedRescueRow;

            if (rescue.cancelled_by_id) {
              colorClass = tableStyles.cancelledRescueRow;
            } else if (rescue.closed_by_id) {
              colorClass = tableStyles.closedRescueRow;
            } else if (isRescueClaimed(rescue)) {
              colorClass = tableStyles.claimedRescueRow;
            } else if (!isRescueClaimed(rescue)) {
              colorClass = tableStyles.unclaimedRescueRow;
            }

            return {
              className: colorClass,
            };
          },
          rowsPerPage: 15,
          selectableRows: 'none',
          responsive: 'simple',
        }}
      />
    </MuiThemeProvider>
  );
};

export default SiteCoordinatorUnclaimedRescuesTable;
