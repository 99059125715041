import React from 'react';
import Bluebird from 'bluebird';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import ButtonWithLoading from './ButtonWithLoading';
import useNotificationService from '../hooks/useNotificationService';
import { updateReceiverNeedProfile } from '../actions/receivers';

const useStyles = makeStyles((theme) => ({
  container: {
    columns: '3 auto',
    maxWidth: 700,
    marginTop: theme.spacing(2),
  },
  selected: {
    fontWeight: 'bold',
  },
}));

const ReceiverNeedsProfile = ({ receiverId, receiverLocationId, categories, initialValues = {} }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addSuccessNotification, addErrorNotification } = useNotificationService();

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { dirty, isSubmitting, dirtyFields },
  } = useForm({
    defaultValues: initialValues,
  });

  const onSubmit = (values) => {
    const data = Object.entries(values)
      .map(([key, value]) => ({ rating: value, food_description_id: Number(key) }))
      .filter((value) => value.rating !== undefined);

    Bluebird.try(() => dispatch(updateReceiverNeedProfile(receiverId, receiverLocationId, data)))
      .then(() => {
        reset(getValues(), { isSubmitted: true });
        addSuccessNotification('Needs Profile updated.');
      })
      .catch((err) => addErrorNotification(err, err.message));
  };

  return (
    <Box p={2} bgcolor="background.paper" boxShadow={25}>
      <Typography variant="h5">Needs Profile</Typography>
      <Typography variant="caption">
        Rank categories according to need. This will be used to route food more effectively,
        but is not a guarantee that you will receive the food ranked highest.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.container}>
          {categories.map((category) => (
            <Box mb={3} key={category.id}>
              <Typography
                className={dirtyFields.has(category.id.toString()) ? classes.selected : ''}
                component="legend"
              >
                {category.name}
              </Typography>
              <Controller
                name={category.id.toString()}
                control={control}
                as={(props) => (
                  <Rating
                    name={category.name}
                    value={props.value}
                    onChange={(e, newValue) => {
                      props.onChange(newValue !== null ? newValue : 0)
                    }}
                  />
                )}
              />
            </Box>
          ))}
        </Box>
        <ButtonWithLoading disabled={!dirty} type="submit" variant="contained" color="primary" isLoading={isSubmitting}>
          Update Needs
        </ButtonWithLoading>
      </form>
    </Box>
  );
};

export default ReceiverNeedsProfile;
