import React, { useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Bluebird from 'bluebird';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getUserFullName, getUserFullNameWithEmail, isUserAbleToClaimRescue } from '../../../../helpers/user';
import { fetchSiteRescueIfNeeded } from '../../../../actions/sites';
import * as sitesActions from '../../../../actions/sites';
import * as rescuesActions from '../../../../actions/rescues';
import * as authService from '../../../../services/auth';
import { Roles } from '../../../../models/roles';
import snackbarHelper from '../../../../helpers/snackbarHelper';
import routes from '../../../../routes';
import useActiveSite from '../../../../hooks/useActiveSite';
import PastRescueView from '../PastRescueForm/PastRescueView';
import RescueDetailsView from '../RescueDetailsView';
import RescueEditAdminForm from './RescueEditAdminForm';
import errorMessages from '../../../../assets/errorMessages';
import { GridRow, RowContent, RowTitle } from '../FormGrid';
import { formatTime } from '../../../../helpers/formatters';
import { getRescueClaimer } from '../../../../helpers/RescuesHelper';

const useStyles = makeStyles(() => ({
  breakWhiteSpaces: {
    whiteSpace: 'pre-wrap',
  },
}));

const RescueEditAdminView = ({ isMobileView, user, rescueId, location: { state } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const site = useActiveSite();
  const rescuers = useSelector(state => state.entities.sites.rescuers);
  const receivers = useSelector(state => state.entities.sites.receivers);
  const rescues = useSelector(state => state.entities.sites.rescues);
  const isScheduleView = useRouteMatch(routes.rescuesScheduleEdit);

  // useMemo prevents re-render when modifiedById change. Basically it mimics original/draft rescue
  const rescue = useMemo(() => rescues.modifiedById[rescueId] || rescues.byId[rescueId], [rescues.byId[rescueId]]);
  const rescuersList = (rescuers.bySiteId[site.id] || []).reduce((acc, curr) => {
    if (isUserAbleToClaimRescue(curr)) {
      acc.push({
        id: curr.id,
        name: getUserFullNameWithEmail(curr),
        value: getUserFullName(curr),
        phone: curr?.phone,
        phone_ext: curr?.phone_ext,
        email: curr?.email,
      });
    }
    return acc;
  }, []);
  const receivingAgenciesList = (receivers.bySiteId[site.id] || []).reduce((acc, curr) => {
    if (curr.active) {
      acc.push({
        id: curr.id,
        name: curr.name,
        value: curr.name,
      });
    }

    return acc;
  }, []);

  const fetchRescue = () => Bluebird
    .try(() => dispatch(fetchSiteRescueIfNeeded(site.id, rescueId)))
    .catch(err => {
      history.push(routes.index);
      switch (err.code) {
        case errorMessages.ERR_RESCUE_NOT_FOUND.code:
          snackbarHelper.error(errorMessages.ERR_RESCUE_NOT_FOUND.message);
          break;
        default:
          snackbarHelper.error(err.message);
          break;
      }
    });

  const getRedirectPath = () => {
    if (state && state.prevPath) {
      return state.prevPath;
    }

    if (isScheduleView) {
      return routes.rescuesSchedule;
    }

    return routes.rescues;
  };

  const updateRescue = data =>
    Bluebird.try(() => dispatch(rescuesActions.updateRescue(rescue.id, data))).then(() => {
      snackbarHelper.success('Rescue updated successfully!');

      return history.push(getRedirectPath(), { ...state });
    });

  useEffect(() => {
    fetchRescue();
    dispatch(sitesActions.fetchSiteRescuersIfNeeded(site.id));
    dispatch(sitesActions.fetchSiteReceiversIfNeeded(site.id));
  }, [dispatch]);

  if (!rescue || rescues.inflight) {
    return 'loading....';
  }

  if (
    !authService.hasAnyRoleInSite(user, rescue.site_id, [
      Roles.NationalSiteDirector,
      Roles.Admin,
      Roles.SiteCoordinator,
      Roles.SiteDirector,
    ])
  ) {
    return 'no access';
  }

  // let's check if rescue is past
  if (rescue.is_past) {
    // check if past rescue feature is enabled, if so, let's show edit past rescue screen
    // remove !isMobileView once mobile view is finished
    if (!isMobileView) {
      return <PastRescueView isMobileView={isMobileView} rescue={rescue} />;
    }

    // past rescue feature is disabled, show read-only version of the page, without ability to edit "past" rescue by SD
    const claimer = getRescueClaimer(rescue);

    return (
      <RescueDetailsView rescue={rescue} data-testid="rescue-admin-details">
        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
          <GridRow>
            <RowTitle>
              Pickup Window:
            </RowTitle>

            <RowContent>
              <span>
                {formatTime(rescue.pickup_begin)} - {formatTime(rescue.pickup_end)}
              </span>
            </RowContent>
          </GridRow>

          <GridRow>
            <RowTitle>
              Rescuer Notes:
            </RowTitle>

            <RowContent>
              <span className={classes.breakWhiteSpaces}>
                {rescue.rescuer_notes}
              </span>
            </RowContent>
          </GridRow>

          <GridRow>
            <RowTitle>
              Rescuer:
            </RowTitle>

            <RowContent>
              <span>
                {claimer ? claimer.name : '-'}
              </span>
            </RowContent>
          </GridRow>
        </Grid>
      </RescueDetailsView>
    );
  }

  // rescue is not past, let's show an edit (future) rescue page
  return (
    <RescueDetailsView rescue={rescue} data-testid="rescue-admin-details">
      <RescueEditAdminForm
        updateRescue={updateRescue}
        rescuersList={rescuersList}
        receivingAgenciesList={receivingAgenciesList}
        user={user}
        rescue={rescue}
        isMobileView={isMobileView}
      />
    </RescueDetailsView>
  );
};

export default RescueEditAdminView;
