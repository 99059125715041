import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { generatePath, Link, Route, Switch, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Breadcrumbs, Typography, AppBar, Tabs, Tab } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Home as HomeIcon } from '@material-ui/icons';
import * as authService from '../../services/auth';
import * as usersActions from '../../actions/users';
import * as rescuesActions from '../../actions/rescues';
import * as rescuerLocationsActions from '../../actions/rescuerLocations';
import routes from '../../routes';
import ProfileView from './ProfileView';
import RescuerUpcomingRescues from './RescuerUpcomingRescues';
import RescuerRecentRescues from './RescuerRecentRescues';
import { Roles } from '../../models/roles';
import { getRecentRescues, getUpcomingRescuesIncludingToday } from '../../helpers/RescuesHelper';
import useRescuerRescueActions from '../../hooks/useRescuerRescueActions';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';

export const paths = [
  generatePath(routes.profile),
  generatePath(routes.profile, { tab: 'manage' }),
  generatePath(routes.profile, { tab: 'upcoming-rescues' }),
  generatePath(routes.profile, { tab: 'past-rescues' }),
];

const ProfileContainer = () => {
  const history = useHistory();
  const params = useParams();
  const rescues = useSelector(state => state.entities.rescues);
  const rescuerLocationTypes = useSelector(state => state.entities.rescuerLocations.locationTypes.byId);
  const dispatch = useDispatch();
  const user = authService.getCurrentlyLoggedInOrImpersonatingUser();
  const userIdentities = useSelector(state => state.entities.users.identities.byUserId[user.id]);
  const { rescueUnclaim, releaseRescue } = useRescuerRescueActions();

  const [currentTab, setCurrentTab] = useState(params.tab || 'manage');
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.only('xs'));

  const getRescuerRescuesData = (fetchRescuerPastRescues = true) => {
    dispatch(
      rescuesActions.fetchRescuerClaimedAndAdoptedRescuesIfNeeded(user.id, moment().format('YYYYMMDD'), null, false)
    );

    if (fetchRescuerPastRescues) {
      dispatch(rescuesActions.fetchRescuerPastRescuesIfNeeded(user.id, false));
    }
  };

  useEffect(() => {
    dispatch(usersActions.fetchUserIdentitiesIfNeeded(user.id));
    dispatch(rescuerLocationsActions.fetchRescuerLocationTypesIfNeeded());
  }, []);

  useEffect(() => {
    // fetch rescues afterPageLoad only if user has Rescuer role
    if (authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([Roles.Rescuer])) {
      getRescuerRescuesData();
    }
  }, [dispatch]);

  useEffect(() => {
    history.push(generatePath(routes.profile, { tab: currentTab }));
  }, [currentTab]);

  return (
    <div>
      {isMobileView ? (
        <Breadcrumbs aria-label="Breadcrumbs">
          <StyledRescuerBreadcrumb
            component={Link}
            to={routes.mobileDashboard}
            label="Home"
            icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
          />
          <StyledRescuerTextBreadcrumb component={Typography} label="Profile" />
        </Breadcrumbs>
      ) : (
        <Breadcrumbs aria-label="Breadcrumbs">
          <StyledRescuerBreadcrumb
            component={Link}
            to={routes.index}
            label="Home"
            icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
          />
          <Typography color="textPrimary">Profile</Typography>
        </Breadcrumbs>
      )}

      {authService.currentlyLoggedInOrImpersonatingUserHasRoleInCurrentlySelectedSite(Roles.Rescuer) ? (
        <>
          <AppBar position="static">
            <Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)}>
              <Tab label="Profile" value="manage" />
              <Tab label="Upcoming Rescues" value="upcoming-rescues" />
              <Tab label="Past Rescues" value="past-rescues" />
            </Tabs>
          </AppBar>

          <Switch>
            <Route
              path={[paths[0], paths[1]]}
              exact
              render={() => (
                <ProfileView
                  user={user}
                  userIdentities={userIdentities}
                  rescuerLocationTypes={rescuerLocationTypes}
                  upcomingRescues={getUpcomingRescuesIncludingToday(user.id, rescues)}
                />
              )}
            />
            <Route
              path={paths[2]}
              exact
              render={props => (
                <RescuerUpcomingRescues
                  {...props}
                  user={user}
                  handleRescueUnclaim={rescueUnclaim}
                  handleRescueRelease={releaseRescue}
                  refreshData={() => getRescuerRescuesData(false)}
                  upcomingRescues={getUpcomingRescuesIncludingToday(user.id, rescues)}
                  isLoading={rescues.inflight}
                />
              )}
            />
            <Route
              path={paths[3]}
              exact
              render={props => (
                <RescuerRecentRescues
                  {...props}
                  user={user}
                  recentRescues={getRecentRescues(user.id, rescues)}
                  isLoading={rescues.inflight}
                  onCellClick={rescue => history.push(generatePath(routes.rescue, { rescueId: rescue.id }))}
                />
              )}
            />
          </Switch>
        </>
      ) : (
        <ProfileView user={user} rescuerLocationTypes={rescuerLocationTypes} />
      )}
    </div>
  );
};

export default ProfileContainer;
