import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Colors } from '../assets/theme/Colors';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    color: Colors.white,
    cursor: 'default',
    containerType: 'size',
  },
  text: {
    fontSize: '60cqw',
  },
}));

const DonorLinkIcon = ({ size, isActive = true }) => {
  const classes = useStyles();
  return (
    <Box
      bgcolor={isActive ? Colors.donorLink.color : Colors.lightGray}
      className={classes.container}
      width={size}
      height={size}
    >
      <Typography className={classes.text} variant="subtitle2">
        DL
      </Typography>
    </Box>
  );
};

export default DonorLinkIcon;
