import React from 'react';
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { Link, generatePath } from 'react-router-dom';
import { GridRow, RowContent, RowTitle } from '../../../RescueEdit/FormGrid';
import { getRescuePickupLocationFullName } from '../../../../../helpers/RescuesHelper';
import { formatAddress } from '../../../../../helpers/formatters';
import { getUserFullName } from '../../../../../helpers/user';
import routes from '../../../../../routes';

const handleAddressClick = address => window.open(`https://maps.google.com/?q=${address}`, '_blank');

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(({ spacing }) => ({
  foodTypes: {
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  dialogContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    padding: spacing(1),
  },
}));

const RenderTableRow = (row, index) => (
  <StyledTableRow key={index}>
    <TableCell>{row.qty}</TableCell>
    <TableCell>{row.unit?.name === 'Other' ? row.unit_other : row.unit?.name}</TableCell>
    <TableCell>
      {row.food_description?.name === 'Other' ? row.food_description_other : row.food_description?.name}
    </TableCell>
  </StyledTableRow>
);

const DialogDetails = ({ rescuePhotoData }) => {
  const classes = useStyles();
  const { rescue, rescuer, rescue_close } = rescuePhotoData;
  const pickupAddress = rescue
    ? formatAddress({
        address: rescue.food_donor.pickup_location.address,
        city: rescue.food_donor.pickup_location.city,
        st: rescue.food_donor.pickup_location.state,
        zip: rescue.food_donor.pickup_location.zip_code,
      })
    : 'N/A';

  const dropoffAddress = rescue
    ? formatAddress({
        address: rescue.receiver.dropoff_location.address,
        city: rescue.receiver.dropoff_location.city,
        st: rescue.receiver.dropoff_location.state,
        zip: rescue.receiver.dropoff_location.zip_code,
      })
    : 'N/A';

  if (!rescue) {
    return (
      <Typography variant="body1">
        <strong>Rescue data unavailable.</strong>
      </Typography>
    );
  }
  return (
    <Box className={classes.dialogContainer}>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
        <GridRow>
          <RowTitle>Date:</RowTitle>
          <RowContent>{moment(rescue.date.value, rescue.date.format).format('dddd, MMMM Do')}</RowContent>
        </GridRow>
        <GridRow>
          <RowTitle>From:</RowTitle>
          <RowContent>
            {getRescuePickupLocationFullName({
              pickup_location_name: rescue.food_donor.pickup_location.name,
              location: rescue.food_donor.name,
            })}
          </RowContent>
        </GridRow>
        <GridRow>
          <RowTitle>To:</RowTitle>
          <RowContent>
            <Link
              to={generatePath(routes.receiver, {
                id: rescue.receiver.id,
              })}
            >
              {rescue.receiver.name}
            </Link>
          </RowContent>
        </GridRow>

        <GridRow hidden={!rescue.rescue_size}>
          <RowTitle>Rescue Vehicle Size:</RowTitle>
          <RowContent>{rescue.rescue_size.name}</RowContent>
        </GridRow>

        <GridRow>
          <RowTitle>Pickup Address:</RowTitle>
          <RowContent>
            <span style={{ cursor: 'pointer' }} onClick={() => handleAddressClick(pickupAddress)}>
              {pickupAddress}
            </span>
          </RowContent>
        </GridRow>

        <GridRow>
          <RowTitle>Dropoff Address:</RowTitle>
          <RowContent>
            <span style={{ cursor: 'pointer' }} onClick={() => handleAddressClick(dropoffAddress)}>
              {dropoffAddress}
            </span>
          </RowContent>
        </GridRow>

        <GridRow>
          <RowTitle>Rescuer:</RowTitle>
          <RowContent>
            <Link to={generatePath(routes.userEdit, { id: rescuer.id })}>{getUserFullName(rescuer)}</Link>
          </RowContent>
        </GridRow>

        {rescue_close && (
          <>
            <GridRow hidden={!rescue_close.comments}>
              <RowTitle>Close comments:</RowTitle>
              <RowContent>{rescue_close.comments}</RowContent>
            </GridRow>

            {rescue_close.rescued_food.length > 0 && (
              <GridRow>
                <RowTitle>Food Categories:</RowTitle>
                <RowContent>
                  {rescue_close.rescued_food.map((food, index) => (
                    <Typography key={food.food_description?.id || index} variant="body1">
                      {food.food_description?.name}
                    </Typography>
                  ))}
                </RowContent>
              </GridRow>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default DialogDetails;
