import React from 'react';
import { Box, Button, Typography, CardContent, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import { Room as RoomIcon, Language as LanguageIcon } from '@material-ui/icons';
import { BaseCard } from '../../components/Common/BaseCard';
import { Colors } from '../../assets/theme/Colors';
import { formatAddress, formatTime } from '../../helpers/formatters';
import { Description as DescriptionIcon } from '@material-ui/icons';

const useStyles = makeStyles(({ spacing }) => ({
  cardActions: {
    padding: spacing(0, 2, 2, 2),
  },
}));

const EventMobileCard = ({ isLoading = false, eventData, onSignupClick }) => {
  const classes = useStyles();

  const eventDate = moment(eventData.date.value, eventData.date.format).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd, MMM D',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd, MMM D',
    sameElse: date => (moment().isSame(date, 'year') ? 'dddd, MMM D' : 'dddd, MMM D YYYY'),
  });
  const eventTime = eventData ? `${formatTime(eventData.begin.value)} - ${formatTime(eventData.end.value)}` : null;

  return (
    <BaseCard>
      <CardContent>
        <Box display="flex">
          <Box flexGrow={1} display="flex" justifyContent="flex-start" flexDirection="row" alignItems="stretch">
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Typography variant="h5" gutterBottom data-testid="event-name">
                {isLoading ? <Skeleton /> : eventData.name}
              </Typography>

              <Typography data-testid="event-date" variant="subtitle1">
                {isLoading ? <Skeleton /> : eventDate}
              </Typography>
              <Typography data-testid="event-time" variant="subtitle2" color="textSecondary">
                {isLoading ? <Skeleton /> : eventTime}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" py={2}>
            {eventData && eventData.digital && (
              <>
                <LanguageIcon style={{ color: Colors.appBar.main, marginLeft: -5, marginRight: 2 }} />
                <Typography variant="body1" data-testid="event-location">
                  {isLoading ? <Skeleton /> : 'Virtual Event'}
                </Typography>
              </>
            )}

            {eventData && !eventData.digital && eventData.location && (
              <>
                <RoomIcon style={{ color: Colors.appBar.main, marginLeft: -5 }} />
                <Typography variant="body1" data-testid="event-location">
                  {isLoading ? <Skeleton /> : formatAddress(eventData.location)}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </CardContent>

      <CardActions disableSpacing className={classes.cardActions}>
        <Button
          startIcon={<DescriptionIcon />}
          data-testid="details-button"
          variant="contained"
          color="primary"
          onClick={onSignupClick}
        >
          View Details
        </Button>
      </CardActions>
    </BaseCard>
  );
};

export default EventMobileCard;
