import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Breadcrumbs, Button, Grid, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import * as sitesActions from '../actions/sites';
import UsersTable from '../components/UsersTable';
import routes from '../routes';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import * as usersActions from '../actions/users';
import * as authActions from '../actions/auth';
import snackbarHelper from '../helpers/snackbarHelper';
import FABContext from '../context/FABContext/FABContext';
import { setFABActions } from '../helpers/fab';
import { transformRoleToApi } from '../models/users';

class UsersListView extends Component {
  constructor(props) {
    super(props);

    const { activeUser } = props;

    this.state = {
      isAllowedToCreateUser: authService.hasAnyRoleInCurrentlySelectedSite(activeUser, [
        Roles.Admin,
        Roles.NationalSiteDirector,
        Roles.SiteDirector,
      ]),
      isAllowedToEditUser: authService.hasAnyRoleInCurrentlySelectedSite(activeUser, [
        Roles.Admin,
        Roles.NationalSiteDirector,
        Roles.SiteDirector,
        Roles.SiteCoordinator,
      ]),
      isAllowedToDeleteUser: authService.hasAnyRoleInCurrentlySelectedSite(activeUser, [Roles.Admin]),
      isAllowedToDeleteSiteCoordinator: authService.hasAnyRoleInCurrentlySelectedSite(activeUser, [Roles.SiteDirector]),
      isAllowedToSentInvitations: authService.hasAnyRoleInCurrentlySelectedSite(activeUser, [
        Roles.Admin,
        Roles.NationalSiteDirector,
        Roles.SiteDirector,
        Roles.SiteCoordinator,
      ]),
      isAllowedToSendEmail: authService.hasAnyRoleInCurrentlySelectedSite(activeUser, [
        Roles.Admin,
        Roles.NationalSiteDirector,
        Roles.SiteDirector,
        Roles.SiteCoordinator,
      ]),
      isAllowedToSendSms: authService.hasAnyRoleInCurrentlySelectedSite(activeUser, [
        Roles.Admin,
        Roles.NationalSiteDirector,
        Roles.SiteDirector,
        Roles.SiteCoordinator,
      ]),
      isAllowedToImpersonateUser: authService.hasAnyRoleInCurrentlySelectedSite(activeUser, [Roles.Admin]),
    };
  }

  componentDidMount() {
    const { fetchSiteUsers, site } = this.props;

    fetchSiteUsers(site.id);
  }

  sendEmailInvitation = user => {
    const { site, sendInvitationEmail } = this.props;

    sendInvitationEmail(user, site.id);

    snackbarHelper.success(`Invitation has been ${user.invitation_sent ? 'resent' : 'sent'}`);
  };

  handleSendEmailButtonClick = users => {
    const { history } = this.props;

    if (users.every(r => !r.email_verified)) {
      snackbarHelper.info(
        `You don't have any recipients with verified e-mail address selected` +
          ` therefore you will not be able to send the e-mail.`
      );
    }

    return history.push(routes.sendEmail, { usersIds: users.map(r => r.id) });
  };

  handleSendSMSButtonClick = users => {
    const { history } = this.props;

    if (users.every(r => !r.phone_verified)) {
      snackbarHelper.info(
        `You don't have any recipients with verified phone# selected` +
          ` therefore you will not be able to send the SMS.`
      );
    }

    return history.push(routes.sendSms, { usersIds: users.map(r => r.id) });
  };

  handleImpersonateUserButtonClick = userId => {
    const { users, impersonateUser, history } = this.props;

    impersonateUser(userId);

    setFABActions(users.byId[userId], history, this.context);
  };

  handleDeleteSiteCoordinator = async (user) => {
    const { site, updateUser, fetchSiteUsers } = this.props;

    await updateUser(
      user.id,
      {
        roles_to_detach: [
          { role_name: transformRoleToApi(Roles.SiteCoordinator), site_id: site.id },
        ],
      }
    );

    return fetchSiteUsers(site.id);
  };

  renderCustomTableToolbar = () => {
    const { history } = this.props;
    const { isAllowedToCreateUser } = this.state;

    if (!isAllowedToCreateUser) {
      return null;
    }
    
    return (
      <Button
        startIcon={<Add />}
        type="button"
        variant="contained"
        color="primary"
        size="small"
        onClick={() => history.push(routes.usersAdd)}
        data-testid="add-new-user-button"
      >
        Add New Coordinator
      </Button>
    );
  };

  render() {
    const { usersList, deleteUser, isFetchingUsers } = this.props;
    const {
      isAllowedToEditUser,
      isAllowedToDeleteUser,
      isAllowedToDeleteSiteCoordinator,
      isAllowedToSentInvitations,
      isAllowedToSendEmail,
      isAllowedToSendSms,
      isAllowedToImpersonateUser,
    } = this.state;

    if (isFetchingUsers) {
      return <div>loading users</div>;
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <UsersTable
            tableId="#users/users"
            title="People"
            customToolbar={this.renderCustomTableToolbar}
            sendInvitation={this.sendEmailInvitation}
            onUserDelete={deleteUser}
            onSiteCoordinatorDelete={this.handleDeleteSiteCoordinator}
            onSendEmailButtonClick={this.handleSendEmailButtonClick}
            onSendSMSButtonClick={this.handleSendSMSButtonClick}
            onImpersonateUserButtonClick={this.handleImpersonateUserButtonClick}
            users={usersList}
            showRolesColumn
            showVerifiedColumn
            showEditUserButton={isAllowedToEditUser}
            showDeleteUserButton={isAllowedToDeleteUser}
            showDeleteSiteCoordinatorButton={isAllowedToDeleteSiteCoordinator}
            showSentInvitationUserButton={isAllowedToSentInvitations}
            showSelectableRows={isAllowedToSendEmail || isAllowedToSendSms}
            showSendEmailButton={isAllowedToSendEmail}
            showSendSmsButton={isAllowedToSendSms}
            showImpersonateUserButton={isAllowedToImpersonateUser}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({
  app: { site, loggedInUser, impersonating },
  entities: {
    sites: { users },
  },
}) => {
  return {
    site,
    activeUser: impersonating || loggedInUser,
    isFetchingUsers: users.inflight,
    users: users,
    usersList: users.bySiteId[site.id],
  };
};
const mapDispatchToProps = dispatch => ({
  fetchSiteUsers: siteId => dispatch(sitesActions.fetchSiteUsersIfNeeded(siteId)),
  deleteUser: user => dispatch(usersActions.deleteUser(user)),
  updateUser: (userId, data, siteId = null) => dispatch(usersActions.updateUser(userId, data, siteId)),
  sendInvitationEmail: (user, siteId) => dispatch(usersActions.resendUserInvitationEmail(user.id, siteId)),
  impersonateUser: userId => dispatch(authActions.changeUser(userId)),
});

UsersListView.contextType = FABContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(UsersListView)
);
