import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import {  useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import { BorderColor as BorderColorIcon, Add } from '@material-ui/icons';
import Bluebird from 'bluebird';
import { get, uniqBy } from 'lodash';
import DonationsTable from '../components/DonationsTable';
import * as sitesActions from '../actions/sites';
import routes from '../routes';
import useActiveSite from '../hooks/useActiveSite';
import DonationsBulkEditReviewAndSave from '../components/DonationsBulkEditReviewAndSave';
import useNotificationService from '../hooks/useNotificationService';
import { updateDonations } from '../actions/sites';
import { fetchRescueSizesIfNeeded } from '../actions/rescueSizes';
import { fetchSystemSettings } from '../actions/systemSettings';
import useActiveUser from '../hooks/useActiveUser';
import { setUserTableOptions } from '../actions/tableOptions';
import { renderDonationPickupLocationNameOrAdress } from '../helpers/RescuesHelper';

const TABLE_ID = '#donations/donations';

const DonationsListView = () => {
  const site = useActiveSite();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const activeUser = useActiveUser();
  const { addSuccessNotification, addErrorNotification } = useNotificationService();
  const siteDonations = useSelector((state) => state.entities.sites.donations);
  const siteDonationsBySiteId = siteDonations.bySiteId[site.id] || [];
  const rescueSizes = useSelector((state) => state.entities.rescueSizes);
  const tableOptions = useSelector((state) => state.tableOptions);
  const [editedDonations, setEditedDonations] = useState([]);
  const [inReview, setInReview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    dispatch(sitesActions.fetchSiteDonationsIfNeeded(site.id));
    dispatch(fetchRescueSizesIfNeeded());
    dispatch(fetchSystemSettings());
  }, [site]);

  const renderCustomTableToolbar = ({
    handleEditClick,
    editMode,
    showEditRescueButton = true,
    hasActiveBulkEditFeatureFlag = true,
    handleReviewClick,
    reviewButtonEnabled,
  }) => (
    <>
      {!editMode && showEditRescueButton && hasActiveBulkEditFeatureFlag && (
        <Tooltip title="Edit Mode">
          <IconButton onClick={handleEditClick}>
            <BorderColorIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {editMode && (
        <Box display="flex" justifyContent="flex-end" flexWrap="no-wrap">
          <Button onClick={handleEditClick}>Exit Edit Mode</Button>
          <Button
            disabled={!reviewButtonEnabled}
            onClick={handleReviewClick}
            style={{ marginLeft: '1rem' }}
            variant="contained"
            color="primary"
          >
            Review and Save
          </Button>
        </Box>
      )}
      {!editMode && (
        <Button
          startIcon={<Add />}
          type="button"
          variant="contained"
          color="primary"
          size="small"
          onClick={() => history.push(routes.donation_add_new)}
          data-testid="add-donation"
        >
          Add Rescue
        </Button>
      )}
    </>
  );

  useEffect(() => {
    if (location.state?.prevScrollPosition && !siteDonations.inflight && !rescueSizes.inflight) {
      window.scrollTo({ top: location.state.prevScrollPosition });
    }
  }, [siteDonations.inflight, rescueSizes.inflight]);

  const scrollTop = () => window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });

  const handleSaveClick = (data) => {
    setIsLoading(true);
    const updatedData = data.map((d) => d.newData);
    return Bluebird.try(() =>
      dispatch(updateDonations(updatedData, site.id)).then(() => {
        setInReview(false);
        setEditMode(false);
        setEditedDonations([]);
        setIsLoading(false);
        addSuccessNotification('Donation(s) updated.');
        scrollTop();
      })
    ).catch((error) => {
      setIsLoading(false);
      addErrorNotification(error, error.message);
      scrollTop();
    });
  };

  if (siteDonations.inflight === undefined || siteDonations.inflight || rescueSizes.inflight) {
    return <div>fetching donations</div>;
  }

  const siteDonationsWithoutApprovedRequest = siteDonationsBySiteId.filter(
    (donation) => !(donation.verification_status === 'approved' && donation.isRequest === true)
  );

  const uniqueFoodDonors = uniqBy(siteDonationsWithoutApprovedRequest, 'location_id');
  const getDonationFoodDonorType = () => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    const columnIndex = get(previousTableState, 'columns', []).findIndex((c) => c.name === 'location');

    const foodDonorType = get(previousTableState, ['filterList', columnIndex, 0], 'All');

    // Clear food donor filter if the option is no longer selectable
    if (!uniqueFoodDonors.map((rescue) => rescue.location).includes(foodDonorType)) {
      const previousFilters = get(previousTableState, 'filterList', []);
      previousFilters[columnIndex] = [];
      return 'All';
    }

    return get(previousTableState, ['filterList', columnIndex, 0], 'All');
  };

  const rescuesWithSelectedFoodDonor = siteDonationsWithoutApprovedRequest.filter((rescue) => rescue.location === getDonationFoodDonorType());
  const uniquePickupLocations = uniqBy(rescuesWithSelectedFoodDonor, 'pickup_location_id');

  const getDonationPickupLocationType = () => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    const columnIndex = get(previousTableState, 'columns', []).findIndex((c) => c.name === 'pickup_location_name');

    // Clear pickup filter if the option is no longer selectable
    const pickupLocationOptions = uniquePickupLocations.map((donation) =>
      renderDonationPickupLocationNameOrAdress(donation)
    );
    const pickupLocationType = get(previousTableState, ['filterList', columnIndex, 0], 'All');
    if (!pickupLocationOptions.includes(pickupLocationType)) {
      const previousFilters = get(previousTableState, 'filterList', []);
      previousFilters[columnIndex] = [];
      return 'All';
    }

    return pickupLocationType;
  };

  const onFoodDonorFilterChange = (choice) => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    const columnIndex = get(previousTableState, 'columns', []).findIndex((c) => c.name === 'location');
    const pickupLocationColumnIndex = get(previousTableState, 'columns', []).findIndex(
      (c) => c.name === 'pickup_location_name'
    );
    const previousFilters = get(previousTableState, 'filterList', []);

    if (columnIndex < 0 || previousFilters.length === 0) {
      return;
    }

    if (choice === 'All') {
      previousFilters[columnIndex] = [];
      previousFilters[pickupLocationColumnIndex] = [];
      dispatch(
        setUserTableOptions(activeUser.id, TABLE_ID, {
          ...previousTableState,
          filterList: previousFilters,
        })
      );
    } else {
      previousFilters[columnIndex] = [choice];
      previousFilters[pickupLocationColumnIndex] = [];
      dispatch(
        setUserTableOptions(activeUser.id, TABLE_ID, {
          ...previousTableState,
          filterList: previousFilters,
        })
      );
    }
  };

  const onPickupLocationFilterChange = (choice) => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    const columnIndex = get(previousTableState, 'columns', []).findIndex((c) => c.name === 'pickup_location_name');
    const previousFilters = get(previousTableState, 'filterList', []);

    if (columnIndex < 0 || previousFilters.length === 0) {
      return;
    }

    if (choice === 'All') {
      previousFilters[columnIndex] = [];
      dispatch(
        setUserTableOptions(activeUser.id, TABLE_ID, {
          ...previousTableState,
          filterList: previousFilters,
        })
      );
    } else {
      previousFilters[columnIndex] = [choice];
      dispatch(
        setUserTableOptions(activeUser.id, TABLE_ID, {
          ...previousTableState,
          filterList: previousFilters,
        })
      );
    }
  };

  return (
    <>
      {inReview && (
        <DonationsBulkEditReviewAndSave
          onExit={() => setInReview(false)}
          onSave={handleSaveClick}
          dialogOpen={inReview}
          editedDonations={editedDonations}
          donations={siteDonationsBySiteId}
          isLoading={isLoading}
        />
      )}

      <Grid container>
        <Grid item xs={12}>
          <DonationsTable
            tableId="#donations/donations"
            customToolbar={renderCustomTableToolbar}
            donations={siteDonationsWithoutApprovedRequest}
            handleReviewClick={() => setInReview(true)}
            onEditClick={id => history.push(generatePath(routes.donation_edit, { donationId: id }), { prevScrollPosition: window.scrollY })}
            onReviewClick={id => history.push(generatePath(routes.donation_request_verify, { donationId: id }), { prevScrollPosition: window.scrollY })}
            onLocationClick={(donorId, locationId) =>
              history.push(generatePath(routes.foodDonor, { foodDonorId: locationId }))
            }
            customFilters={{
              showPendingRegistrationFilter: true,
              displayFoodDonorFilters: true,
              donations: siteDonationsWithoutApprovedRequest,
              getDonationFoodDonorType: getDonationFoodDonorType,
              getDonationPickupLocationType: getDonationPickupLocationType,
              onFoodDonorFilterChange: onFoodDonorFilterChange,
              onPickupLocationFilterChange: onPickupLocationFilterChange,
            }}
            editedDonations={editedDonations}
            setEditedDonations={setEditedDonations}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DonationsListView;
