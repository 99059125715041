import queryString from 'query-string';
import { apiGET, apiPOST, apiPATCH, apiDELETE } from './api';


export const getUsers = params => {
  let query = '';
  if (params) {
    query = `?${queryString.stringify(params)}`;
  }

  return apiGET(`/users${query}`);
};

export const getUsersWithoutRoles = () => {
  return apiGET('/users?norole=true');
};

export const getUser = (userId, accessTokenOverride = false) => {
  console.log( `getUser(${userId})`);
  return apiGET(`/users/${userId}`, accessTokenOverride)
  .then((res)=>{
    return res.json();
  })
  .then((json) => {
    console.log('getUser: ', {res:json});
    // this is the singular form
    return {
      json : () => { return Promise.resolve({ result : json.result, user : json.data}); }
    };
  });
};

export const getUserByInvitationHash = (invitationHash, accessTokenOverride) => {
  console.log( `getUserByInvitationHash(${invitationHash})`);
  return apiGET(
  `/users?invitation_hash=${invitationHash}`,
    accessTokenOverride
  )
  .then((res)=>{
    return res.json();
  })
  .then((json) => {
    console.log('getUserByInvitationHash: ', {res:json});
    // convert from the collection to the singular form
    return {
      json : () => { return Promise.resolve({ result : json.result, user : json.data[0]}); }
    };
  });
};

export const updateUser = (userId, body) => apiPATCH(`/users/${userId}`, body);

export const deleteUser = userId => {
  return apiDELETE(`/users/${userId}`);
};

export const deleteMe = (body) => apiDELETE('/me', body);

export const sendSmsVerifyRequest = userId => apiPOST(
  `/users/${userId}/smsverifyrequests`,
);

export const requestUserVerificationEmail = (userId, siteId = null) => apiPOST(
  `/users/${userId}/emailverifyrequests`,
  {
    site_id: siteId,
  }
);

export const requestUserInvitationEmail = (userId, siteId) => apiPOST(
  `/users/${userId}/invitations`,
  {
    site_id: siteId,
  }
);

export const getUserIdentities = userId => apiGET(`/users/${userId}/identities`);

export const createUserDevice = (userId, payload) => apiPOST(`/users/${userId}/devices`, payload);

export const updateUserNewBadges = (userId, badgeId, payload) =>
  apiPATCH(`/users/${userId}/badges/${badgeId}`, payload);
