import React, { useState } from 'react';
import moment from 'moment';
import ReactSelect from 'react-select';
import { FormControl, Grid, InputLabel, MenuItem, Select, Tooltip, makeStyles } from '@material-ui/core';
import DateAndGranularityPicker, {
  dateRangeOptionsList,
} from '../components/DateAndGranularityPicker/DateAndGranularityPicker';
import { getEarliestSiteDate } from '../helpers/getters';
import useActiveSite from '../hooks/useActiveSite';
import { renderPickupLocationNameOrAdress } from '../helpers/RescuesHelper';
import { RescuesFromSiteFilterTypes } from './FutureRescuesListView';

export const dateRangeOptions = {
  lastWeek: dateRangeOptionsList.lastWeek,
  last7Days: dateRangeOptionsList.last7Days,
  last90Days: dateRangeOptionsList.last90Days,
  thisMTD: dateRangeOptionsList.thisMTD,
  thisQTD: dateRangeOptionsList.thisQTD,
  thisYTD: dateRangeOptionsList.thisYTD,
  allHistory: dateRangeOptionsList.allHistory,
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 160,
  },
  paper: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(1),
  },
}));

const PastRescuesListForm = ({
  sitesList,
  onSubmit,
  defaultValues,
  disabled,
  foodDonorFilterValue,
  pickupLocationFilterValue,
  onFoodDonorFilterChange,
  onPickupLocationFilterChange,
  displayFoodDonorFilters,
  showOnlyPickupFilter,
  rescuesFromType,
  onRescuesFromFilterChange,
  uniquePickupLocations = [],
  uniqueFoodDonors = [],
  showDisabledSiteFilter = false,
}) => {
  const classes = useStyles();
  const [selectedSites, setSelectedSites] = useState(defaultValues.sites);
  const [dateRange, setDateRange] = useState(defaultValues.dateRange);
  const [period, setPeriod] = useState(defaultValues.period);
  const currentSite = useActiveSite();

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
      {sitesList.length > 1 && (
        <Grid item xs={12}>
          <ReactSelect
            isDisabled={disabled}
            isMulti
            placeholder="Select site(s) or leave blank to show data for all sites"
            value={selectedSites}
            options={[{ id: -1, name: 'All' }, ...sitesList]}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            onChange={option => {
              let choices = option !== null ? option : [];
              if (choices.some(site => site.id === -1)) {
                choices = [];
              }
              setSelectedSites(choices);

              return onSubmit({
                sites: choices,
                dateRange: dateRange,
                period: period,
              });
            }}
            styles={{
              menu: provided => ({
                ...provided,
                zIndex: 555,
              }),
            }}
          />
        </Grid>
      )}

      {showDisabledSiteFilter && (
        <Grid item>
          <FormControl variant="outlined" size="small" className={classes.formControl}>
            <InputLabel shrink>Rescues from:</InputLabel>

            <Select
              label="Rescues from"
              value={rescuesFromType}
              onChange={(event) => onRescuesFromFilterChange(event.target.value)}
            >
              {[
                RescuesFromSiteFilterTypes.all,
                RescuesFromSiteFilterTypes.enabled,
                RescuesFromSiteFilterTypes.disabled,
              ].map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {displayFoodDonorFilters && (
        <>
          {!showOnlyPickupFilter && (
            <Grid item>
              <FormControl variant="outlined" size="small" className={classes.formControl}>
                <InputLabel shrink>Food Donor</InputLabel>
                <Select
                  label="Food Donor"
                  value={foodDonorFilterValue}
                  onChange={(event) => {
                    onFoodDonorFilterChange(event.target.value)}}
                >
                  <MenuItem value="All" data-name="all">
                    All
                  </MenuItem>
                  {uniqueFoodDonors.map((item) => (
                    <MenuItem key={item.location_id} value={item.location}>
                      {item.location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item>
            <Tooltip title={foodDonorFilterValue === 'All' ? 'Please select a Food Donor first.' : ''}>
              <FormControl variant="outlined" size="small" className={classes.formControl}>
                <InputLabel shrink>Pickup Location</InputLabel>
                <Select
                  label="Pickup Location"
                  disabled={showOnlyPickupFilter ? disabled : foodDonorFilterValue === 'All' || disabled}
                  value={pickupLocationFilterValue}
                  onChange={(event) => {
                    onPickupLocationFilterChange(event.target.value)}}
                >
                  <MenuItem value="All" data-name="all">
                    All
                  </MenuItem>
                  {uniquePickupLocations.map((item) => (
                    <MenuItem key={item.pickup_location_id} value={renderPickupLocationNameOrAdress(item)}>
                      {renderPickupLocationNameOrAdress(item)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
          </Grid>
        </>
      )}

      <Grid item>
        <DateAndGranularityPicker
          showGranularity={false}
          returnDateObject={false}
          customDateRangeValue={dateRange}
          customDateRangeOptions={{
            maxDate: moment().toDate(),
            minDate: sitesList.length > 1 ? getEarliestSiteDate(selectedSites, sitesList) : getEarliestSiteDate(currentSite, sitesList),
          }}
          defaultDateRange={period}
          dateRangeOptions={dateRangeOptions}
          onChange={state => {
            setPeriod(state.period);
            setDateRange(state.dateRange);

            return onSubmit({
              dateRange: state.dateRange,
              sites: selectedSites,
              period: state.period,
            });
          }}
          showCustomDateRange
        />
      </Grid>
    </Grid>
  );
};

export default PastRescuesListForm;
