import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link as RouterLink, Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Breadcrumbs,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ExpandMore as ExpandMoreIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  Home as HomeIcon,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import routes from '../../../../routes';
import Description from '../Components/Description';
import OverlayLoader from '../../../../components/OverlayLoader';
import Schedule from '../Components/Schedule';
import Pickups from '../Components/Pickups';
import Calendar from '../Components/Calendar';
import DonationContext from '../../../../context/DonationContext/DonationContext';
import useActiveSite from '../../../../hooks/useActiveSite';
import useNotificationService from '../../../../hooks/useNotificationService';
import * as requestDonationsApi from '../../../../api/requestDonations';
import {
  fetchSiteDonorsLocationsIfNeeded,
  fetchSitePickupLocationsIfNeeded,
  fetchSiteReceiversIfNeeded,
  fetchSiteRescuersIfNeeded,
} from '../../../../actions/sites';
import { fetchRescueSizesIfNeeded } from '../../../../actions/rescueSizes';
import { fetchSystemSettingsIfNeeded } from '../../../../actions/systemSettings';
import {
  diffDonation,
  DONATION_TYPE_REQUEST_APPROVAL,
  saveDonation,
  setDonationDiffInflight,
} from '../../../../actions/donationNew';
import useDonationManageActions from '../../../../hooks/useDonationManageActions';
import { diffCreateRequestDonation } from '../../../../actions/requestDonations';
import {
  FREQUENCY_EVERY_OTHER_WEEK,
  FREQUENCY_MONTHLY,
  FREQUENCY_ONCE,
  FREQUENCY_WEEKLY,
  FREQUENCY_WEEKLY_EVERY_TWO_WEEKS,
} from '../../../../models/donationsNew';
import { getDayIndexByShortName } from '../../../../models/donations';
import { compareDonations, donationManageActions, getPickupSpecBaseDay } from '../../../../helpers/donations';
import errorMessages from '../../../../assets/errorMessages';
import { validateDonation } from '../donationValidator';
import DonationRequestDeclineDialog from './DonationRequestDeclineDialog';
import DonationRequestModifyDialog from './DonationRequestModifyDialog';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../../../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import { fetchReceiversIfNeeded } from '../../../../actions/receivers';

const useStyles = makeStyles(({ typography, spacing, breakpoints }) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    display: 'flex',
    columnGap: spacing(1),
    alignItems: 'baseline',
  },
  buttonsFlex: {
    marginTop: spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  noMarginTop: {
    marginTop: 0,
  },
  buttonsRow: {
    display: 'flex',
    gridColumnGap: '8px',
    [breakpoints.only('xs')]: {
      marginTop: spacing(1),
      flexGrow: 1,
      flexShrink: 0,
    },
    [breakpoints.only('md')]: {
      marginTop: spacing(1),
      flexGrow: 1,
      flexShrink: 0,
    },
  },
}));

const AGENDA_DESCRIPTION = 'Description';
const AGENDA_SCHEDULE = 'Schedule';

const hasDescriptionValidationErrors = errors => ['lbs'].some(field => errors.includes(field));

const hasScheduleValidationErrors = errors => errors.length > 0 && !hasDescriptionValidationErrors(errors);

const DonationRequestVerify = ({ isMobileView, match, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeSite = useActiveSite();
  const location = useLocation();
  const [expanded, setExpanded] = useState(AGENDA_SCHEDULE);
  const [initialDonationRequestState, setInitialDonationRequestState] = useState({});
  const [firstFoodDonorChoice, setFirstFoodDonorChoice] = useState();
  const [showDonationRequestDiscardDialog, setShowDonationRequestDiscardDialog] = useState(false);
  const [showDonationRequestModifyDialog, setShowDonationRequestModifyDialog] = useState(false);
  const editDonationId = parseInt(match.params.donationId, 10);
  const rescueSizes = useSelector(state => state.entities.rescueSizes);
  const rescueSizesInflight = useSelector(state => state.entities.rescueSizes.inflight);
  const pickupLocationsInflight = useSelector(state => state.entities.sites.pickupLocations.inflight);
  const donationData = useSelector(state => state.ui.donation_new.draft);
  const currentDonationState = useSelector(state => state.ui.donation_new.currentState);
  const diffInflight = useSelector(state => state.ui.donation_new.diffInflight);
  const donationFormErrors = useSelector(state => state.ui.donation_new.errors);
  const { addErrorNotification, addSuccessNotification } = useNotificationService();
  const rescueSizesArray = rescueSizes.sorted;

  const {
    undoLastAction,
    clearDonation,

    setError,
    removeError,

    setDescription,
    setLBSValue,
    setAddFoodTypes,
    setRemoveFoodTypes,
    setFoodTypeOther,
    setRescueSize,
    setFoodSize,
    setAddDayOfWeek,
    setRemoveDayOfWeek,

    setAddDayOfMonth,
    setDayOfMonthDay,
    setDayOfMonthOrdinal,
    setRemoveDayOfMonth,

    setStartDate,
    setEndDate,
    setPauseDate,
    setResumeDate,
    setFrequency,
    setAppendPickup,
    setRemovePickup,
    setPickupReceiver,
    setPickupAdopter,
    setPickupRescuerNotes,
    setPickupBegin,
    setPickupEnd,
    setAllDaysTheSame,
    clearAllDaysTheSame,
    setAllPickupsTheSame,
    clearAllPickupsTheSame,

    setRescuePickupBegin,
    setRescuePickupEnd,
    setRescueRescuerNotes,
    setRescueCanceller,
    setRescueRescuer,
    setDonorNotes,
  } = useDonationManageActions();

  const triggerFormChange = async () => {
    if (firstFoodDonorChoice) {
      try {
        await dispatch(diffDonation(null, firstFoodDonorChoice));
      } catch (error) {
        addErrorNotification(dispatch, dispatch.message || 'Unknown error during modifying donation');
        dispatch(setDonationDiffInflight(false));
        undoLastAction();
      }
    }
  };

  const createDraftDonation = async () => {
    try {
      const { donation } = await dispatch(diffCreateRequestDonation(editDonationId));
      setFirstFoodDonorChoice(donation.location_id);
      let initialDonationModifications = 6;
      await setRescueSize({
        id: donation.rescue_size_id,
        name: 'foo',
      });
      await setFoodSize({
        id: donation.donation_size_id,
        name: 'foo',
      });
      await donation.food_types.forEach(foodType => {
        initialDonationModifications++;
        return setAddFoodTypes(foodType);
      });
      await setFoodTypeOther(donation.food_type_other);
      await setDescription(donation.slug);
      donation.lbs && (await setLBSValue(donation.lbs));

      if (donation.frequency === FREQUENCY_WEEKLY && donation.frequency_every === FREQUENCY_WEEKLY_EVERY_TWO_WEEKS) {
        await setFrequency(FREQUENCY_EVERY_OTHER_WEEK);
      } else {
        await setFrequency(donation.frequency);
      }

      if (donation.all_pickups_are_the_same) {
        await setAllPickupsTheSame();
      } else {
        await clearAllPickupsTheSame();
      }

      if (donation.all_days_are_the_same) {
        await setAllDaysTheSame();
      } else {
        await clearAllDaysTheSame();
      }

      switch (donation.frequency) {
        case FREQUENCY_ONCE:
          break;
        case FREQUENCY_WEEKLY:
        case FREQUENCY_EVERY_OTHER_WEEK:
          // clear all selected days first, eg monday is pre-selected by default
          [0, 1, 2, 3, 4, 5, 6].forEach(setRemoveDayOfWeek);
          // now select only days selected while creating donation request
          Object.entries(JSON.parse(donation.days_of_week)).forEach(([dayName, dayValue]) => {
            if (dayValue.checked) {
              initialDonationModifications++;
              const dayIndex = getDayIndexByShortName(dayName);
              setAddDayOfWeek(dayIndex);
            }
          });
          break;

        case FREQUENCY_MONTHLY:
          // clear all selected days first, eg monday is pre-selected by default
          [0, 1, 2, 3, 4, 5, 6].forEach(dayIndex => {
            [1, 2, 3, 4, -1].forEach(ordinal => {
              setRemoveDayOfMonth({
                day: dayIndex,
                ordinal: ordinal,
              });
            });
          });

          Object.entries(JSON.parse(donation.days_of_month)).forEach(([day, value]) => {
            const dayIndex = getDayIndexByShortName(day);
            value.forEach(dayEntry => {
              initialDonationModifications++;
              return setAddDayOfMonth({
                day: dayIndex,
                ordinal: dayEntry.ordinal,
              });
            });
          });
          break;
      }

      initialDonationModifications++;
      await setStartDate({
        startDate: donation.start_date ? donation.start_date.value : null,
      });

      if (donation.end_date) {
        initialDonationModifications++;
        await setEndDate(donation.end_date.value);
      }

      if (donation.pause_date) {
        initialDonationModifications++;
        await setPauseDate(donation.pause_date.value);
      }

      if (donation.resume_date) {
        initialDonationModifications++;
        await setResumeDate(donation.resume_date.value);
      }

      if (donation.food_donor_notes) {
        initialDonationModifications++;
        await setDonorNotes(donation.food_donor_notes);
      }

      if (donation.all_days_are_the_same) {
        initialDonationModifications++;
        await setPickupBegin({
          begin: donation.pickup_specs[0].pickup_begin.value,
        });

        initialDonationModifications++;
        await setPickupEnd({
          end: donation.pickup_specs[0].pickup_end.value,
        });

        initialDonationModifications++;
        await setPickupRescuerNotes({
          rescuer_notes: donation.pickup_specs[0].rescuer_notes,
        });
      } else {
        donation.pickup_specs.forEach(pickup => {
          setPickupBegin({
            ...getPickupSpecBaseDay(pickup, donation.frequency),
            begin: pickup.pickup_begin.value,
          });
          setPickupEnd({
            ...getPickupSpecBaseDay(pickup, donation.frequency),
            end: pickup.pickup_end.value,
          });
          setPickupRescuerNotes({
            ...getPickupSpecBaseDay(pickup, donation.frequency),
            rescuer_notes: pickup.rescuer_notes,
          });
        });
      }

      const response = await dispatch(diffDonation(null, donation.location_id));
      setInitialDonationRequestState(response.donation);
    } catch (error) {
      if (error.statusCode === 404) {
        addErrorNotification(error, 'Donation not found');
        return history.push(generatePath(isMobileView ? routes.index : routes.donations));
      }

      addErrorNotification(error, error.message || 'Unknown error during modifying donation');
      dispatch(setDonationDiffInflight(false));
      undoLastAction();
    }
  };

  useEffect(() => {
    createDraftDonation();
    dispatch(fetchSiteReceiversIfNeeded(activeSite.id));
    dispatch(fetchSiteDonorsLocationsIfNeeded(activeSite.id));
    dispatch(fetchSitePickupLocationsIfNeeded(activeSite.id));
    dispatch(fetchRescueSizesIfNeeded());
    dispatch(fetchSiteRescuersIfNeeded(activeSite.id));
    dispatch(fetchSystemSettingsIfNeeded()); // used in LBSSelector
    dispatch(fetchReceiversIfNeeded());

    return () => clearDonation();
  }, [dispatch]);

  useEffect(() => {
    if (donationData && !donationData.rescue_size && rescueSizesArray && rescueSizesArray.length) {
      setRescueSize({
        id: rescueSizesArray[0].id,
        name: rescueSizesArray[0].name,
      });
    }
  }, [donationData, rescueSizesArray]);

  const handleAccordionChange = panel => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : expanded === AGENDA_DESCRIPTION ? AGENDA_SCHEDULE : AGENDA_DESCRIPTION);

  if (donationData === null || donationData.inflight === true || rescueSizesInflight || pickupLocationsInflight) {
    return 'loading...';
  }

  const handleDonationRequestModify = async modifiedFields => {
    try {
      const validation = validateDonation(donationData, {
        validateReceivingAgency: true,
      });

      if (!validation.isValid) {
        return Object.keys(validation.errors).forEach(error => setError(error, validation.errors[error]));
      }
      const donation = await dispatch(
        saveDonation(null, donationData.location.id, currentDonationState, {
          donation_request_id: editDonationId,
          modified_fields: modifiedFields,
        })
      );
      addSuccessNotification('Donation modified!');

      const redirectUrl = isMobileView
        ? routes.pendingDonations
        : generatePath(routes.donation_edit, { donationId: donation.id });
      history.push(redirectUrl);
    } catch (e) {
      dispatch(setDonationDiffInflight(false));

      return addErrorNotification(e, 'Unable to modify donation');
    }
  };

  const handleDonationRequestDecline = async reason => {
    try {
      dispatch(setDonationDiffInflight(true));
      await requestDonationsApi.declineDonationRequest(editDonationId, reason);
      history.push(generatePath(routes.dashboardSC));
    } catch (error) {
      dispatch(setDonationDiffInflight(false));

      return addErrorNotification(error, 'Unable to decline Request to Donate');
    }
  };

  const createDonation = async () => {
    try {
      const validation = validateDonation(donationData, {
        validateReceivingAgency: true,
      });

      if (!validation.isValid) {
        return Object.keys(validation.errors).forEach(error => setError(error, validation.errors[error]));
      }
      const donation = await dispatch(
        saveDonation(null, donationData.location.id, currentDonationState, {
          donation_request_id: editDonationId,
        })
      );
      addSuccessNotification('Rescue Scheduled');

      const redirectUrl = isMobileView
        ? routes.pendingDonations
        : generatePath(routes.donation_edit, { donationId: donation.id });
      history.push(redirectUrl);
    } catch (e) {
      dispatch(setDonationDiffInflight(false));

      return addErrorNotification(e, 'Unable to schedule rescue');
    }
  };

  const handleModifyClick = () => {
    const validation = validateDonation(donationData, {
      validateReceivingAgency: true,
    });

    if (!validation.isValid) {
      return Object.keys(validation.errors).forEach(error => setError(error, validation.errors[error]));
    }

    return setShowDonationRequestModifyDialog(true);
  };

  const modifiedDonationFields = compareDonations(initialDonationRequestState, donationData);

  const renderDonationButtons = () => (
    <Box
      className={classNames({
        [classes.buttonsFlex]: true,
        [classes.noMarginTop]: isMobileView,
      })}
    >
      {modifiedDonationFields.length > 0 && (
        <Box className={classes.buttonsRow}>
          <Button
            disabled={currentDonationState.length === 0}
            color="primary"
            variant="contained"
            type="button"
            onClick={handleModifyClick}
            startIcon={<SaveIcon />}
            fullWidth={isMobileView}
          >
            Modify Donation Request
          </Button>
        </Box>
      )}
      {modifiedDonationFields.length === 0 && (
        <Box className={classes.buttonsRow}>
          <Button
            disabled={currentDonationState.length === 0}
            color="primary"
            variant="contained"
            type="button"
            onClick={createDonation}
            startIcon={<SaveIcon />}
            fullWidth={isMobileView}
          >
            Accept Request
          </Button>
        </Box>
      )}
      <Box className={classes.buttonsRow}>
        <Button
          disabled={currentDonationState.length === 0}
          color="secondary"
          variant="contained"
          type="button"
          onClick={() => setShowDonationRequestDiscardDialog(true)}
          startIcon={<DeleteIcon />}
          fullWidth={isMobileView}
        >
          Decline Request
        </Button>
      </Box>
    </Box>
  );

  const handleFieldChange = (actionName, fieldValue) => {
    switch (actionName) {
      case donationManageActions.set_description:
        setDescription(fieldValue);
        break;

      case donationManageActions.set_lbs_value:
        setLBSValue(fieldValue);
        if (fieldValue === '') {
          setError('lbs', errorMessages.REQUIRED.message);
        } else {
          removeError('lbs');
        }
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_size:
        setRescueSize(fieldValue);
        break;

      case donationManageActions.set_food_size:
        setFoodSize(fieldValue);
        break;

      case donationManageActions.add_food_type:
        setAddFoodTypes(fieldValue);
        break;

      case donationManageActions.remove_food_type:
        setRemoveFoodTypes(fieldValue);
        break;

      case donationManageActions.set_food_type_other:
        setFoodTypeOther(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_frequency:
        setFrequency(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.add_day_of_week:
        setAddDayOfWeek(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.remove_day_of_week:
        setRemoveDayOfWeek(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.add_day_of_month:
        setAddDayOfMonth(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_day_of_month_day:
        setDayOfMonthDay(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_day_of_month_ordinal:
        setDayOfMonthOrdinal(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.remove_day_of_month:
        setRemoveDayOfMonth(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_start_date:
        setStartDate({
          startDate: fieldValue,
        });
        triggerFormChange();
        break;

      case donationManageActions.set_end_date:
        setEndDate(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pause_date:
        setPauseDate(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_resume_date:
        setResumeDate(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_all_days_are_the_same:
        setAllDaysTheSame();
        triggerFormChange();
        break;

      case donationManageActions.clear_all_days_are_the_same:
        clearAllDaysTheSame();
        triggerFormChange();

        break;
      case donationManageActions.set_all_pickups_are_the_same:
        setAllPickupsTheSame();
        triggerFormChange();
        break;

      case donationManageActions.clear_all_pickups_are_the_same:
        clearAllPickupsTheSame();
        triggerFormChange();
        break;

      case donationManageActions.add_pickup:
        setAppendPickup(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.remove_pickup:
        setRemovePickup(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_receiver:
        setPickupReceiver(fieldValue);
        if (fieldValue) {
          removeError('receiver');
        }

        triggerFormChange();
        break;

      case donationManageActions.set_pickup_adopter:
        setPickupAdopter(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_rescuer_notes:
        setPickupRescuerNotes(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_begin:
        setPickupBegin(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_end:
        setPickupEnd(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_pickup_begin:
        setRescuePickupBegin(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_pickup_end:
        setRescuePickupEnd(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_rescuer_notes:
        setRescueRescuerNotes(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_canceller:
        setRescueCanceller(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_rescuer:
        setRescueRescuer(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_donor_notes:
        setDonorNotes(fieldValue);
        triggerFormChange();
        break;

      default:
        console.error(`---->>>> #Unknown donation field "${actionName}" with given value "${fieldValue}"`);
    }
  };

  const renderBreadcrumbs = () => {
    if (
      location.state?.prevPath === routes.donations ||
      location.state?.prevPath === generatePath(routes.rescuesList)
    ) {
      return (
        <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
          <Link color="inherit" to={routes.donations}>
            Rescues
          </Link>

          <Typography color="textPrimary">Verify Donation</Typography>
        </Breadcrumbs>
      );
    }

    return (
      <>
        {isMobileView ? (
          <Box boxShadow={0}>
            <Breadcrumbs aria-label="breadcrumb">
              <StyledRescuerBreadcrumb
                component={RouterLink}
                to={generatePath(routes.mobileDashboard)}
                label="Home"
                icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
              />
              <StyledRescuerTextBreadcrumb
                component={RouterLink}
                to={routes.pendingDonations}
                label="Pending Donations"
              />
              <StyledRescuerTextBreadcrumb component="a" href="#" label="Verify Donation" />
            </Breadcrumbs>
          </Box>
        ) : (
          <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
            <Link color="inherit" to={generatePath(routes.dashboardSC)}>
              Dashboard
            </Link>

            <Typography color="textPrimary">Verify Donation</Typography>
          </Breadcrumbs>
        )}
      </>
    )
  }

  return (
    <DonationContext.Provider
      value={{
        onFieldChange: handleFieldChange,
        hasFoodDonorDefined: donationData && !!donationData.location,
        isLoading: diffInflight,
        isNew: false,
        isMobileView: isMobileView,
        errors: donationFormErrors,
        donationType: DONATION_TYPE_REQUEST_APPROVAL,
      }}
    >
      {renderBreadcrumbs()}

      {!isEmpty(initialDonationRequestState) && !initialDonationRequestState.rescue_size && (
        <Box mb={2}>
          <Alert severity="info">
            Food Donor did not specify the Rescue Vehicle Size option. Please modify it to match the rescue needs.
          </Alert>
        </Box>
      )}

      <Grid container spacing={3}>
        <Grid container direction="column" justify="flex-start" alignItems="stretch" item xs={12} md={6} lg={8}>
          <Grid item xs={12}>
            <Accordion
              elevation={25}
              expanded={expanded === AGENDA_DESCRIPTION}
              onChange={handleAccordionChange(AGENDA_DESCRIPTION)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-description"
                id="description-header"
              >
                <Typography className={classes.heading}>
                  Description
                  {hasDescriptionValidationErrors(Object.keys(donationFormErrors)) && (
                    <Typography component="span" color="error">
                      Missing info
                    </Typography>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Description />
              </AccordionDetails>
            </Accordion>
            <Accordion
              elevation={25}
              expanded={expanded === AGENDA_SCHEDULE}
              onChange={handleAccordionChange(AGENDA_SCHEDULE)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-schedule" id="schedule-header">
                <Typography className={classes.heading}>
                  Schedule
                  {hasScheduleValidationErrors(Object.keys(donationFormErrors)) && (
                    <Typography component="span" color="error">
                      Validation error
                    </Typography>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <OverlayLoader isLoading={diffInflight} wrapperStyles={{ width: '100%' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Schedule />
                    </Grid>
                    <Grid item xs={12}>
                      <Pickups />
                    </Grid>
                  </Grid>
                </OverlayLoader>
              </AccordionDetails>
            </Accordion>
            {!isMobileView && renderDonationButtons()}
          </Grid>
        </Grid>
        {!isMobileView && (
          <Grid item xs>
            <Card square elevation={25}>
              <CardContent>
                <Calendar />
              </CardContent>
            </Card>
          </Grid>
        )}

        {isMobileView && (
          <Grid item xs>
            {renderDonationButtons()}
          </Grid>
        )}
      </Grid>

      <DonationRequestModifyDialog
        modifiedDonationFields={modifiedDonationFields || []}
        isOpen={showDonationRequestModifyDialog}
        handleCancel={() => setShowDonationRequestModifyDialog(false)}
        onClose={() => setShowDonationRequestModifyDialog(false)}
        handleModify={handleDonationRequestModify}
      />

      <DonationRequestDeclineDialog
        isOpen={showDonationRequestDiscardDialog}
        handleCancel={() => setShowDonationRequestDiscardDialog(false)}
        onClose={() => setShowDonationRequestDiscardDialog(false)}
        handleDecline={handleDonationRequestDecline}
      />
    </DonationContext.Provider>
  );
};

export default DonationRequestVerify;
