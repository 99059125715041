import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useActiveUser from './useActiveUser';
import {
  fetchRescuerClaimedAndAdoptedRescuesV2IfNeeded,
  fetchRescuerClaimedAndAdoptedRescuesV2,
  fetchRescuerPastRescues,
  fetchRescuerPastRescuesIfNeeded,
  fetchScheduleRescues,
  fetchScheduleRescuesIfNeeded,
  fetchScheduleRescuesPaginated,
  fetchScheduleRescuesPaginatedIfNeeded,
} from '../actions/rescues';
import { fetchRescuerEvents, fetchRescuerEventsIfNeeded } from '../actions/rescuers';
import moment from 'moment';
import { fetchEvents, fetchEventsIfNeeded } from '../actions/events';
import { getUserSitesByRole } from '../services/auth';
import { Roles } from '../models/roles';
import { fetchBadges, fetchBadgesIfNeeded, fetchRescuerBadges, fetchRescuerBadgesIfNeeded } from '../actions/badges';
import { fetchSiteGoals } from '../actions/siteGoals';
import { fetchRescuerEnvironmentImpact } from '../actions/environmentImpact';
import useHasActiveFeatureFlag from './useFeatureFlags';

export default function useRescuerDashboardActions() {
  const dispatch = useDispatch();
  const activeUser = useActiveUser();

  const fetchMyFrusTab = useCallback(
    (quietMode = false, force = false) => {
      const today = moment().format('YYYY-MM-DD');

      if (force) {
        dispatch(fetchRescuerClaimedAndAdoptedRescuesV2(activeUser.id, today, undefined, quietMode));
        dispatch(fetchRescuerPastRescues(activeUser.id, quietMode));
        return;
      }
      dispatch(fetchRescuerClaimedAndAdoptedRescuesV2IfNeeded(activeUser.id, today, undefined, quietMode));
      dispatch(fetchRescuerPastRescuesIfNeeded(activeUser.id, quietMode));
    },
    [activeUser.id, dispatch]
  );

  const fetchScheduleTabPaginated = useCallback(
    (quietMode = false, force = false, page = 1, clearPreviousData = false) => {
      const today = moment().format('YYYY-MM-DD');
      const siteIds = getUserSitesByRole(activeUser.role_assignments, Roles.Rescuer);

      if (force) {
        return dispatch(
          fetchScheduleRescuesPaginated({
            from: today,
            page: page,
            limit: 150,
            rescuerId: activeUser.id,
            sitesIds: siteIds,
            quietMode: quietMode,
            clearPreviousData: clearPreviousData,
          })
        );
      }

      return dispatch(
        fetchScheduleRescuesPaginatedIfNeeded({
          from: today,
          page: page,
          limit: 150,
          rescuerId: activeUser.id,
          sitesIds: siteIds,
          quietMode: quietMode,
          clearPreviousData: clearPreviousData,
        })
      );
    },
    [activeUser.id, dispatch]
  );

  const fetchScheduleTab = useCallback(
    (quietMode = false, force = false) => {
      const today = moment().format('YYYY-MM-DD');
      const siteIds = getUserSitesByRole(activeUser.role_assignments, Roles.Rescuer);

      if (force) {
        return dispatch(
          fetchScheduleRescues(
            moment(today, 'YYYY-MM-DD').format('YYYYMMDD'),
            moment()
              .add(3, 'M')
              .format('YYYYMMDD'),
            activeUser.id,
            siteIds
          )
        );
      }

      return dispatch(
        fetchScheduleRescuesIfNeeded(
          moment(today, 'YYYY-MM-DD').format('YYYYMMDD'),
          moment()
            .add(3, 'M')
            .format('YYYYMMDD'),
          activeUser.id,
          siteIds,
          quietMode
        )
      );
    },
    [activeUser.id, dispatch]
  );

  const fetchGoals = useCallback(
    (quietMode = false) => {
      const today = moment().format('YYYY-MM-DD');
      const siteIds = getUserSitesByRole(activeUser.role_assignments, Roles.Rescuer);

      return dispatch(fetchSiteGoals({
        sites_ids: siteIds,
        active: true,
      }, quietMode));
    },
    [activeUser.id, dispatch]
  );

  const fetchMyEvents = useCallback(
    (quietMode = false, force = false) => {
      if (force) {
        dispatch(fetchRescuerEvents(activeUser.id, quietMode));
        return;
      }

      dispatch(fetchRescuerEventsIfNeeded(activeUser.id, quietMode));
    },
    [activeUser.id, dispatch]
  );

  const fetchEventsTab = useCallback(
    (quietMode = false, force = false) => {
      if (force) {
        return dispatch(
          fetchEvents(quietMode, {
            from: moment().format('YYYY-MM-DD'),
            sites_ids: getUserSitesByRole(activeUser.role_assignments, Roles.Rescuer),
          })
        );
      }

      return dispatch(
        fetchEventsIfNeeded(quietMode, {
          from: moment().format('YYYY-MM-DD'),
          sites_ids: getUserSitesByRole(activeUser.role_assignments, Roles.Rescuer),
        })
      );
    },
    [activeUser.id, dispatch]
  );

  const fetchMyBadges = useCallback(
    (quietMode = false, force = false) => {
      if (force) {
        dispatch(fetchRescuerBadges(quietMode, activeUser.id));
        return;
      }

      dispatch(fetchRescuerBadgesIfNeeded(quietMode, activeUser.id));
    },
    [activeUser.id, dispatch]
  );

  const fetchBadgesTab = useCallback(
    (quietMode = false, force = false) => {
      if (force) {
        return dispatch(fetchBadges(quietMode));
      }

      return dispatch(fetchBadgesIfNeeded(quietMode));
    },
    [activeUser.id, dispatch]
  );

  const fetchEnviromentImpact = useCallback(
    (quietMode = false) => dispatch(fetchRescuerEnvironmentImpact(activeUser.id, quietMode)),
    [activeUser.id, dispatch]
  );

  return {
    fetchMyEvents: fetchMyEvents,
    fetchMyFrusTab: fetchMyFrusTab,
    fetchScheduleTab: fetchScheduleTab,
    fetchScheduleTabPaginated: fetchScheduleTabPaginated,
    fetchEventsTab: fetchEventsTab,
    fetchBadgesTab: fetchBadgesTab,
    fetchMyBadges: fetchMyBadges,
    fetchGoals: fetchGoals,
    fetchEnviromentImpact: fetchEnviromentImpact,
  };
}
