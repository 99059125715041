import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MuiThemeProvider,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { mainTheme } from '../assets/theme';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import { store } from '../store';

export const generateKeyId = label => `confirmation-dialog-btn-${label.toLocaleLowerCase().replace(/[\s_]/g, '_')}`;
const RescuerSitesSwitchDialog = props => {
  const { closeDialog, buttons, title, message, sites = [], dialogProps = {}, secondStep = {}, thirdStep = {}, upcomingRescues = [] } = props;
  const [currentStep, setCurrentStep] = useState({ title, message, buttons });
  const [secondStepVisible, setSecondStepVisible] = useState(false);
  const [thirdStepVisible, setThirdStepVisible] = useState(false);
  const [selectedSites, setSelectedSites] = useState([]);

  // Filter rescuers to unclaim depending on selected sites
  const rescuesToUnclaim = !thirdStep.display
    ? upcomingRescues
    : upcomingRescues.filter((rescue) => selectedSites.some((site) => site.id === rescue.site_id));

  const handleButtonClick = onClick => {
    // Check if second step is required and not yet activated
    if (onClick && secondStep.display && !secondStepVisible && !thirdStepVisible) {
      setCurrentStep({title: secondStep.title || title, message: secondStep.message, buttons: secondStep.buttons || buttons })
      return setSecondStepVisible(true);
    }

    // Check if third step is required and not yet activated
    if (onClick && thirdStep.display && secondStepVisible && rescuesToUnclaim.length) {
      setCurrentStep({title: thirdStep.title || title, message: thirdStep.message, buttons: thirdStep.buttons || buttons  })
      setSecondStepVisible(false);
      return setThirdStepVisible(true);
    }

    if (onClick) {
      onClick(selectedSites);
    }
    closeDialog();
  };

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={mainTheme}>
        <Dialog open aria-labelledby="draggable-dialog-title" {...dialogProps}>
          {currentStep.title && <DialogTitle data-testid="confirmation-dialog-title">{currentStep.title}</DialogTitle>}
          <DialogContent>
            {currentStep.message && (
              <DialogContentText data-testid="confirmation-dialog-text">{currentStep.message}</DialogContentText>
            )}

            {secondStepVisible && (
              <FormControl fullWidth style={{ marginTop: 15 }}>
                <FormGroup>
                  {sites.map((site) => (
                    <FormControlLabel
                      key={site.id}
                      control={
                        <Checkbox
                          onChange={() => {
                            setSelectedSites((prev) => {
                              const selected = prev.find((s) => s.id === site.id);
                              if (selected) {
                                return prev.filter((s) => s.id !== site.id);
                              }
                              return [...prev, site];
                            })
                          }
                          }
                          value={site.id}
                          style={{ paddingTop: 2.5, paddingBottom: 2.5 }}
                        />
                      }
                      label={site.name}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            )}

            {((secondStepVisible && rescuesToUnclaim.length && !thirdStep.display) ||
              (thirdStepVisible && rescuesToUnclaim.length)) && (
              <Box>
                <Typography variant="h6">{secondStep.tableTitle || 'Upcoming Rescues'}</Typography>
                <BaseMUIDataTable
                  tableId="#rescues/confirmationDialogTable"
                  data={rescuesToUnclaim}
                  columns={[
                    {
                      name: 'date',
                      label: 'Date',
                      options: {
                        forceDisplay: true,
                        customBodyRender: (value) => moment(value, 'YYYYMMDD').format('MM/DD/YYYY'),
                      },
                    },
                    {
                      name: 'pickup_begin',
                      label: 'Pickup Begin',
                      options: {
                        forceDisplay: true,
                        customBodyRender: (value) => value || '-',
                      },
                    },
                    {
                      name: 'pickup_end',
                      label: 'Pickup End',
                      options: {
                        forceDisplay: true,
                        customBodyRender: (value) => value || '-',
                      },
                    },
                    {
                      name: 'location',
                      label: 'Food Donor',
                      options: {
                        forceDisplay: true,
                        customBodyRender: (value) => value || '-',
                      },
                    },
                    {
                      name: 'receiver',
                      label: 'Receiving Agency',
                      options: {
                        forceDisplay: true,
                        customBodyRender: (value) => value || '-',
                      },
                    },
                  ]}
                  options={{
                    sortOrder: {
                      name: 'date',
                      direction: 'asc',
                    },
                    sort: false,
                    filter: false,
                    download: false,
                    search: false,
                    viewColumns: false,
                    pagination: false,
                    elevation: 0,
                    selectableRows: 'none',
                    responsive: 'simple',
                  }}
                />
              </Box>
            )}
          </DialogContent>

          <DialogActions>
            {Array.isArray(currentStep.buttons) &&
              currentStep.buttons.length > 0 &&
              currentStep.buttons.map((button) => {
                const { label, onClick, requireSiteSelection = false, ...rest } = button;
                return (
                  <Button
                    type="button"
                    variant={button?.variant || 'contained'}
                    key={generateKeyId(label)}
                    data-testid={generateKeyId(label)}
                    onClick={() => handleButtonClick(onClick)}
                    disabled={requireSiteSelection && !selectedSites.length}
                    {...rest}
                  >
                    {label}
                  </Button>
                );
              })}

            {!buttons && (
              <Button autoFocus onClick={() => closeDialog()} color="primary" data-testid={generateKeyId('Cancel')}>
                Cancel
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    </Provider>
  );
};

RescuerSitesSwitchDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  buttons: PropTypes.array,
  dialogProps: PropTypes.object,
};

export default RescuerSitesSwitchDialog;
