import Bluebird from 'bluebird';
import * as rescuesApi from '../api/rescues';
import * as rescuersApi from '../api/rescuers';
import * as pickupSpecsActions from '../actions/pickupSpecs';
import * as rescuesModel from '../models/rescues';
import * as pastRescuesModel from '../models/pastRescue';
import { shouldFetch } from '../helpers/cache';
import { receiveSiteRescue } from './sites';

export const RECEIVE_RESCUE = 'RECEIVE_RESCUE';
export const REQUEST_RESCUES = 'REQUEST_RESCUES';
export const RECEIVE_RESCUES = 'RECEIVE_RESCUES';
export const MODIFY_RESCUE = 'MODIFY_RESCUE';
export const RESET_RESCUE = 'RESET_RESCUE';
export const RECEIVE_RESCUE_POST = 'RECEIVE_RESCUE_POST';
export const REQUEST_RESCUER_AVAILABLE_RESCUES = 'REQUEST_RESCUER_AVAILABLE_RESCUES';
export const RECEIVE_RESCUER_AVAILABLE_RESCUES = 'RECEIVE_RESCUER_AVAILABLE_RESCUES';
export const ADD_RESCUER_AVAILABLE_RESCUES = 'ADD_RESCUER_AVAILABLE_RESCUES';
export const REMOVE_RESCUER_AVAILABLE_RESCUE = 'REMOVE_RESCUER_AVAILABLE_RESCUE';
export const ADD_RESCUER_CLAIMED_AND_ADOPTED_RESCUE = 'ADD_RESCUER_CLAIMED_AND_ADOPTED_RESCUE';
export const REMOVE_RESCUER_CLAIMED_AND_ADOPTED_RESCUES_REGEX = 'REMOVE_RESCUER_CLAIMED_AND_ADOPTED_RESCUES_REGEX';
export const REQUEST_RESCUER_CLAIMED_AND_ADOPTED_RESCUES = 'REQUEST_RESCUER_CLAIMED_AND_ADOPTED_RESCUES';
export const RECEIVE_RESCUER_CLAIMED_AND_ADOPTED_RESCUES = 'RECEIVE_RESCUER_CLAIMED_AND_ADOPTED_RESCUES';
export const REQUEST_RESCUER_PAST_RESCUES = 'REQUEST_RESCUER_PAST_RESCUES';
export const RECEIVE_RESCUER_PAST_RESCUES = 'RECEIVE_RESCUER_PAST_RESCUES';
export const INVALIDATE_RESCUES_DATA = 'INVALIDATE_RESCUER_DASHBOARD';

export const requestRescues = () => ({
  type: REQUEST_RESCUES,
});

export const requestRescuerClaimedAndAdoptedRescues = rescuerId => ({
  type: REQUEST_RESCUER_CLAIMED_AND_ADOPTED_RESCUES,
  rescuerId: rescuerId,
});

export const invalidateRescuesData = () => ({
  type: INVALIDATE_RESCUES_DATA,
});

export const requestRescuerPastRescues = rescuerId => ({
  type: REQUEST_RESCUER_PAST_RESCUES,
  rescuerId: rescuerId,
});

export const receiveRescues = json => {
  return {
    type: RECEIVE_RESCUES,
    rescues: json.data,
    receivedAt: Date.now(),
  };
};

export const receiveRescue = json => ({
  type: RECEIVE_RESCUE,
  payload: {
    rescue: json.data,
    receivedAt: Date.now(),
  },
});

export const receiveRescuePost = json => {
  return {
    type: RECEIVE_RESCUE_POST,
    rescue: json.rescue,
    receivedAt: Date.now(),
  };
};

export const diff = (o1, o2) =>
  Object.keys(o2).reduce((diff, key) => {
    if (!(key in o1)) {
      return {
        ...diff,
        [key]: o2[key],
      };
    }
    if (o1[key] === o2[key]) return diff;
    return {
      ...diff,
      [key]: o2[key],
    };
  }, {});

export const fetchRescues = (
  from = '20190501',
  to = '20190530',
  site_id = null,
  umbrellaFoodDonorId = null
) => dispatch => {
  dispatch(requestRescues());

  return Bluebird
    .try(() => rescuesApi.getRescues(from, to, site_id, undefined, undefined, umbrellaFoodDonorId))
    .then(response => response.json())
    .then(json => {
      dispatch(receiveRescues(json));
    });
};

export const fetchScheduleRescues = (from, to, rescuer_id, sites_ids, quietMode) => dispatch => {
  if (!quietMode) {
    dispatch(requestRescuerAvailableRescues(rescuer_id));
  }

  return Bluebird.try(() => rescuesApi.getRescues(from, to, sites_ids, undefined, false))
    .then(res => res.json())
    .then(res => dispatch(receiveRescuerAvailableRescues(rescuer_id, res)));
};

export const fetchScheduleRescuesPaginated = ({
  from: from,
  page: page,
  limit: limit,
  rescuerId: rescuer_id,
  sitesIds: sites_ids,
  quietMode: quietMode,
  clearPreviousData: clearPreviousData,
}) => dispatch => {
  if (!quietMode) {
    dispatch(requestRescuerAvailableRescues(rescuer_id));
  }

  return Bluebird.try(() => rescuesApi.getRescuesPaginated(from, page, limit, sites_ids))
    .then(res => res.json())
    .then(res => dispatch(receiveRescuerAvailableRescues(rescuer_id, res, clearPreviousData)));
};

export const fetchScheduleRescuesPaginatedIfNeeded = ({
  from: from,
  page: page,
  limit: limit,
  rescuer_id: rescuer_id,
  sites_ids: sites_ids,
  quietMode: quietMode,
  clearPreviousData: clearPreviousData,
}) => (dispatch, getState) => {
  if (shouldFetchRescuerAvailableRescues(rescuer_id, getState())) {
    dispatch(
      fetchScheduleRescuesPaginated({
        from: from,
        page: page,
        limit: limit,
        rescuerId: rescuer_id,
        sitesIds: sites_ids,
        quietMode: quietMode,
        clearPreviousData: clearPreviousData,
      })
    );
  }

  return null;
};

export const fetchScheduleRescuesIfNeeded = (from, to, rescuer_id, sites_ids) => (dispatch, getState) => {
  if (shouldFetchRescuerAvailableRescues(rescuer_id, getState())) {
    dispatch(fetchScheduleRescues(from, to, rescuer_id, sites_ids));
  }

  return null;
};

export const fetchRescue = id => dispatch => {
  return Bluebird.try(() => rescuesApi.getRescue(id))
    .then(response => response.json())
    .then(json => dispatch(receiveRescue(json)));
};

export const fetchRescuesIfNeeded = (from = '20190501', to = '20190530', site_id = null) => dispatch => {
  return dispatch(fetchRescues(from, to, site_id));
};

export const fetchRescueIfNeeded = id => dispatch => {
  return dispatch(fetchRescue(id));
};

export const requestRescuerAvailableRescues = rescuerId => ({
  type: REQUEST_RESCUER_AVAILABLE_RESCUES,
  rescuerId: rescuerId,
});

export const receiveRescuerAvailableRescues = (rescuerId, json, clearPreviousData) => ({
  type: RECEIVE_RESCUER_AVAILABLE_RESCUES,
  rescues: json.data,
  pagination: json.pagination,
  rescuerId: rescuerId,
  receivedAt: Date.now(),
  clearPreviousData: clearPreviousData,
});

export const receiveRescuerClaimedAndAdoptedRescues = (rescuerId, json) => ({
  type: RECEIVE_RESCUER_CLAIMED_AND_ADOPTED_RESCUES,
  rescues: json.data,
  rescuerId: rescuerId,
  receivedAt: Date.now(),
});

export const removeRescuerClaimedAndAdoptedRescuesREGEX = (rescuerId, regex) => ({
  type: REMOVE_RESCUER_CLAIMED_AND_ADOPTED_RESCUES_REGEX,
  rescuerId: rescuerId,
  regex: regex,
});

export const addRescuerClaimedAndAdoptedRescue = (rescuerId, rescueId) => ({
  type: ADD_RESCUER_CLAIMED_AND_ADOPTED_RESCUE,
  rescueId: rescueId,
  rescuerId: rescuerId,
});

export const removeAvailableRescue = (rescuerId, rescueId) => ({
  type: REMOVE_RESCUER_AVAILABLE_RESCUE,
  rescuerId: rescuerId,
  rescueId: rescueId,
});

export const addAvailableRescues = (rescuerId, rescuesIds) => ({
  type: ADD_RESCUER_AVAILABLE_RESCUES,
  rescuesIds: rescuesIds,
  rescuerId: rescuerId,
});

export const receiveRescuerPastRescues = (rescuerId, json) => ({
  type: RECEIVE_RESCUER_PAST_RESCUES,
  rescues: json.data,
  rescuerId: rescuerId,
  receivedAt: Date.now(),
});

export const fetchRescuerAvailableRescues = (
  rescuer_id,
  rescueId,
  from,
  to,
  quietMode,
  nextRescuesDates,
  sortBy
) => dispatch => {
  if (!quietMode) {
    dispatch(requestRescuerAvailableRescues(rescuer_id));
  }

  return Bluebird.try(() => rescuersApi.getAvailableRescues(rescuer_id, rescueId, from, to, nextRescuesDates, sortBy))
    .then(res => res.json())
    .then(res =>
      dispatch(
        receiveRescuerAvailableRescues(rescuer_id, {
          ...res,
          data: rescueId ? [res.data] : res.data,
        })
      )
    );
};

export const fetchRescuerClaimedAndAdoptedRescues = (rescuerId, from, to, quietMode, queryParams) => dispatch => {
  if (!quietMode) {
    dispatch(requestRescuerClaimedAndAdoptedRescues(rescuerId));
  }

  return Bluebird.try(() => rescuersApi.getClaimedAndAdoptedRescues(rescuerId, from, to, queryParams))
    .then(response => response.json())
    .then(json => dispatch(receiveRescuerClaimedAndAdoptedRescues(rescuerId, json)));
};

export const fetchRescuerClaimedAndAdoptedRescuesV2 = (rescuerId, from, to, quietMode, queryParams) => dispatch => {
  if (!quietMode) {
    dispatch(requestRescuerClaimedAndAdoptedRescues(rescuerId));
  }

  return Bluebird.try(() => rescuersApi.getClaimedAndAdoptedRescuesV2(rescuerId, from, to, queryParams))
    .then(response => response.json())
    .then(json => dispatch(receiveRescuerClaimedAndAdoptedRescues(rescuerId, json)));
};

export const fetchRescuerPastRescues = (rescuerId, quietMode) => dispatch => {
  if (!quietMode) {
    dispatch(requestRescuerPastRescues(rescuerId));
  }

  return Bluebird.try(() => rescuersApi.getPastRescues(rescuerId))
    .then(response => response.json())
    .then(json => dispatch(receiveRescuerPastRescues(rescuerId, json)));
};

const shouldFetchRescuerAvailableRescues = (rescuerId, state) => {
  const availableIdsData = state.entities.rescues.availableIds[rescuerId];

  if (!availableIdsData) {
    return true;
  }

  if (availableIdsData.data.length === 0 && !availableIdsData.lastUpdated) {
    return true;
  }

  return shouldFetch(availableIdsData.lastUpdated, { minutes: 10 });
};

export const fetchRescuerAvailableRescuesIfNeeded = (
  rescuerId = null,
  rescueId,
  from,
  to,
  quietMode,
  nextRescuesDates,
  sortBy
) => (dispatch, getState) => {
  if (shouldFetchRescuerAvailableRescues(rescuerId, getState())) {
    dispatch(fetchRescuerAvailableRescues(rescuerId, rescueId, from, to, quietMode, nextRescuesDates, sortBy));
  }

  return null;
};

const shouldFetchClaimedAndAdopterRescues = (rescuerId, state) => {
  const claimedAdoptedData = state.entities.rescues.claimedAndAdoptedIds[rescuerId];

  if (!claimedAdoptedData) {
    return true;
  }

  if (claimedAdoptedData.inflight) {
    return false;
  }

  if (!claimedAdoptedData.data.length === 0 && !claimedAdoptedData.lastUpdated) {
    return true;
  }

  return shouldFetch(claimedAdoptedData.lastUpdated, { minutes: 10 });
};

export const fetchRescuerClaimedAndAdoptedRescuesIfNeeded = (rescuerId = null, from, to, quietMode, queryParams) => (
  dispatch,
  getState
) => {
  if (shouldFetchClaimedAndAdopterRescues(rescuerId, getState())) {
    return dispatch(fetchRescuerClaimedAndAdoptedRescues(rescuerId, from, to, quietMode, queryParams));
  }
  return null;
};

export const fetchRescuerClaimedAndAdoptedRescuesV2IfNeeded = (rescuerId = null, from, to, quietMode, queryParams) => (
  dispatch,
  getState
) => {
  if (shouldFetchClaimedAndAdopterRescues(rescuerId, getState())) {
    return dispatch(fetchRescuerClaimedAndAdoptedRescuesV2(rescuerId, from, to, quietMode, queryParams));
  }
  return null;
};

const shouldFetchRescuerPastRescues = (rescuerId, state) => {
  const pastRescues = state.entities.rescues.pastIds[rescuerId];

  if (!pastRescues) {
    return true;
  }

  if (pastRescues.inflight) {
    return false;
  }

  if (pastRescues.data.length === 0 && !pastRescues.lastUpdated) {
    return true;
  }

  return shouldFetch(pastRescues.lastUpdated, { minutes: 10 });
};

export const fetchRescuerPastRescuesIfNeeded = (rescuerId = null, quietMode) => (dispatch, getState) => {
  if (shouldFetchRescuerPastRescues(rescuerId, getState())) {
    return dispatch(fetchRescuerPastRescues(rescuerId, quietMode));
  }
  return null;
};

export const unclaimRescue = rescue => dispatch => {
  return Bluebird.try(() => rescuesApi.updateRescue(rescue.id, { rescuer_id: null }))
    .then(response => response.json())
    .then(json => dispatch(receiveRescuePost(json)));
};

export const unAdoptRescue = rescue => dispatch => {
  return Bluebird.try(() => dispatch(pickupSpecsActions.adoptPickupSpec(rescue.pickup_spec_id, { adopter_id: null })));
};

export const claimRescue = (rescue, userId) => dispatch =>
  Bluebird.try(() => rescuesApi.updateRescue(rescue.id, { rescuer_id: userId }))
    .then(response => response.json())
    .then(json => dispatch(receiveRescuePost(json)));

export const releaseRescue = (userId, rescue) => dispatch =>
  Bluebird.try(() => rescuesApi.updateRescue(rescue.id, { released_by_id: userId }))
    .then(response => response.json())
    .then(json => dispatch(receiveRescuePost(json)));

export const closeRescue = (rescueId, rescue) => dispatch =>
  Bluebird.try(() => rescuesApi.updateRescue(rescueId, rescue))
    .then(response => response.json())
    .then(json => dispatch(receiveRescuePost(json)));

export const updateRescue = (rescueId, rescue) => dispatch =>
  Bluebird.try(() => rescuesApi.updateRescueNew(rescueId, rescuesModel.mapFormToUpdateRescue(rescue)))
    .then(response => response.json())
    .then(json => dispatch(receiveRescuePost(json)));

export const updateRescues = (rescues, fetchRescues) => () =>
  Bluebird.try(() => rescuesApi.updateRescues(rescuesModel.mapFormToUpdateRescues(rescues)))
    .then(() => fetchRescues());

export const updatePastRescue = (rescueId, rescue) => dispatch =>
  Bluebird.try(() => rescuesApi.updateRescueNew(rescueId, pastRescuesModel.mapFormPastRescuePUT(rescue)))
    .then(response => response.json())
    .then(json => {
      dispatch(receiveRescuePost(json));
      dispatch(receiveSiteRescue(json.rescue));
      return json.rescue;
    });
