import React from 'react';
import BaseMUIDataTable from '../../../../../components/BaseMUIDataTable/BaseMUIDataTable';
import { formatDate, formatTime } from '../../../../../helpers/formatters';
import { sortByDateAndTime } from '../../../../../helpers/sorters';

const RescuerEventsDesktopTable = ({ eventsList, tableId, onRowClick, customToolbar = () => null, ...props }) => {
  const activeEvents = eventsList.filter((event) => !event.cancelled_at);

  return (
    <BaseMUIDataTable
      {...props}
      tableId={tableId}
      title=""
      data={activeEvents}
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            filter: false,
            display: false,
          },
        },
        {
          name: 'name',
          label: 'Name',
          options: {
            filter: true,
            display: true,
          },
        },
        {
          name: 'date',
          label: 'Date',
          options: {
            sortCompare: order => (row1, row2) => sortByDateAndTime(order, row1.data?.value, row2.data?.value),
            customBodyRender: value => value ? formatDate(value?.value) : '-',
            filter: true,
            display: true,
          },
        },
        {
          name: 'begin',
          label: 'Start time',
          options: {
            customBodyRender: value => formatTime(value.value),
            filter: true,
            display: true,
          },
        },
        {
          name: 'end',
          label: 'End time',
          options: {
            customBodyRender: value => formatTime(value.value),
            filter: true,
            display: true,
          },
        },
      ]}
      options={{
        sortOrder: {
          name: 'date',
          direction: 'asc',
        },
        onRowClick: rowData => {
          if (onRowClick) {
            onRowClick(rowData[0]);
          }
        },
        elevation: 0,
        customToolbar: customToolbar,
        responsive: 'simple',
        selectableRows: 'none',
      }}
    />
  );
};

export default RescuerEventsDesktopTable;

